import { adminAxiosInstance } from "../../config/axiosConfig";

export const getActivities = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/activities`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}