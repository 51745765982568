import { useState, useEffect } from "react"
import { Navigate, Routes, useNavigate } from "react-router-dom";

import { tokenAdminJwt } from "../../../constants";
import { localVariables } from "../../local/SecureLocalStorageVariable";
import { convertFromStringToDate } from "../../conversion/stringConversion";
import { useJwt } from "react-jwt";

export const AdminPrivateRoutes = ({ children }) => {
    const _token = localVariables.getItem(tokenAdminJwt);
    const { decodedToken, isExpired } = useJwt(_token);

    if (!isExpired) {
        return children
    } else {
        return <Navigate to="/login" />
    }
}