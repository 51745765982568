import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog } from 'primereact/dialog';

export const DeleteSTechnologiesDialog = ({ display, setDisplay, handleDeleteSelectedTechnology }) => {
    const [position, setPosition] = useState('center');

    const onClick = (position) => {
        setDisplay(true)
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        setDisplay(false)
    }

    useEffect(() => {
        if (display) {
            onClick()
        } else {
            onHide()
        }
    }, [display]);


    return (
        <>
            <Dialog header="Confirmation" visible={display} modal style={{ width: '32rem' }} onHide={() => onHide('displayMaximizable')} position="center">
                <div className="confirmation-content text-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-danger" style={{ fontSize: '2rem' }} />
                        <div>
                            Etes-vous sûr que vous voulez supprimer le(s) technologies selectionnée(s)?
                        </div>
                </div>
                    <div className="modal-footer pt-3 d-flex justify-content-between">
                        <button type="button" className="btn btn-outline-primary my-0">Nom</button>
                        <button type="submit" className="btn btn-danger my-0" onClick={() => handleDeleteSelectedTechnology()}>Oui</button>
                    </div>
            </Dialog>
        </>
    )
}


export const DeleteTechnologyDialog = ({ display, setDisplay, technology, handleDeletetechnology }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState('center');

    const onClick = (position) => {
        setDisplay(true)
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        setDisplay(false)
    }

    useEffect(() => {
        if (display) {
            onClick()
        } else {
            onHide()
        }
    }, [display]);


    return (
        <>
            <Dialog header="Confirmation" visible={display} modal style={{ width: '32rem' }} onHide={() => onHide('displayMaximizable')} position="center">
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {technology && (
                        <span>
                            Etes-vous sûr que vous voulez supprimer l'opérateur <b>{technology.name}</b>?
                        </span>
                    )}
                </div>
                    <div className="modal-footer pt-3 d-flex justify-content-between">
                        <button type="button" className="btn btn-outline-primary my-0">Non</button>
                        <button type="submit" className="btn btn-danger my-0" onClick={() => handleDeletetechnology()}>Oui</button>
                    </div>
            </Dialog>
        </>
    )
}