import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { baseFileUrl } from "../../../../../constants";
import { srtSubDescription } from "../../../../../services/tools/convertions";
import { strDate } from "../../../../../services/conversion/stringConversion";
import ReactToPrint from "react-to-print";


export const AdminNetworkSignageVIewDialog = ({ display, setDisplay, signage }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);
    const [giveAnswer, setGiveAnswer] = useState(false);

    const pbRef = useRef();




    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    const footer = <div>
        <div className="modal-footer pt-3 d-flex justify-content-between">
            <button type="button" className="btn btn-dark my-0" onClick={() => onHide()}>Fermer</button>
            {/* <button type="submit" className="btn btn-primary my-0" ><i className="fa fa-save mr-2"></i>Répondre</button> */}
        </div>
    </div>


    return (<>
        <Dialog
            header="TRAITEMENT DE SIGNALISATION"
            visible={display}
            modal
            style={{ width: "75vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
            footer={footer}
        >
            <div className="mb-2">
                <ReactToPrint
                    trigger={() => <button className="btn btn-dark mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                    content={() => pbRef.current}
                    // onBeforePrint={()=> setEnablePrint(true)}
                    pageStyle={`@media print { @page { size: 300mm 400mm;}}`}
                />
            </div>
            <div className="row " >
                <div className="col-md-12">
                    <div className="border p-2" ref={pbRef}>
                        <div className="d-flex justify-content-between p-1">
                            <div>
                                <h4>Type : <b>Problème avec un réseau</b></h4>
                                <h5>Code : <b>{signage?.code}</b></h5>
                                <h5>Localité : <b>{signage?.locality.name}</b></h5>
                            </div>
                            <div>
                                <div>Date de soumission :{strDate(signage?.created_at)}</div>
                                <div className="bg-light">
                                    <b>De :</b>
                                    <div className="pl-3">
                                        Téléphone : {signage?.phone}<br />
                                        Email : {signage?.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="border">
                            <div className="row p-2 bg-light">
                                <div className="col-md-4">
                                    <div className="text-center">Opérateur concerné</div>
                                    <div className="text-center">
                                        <img src={baseFileUrl + signage?.operator.image_path} height={100} alt="" />
                                        <h6> {signage?.operator.name} </h6>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    {signage?.problems && signage?.problems?.length > 0 ? <>
                                        Prroblèmes détecté(s):
                                        {signage?.problems && signage?.problems.map((problem, index) => {
                                            return (<div key={"pbn-" + index}>
                                                - {problem?.title}
                                            </div>)
                                        })}
                                    </> : <></>}
                                    <div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border mt-2 p-2 bg-light">

                            <h5><b>Commentaire :</b></h5>
                            <div className="pl-3">
                                {signage?.comment?.length > 0 ? <>
                                    {signage?.comment}
                                </> : <>
                                    Aucun commentaire !
                                </>}
                            </div>
                        </div>
                    </div>
                    <div>
                        {giveAnswer ? <>
                            <div class="mt-3">
                                <hr />
                                <h5>Rédiger une réponse:</h5>
                                <div role="alert" className="row alert alert-info alert-dismissible fade show p-2">
                                    <div className="col-md-8">
                                        <textarea className="form-control" placeholder="Entrer une réponse" rows={8}></textarea>
                                    </div>
                                    <div className="col-md-4">

                                        <div className="form-group">
                                            <label htmlFor="" className="form-label">Joindre un fichier  <span className="text-warning">(.png,.jpg,.jpeg,.svg,.pdf)</span> </label>
                                            <input type="file" className="form-control" />
                                        </div>
                                        {signage?.phone && <>
                                            <div class="form-group mt-2 pl-4">
                                                <input type="checkbox" class="form-check-input" />
                                                <label class="form-check-label" >Notifier par téléphone </label>
                                            </div>
                                        </>}
                                        {signage?.email && <>
                                            <div class="form-group mt-2 pl-4">
                                                <input type="checkbox" class="form-check-input" />
                                                <label class="form-check-label" >Notifier par email </label>
                                            </div>
                                        </>}
                                    </div>
                                    <div className="col-md-12 ">
                                        <div className="d-flex justify-content-between mt-2">
                                            <button className="btn btn-danger my-0" onClick={() => setGiveAnswer(false)} ><i className="fa fa-xmark mr-2"></i>Annuler la réponse</button>
                                            <button className="btn btn-primary my-0" onClick={() => setGiveAnswer(true)} ><i className="fa fa-send mr-2"></i>Envoyer une réponse</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <div className="mt-3 d-flex justify-content-center">
                                <button className="btn btn-primary my-0" onClick={() => setGiveAnswer(true)} ><i className="fa fa-send mr-2"></i>Envoyer une réponse</button>
                            </div>
                        </>}
                        <div>

                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}