import { adminAxiosInstance } from "../../../config/axiosConfig"



export const getAppSignages = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/app-signages`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addAppSignage = async (appSignages, setState) => {
    try {
        const {type,level,title, description } = appSignages

        const response = await adminAxiosInstance.post(`/app-signages`, {
            type: type,
            level: level,
            title: title,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            console.log(response.data)
            console.log("AppSignage enregistré :", response.data)
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addAppSignagesList = async (appSignages, setState) => {
    try {
        let isDone;
        appSignages.forEach(async dist => {
            const { name, description } = dist

            const response = await adminAxiosInstance.post(`/app-signages`, {
                name: name,
                description: description,
            }).then((res => res))

            if (response.status != 201) {
                return
            }

        });

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editAppSignage = async (appSignages, setState) => {
    try {
        const {type,level,title, description } = appSignages

        await adminAxiosInstance.put('/app-signages/' + appSignages.id, {
            type: type,
            level: level,
            title: title,
            description: description,
        }).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteAppSignage = async (appSignages, setState) => {
    try {

        await adminAxiosInstance.delete('/app-signages/' + appSignages.id).then((response) => {
            console.log("Donnée de =======++++>", response)
            setState(response.data);
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}