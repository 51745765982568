import { Link } from "react-router-dom"
import { OperatorMainPageContainer } from "../OperatorMainPageContainer"
import { AdminBufferCoverageList } from "../../../admin/admin/geoData/adminCoverage/AdminCoverageList"

import './operatoCoverage.css'
import { useEffect, useRef, useState } from "react"
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent"
import { addCoverage, getCoverageByOperator } from "../../../../services/api/admin/bufferCoverages/bufferCoverageApiService"
import { localVariables } from "../../../../services/local/SecureLocalStorageVariable"
import { getAllLocalityData, getLocalities, getLocalityById } from "../../../../services/api/admin/adminEntities/locality/localityApiService"
import { AdminCoverageLocalityList } from "../../../admin/admin/geoData/adminCoverage/AdminCoverageLocalityList"
import { getOperators } from "../../../../services/api/admin/operators/operatorApiService"
import { getTechnologies } from "../../../../services/api/admin/technologies/technologyApiService"
import { OperatorCoverageDialog } from "./OperatorCoverageDialog"
import { OperatorCoverageLocalityList } from "./OperatorCoverageLocalityList"
import { userOperator } from "../../../../constants"



export const OperatorCoveragePage = () => {

    const [openCoverageViewDialog, setOpenCoverageViewDialog] = useState(false);
    const [openCoverageDialog, setOpenCoverageDialog] = useState(false);
    const [openDeleteSelected, setopenDeleteSelected] = useState(false);
    const [bufferCoverages, setBufferCoverage] = useState(null);
    const [selectedCoverages, setSelectedCoverages] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [localityDialogTitle, setLocalityDialogTitle] = useState(
        "CREATION D'UNE MISE A JOUR"
    );


    const [createResponse, setCreateResponse] = useState();
    const [editResponse, setEditResponse] = useState();
    const [deleteResponse, setDeleteResponse] = useState();

    const [operators, setOperators] = useState();
    const [technologies, setTechnologies] = useState();
    const [localities, setLocalities] = useState(null);

    const [selectedLocalities, setSelectedLocalities] = useState(null);
    const [locality, setLocality] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const [user, setUser] = useState(localVariables.getItem(userOperator));


    useEffect(() => {
    }, []);

    useEffect(() => {
        getOperators(setOperators);
        getTechnologies(setTechnologies);
        getAllLocalityData(setLocalities);
        if (user) {
            getCoverageByOperator(setBufferCoverage, user?.operator?.id);
        }
    }, []);

    //EDITION
    const handleEditCoverage = (loc) => {
        setLocalityDialogTitle("MODIFICATION D'UNE MISE A JOUR");
        console.log("AZERTY", loc);
        setLocality({ ...loc });
        setOpenCoverageDialog(true);
    };

    //SUPPRESSION
    const handleDeleteCoverageDialog = (loc) => {
        setLocality({ ...loc });
        setOpenDeleteDialog(true);
    };

    //VISUALISATION
    const handleViewCoverage = (loc) => {
        setLocality({ ...loc });
        setOpenCoverageViewDialog(true);
    };

    const handleEditLocality = async (l) => {
        setOpenCoverageDialog(true)
        setLocality(l)
    }


    const handleViewLocality = () => {
    }

    const handleSubmitCoverage = async (e) => {
        e.preventDefault();
        await addCoverage(locality, setCreateResponse);
    };

    //INITIALISATION
    const handleInitVar = () => {
        setLocality();
        setCreateResponse();
        setEditResponse();
        setDeleteResponse();
        setLocalityDialogTitle("CREATION D'UNE MISE A JOUR");
        setSelectedCoverages();
        setOpenCoverageDialog(false);
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _locality = { ...locality };

        _locality[`${name}`] = val;

        setLocality(_locality);
    };

    const onInputCheckChange = (e, name) => {
        const val = (e.target && e.target.checked) || "";
        let _locality = { ...locality };

        _locality[`${name}`] = val;

        setLocality(_locality);
    };




    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const cols = [
        { field: "code", header: "Code" },
        { field: "name", header: "Nom" },
        { field: "nb_locs", header: "Localités" },
        { field: "nb_locs_cov", header: "Localité couvertes" },
        { field: "nb_locs_not_cov", header: "Localités non couvertes" },
        { field: "nb_pop", header: "Population" },
        { field: "nb_cov_pop", header: "Population couverte" },
        { field: "nb_pop_not_cov", header: "Population non couverte" },
        { field: "description", header: "Description" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default({
                    format: "a4",
                    orientation: "landscape",
                    unit: "cm",
                    compressPdf: true,
                });

                doc.autoTable(exportColumns, bufferCoverages, {
                    headStyles: { fillColor: [255, 140, 0] },
                });
                doc.save("artci-cartodonnees-extract-loc-optical.pdf");
            });
        });
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(bufferCoverages);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-bufferCoverages");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
                );
            }
        });
    };

    return (<>
        <OperatorMainPageContainer
            user={user}
            title={"GESTION DES DONNEES DE COUVERTURE"}
            children={
                <>
                    <div className="container-fluid">
                        <div className="d-flex justify-content-between">
                            <div>
                                <Link to="/operator-dashboard" className="btn btn-dark" >  <h6><i className="fa fa-home mr-2"></i>Tableau de bord</h6> </Link>
                            </div>
                            <div role="alert" class=" alert alert-info alert-dismissible fade show p-1">
                                <div>
                                    <small className="">
                                        La gestion des données de couverture est un pilier essentiel de l'analyse géospatiale moderne, offrant des perspectives significatives pour la compréhension et la gestion de notre monde en constante évolution. <br />
                                        Modifer, Créer des demande de mise à jours, consulter vos données de couverture.
                                    </small>
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="bg-">
                                <nav className=" mt-3">
                                    <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                        <button
                                            className="nav-link active"
                                            id="nav-control-tab"
                                            data-toggle="tab"
                                            data-target="#nav-control"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-control"
                                            aria-selected="true"
                                        >
                                            Données de couverture des localités
                                        </button>
                                        <button
                                            className="nav-link"
                                            id="nav-history-tab"
                                            data-toggle="tab"
                                            data-target="#nav-history"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-history"
                                            aria-selected="false"
                                        >
                                            Historique des demandes de mise à jours
                                        </button>
                                    </div>
                                </nav>
                                <div className="tab-content pt-0 m-0" id="nav-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="nav-control"
                                        role="tabpanel"
                                        aria-labelledby="nav-control-tab"
                                    >
                                        <div className="row">
                                            <div>
                                                <input type="checkbox" class="check" id="ORANGE1" />
                                                <label id="lbor" class="impO" for="ORANGE1"></label>
                                            </div>
                                            <label for="ORANGE1" className="ml-2" >Montrer uniquement les localités couverte par Orange</label>
                                            </div>
                                            <div className="no-gutters">
                                                <div className="mb-3 card">
                                                    <div className="card-header-tab card-header">
                                                        <div className="card-header-title font-size-lg font-weight-normal">
                                                            <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                                                            Données de couverture des localités
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                    <div>
                                                        {localities?.length > 0 ? (
                                                            <OperatorCoverageLocalityList
                                                                localities={localities}
                                                                handleEditLocality={handleEditLocality}
                                                                setopenDeleteSelected={setopenDeleteSelected}
                                                                exportCSV={exportCSV}
                                                                exportPDF={exportPdf}
                                                                exportExcel={exportExcel}
                                                                dt={dt}
                                                                selectedLocalities={selectedLocalities}
                                                                setSelectedLocalities={setSelectedLocalities}
                                                                handleViewLocality={handleViewLocality}
                                                                technologies={technologies}
                                                                operator={user?.operator}
                                                            />
                                                        ) : (
                                                            <>
                                                                {localities != null ? <>
                                                                    <h5 className="text-center">Aucune donnnée disponible !</h5>
                                                                </> : <>
                                                                    <div className="container px-1 px-md-5 px-lg-1 mbody">
                                                                        <DataLoaderTailSpin />
                                                                    </div>
                                                                </>}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane"
                                        id="nav-history"
                                        role="tabpanel"
                                        aria-labelledby="nav-history-tab"
                                    >
                                        <div className="mb-3 card">
                                            <div className="card-header-tab card-header">
                                                <div className="card-header-title font-size-lg font-weight-normal">
                                                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                                                    Historique des mises à jours
                                                </div>
                                            </div>
                                            <div className="no-gutters">
                                                <div className="card w-100">
                                                    <div className="card-body">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <OperatorCoverageDialog
                        display={openCoverageDialog}
                        setDisplay={setOpenCoverageDialog}
                        handleSumitCoverage={handleSubmitCoverage}
                        locality={locality}
                        title={localityDialogTitle}
                        handleInitVar={handleInitVar}
                        onInputChange={onInputChange}
                        onInputCheckChange={onInputCheckChange}
                        setLocality={setLocality}
                        localities={localities}
                        technologies={technologies}
                        operator={user?.operator}
                    />
                </>
            }
        />
    </>)
}