import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminDistrictList } from "./AdminDistrictList";
import {
  DeleteDistrictDialog,
  DeleteDistrictsDialog,
  DeleteSDistrictsDialog,
} from "./AdminDistrictDeleteDialog";
import {
  addDistrict,
  deleteDistrict,
  editDistrict,
  getDistricts,
} from "../../../../../services/api/admin/adminEntities/districts/districtApiService";
import { AdminDistrictDialog } from "./AdminDistrictDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { AdminDistrictVIewDialog } from "./AdminDistrictViewDialog";
import { AdminDistrictExcelDialog } from "./AdminDistrictExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";

export const AdminDistrictPage = () => {
  const [openDistrictViewDialog, setOpenDistrictViewDialog] = useState(false);
  const [openDistrictDialog, setOpenDistrictDialog] = useState(false);
  const [openDistrictExcelDialog, setOpenDistrictExcelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [districts, setDistricts] = useState();
  const [district, setDistrict] = useState();

  const [selectedDistricts, setSelectedDistricts] = useState(null);

  const [districtDialogTitle, setDistrictDialogTitle] = useState(
    "CREATION D'UN DISTRICT"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getDistricts(setDistricts);
  }, []);

  useEffect(() => {
    console.log("Les districts :", district);
  }, [district]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de district effectué avec succès!");
      getDistricts(setDistricts);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du district !");
    }
    setOpenDistrictDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du district effectué avec succès!");
      getDistricts(setDistricts);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du district !");
    }
    setOpenDistrictDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du district effectué avec succès!");
      getDistricts(setDistricts);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du district !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setDistrict();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setDistrictDialogTitle("CREATION D'UN DISTRICT");
    setSelectedDistricts();
    setOpenDistrictDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _district = { ...district };

    _district[`${name}`] = val;

    setDistrict(_district);
  };

  const handleSubmitDistrict = async (e) => {
    e.preventDefault();

    if (district.name.trim()) {
      district?.description?.trim();
      if (district.id) {
        await editDistrict(district, setEditResponse);
      } else {
        await addDistrict(district, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewDistrict = (dist) => {
    setDistrict({ ...dist });
    setOpenDistrictViewDialog(true);
  };

  //EDITION
  const handleEditDistrict = (dist) => {
    setDistrictDialogTitle("MODIFICATION D'UN DISTRICT");
    console.log("AZERTY", dist);
    setDistrict({ ...dist });
    setOpenDistrictDialog(true);
  };

  //SUPPRESSION
  const handleDeleteDistrictDialog = (dist) => {
    setDistrict({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteDistrict = async () => {
    await deleteDistrict(district, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteDistricts = async () => {
    try {
      selectedDistricts.forEach(async (district) => {
        await deleteDistrict(district, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du districts selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, districts, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(districts);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-districts");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-draw-polygon"
                title="Gestion des districts"
                subTitle="Tableau de bord"
                description="Gestion des districts. "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des districts
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenDistrictDialog(true);
                        setDistrict();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un district
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {districts?.length > 0 ? (
                        <AdminDistrictList
                          districts={districts}
                          handleEditDistrict={handleEditDistrict}
                          handleDeleteDistrictDialog={
                            handleDeleteDistrictDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedDistricts={selectedDistricts}
                          setSelectedDistricts={setSelectedDistricts}
                          handleViewDistrict={handleViewDistrict}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminDistrictVIewDialog
              display={openDistrictViewDialog}
              setDisplay={setOpenDistrictViewDialog}
              district={district}
            />

            <AdminDistrictDialog
              display={openDistrictDialog}
              setDisplay={setOpenDistrictDialog}
              handleSumitDistrict={handleSubmitDistrict}
              district={district}
              title={districtDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setDistrict={setDistrict}
            />

            <DeleteDistrictDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletedistrict={handleDeleteDistrict}
              district={district}
            />
            <DeleteSDistrictsDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedDistrict={handleDeleteDistricts}
            />
          </>
        }
      />
    </>
  );
};
