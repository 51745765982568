import { useEffect, useRef, useState } from "react";
import { AdminMainPageContainer } from "../../AdminMainPageContainer"
import { Chart } from 'primereact/chart';
import { baseFileUrl, colors } from "../../../../constants";
import { DashboardBanner } from "../../../../components/layout/banner/DashboardBanner";
import { getGeneraleStatistics } from "../../../../services/api/map/statisticApiService";
import { handleNumThousand } from "../../../../services/tools/convertions";
import { getAdminTechnologies } from "../../../../services/api/user/technologies/technologyApiService";
import { getAdminOperators } from "../../../../services/api/user/operators/operatorApiService";
import { getDistritcsByGeoData } from "../../../../services/api/admin/adminEntities/districts/districtApiService";
import { getRRegionsByGeoData } from "../../../../services/api/admin/adminEntities/region/regionApiService";
import { getDDepartmentsByGeoData } from "../../../../services/api/admin/adminEntities/department/departmentApiService";
import { getSSubprefecturesByGeoData } from "../../../../services/api/admin/adminEntities/subPrefecture/subPrefectureApiService";
import ReactToPrint from "react-to-print";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";
import { StatisticsListComponent } from "../../../../components/modals/statistic/StatisticsListComponent";
import { StatisticsDetailsModalComponent } from "../../../../components/modals/statistic/StatisticsDetailsModalComponent";
import { getActivities } from "../../../../services/api/admin/activities/activitiesApiService";


export const AdminDashboardPage = () => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [operators, setOperators] = useState(null);
    const [technologies, setTechnologies] = useState(null);

    const [generaleData, setGeneraleData] = useState();
    const [activities, setActivities] = useState(null);

    const [chartLocalities, setChartLocalities] = useState({});
    const [chartOptionLocalities, setChartOptionLocalities] = useState({});

    const [data, setData] = useState(null);
    const [type, setType] = useState(0);
    const [displayStatisticsEntity, setDisplayStatisticsEntity] = useState(false);
    const [entity, setEntity] = useState(null);
    const contentRef = useRef();

    const [isLoading, setIsLoading] = useState(false);


    const optionDataForLocalities = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        title: {
            display: true,
            text: "Localités couvertes par technologie",
        },
        tooltips: {
            mode: "index",
            intersect: false,
        },
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    boxWidth: 10,
                    fontSize: 6,
                },
                position: "bottom",
            },
        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    font: {
                        size: 15,
                        weight: 500
                    },
                },
            },
            y: {
                ticks: {
                    display: true,
                    font: {
                        size: 10,
                    },
                },
            },
        },
    };

    const [chartPopulation, setchartPopulation] = useState({});
    const [chartOptionPopulation, setchartOptionPopulation] = useState({});

    const optionDataForPopulation = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        title: {
            display: true,
            text: "Population couvertes par technologie",
        },
        tooltips: {
            mode: "index",
            intersect: false,
        },
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    boxWidth: 10,
                    fontSize: 6,
                },
                position: "bottom",
            },
        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    font: {
                        size: 15,
                        weight: 500
                    },
                },
            },
            y: {
                ticks: {
                    display: true,
                    font: {
                        size: 10,
                    },
                },
            },
        },
    };


    useEffect(() => {
        handleGetGeneraleData();
        getAdminTechnologies(setTechnologies);
        getAdminOperators(setOperators)
        setChartOptionLocalities(optionDataForLocalities);
        setchartOptionPopulation(optionDataForPopulation)
        getActivities(setActivities)
    }, []);

    useEffect(() => {
        if (type == 0) {
            getDistritcsByGeoData(setData);
        } else if (type == 1) {
            getRRegionsByGeoData(setData);
        } else if (type == 2) {
            getDDepartmentsByGeoData(setData)
        } else if (type == 3) {
            getSSubprefecturesByGeoData(setData)
        }
    }, [type]);

    useEffect(() => {
        if (entity != null) {
            setDisplayStatisticsEntity(true);
        }
    }, [entity]);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        }

    }, [isLoading]);

    const handleGetGeneraleData = async () => {
        const _data = await getGeneraleStatistics();
        console.log('TEST ===>', _data);

        const _cDataLoc = {
            labels: ["2G", "3G", "4G"],
            datasets: [
                {
                    label: "Localités couvertes par Orange",
                    backgroundColor: colors.coverage.orangeData,
                    data: [_data?.locs_cov_2G_orange, _data?.locs_cov_3G_orange, _data.locs_cov_4G_orange],
                    borderWidth: 1,
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 20
                },
                {
                    label: "Localités couvertes par MTN",
                    backgroundColor: colors.coverage.mtnData,
                    data: [_data?.locs_cov_2G_mtn, _data?.locs_cov_3G_mtn, _data.locs_cov_4G_mtn],
                    borderWidth: 1,
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 20
                },
                {
                    label: "Localités couvertes par Moov",
                    backgroundColor: colors.coverage.moovData,
                    data: [_data?.locs_cov_2G_moov, _data?.locs_cov_3G_moov, _data.locs_cov_4G_moov],
                    borderWidth: 1,
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 20
                }
            ]
        }
        const _cDataPop = {
            labels: ["2G", "3G", "4G"],
            datasets: [
                {
                    label: "Population couverte par Orange",
                    backgroundColor: colors.coverage.orangeData,
                    data: [_data?.pop_cov_2G_orange, _data?.pop_cov_3G_orange, _data.pop_cov_4G_orange],
                    borderWidth: 1,
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 20
                },
                {
                    label: "Population couverte par MTN",
                    backgroundColor: colors.coverage.mtnData,
                    data: [_data?.pop_cov_2G_mtn, _data?.pop_cov_3G_mtn, _data.pop_cov_4G_mtn],
                    borderWidth: 1,
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 20
                },
                {
                    label: "Population couverte par Moov",
                    backgroundColor: colors.coverage.moovData,
                    data: [_data?.pop_cov_2G_moov, _data?.pop_cov_3G_moov, _data.pop_cov_4G_moov],
                    borderWidth: 1,
                    barPercentage: 0.5,
                    barThickness: 10,
                    maxBarThickness: 20
                }
            ]
        }

        setChartLocalities(_cDataLoc)
        setchartPopulation(_cDataPop)
        setGeneraleData(_data)
    }


    const handleSowTab = (type) => {
        setIsLoading(true)
        setType(type)
    }
    const handleSelectEntity = (type, data) => {
        setEntity({
            type: type,
            data: data
        });
    }


    return (
        <>
            <AdminMainPageContainer
                icon="fas fa-home"
                subTitle="Tableau de bord"
                description="Visualiser les statistiques sur l'observatoire des réseaux de téléommunication . "
                children={<>
                    <div role="alert" class="mt-2 mbg-3 alert alert-info alert-dismissible fade show">
                        <span class="pr-2"></span> Dernières notifications
                    </div>
                    <DashboardBanner generaleData={generaleData} />
                    <div className="row">
                        <div className="col-md-6 p-0 pr-1">
                            <div class="main-card mb-3 card">
                                <div class="rm-border card-header">
                                    <div>
                                        <h5 class="menu-header-title text-capitalize text-primary">Localités couvertes</h5>
                                    </div>
                                    <div class="btn-actions-pane-right actions-icon-btn dropdown">
                                        <button class="btn btn-dark" >
                                            <i className="fa fa-plus mr-2"></i>
                                            Voir plus
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <Chart type="bar" data={chartLocalities} options={chartOptionLocalities} />
                                    <div class="mt-3 row">
                                        <div className="col-md-12">
                                            <h6 className="menu-header-subtitle opacity-6">Localités couvertes par <b></b></h6>
                                        </div>
                                        <div class="col-md-12">
                                            {operators && operators?.map((operator, inndex) => {
                                                return (<>
                                                    <div class="card-border mb-0 border-light no-shadow card">
                                                        <div class="widget-content p-1">
                                                            <div class="widget-content-outer">
                                                                <div class="widget-content-wrapper w-100">
                                                                    <div class="row w-100">
                                                                        <div className="col-md-4 text-center">
                                                                            <img src={baseFileUrl + operator.image_path} className="" height={30} width={30} alt="" />
                                                                            <h6 className="menu-header-subtitle opacity-6"><b> {operator?.name} </b></h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="">
                                                                                <p><b>{handleNumThousand(generaleData?.['locs_cov_' + operator?.name.toLowerCase()])} </b></p>
                                                                                <p><b> {((generaleData?.['locs_cov_' + operator?.name.toLowerCase()] * 100) / generaleData?.locs).toFixed(2)} %</b></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0 pr-1 pl-1">
                            <div class="main-card mb-3 card">
                                <div class="rm-border card-header">
                                    <div>
                                        <h5 class="menu-header-title text-capitalize text-primary">Population couverte</h5>
                                    </div>
                                    <div class="btn-actions-pane-right actions-icon-btn dropdown">
                                        <button class="btn btn-dark" >
                                            <i className="fa fa-plus mr-2"></i>
                                            Voir plus
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <Chart type="bar" data={chartPopulation} options={chartOptionPopulation} barWidth={50} />
                                    <div class="mt-3 row">
                                        <div class="col-md-12">
                                            <div className="col-md-12">
                                                <h6 className="menu-header-subtitle opacity-6">Population couverte par <b></b></h6>
                                            </div>
                                            {operators && operators?.map((operator, inndex) => {
                                                return (<>
                                                    <div class="card-border mb-0 border-light no-shadow card">
                                                        <div class="widget-content p-1">
                                                            <div class="widget-content-outer">
                                                                <div class="widget-content-wrapper w-100">
                                                                    <div class="row w-100">
                                                                        <div className="col-md-4 text-center">
                                                                            <img src={baseFileUrl + operator.image_path} className="" height={30} width={30} alt="" />
                                                                            <h6 className="menu-header-subtitle opacity-6"><b> {operator?.name} </b></h6>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="">
                                                                                <p><b>{handleNumThousand(generaleData?.['pop_cov_' + operator?.name.toLowerCase()])} </b></p>
                                                                                <p><b> {((generaleData?.['pop_cov_' + operator?.name.toLowerCase()] * 100) / generaleData?.pop).toFixed(2)} %</b></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 pr-1 pl-1 mb-2">
                            <div class="card-hover-shadow-2x  card">
                                <div class="card-header">
                                    <div>STATISTIQUES PAR ENTITE ADMINISTRATIVE</div>
                                </div>
                                <div className="card-body">
                                    <div className="backdrop">
                                        <div>
                                            <nav className="">
                                                <div className="nav nav-tabs " id="nav-tab" role="tablist">
                                                    <button
                                                        className="nav-link active"
                                                        id="nav-general-tab"
                                                        data-toggle="tab"
                                                        data-target="#nav-general"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-general"
                                                        aria-selected="true"
                                                    >
                                                        Généralités
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-data-tab"
                                                        data-toggle="tab"
                                                        data-target="#nav-data"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-data"
                                                        aria-selected="false"
                                                        onClick={() => handleSowTab(0)}
                                                    >
                                                        Districts
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-data-tab"
                                                        data-toggle="tab"
                                                        data-target="#nav-data"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-data"
                                                        aria-selected="false"
                                                        onClick={() => handleSowTab(1)}
                                                    >
                                                        Régions
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-data-tab"
                                                        data-toggle="tab"
                                                        data-target="#nav-data"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-data"
                                                        aria-selected="false"
                                                        onClick={() => handleSowTab(2)}
                                                    >
                                                        Départements
                                                    </button>
                                                    <button
                                                        className="nav-link"
                                                        id="nav-sub-pref-tab"
                                                        data-toggle="tab"
                                                        data-target="#nav-sub-pref"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="nav-sub-pref"
                                                        aria-selected="false"
                                                        onClick={() => handleSowTab(3)}
                                                    >
                                                        Sous-préfectures
                                                    </button>
                                                </div>
                                            </nav>
                                            <div className="tab-content pt-0 m-0" id="nav-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-general"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-general-tab"
                                                >
                                                    <div className="d-flex justify-content-end">
                                                        <ReactToPrint
                                                            trigger={() => <button className="btn btn-dark mb-0 mr-2"><i className="fa fa-print mr-2"></i>  IMPRIMER LE TABLEAU</button>}
                                                            content={() => contentRef.current}
                                                            // onBeforePrint={()=> {!district?.description?.length > 0 && setIsPrinting(true)}}
                                                            // onAfterPrint={() => setIsPrinting(false)}
                                                            copyStyles={true}
                                                            pageStyle={`@media print {body {
                                            padding: 20px; /* Adjust the padding as needed */
                                        } @page { size: 297mm 210mm;}};`}
                                                        />
                                                    </div>
                                                    <div className="" ref={contentRef}>
                                                        <div>
                                                            <div className="pb-2 bwd">
                                                                <h4 className="bwd"><u><em>STATISTIQUES GENERALES :</em></u> </h4>
                                                            </div>
                                                            <table className="table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan={2} rowSpan={2} className="bbt"></th>
                                                                        <th colSpan={operators?.length} className="text-center border-secondary border bg-light">TAUX DE COUVERTURE</th>
                                                                        <th rowspan={2} className="text-center pb-5 border-secondary border bg-light">TAUX DE COUVERTURE GLOBAL</th>
                                                                    </tr>
                                                                    <tr>
                                                                        {operators && operators?.map((operator, index) => {
                                                                            return (
                                                                                <th key={'op1' + index} className="text-center border-secondary border bg-light" >
                                                                                    <img src={baseFileUrl + operator?.image_path} className="mr-2" height={30} width={30} alt="" />
                                                                                    <div>{operator?.id != 3 ? operator?.name + ' CI' : 'MOOV AFRICA'}</div>

                                                                                </th>)
                                                                        })}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {generaleData && <>
                                                                        {technologies && technologies?.map((technology, index) => {
                                                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                                                return (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td rowSpan={2} className="bg-light border border-secondary">Couverture {technology?.name} </td>
                                                                                            <td className="border">Population couverte</td>
                                                                                            {operators && operators?.map((operator, index) => {
                                                                                                return (
                                                                                                    <td key={"op2" + index} className="text-center border">
                                                                                                        <b>{handleNumThousand(generaleData?.["pop_cov_" + technology?.name + "_" + operator?.name.toLowerCase()])}</b>
                                                                                                        <div>
                                                                                                            <small>
                                                                                                                {generaleData?.["per_pop_cov_" + technology?.name + "_" + operator?.name.toLowerCase()]?.toFixed(2)} %
                                                                                                            </small>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            })}
                                                                                            <td className="text-center border">
                                                                                                <b>{handleNumThousand(generaleData?.["pop_cov_" + technology?.name])}</b>
                                                                                                <div>
                                                                                                    <small>
                                                                                                        {generaleData?.['per_pop_cov_' + technology?.name]?.toFixed(2)} %
                                                                                                    </small>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="border">Localités couvertes</td>
                                                                                            {operators && operators?.map((operator, index) => {
                                                                                                return (
                                                                                                    <td key={"op3" + index} className="text-center border">
                                                                                                        <b>{handleNumThousand(generaleData?.["locs_cov_" + technology?.name + "_" + operator?.name.toLowerCase()])}</b>
                                                                                                        <div>
                                                                                                            <small>
                                                                                                                {generaleData?.["per_locs_cov_" + technology?.name + "_" + operator?.name.toLowerCase()]?.toFixed(2)} %
                                                                                                            </small>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                )
                                                                                            })}
                                                                                            <td className="text-center border">
                                                                                                <b> {handleNumThousand(generaleData?.['locs_cov_' + technology?.name])} </b>
                                                                                                <div>
                                                                                                    <small>
                                                                                                        {generaleData?.['per_cov_' + technology?.name]?.toFixed(2)} %
                                                                                                    </small>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </>)
                                                                            }
                                                                        })}
                                                                    </>}

                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div>
                                                            <p>*Nombre de localités total : <b>{handleNumThousand(generaleData?.locs)}</b> </p>
                                                            <p>*Population totale (source RGPH 2014) : <b>{handleNumThousand(generaleData?.pop)}</b>  </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* DISTRICT */}
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-data"
                                                    role="tabpanel"
                                                    aria-labelledby="nav-data-tab"
                                                >
                                                    {!isLoading ? <>
                                                        {data && <>
                                                            <StatisticsListComponent
                                                                technologies={technologies}
                                                                operator={operators}
                                                                generaleData={generaleData}
                                                                handleSelectEntity={handleSelectEntity}
                                                                data={data}
                                                                setData={setData}
                                                                type={type}
                                                            />
                                                        </>}
                                                    </> : <>
                                                        <div className="container px-1 px-md-5 px-lg-1 mbody">
                                                            <DataLoaderTailSpin />
                                                        </div>
                                                    </>}
                                                </div>
                                                {/* END DISTRICT */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 p-0 pl-1">
                            <div class="card-hover-shadow-2x  card">
                                <div class="card-header">
                                    <div>ACTIVITES</div>
                                    <div ngbdropdown="" class="btn-actions-pane-right actions-icon-btn dropdown">
                                        <button class="btn btn-dark" >
                                            Voir plus
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h6>Nombre d'inscrits: 0</h6>
                                    <h6>Nombre de visiteur ce mois ci : 0</h6>
                                    <div className="scroll-area" >
                                    <div class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                        {activities && activities.map((activity, index) => {
                                            return(<>
                                                <div class="vertical-timeline-element" key={'act'+index}>
                                                    <div>
                                                        <span class="vertical-timeline-element-icon bounce-in"></span>
                                                        <div class="vertical-timeline-element-content bounce-in">
                                                            <h4 class="timeline-title"> {activity?.description+ ' à '+activity?.created_at } </h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <StatisticsDetailsModalComponent
                        display={displayStatisticsEntity}
                        setDisplay={setDisplayStatisticsEntity}
                        technologies={technologies}
                        operators={operators}
                        entity={entity}
                        setEntity={setEntity}
                    />
                </>} />
        </>
    )
}