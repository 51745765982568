import { adminAxiosInstance } from "../../config/axiosConfig";



export const getTechnologies = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/technologies`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            console.log("liste des techhnologies ============>", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addTechnology = async (technology, setState) => {
    try {
        const { name, description } = technology

        const response = await adminAxiosInstance.post(`/technologies`, {
            name: name,
            description: description,
        }).then((res => res))

        console.log("Reponse :============>", response)

        if (response.status == 201) {
            console.log("Operateur enregistré :", response.data)
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addTechnologiesList = async (technologies, setState) => {
    try {
        let isDone;
        technologies.forEach(async dist => {
            const { name, description } = dist

            const response = await adminAxiosInstance.post(`/technologies`, {
                name: name,
                description: description,
            }).then((res => res))

            if (response.status != 201) {
                return
            }

        });

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editTechnology = async (technology, setState) => {
    try {
        const { name, description } = technology

        await adminAxiosInstance.put('/technologies/' + technology.id, {
            name: name,
            description: description,
        }).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteTechnology = async (technology, setState) => {
    try {

        await adminAxiosInstance.delete('/technologies/' + technology.id).then((response) => {
            setState(response.data);
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}