import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminBufferLimiteList } from "./AdminBufferLimiteList";
import {
  DeleteBufferLimiteDialog,
  DeleteBufferLimitesDialog,
  DeleteSBufferLimitesDialog,
} from "./AdminBufferLimiteDeleteDialog";
import { AdminBufferLimiteDialog } from "./AdminBufferLimiteDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { AdminBufferLimiteVIewDialog } from "./AdminBufferLimiteViewDialog";
import { AdminBufferLimiteExcelDialog } from "./AdminBufferLimiteExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { addBufferLimite, deleteBufferLimite, editBufferLimite, getBufferLimites } from "../../../../../services/api/admin/bufferLimites/bufferLimiteApiService";

export const AdminBufferLimitePage = () => {
  const [openBufferLimiteViewDialog, setOpenBufferLimiteViewDialog] = useState(false);
  const [openBufferLimiteDialog, setOpenBufferLimiteDialog] = useState(false);
  const [openBufferLimiteExcelDialog, setOpenBufferLimiteExcelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [bufferLimites, setBufferLimites] = useState([]);
  const [bufferLimite, setBufferLimite] = useState();

  const [selectedBufferLimites, setSelectedBufferLimites] = useState(null);

  const [bufferLimiteDialogTitle, setBufferLimiteDialogTitle] = useState(
    "CREATION D'UN DISTRICT"
  );

  const [bufferLimiteTye, setBufferLimiteTye] = useState();
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getBufferLimites(setBufferLimites);
  }, []);

  useEffect(() => {
    console.log("Les bufferLimites :", bufferLimite);
  }, [bufferLimite]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de bufferLimite effectué avec succès!");
      getBufferLimites(setBufferLimites);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du bufferLimite !");
    }
    setOpenBufferLimiteDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du bufferLimite effectué avec succès!");
      getBufferLimites(setBufferLimites);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du bufferLimite !");
    }
    setOpenBufferLimiteDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du bufferLimite effectué avec succès!");
      getBufferLimites(setBufferLimites);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du bufferLimite !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setBufferLimite();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setSelectedBufferLimites();
    setOpenBufferLimiteDialog(false);
  };


  const handleShowDialog = (eName) => {


    setBufferLimiteTye(eName)
    setOpenBufferLimiteDialog(true)
    setBufferLimite();
}

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _bufferLimite = { ...bufferLimite };

    _bufferLimite[`${name}`] = val;

    setBufferLimite(_bufferLimite);
  };

  const handleFileChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(undefined)
      return
    }
    // I've kept this example simple by using the first imageFile instead of multiple

    let _bufferLimite = { ...bufferLimite };
    _bufferLimite["file"] = e.target.files[0];

    setFileUrl(e.target.files[0].filename)
    setBufferLimite(_bufferLimite);
    setFile(e.target.files[0]);
  };

  const handleSubmitBufferLimite = async (e) => {
    e.preventDefault();
    bufferLimite?.description?.trim()
    if (bufferLimite.id) {
        await editBufferLimite(bufferLimite, setEditResponse);
    } else {
        await addBufferLimite(bufferLimite, setCreateResponse);
    }
  };

  //VISUALISATION
  const handleViewBufferLimite = (dist) => {
    setBufferLimite({ ...dist });
    setOpenBufferLimiteViewDialog(true);
  };

  //EDITION
  const handleEditBufferLimite = (dist) => {
    // setBufferLimiteDialogTitle("MODIFICATION D'UN DISTRICT");
    console.log("AZERTY", dist);
    setBufferLimite({ ...dist });
    setOpenBufferLimiteDialog(true);
  };

  //SUPPRESSION
  const handleDeleteBufferLimiteDialog = (dist) => {
    setBufferLimite({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteBufferLimite = async () => {
    await deleteBufferLimite(bufferLimite, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteBufferLimites = async () => {
    try {
      selectedBufferLimites.forEach(async (bufferLimite) => {
        await deleteBufferLimite(bufferLimite, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du bufferLimites selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, bufferLimites, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(bufferLimites);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-bufferLimites");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);



  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-draw-polygon"
        title="Gestion des bufferLimites"
        subTitle="Tableau de bord"
        description="Gestion des bufferLimites. "
        children={
          <>
            <div className="">
              <div className="card mb-2">
                <div className="card-header">
                  Gestion des limites géograpiques
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="card-shadow-primary card-border mb-3 profile-responsive card">
                        <div className="dropdown-menu-header">
                          <div className="dropdown-menu-header-inner bg-asteroid">
                            <div className="menu-header-image opacity-2 dd-header-bg-4"></div>
                            <div className="menu-header-content btn-pane-right">
                              <div>
                                <h5 className="menu-header-title">
                                  Données Geojson <b><span className="text-warning"> DISTRICTS</span>
                                  </b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="p-0 list-group-item">
                            <div className="grid-menu grid-menu-2col overflow-hidden">
                              <div className="no-gutters row">
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-success">
                                    <i className="fas fa-download btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Télécharger
                                  </button>
                                </div>
                                <div className="col-sm-6">
                                  <a type="button" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-primary"
                                    onClick={() => handleShowDialog("districts")}>
                                    <i className="fas fa-upload btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Mise à jours
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-shadow-primary card-border mb-3 profile-responsive card">
                        <div className="dropdown-menu-header">
                          <div className="dropdown-menu-header-inner bg-asteroid">
                            <div className="menu-header-image opacity-2 dd-header-bg-4"></div>
                            <div className="menu-header-content btn-pane-right">
                              <div>
                                <h5 className="menu-header-title">
                                  Données Geojson <b><span className="text-warning"> REGIONS</span>
                                  </b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="p-0 list-group-item">
                            <div className="grid-menu grid-menu-2col overflow-hidden">
                              <div className="no-gutters row">
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-success">
                                    <i className="fas fa-download btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Télécharger
                                  </button>
                                </div>
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-primary"
                                    onClick={() => handleShowDialog("regions")}
                                  >
                                    <i className="fas fa-upload btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Mise à jours
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-shadow-primary card-border mb-3 profile-responsive card">
                        <div className="dropdown-menu-header">
                          <div className="dropdown-menu-header-inner bg-asteroid">
                            <div className="menu-header-image opacity-2 dd-header-bg-4"></div>
                            <div className="menu-header-content btn-pane-right">
                              <div>
                                <h5 className="menu-header-title">
                                  Données Geojson <b><span className="text-warning"> DEPARTEMENTS</span>
                                  </b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="p-0 list-group-item">
                            <div className="grid-menu grid-menu-2col overflow-hidden">
                              <div className="no-gutters row">
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-success"
                                  >
                                    <i className="fas fa-download btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Télécharger
                                  </button>
                                </div>
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-primary" onClick={() => handleShowDialog("départements")} >
                                    <i className="fas fa-upload btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Mise à jours
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-shadow-primary card-border mb-3 profile-responsive card">
                        <div className="dropdown-menu-header">
                          <div className="dropdown-menu-header-inner bg-asteroid">
                            <div className="menu-header-image opacity-2 dd-header-bg-4"></div>
                            <div className="menu-header-content btn-pane-right">
                              <div>
                                <h5 className="menu-header-title">
                                  Données Geojson <b><span className="text-warning"> SOUS-PREFECTURE</span>
                                  </b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="list-group list-group-flush">
                          <li className="p-0 list-group-item">
                            <div className="grid-menu grid-menu-2col overflow-hidden">
                              <div className="no-gutters row">
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-success"
                                  >
                                    <i className="fas fa-download btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Télécharger
                                  </button>
                                </div>
                                <div className="col-sm-6">
                                  <button type="button" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-primary"
                                    onClick={() => handleShowDialog("sous-prefectures")} >
                                    <i className="fas fa-upload btn-icon-wrapper btn-icon-lg mb-3"></i>
                                    Mise à jours
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                      Historiquue de mise à jours des limites
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {bufferLimites?.length > 0 ? (
                        <AdminBufferLimiteList
                          bufferLimites={bufferLimites}
                          handleEditBufferLimite={handleEditBufferLimite}
                          handleDeleteBufferLimiteDialog={
                            handleDeleteBufferLimiteDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedBufferLimites={selectedBufferLimites}
                          setSelectedBufferLimites={setSelectedBufferLimites}
                          handleViewBufferLimite={handleViewBufferLimite}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminBufferLimiteVIewDialog
              display={openBufferLimiteViewDialog}
              setDisplay={setOpenBufferLimiteViewDialog}
              bufferLimite={bufferLimite}
            />

            <AdminBufferLimiteDialog
              display={openBufferLimiteDialog}
              setDisplay={setOpenBufferLimiteDialog}
              handleSumitBufferLimite={handleSubmitBufferLimite}
              bufferLimite={bufferLimite}
              title={bufferLimiteDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setBufferLimite={setBufferLimite}
              bufferLimiteTye={bufferLimiteTye}
              handleFileChange={handleFileChange}
              fileUrl={fileUrl}
            />

            <DeleteBufferLimiteDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletebufferLimite={handleDeleteBufferLimite}
              bufferLimite={bufferLimite}
            />
            <DeleteSBufferLimitesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedBufferLimite={handleDeleteBufferLimites}
            />
          </>
        }
      />
    </>
  );
};
