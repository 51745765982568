import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminLocalityList } from "./AdminLocalityList";
import {
  DeleteLocalityDialog,
  DeleteLocalitiesDialog,
  DeleteSLocalitiesDialog,
} from "./AdminLocalityDeleteDialog";
import {
  addLocality,
  deleteLocality,
  editLocality,
  getLocalities,
} from "../../../../../services/api/admin/adminEntities/locality/localityApiService";
import { AdminLocalityDialog } from "./AdminLocalityDialog";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { getSubPrefectures } from "../../../../../services/api/admin/adminEntities/subPrefecture/subPrefectureApiService";
import { AdminLocalityVIewDialog } from "./AdminLocalityViewDialog";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";

export const AdminLocalityPage = () => {
  const [openLocalityViewDialog, setOpenLocalityViewDialog] = useState(false);
  const [openLocalityDialog, setOpenLocalityDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [localities, setLocalities] = useState();
  const [locality, setLocality] = useState();
  const [subPrefectures, setSubPrefectures] = useState();

  const [selectedLocalities, setSelectedLocalities] = useState(null);

  const [localityDialogTitle, setLocalityDialogTitle] = useState(
    "CREATION D'UNE LOCALITE"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getSubPrefectures(setSubPrefectures);
    getLocalities(setLocalities);
  }, []);

  useEffect(() => {
    console.log("Les localités :", locality);
  }, [locality]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de localité effectué avec succès!");
      getLocalities(setLocalities);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement de la localité !");
    }
    setOpenLocalityDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification de la localité effectué avec succès!");
      getLocalities(setLocalities);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification de la localité !");
    }
    setOpenLocalityDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression de la localité effectué avec succès!");
      getLocalities(setLocalities);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression de la localité !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setLocality();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setLocalityDialogTitle("CREATION D'UNE LOCALITE");
    setSelectedLocalities();
    setOpenLocalityDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _locality = { ...locality };

    _locality[`${name}`] = val;

    setLocality(_locality);
  };

  const handleSubmitLocality = async (e) => {
    e.preventDefault();

    if (locality.name.trim()) {
      locality?.description?.trim();
      if (locality.id) {
        await editLocality(locality, setEditResponse);
      } else {
        await addLocality(locality, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewLocality = (reg) => {
    console.log("Locality à affichier :", reg);
    setLocality({ ...reg });
    setOpenLocalityViewDialog(true);
  };

  //EDITION
  const handleEditLocality = (reg) => {
    setLocalityDialogTitle("MODIFICATION D'UNE LOCALITE");
    console.log("AZERTY", reg);
    setLocality({ ...reg });
    setOpenLocalityDialog(true);
  };

  //SUPPRESSION
  const handleDeleteLocalityDialog = (reg) => {
    setLocality({ ...reg });
    setOpenDeleteDialog(true);
  };

  const handleDeleteLocality = async () => {
    await deleteLocality(locality, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteLocalities = async () => {
    try {
      selectedLocalities.forEach(async (locality) => {
        await deleteLocality(locality, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du localities selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, localities, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(localities);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-localities");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-draw-polygon"
        title="Gestion des localités"
        subTitle="Gestion des localités"
        description="Creer, modifier, alerter ou supprimer des localités."
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des localités
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenLocalityDialog(true);
                        setLocality();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer une localité
                    </button>
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-success btn-sm"
                      onClick={() => {
                        setOpenLocalityDialog(true);
                        setLocality();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Ajouter par fichier
                      excel
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {localities?.length > 0 ? (
                        <AdminLocalityList
                          localities={localities}
                          handleEditLocality={handleEditLocality}
                          handleDeleteLocalityDialog={
                            handleDeleteLocalityDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedLocalities={selectedLocalities}
                          setSelectedLocalities={setSelectedLocalities}
                          handleViewLocality={handleViewLocality}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminLocalityVIewDialog
              display={openLocalityViewDialog}
              setDisplay={setOpenLocalityViewDialog}
              locality={locality}
            />

            <AdminLocalityDialog
              display={openLocalityDialog}
              setDisplay={setOpenLocalityDialog}
              handleSumitLocality={handleSubmitLocality}
              locality={locality}
              subPrefectures={subPrefectures}
              title={localityDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setLocality={setLocality}
            />
            <DeleteLocalityDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletelocality={handleDeleteLocality}
              locality={locality}
            />
            <DeleteSLocalitiesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedLocality={handleDeleteLocalities}
            />
          </>
        }
      />
    </>
  );
};
