import { ReactSpreadsheetImport,StepType } from "react-spreadsheet-import";



export const ImportLocalityExcelComponent = ({fields,isOpen,setIsOpen, handleSubmit}) =>{

    return  <ReactSpreadsheetImport
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onSubmit={(data) => {
        handleSubmit(data.validData)
      }}
      fields={fields}
      autoMapSelectValues={true}
      isNavigationEnabled={true}
      translations={{
        uploadStep: {
          title: "Selectionné un fichier",
          manifestTitle:"Données attendues :",
          manifestDescription:"(Attention une fois une étape terminé, vous ne pouvez plus y revenir. Cependant, Vous aurez l'occasion de renommer ou de supprimer des colonnes dans les étapes suivantes)",
          dropzone:{
            title:"Glisser deposer un fichier .xlsx, .xls ou .csv",
            buttonTitle:"Selectionner un fichier",
            errorToastDescription:"Erreur, le type de fichier doit être .xlsx, .xls ou .csv",
            loadingTitle:"Chargement...",
          },
        },
        selectHeaderStep:{
          title:"Sélectionner l'en-tête ",
          nextButtonTitle:"Suivant",
          backButtonTitle: "Retour",
        },
        matchColumnsStep:{
          title:'Colonnes de correspondance',
          nextButtonTitle:"Suivant",
          userTableTitle:"Votre tableeau",
          templateTitle:"Deviendra",
          selectPlaceholder:"Selectionner une colonne",
          duplicateColumnWarningTitle:"Vous tentez de dupliquer une colonne",
          duplicateColumnWarningDescription:"Les colonnes ne peuvent pas être dupliquées",
          backButtonTitle: "Retour",
        },
        validationStep:{
          title:"Valider les données",
          nextButtonTitle:"Suivant",
          noRowsMessage:"Aucune donnée",
          discardButtonTitle:"Rejeter la ligne sélectionnée",
          filterSwitchTitle:"Afficher uniquement les erreurs de lignes",
          backButtonTitle: "Retour",
        },
        alerts: {
          confirmClose: {
            headerTitle: "Quitter le flux d'importation",
            bodyText: "Vous êtes sûr de vous ? Vos informations actuelles ne seront pas sauvegardées.",
            cancelButtonTitle: "Annuler",
            exitButtonTitle: "Sortir",
          },
          submitIncomplete: {
            headerTitle: "Erreurs détectées",
            bodyText: "Certaines lignes contiennent encore des erreurs. Les lignes contenant des erreurs seront ignorées lors de la soumission.",
            bodyTextSubmitForbidden: "Certaines lignes contiennent encore des erreurs.",
            cancelButtonTitle: "Annuler",
            finishButtonTitle: "Envoyer",
          },
          submitError: {
            title: "Erreur",
            defaultMessage: "Une erreur s'est produite lors de l'envoi des données",
          },
          unmatchedRequiredFields: {
            headerTitle: "Toutes les colonnes ne correspondent pas",
            bodyText: "Certaines colonnes obligatoires ne sont pas prises en compte ou sont ignorées. Voulez-vous continuer ?",
            listTitle: "Les colonnes ne correspondent pas :",
            cancelButtonTitle: "Annuler",
            continueButtonTitle: "Continuer",
          },
          toast: {
            error: "Erreur",
          },
        },
        
      }}
      customTheme={{
        components: {
          Button: {
            baseStyle: {
              borderRadius: "10px",
            },
            defaultProps: {
              colorScheme: "orange",
            },
          },
        },
      }}
    />
}