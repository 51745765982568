import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminOperatorList } from "./AdminOperatorList";
import {
  DeleteOperatorDialog,
  DeleteOperatorsDialog,
  DeleteSOperatorsDialog,
} from "./AdminOperatorDeleteDialog";
import { 
  addOperator,
  deleteOperator,
  editOperator,
  getOperators } from "../../../../services/api/admin/operators/operatorApiService";
import { AdminOperatorDialog } from "./AdminOperatorDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../AdminMainPageContainer";
import { AdminOperatorVIewDialog } from "./AdminOperatorViewDialog";
import { AdminOperatorExcelDialog } from "./AdminOperatorExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";
import { getGeneraleStatistics } from "../../../../services/api/map/statisticApiService";
import { getTechnologies } from "../../../../services/api/admin/technologies/technologyApiService";

export const AdminOperatorPage = () => {
  const [openOperatorViewDialog, setOpenOperatorViewDialog] = useState(false);
  const [openOperatorDialog, setOpenOperatorDialog] = useState(false);
  const [openOperatorExcelDialog, setOpenOperatorExcelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [operators, setOperators] = useState();
  const [operator, setOperator] = useState();
  const [technologies, setTechnologies] = useState();

  const [generaleData, setGeneraleData] = useState();

  const [selectedOperators, setSelectedOperators] = useState(null);

  const [operatorDialogTitle, setOperatorDialogTitle] = useState(
    "CREATION D'UN OPERATEUR"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getOperators(setOperators);
    getTechnologies(setTechnologies)
  }, []);

  useEffect(() => {
    console.log("Les operateurs :", operator);
  }, [operator]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de operateur effectué avec succès!");
      getOperators(setOperators);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement de operateur !");
    }
    setOpenOperatorDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification de l' operateur effectuée avec succès!");
      getOperators(setOperators);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification de operateur !");
    }
    setOpenOperatorDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression de operateur effectué avec succès!");
      getOperators(setOperators);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du operator !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setOperator();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setOperatorDialogTitle("CREATION D'UN OPERATEUR");
    setSelectedOperators();
    setOpenOperatorDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _operator = { ...operator };

    _operator[`${name}`] = val;

    setOperator(_operator);
  };

  const handleSubmitOperator = async (e) => {
    e.preventDefault();

    if (operator.name.trim()) {
      operator?.description?.trim();
      if (operator.id) {
        await editOperator(operator, setEditResponse);
      } else {
        await addOperator(operator, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewOperator = (dist) => {
    setOperator({ ...dist });
    setOpenOperatorViewDialog(true);
  };

  //EDITION
  const handleEditOperator = (dist) => {
    setOperatorDialogTitle("MODIFICATION D'UN OPERATEUR");
    console.log("AZERTY", dist);
    setOperator({ ...dist });
    setOpenOperatorDialog(true);
  };

  //SUPPRESSION
  const handleDeleteOperatorDialog = (dist) => {
    setOperator({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteOperator = async () => {
    await deleteOperator(operator, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteOperators = async () => {
    try {
      selectedOperators.forEach(async (operator) => {
        await deleteOperator(operator, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du operators selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, operators, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(operators);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-operators");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-satellite-dish"
        title="Gestion des operateurs"
        subTitle="Gestion des operateurs"
        description="Creer, modifier, alerter ou supprimer des operateurs."
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des operateurs
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenOperatorDialog(true);
                        setOperator();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un operateur
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {operators?.length > 0 ? (
                        <AdminOperatorList
                          operators={operators}
                          handleEditOperator={handleEditOperator}
                          handleDeleteOperatorDialog={
                            handleDeleteOperatorDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedOperators={selectedOperators}
                          setSelectedOperators={setSelectedOperators}
                          handleViewOperator={handleViewOperator}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminOperatorVIewDialog
              display={openOperatorViewDialog}
              setDisplay={setOpenOperatorViewDialog}
              operator={operator}
              generaleData={generaleData}
              technologies={technologies}
            />

            <AdminOperatorDialog
              display={openOperatorDialog}
              setDisplay={setOpenOperatorDialog}
              handleSumitOperator={handleSubmitOperator}
              operator={operator}
              title={operatorDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setOperator={setOperator}
            />

            <DeleteOperatorDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeleteoperator={handleDeleteOperator}
              operator={operator}
            />
            <DeleteSOperatorsDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedOperator={handleDeleteOperators}
            />
          </>
        }
      />
    </>
  );
};
