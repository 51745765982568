import { adminAxiosInstance } from "../../config/axiosConfig";



export const getOperators = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/operators`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addOperator = async (operator, setState) => {
    try {
        const { name,imageFile, description } = operator

        const response = await adminAxiosInstance.post(`/operators`, {
            name: name,
            image:imageFile,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addOperatorsList = async (operators, setState) => {
    try {
        let isDone;
        operators.forEach(async dist => {
            const { name, description } = dist

            const response = await adminAxiosInstance.post(`/operators`, {
                name: name,
                description: description,
            }).then((res => res))

            if (response.status != 201) {
                return
            }

        });

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editOperator = async (operator, setState) => {
    try {
        const { name,imageFile, description } = operator

        const response = await adminAxiosInstance.post('/operator-update/' + operator.id, {
            name: name,
            image:imageFile,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteOperator = async (operator, setState) => {
    try {

        await adminAxiosInstance.delete('/operators/' + operator.id).then((response) => {
            setState(response.data);
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}