import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminSubPrefectureList } from "./AdminSubPrefectureList";
import {
  DeleteSubPrefectureDialog,
  DeleteSubPrefecturesDialog,
  DeleteSSubPrefecturesDialog,
} from "./AdminSubPrefectureDeleteDialog";
import {
  addSubPrefecture,
  deleteSubPrefecture,
  editSubPrefecture,
  getSubPrefectures,
} from "../../../../../services/api/admin/adminEntities/subPrefecture/subPrefectureApiService";
import { AdminSubPrefectureDialog } from "./AdminSubPrefectureDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { getDepartments } from "../../../../../services/api/admin/adminEntities/department/departmentApiService";
import { AdminSubPrefectureVIewDialog } from "./AdminSubPrefectureViewDialog";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";

export const AdminSubPrefecturePage = () => {
  const [openSubPrefectureViewDialog, setOpenSubPrefectureViewDialog] =
    useState(false);
  const [openSubPrefectureDialog, setOpenSubPrefectureDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [subPrefectures, setSubPrefectures] = useState();
  const [subPrefecture, setSubPrefecture] = useState();
  const [departments, setDepartments] = useState();

  const [selectedSubPrefectures, setSelectedSubPrefectures] = useState(null);

  const [subPrefectureDialogTitle, setSubPrefectureDialogTitle] = useState(
    "CREATION D'UNE SOUS-PREFECTURE"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getDepartments(setDepartments);
    getSubPrefectures(setSubPrefectures);
  }, []);

  useEffect(() => {
    console.log("Les sous-prefectures :", subPrefecture);
  }, [subPrefecture]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de sous-prefectures effectué avec succès!");
      getSubPrefectures(setSubPrefectures);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement de la sous-prefecture !");
    }
    setOpenSubPrefectureDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification de la sous-prefecture effectué avec succès!");
      getSubPrefectures(setSubPrefectures);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification de la sous-prefecture !");
    }
    setOpenSubPrefectureDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression de la sous-prefecture effectué avec succès!");
      getSubPrefectures(setSubPrefectures);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression de la sous-prefecture !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setSubPrefecture();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setSubPrefectureDialogTitle("CREATION D'UNE SOUS-PREFECTURE");
    setSelectedSubPrefectures();
    setOpenSubPrefectureDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _subPrefecture = { ...subPrefecture };

    _subPrefecture[`${name}`] = val;

    setSubPrefecture(_subPrefecture);
  };

  const handleSubmitSubPrefecture = async (e) => {
    e.preventDefault();

    if (subPrefecture.name.trim()) {
      subPrefecture?.description?.trim();
      if (subPrefecture.id) {
        await editSubPrefecture(subPrefecture, setEditResponse);
      } else {
        await addSubPrefecture(subPrefecture, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewSubPrefecture = (reg) => {
    console.log("SubPrefecture à affichier :", reg);
    setSubPrefecture({ ...reg });
    setOpenSubPrefectureViewDialog(true);
  };

  //EDITION
  const handleEditSubPrefecture = (reg) => {
    setSubPrefectureDialogTitle("MODIFICATION D'UNE SOUS-PREFECTURE");
    console.log("AZERTY", reg);
    setSubPrefecture({ ...reg });
    setOpenSubPrefectureDialog(true);
  };

  //SUPPRESSION
  const handleDeleteSubPrefectureDialog = (reg) => {
    setSubPrefecture({ ...reg });
    setOpenDeleteDialog(true);
  };

  const handleDeleteSubPrefecture = async () => {
    await deleteSubPrefecture(subPrefecture, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSubPrefectures = async () => {
    try {
      selectedSubPrefectures.forEach(async (subPrefecture) => {
        await deleteSubPrefecture(subPrefecture, setDeleteResponse);
      });
    } catch (error) {
      toastError(
        "Erreur lors de la suppression du subPrefectures selectionnés !"
      );
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, subPrefectures, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(subPrefectures);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-subPrefectures");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-draw-polygon"
                title="Gestion des sous-préfectures"
                subTitle="Tableau de bord"
                description="Gestion des sous-préfectures. "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des sous-prefectures
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenSubPrefectureDialog(true);
                        setSubPrefecture();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer une
                      sous-prefecture
                    </button>
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-success btn-sm"
                      onClick={() => {
                        // setOpenDepartmentDialog(true);
                        // setDepartment();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Ajouter par fichier
                      excel
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {subPrefectures?.length > 0 ? (
                        <AdminSubPrefectureList
                          subPrefectures={subPrefectures}
                          handleEditSubPrefecture={handleEditSubPrefecture}
                          handleDeleteSubPrefectureDialog={
                            handleDeleteSubPrefectureDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedSubPrefectures={selectedSubPrefectures}
                          setSelectedSubPrefectures={setSelectedSubPrefectures}
                          handleViewSubPrefecture={handleViewSubPrefecture}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminSubPrefectureVIewDialog
              display={openSubPrefectureViewDialog}
              setDisplay={setOpenSubPrefectureViewDialog}
              subPrefecture={subPrefecture}
            />

            <AdminSubPrefectureDialog
              display={openSubPrefectureDialog}
              setDisplay={setOpenSubPrefectureDialog}
              handleSumitSubPrefecture={handleSubmitSubPrefecture}
              subPrefecture={subPrefecture}
              departments={departments}
              title={subPrefectureDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setSubPrefecture={setSubPrefecture}
            />
            <DeleteSubPrefectureDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletesubPrefecture={handleDeleteSubPrefecture}
              subPrefecture={subPrefecture}
            />
            <DeleteSSubPrefecturesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedSubPrefecture={handleDeleteSubPrefectures}
            />
          </>
        }
      />
    </>
  );
};
