
import { Link, useNavigate } from 'react-router-dom';
import './verify.css'

export const PasswordForgotPage = () => {

    return (
        <>
            <div class="h-100">
                <div class="row h-100 no-gutters">
                    <div class="d-none d-lg-block col-lg-4">
                        <div class="position-relative h-100 d-flex  align-items-center bg-plum-plate">
                            <div className="container d-flex justify-content-center">
                                <div className=''>
                                    <div>
                                        <Link href="https://www.artci.ci" target="_blank">
                                            <img src="../images/logo-artci.png" className='' width={400} />
                                        </Link>

                                        <h1 className='text-center mt-2'><i className="fa fa-user"></i></h1>
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <Link to="/login" className="btn btn-dark"><i className="fa fa-arrow-left mr-2"></i> Retour à la connexion</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
                        <div class="mx-auto div-box col-sm-12 col-md-10 col-lg-9">
                            <div class="app-logo"></div>
                            <h4 class="mb-0">
                                <h2 className='title-login mb-2'>Cartodonnees récupération de compte</h2>
                                <div className='text-primary'>Entrez votre email dans le champs ci-dessous.</div>

                                <span>Vous aller recevoir un mail pour recupérer votre compte et créer un nouveau mot de passe</span>
                                <div className='pt-4'>
                                                    <input id="exampleEmail" name="email" type="email" placeholder="Entrez votre email ici..." class="form-control" /></div>
                            </h4>
                            <div className='container d-flex justify-content-center pt-3 '>
                            <Link to="/login" type="button" class="btn btn-block btn-success btn-lg">Envoyer</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}