
import axios from "axios";
import { baseGeojsonDataUrl, colors, layers, sources } from "../../constants";
import { localVariables } from "../../services/local/SecureLocalStorageVariable";


export const addDataSourceController = (map) => {
    //LIMITES
    //State of Ivory Coast

    //Sub-prefectures
    addSource(map, sources.map.subPrefectureId, 'geojson', baseGeojsonDataUrl + 'sub_prefecture_limites2023.geojson');

    //Departments
    addSource(map, sources.map.departmentId, 'geojson', baseGeojsonDataUrl + 'department_limites2023.geojson');
    //Regions
    addSource(map, sources.map.regionId, 'geojson', baseGeojsonDataUrl + 'region_limites2023.geojson');
    //Districts
    addSource(map, sources.map.districtId, 'geojson', baseGeojsonDataUrl + 'district_limites2023.geojson');

    addSource(map, sources.map.stateId, 'geojson', baseGeojsonDataUrl + 'ci_limites_states.geojson');

    map.addSource(sources.fiber.ansutData, {
        type: 'vector',
        url: 'mapbox://yaoparfait.dpqjhzje' // replace with your tileset URL
    });

    // addSource(map, sources.fiber.ansutData, 'geojson', baseGeojsonDataUrl + 'fber_cable.geojson');

    // //END LIMITES

    // //PLACES
    // //Localities
    // addSource(map,sources.map.cityId, 'geojson', baseGeojsonDataUrl+'alldata.geojson');
    //END PLACES

    //Technologies
    //All data
    addSource(map, sources.coverage.allDataId, 'geojson', baseGeojsonDataUrl + 'all_data2023.geojson');

    // //All 2G data
    // addSource(map,sources.coverage.all2GData, 'geojson', baseGeojsonDataUrl+'data2G.geojson');
    // //All 3G data
    // addSource(map,sources.coverage.all3GData, 'geojson', baseGeojsonDataUrl+'data3G.geojson');
    // //All 4G data
    // addSource(map,sources.coverage.all4GData, 'geojson', baseGeojsonDataUrl+'data4G.geojson');

    // //Orange data
    // addSource(map,sources.coverage.allOrangeDataId, 'geojson', baseGeojsonDataUrl+'dataOrange.geojson');
    // addSource(map,sources.coverage.orange2GData, 'geojson', baseGeojsonDataUrl+'dataOrange2G.geojson');
    // addSource(map,sources.coverage.orange3GData, 'geojson', baseGeojsonDataUrl+'dataOrange3G.geojson');
    // addSource(map,sources.coverage.orange4GData, 'geojson', baseGeojsonDataUrl+'dataOrange4G.geojson');

    // //MTN Data
    // addSource(map,sources.coverage.allMtnDataId, 'geojson', baseGeojsonDataUrl+'dataMtn.geojson');
    // addSource(map,sources.coverage.mtn2GData, 'geojson', baseGeojsonDataUrl+'dataMtn2G.geojson');
    // addSource(map,sources.coverage.mtn3GData, 'geojson', baseGeojsonDataUrl+'dataMtn3G.geojson');
    // addSource(map,sources.coverage.mtn4GData, 'geojson', baseGeojsonDataUrl+'dataMtn4G.geojson');

    // //Moov Data
    // addSource(map,sources.coverage.allMoovDataId, 'geojson', baseGeojsonDataUrl+'dataMoov.geojson');
    // addSource(map,sources.coverage.moov2GData, 'geojson', baseGeojsonDataUrl+'dataMoov2G.geojson');
    // addSource(map,sources.coverage.moov3GData, 'geojson', baseGeojsonDataUrl+'dataMoov3G.geojson');
    // addSource(map,sources.coverage.moov4GData, 'geojson', baseGeojsonDataUrl+'dataMoov4G.geojson');

    // //Other
    // addSource(map,sources.coverage.nodataId, 'geojson', baseGeojsonDataUrl+'nodata.geojson');
    // addSource(map,sources.coverage.whiteAreaDataId, 'geojson', baseGeojsonDataUrl+'dataWhiteArea.geojson');

    // addSource(map,sources.coverage.orangeAntenna2GDataId, 'geojson', baseGeojsonDataUrl+'antennaOrange.geojson');
    // addSource(map,sources.coverage.mtnAntenna2GDataId, 'geojson', baseGeojsonDataUrl+'antennaMtn.geojson');
    // addSource(map,sources.coverage.moovAntenna2GDataId, 'geojson', baseGeojsonDataUrl+'antennaMoov.geojson');
}

export const addDataFillLayerController = (map) => {
    addFillLayer(map, layers.fill.districtId, sources.map.districtId, 1, 8, colors.map.fill.districtId, 0.1);
    addFillLayer(map, layers.fill.regionId, sources.map.regionId, 6, 8.3, colors.map.fill.regionId, 0.2);
    addFillLayer(map, layers.fill.departmentId, sources.map.departmentId, 8, 9, colors.map.fill.departmentId, 0.2);
    addFillLayer(map, layers.fill.subPrefectureId, sources.map.subPrefectureId, 9, 20, colors.map.fill.subPrefectureId, 0.2);
}

export const addDataLineLayerController = (map) => {
    addLineLayer(map, layers.line.subPrefectureId, sources.map.subPrefectureId, 9, 20, colors.map.line.subPrefectureId, 1);
    addLineLayer(map, layers.line.departmentId, sources.map.departmentId, 8, 11, colors.map.line.departmentId, 2);
    addLineLayer(map, layers.line.regionId, sources.map.regionId, 6, 8.5, colors.map.line.regionId, 2.5);
    addLineLayer(map, layers.line.districtId, sources.map.districtId, 1, 8, colors.map.line.districtId, 3);
    addLineLayer(map, layers.line.stateId, sources.map.stateId, 1, 7, colors.map.line.stateId, 4);

    // addLineLayer(map,layers.line.fiberAnsut, sources.fiber.ansutData, 1, 20, colors.map.line.ansutData, 4);

    map.addLayer({
        'id': 'fiber-layer',
        'type': 'line',
        'source': sources.fiber.ansutData, // use the source ID defined above
        'minzoom': 0,
        'maxzoom': 22,
        'source-layer': 'bd_Geomap_Liens', // specify the layer name within your tileset
        'layout': {
            'line-join': 'round',
            'line-cap': 'round'
        },
        'paint': {
            'line-color': colors.map.line.ansutData, // specify the line color
            'line-width': 5 // specify the line width
        }
    });

    // map.addLayer({
    //     'id': layers.line.fiberAnsut,
    //     'type': 'line',
    //     'source': {
    //         'type': 'vector',
    //         'url': 'mapbox://styles/yaoparfait/yaoparfait.dpqjhzje' // URL de votre tileset sur Mapbox
    //     },
    //     'minzoom': 0,
    //     'maxzoom': 12,
    //     'layout': {},
    //     'paint': {
    //         'line-color': colors.map.line.ansutData,
    //         'line-width': 3
    //     },
    //     'source-layer': 'bd_Geomap_Liens'
    // });
}

export const addDataCircleLayeController = (map) => {
    // addCircleLayer(map,layers.circle.locality, sources.map.cityId, 'black');

    // addCircleLayer(map,layers.circle.allData, sources.coverage.allDataId, colors.coverage.allData);

    addCircleLayerWithFilter(map, layers.circle.allData2G, sources.coverage.allDataId, colors.coverage.allData, ["==", ['get', 'cov2G'], 1])
    addCircleLayerWithFilter(map, layers.circle.allData3G, sources.coverage.allDataId, colors.coverage.allData, ["==", ['get', 'cov3G'], 1])
    addCircleLayerWithFilter(map, layers.circle.allData4G, sources.coverage.allDataId, colors.coverage.allData, ["==", ['get', 'cov4G'], 1])
    // addCircleLayerWithFilter(map,layers.circle.allData2G, sources.coverage.allDataId, colors.coverage.allData,8,['any',["==",['get', 'covORANGE4G'], 1],["==",['get', 'covORANGE3G'], 1]])
    // addCircleLayerWithFilter(map,layers.circle.allData3G, sources.coverage.allDataId, colors.coverage.allData,7,["==",['get', 'covMOOV4G'], 1])
    // addCircleLayerWithFilter(map,layers.circle.allData4G, sources.coverage.allDataId, colors.coverage.allData,6,["==",['get', 'covMTN4G'], 1])
    // filterDadaToDisplay(map)
    // addCircleLayer(map,layers.circle.allData2G, sources.coverage.all2GData, colors.coverage.allData);
    // addCircleLayer(map,layers.circle.allData3G, sources.coverage.all3GData, colors.coverage.allData);
    // addCircleLayer(map,layers.circle.allData4G, sources.coverage.all4GData, colors.coverage.allData);

    /*
    addCircleLayer(map,layers.circle.allOrangeData, sources.coverage.allMtnDataId, colors.coverage.orangeData);
    addCircleLayer(map,layers.circle.orange2GData, sources.coverage.orange2GData, colors.coverage.orangeData);
    addCircleLayer(map,layers.circle.orange3GData, sources.coverage.orange3GData, colors.coverage.orangeData);
    addCircleLayer(map,layers.circle.orange4GData, sources.coverage.orange4GData, colors.coverage.orangeData);

    addCircleLayer(map,layers.circle.allMtnData, sources.coverage.allOrangeDataId, colors.coverage.mtnData);
    addCircleLayer(map,layers.circle.mtn2GData, sources.coverage.mtn2GData, colors.coverage.mtnData);
    addCircleLayer(map,layers.circle.mtn3GData, sources.coverage.mtn3GData, colors.coverage.mtnData);
    addCircleLayer(map,layers.circle.mtn4GData, sources.coverage.mtn4GData, colors.coverage.mtnData);

    addCircleLayer(map,layers.circle.allMoovData, sources.coverage.allMoovDataId, colors.coverage.moovData);
    addCircleLayer(map,layers.circle.moov2GData, sources.coverage.moov2GData, colors.coverage.moovData);
    addCircleLayer(map,layers.circle.moov3GData, sources.coverage.moov3GData, colors.coverage.moovData);
    addCircleLayer(map,layers.circle.moov4GData, sources.coverage.moov4GData, colors.coverage.moovData);

    addCircleLayer(map,layers.circle.noData, sources.coverage.nodataId, colors.coverage.noData);
    addCircleLayer(map,layers.circle.whiteAreaData, sources.coverage.whiteAreaDataId, colors.coverage.whiteAreaData);
    */

    addLayerLabel(map, layers.label.districtId, sources.map.districtId, "ADM0_FR", 6, 12)
    addLayerLabel(map, layers.label.regionId, sources.map.regionId, "ADM1_FR", 1, 8, 12)
    addLayerLabel(map, layers.label.locality, sources.coverage.allDataId, "name", 8, 20, 12)
}



export const addSource = (map, name, type, data) => {
    map.addSource(name, {
        type: type,
        data: data,
    });
}

export const addFillLayer = (map, id, source, minzoom, maxzoom, color, opacity) => {
    map.addLayer({
        'id': id,
        'type': 'fill',
        'source': source,
        'minzoom': minzoom,
        'maxzoom': maxzoom,
        'layout': {},
        'paint': {
            'fill-color': color,
            'fill-opacity': [
                'case', ['boolean', ['feature-state', 'hover'], false],
                1,
                opacity
            ]
        }
    });
}

export const addLineLayer = (map, id, source, minzoom, maxzoom, color, width) => {
    map.addLayer({
        'id': id,
        'type': 'line',
        'source': source,
        'minzoom': minzoom,
        'maxzoom': maxzoom,
        'layout': {},
        'paint': {
            'line-color': color,
            'line-width': width
        }
    });
}

export const addCircleLayer = (map, id, source, color) => {
    if (!map.getLayer(id)) {
        map.addLayer({
            'id': id,
            'type': 'circle',
            'source': source,
            'minzoom': 1,
            'maxzoom': 22,
            'paint': {
                'circle-radius': [
                    'interpolate', ['linear'], ['zoom'],
                    5, 3, // À un niveau de zoom de 5, le rayon du cercle est de 5 pixels
                    10, 3, // À un niveau de zoom de 10, le rayon du cercle est de 10 pixels
                    12, 3, // À un niveau de zoom de 10, le rayon du cercle est de 10 pixels
                ],
                'circle-color': color,
                'circle-opacity': 0.6,
                'circle-stroke-width': 0.1,
                'circle-stroke-color': 'black'
            }
        });
    }
}

export const addCircleLayer1 = (map, id, source, color) => {
    if (!map.getLayer(id)) {
        map.addLayer({
            'id': id,
            'type': 'circle',
            'source': source,
            'minzoom': 1,
            'maxzoom': 22,
            'paint': {
                'circle-radius': [
                    'interpolate', ['linear'], ['zoom'],
                    5, 5, // À un niveau de zoom de 5, le rayon du cercle est de 5 pixels
                    10, 4, // À un niveau de zoom de 10, le rayon du cercle est de 10 pixels
                    12, 8, // À un niveau de zoom de 10, le rayon du cercle est de 10 pixels
                ],
                'circle-color': color,
                'circle-opacity': 1,
                'circle-stroke-width': 0.1,
                'circle-stroke-color': 'black'
            }
        });
    }
}

export const addCircleLayerWithFilter = (map, id, source, color, filter) => {
    if (!map.getLayer(id)) {
        map.addLayer({
            'id': id,
            'type': 'circle',
            'source': source,
            'minzoom': 1,
            'maxzoom': 22,
            'paint': {
                'circle-radius': [
                    'interpolate', ['linear'], ['zoom'],
                    5, 5, // À un niveau de zoom de 5, le rayon du cercle est de 5 pixels
                    10, 4, // À un niveau de zoom de 10, le rayon du cercle est de 10 pixels
                    12, 8, // À un niveau de zoom de 10, le rayon du cercle est de 10 pixels
                ],
                'circle-color': color,
                'circle-opacity': 0.6,
                'circle-stroke-width': 0.1,
                'circle-stroke-color': 'black'
            },
            filter: filter
        });
    }
}

export const addCircleLayerWithFilterIcon = (map, id, source, size, filter, icon) => {
    if (!map.getLayer(id)) {
        map.addLayer({
            'id': id,
            'type': 'symbol',
            'source': source,
            'minzoom': 1,
            'maxzoom': 22,
            'layout': {
                'icon-image': icon, // Nom de votre image d'icône définie dans votre couche
                'icon-size': [
                    'interpolate', ['linear'], ['zoom'],
                    5, 0.8,
                    8, 1,
                    10, 1.2,
                    12, 1.5,
                ],
                'icon-allow-overlap': true,
                'icon-keep-upright': true,
            },
            'paint': {
                'icon-opacity': 0.7,
                'icon-color': 'blue',
            },
            filter: filter
        });
    }
}

export const removeLayer = (map, layer) => {
    if (map.getLayer(layer)) {
        map.removeLayer(layer);
    } else {
    }
}


export const setLayerVisible = (map, layer) => {
    map.setLayoutProperty(layer, "visibility", "visible");
}

export const setLayerInvisible = (map, layer) => {
    map.setLayoutProperty(layer, "visibility", "none");
}

export const setAllLayerInvisible = (map) => {
    removeLayer(map, layers.circle.locality)
    removeLayer(map, layers.circle.allData)

    // removeLayer(map,layers.circle.allData2G)
    // removeLayer(map,layers.circle.allData3G)
    // removeLayer(map,layers.circle.allData4G)

    removeLayer(map, layers.circle.allOrangeData)
    removeLayer(map, layers.circle.orange2GData)
    removeLayer(map, layers.circle.orange3GData)
    removeLayer(map, layers.circle.orange4GData)

    removeLayer(map, layers.circle.allMtnData)
    removeLayer(map, layers.circle.mtn2GData)
    removeLayer(map, layers.circle.mtn3GData)
    removeLayer(map, layers.circle.mtn4GData)

    removeLayer(map, layers.circle.allMoovData)
    removeLayer(map, layers.circle.moov2GData)
    removeLayer(map, layers.circle.moov3GData)
    removeLayer(map, layers.circle.moov4GData)

    removeLayer(map, layers.circle.noData)
    removeLayer(map, layers.circle.whiteAreaData)
    removeLayer(map, layers.circle.fiberAnsut)

    /*
        map.setLayoutProperty(layers.circle.locality, "visibility", "none");
        map.setLayoutProperty(layers.circle.allData, "visibility", "none");
        map.setLayoutProperty(layers.circle.allData2G, "visibility", "none");
        map.setLayoutProperty(layers.circle.allData3G, "visibility", "none");
        map.setLayoutProperty(layers.circle.allData4G, "visibility", "none");
        map.setLayoutProperty(layers.circle.allOrangeData, "visibility", "none");
        map.setLayoutProperty(layers.circle.orange2GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.orange3GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.orange4GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.allMtnData, "visibility", "none");
        map.setLayoutProperty(layers.circle.mtn2GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.mtn3GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.mtn4GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.allMoovData, "visibility", "none");
        map.setLayoutProperty(layers.circle.moov2GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.moov3GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.moov4GData, "visibility", "none");
        map.setLayoutProperty(layers.circle.noData, "visibility", "none");
        map.setLayoutProperty(layers.circle.whiteAreaData, "visibility", "none");
        map.setLayoutProperty(layers.line.fiberAnsut, "visibility", "none");
    */
}

export const addLayerLabel = (map, id, source, text_f, minzoom, maxzoom, size) => {
    map.addLayer({
        'id': id,
        'type': 'symbol',
        'source': source,
        'layout': {
            //'text-size': size,
            'text-field': ['get', text_f],
            'text-justify': 'auto',
            'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
            'text-font': [
                'literal', ['DIN Pro Italic', 'Arial Unicode MS Regular'],
            ],
            'text-size': 10
        },
        'minzoom': minzoom,
        'maxzoom': maxzoom,
    })
}

const handlePushOnLocalStorage = async (name1, name2) => {
    const location = { f_name: name1, s_name: name2 };
    localVariables.setItem('userLocationAdresse', location);
}

export const handleGetUserAddress = async (coordinates) => {


    try {
        let response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json`, {
            params: {
                access_token: "pk.eyJ1IjoieWFvcGFyZmFpdCIsImEiOiJja2p4enkzM20wbW95MnFwZzdod2h4YWtpIn0.-A0-mEp4INf4laMwOdRVNA",
            },
        }).then(res => res)

        let placeInfo0 = null;
        let placeInfo1 = null;
        placeInfo0 = response.data.features[0]?.place_name;
        placeInfo1 = response.data.features[1]?.place_name;

        const name_split1 = placeInfo0.split(',');
        const name_split2 = placeInfo1.split(',');

        await handlePushOnLocalStorage(name_split1[0], name_split2[1]);

    } catch (error) {
        console.error('Error fetching place information:', error);
    }


}
