import { adminAxiosInstance } from "../../../config/axiosConfig"



export const getCategories = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/categories`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log("reponse ===>",response.data.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addCategory = async (category, setState) => {
    try {
        const {name,description} = category

        const response = await adminAxiosInstance.post(`/categories`, {
            name: name,
            description: description,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Trançon enregistré :", response.data)
                setState(response.data)
        }else{

        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const editCategory = async (category, setState) => {

    try {
        const {name,description} = category

        const response = await adminAxiosInstance.put('/categories/'+category.id, {
            name: name,
            description: description,
        }).then((res => res))
        if(response.status == 201) {
                console.log(response.data)
                console.log("Trançon modifié :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }

}

export const deleteCategory = async (category, setState) => {
    try {

        await adminAxiosInstance.delete('/categories/'+category.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}