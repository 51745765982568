import { adminAxiosInstance } from "../../../config/axiosConfig"



export const getDepartments = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/departments`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log(response.data.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addDepartment = async (department, setState) => {
    try {
        const {region_id,name,description} = department

        const response = await adminAxiosInstance.post(`/departments`, {
            region_id:region_id,
            name: name,
            description: description,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Department enregistré :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}
export const addDepartmentList = async (districts, setState) => {
    try {
        let isDone;
        districts.forEach(async dist => {
            const { name, description } = dist

            const response = await adminAxiosInstance.put(`/districts`, {
                name: name,
                description: description,
            }).then((res => res))

            if (response.status != 201) {
                return
            }

        });

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editDepartment = async (department, setState) => {
    try {
        const {region_id,name,description} = department

        await adminAxiosInstance.put('/departments/'+department.id, {
            region_id: region_id,
            name: name,
            description: description,
        }).then((response) => {
            console.log('Vérification de l\'edition', response.data)
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const deleteDepartment = async (department, setState) => {
    try {

        await adminAxiosInstance.delete('/departments/'+department.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}



export const getDepartmentsByGeoData = async(setState,id) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/department_limites2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data;
            const _districts = _data.features.filter(feature => feature.id == id)
            const _properties = _districts[0]?.properties;
            setState(_properties)
        }

    } catch (error) {
        
    }
}


export const getDDepartmentsByGeoData = async(setState) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/department_limites2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data.features;
            setState(_data)
        }

    } catch (error) {
        
    }
}