import { userTechnologies, year } from "../../../constants/map/mapDataConstants";
import { localVariables } from "../../../local/SecureLocalStorageVariable";
import { adminAxiosInstance, userAxiosInstance } from "../../config/axiosConfig";

export const getAdminTechnologies = async (setState) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get(`/technologies`).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get(`/technologies2021`).then((res => res))
        }
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            // console.log(response.data.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}


export const getUserTechnologies = async (setState) => {
    try {
        const technologies = localVariables.getItem(userTechnologies);

        if(technologies != null && technologies != undefined){
            setState(technologies)
        }else{
            const y = localVariables.getItem(year);
            let response = null;
            if(y == null || y == undefined || y == 2023 ){
                response = await userAxiosInstance.get(`/technologies`).then((res => res))
            }
            else if(y == 2021){
                response = await userAxiosInstance.get(`/technologies2021`).then((res => res))
            }
            // => {
            //     console.log("Données sur la liste des categories", response.data)
            //     setState(response.data)
            // }
            setState([]);
    
            if (response.status == 200) {
                // console.log(response.data.data)
                //console.log("liste des categories :", response.data)
                localVariables.setItem(userTechnologies,response.data.data)
                setState(response.data.data)
            }
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}