import { messagesAlert } from "../../../../constants/map/mapDataConstants";
import { localVariables } from "../../../../local/SecureLocalStorageVariable";
import { adminAxiosInstance } from "../../../config/axiosConfig";



export const getHomeMessages = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/home-messages`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            console.log("liste des Messages d'accueil :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}


export const getActiveHomeMessages = async (setState) => {
    try {
        const messages = localVariables.getItem(messagesAlert);

        if(messages != null && messages != undefined){
            setState(messages)
        }else{
            const response = await adminAxiosInstance.get(`/home-messages-active`).then((res => res))
            // => {
            //     console.log("Données sur la liste des categories", response.data)
            //     setState(response.data)
            // }
            setState([]);
    
            if (response.status == 200) {
                // console.log("liste des Messages d'accueil Actif :", response.data)
                localVariables.setItem(messagesAlert,response.data.data)
                setState(response.data.data)
            }
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addHomeMessage = async (homeMessage, setState) => {
    try {
        const { title, status, description } = homeMessage

        const response = await adminAxiosInstance.post(`/home-messages`, {
            title: title,
            description: description,
            status: status,
        }).then((res => res))

        if (response.status == 201) {
            console.log("Element ==========+++>",response.data )
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editHomeMessage = async (homeMessage, setState) => {
    try {
        const { title, status, description } = homeMessage

        const response = await adminAxiosInstance.post('/home-messages-update/' + homeMessage.id, {
            title: title,
            description: description,
            status: status,
        }).then((res => res))

        if (response.status == 201) {
            setState(response.data)
        } else {
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteHomeMessage = async (homeMessage, setState) => {
    try {

        await adminAxiosInstance.delete('/home-messages/' + homeMessage.id).then((response) => {
            setState(response.data);
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}