import { useState, useEffect, useRef } from "react";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterHomeMessage } from 'primereact/api';
import { baseFileUrl } from "../../../../../constants";
import { srtSubDescription, srtSubDescription2 } from "../../../../../services/tools/convertions";

export const AdminHomeMessageList = ({ homeMessages, handleViewHomeMessage, handleEditHomeMessage, handleDeleteHomeMessageDialog, exportCSV, exportPDF, exportExcel, dt, selectedHomeMessages, setSelectedHomeMessages, setopenDeleteSelected }) => {

    const [globalFilter, setGlobalFilter] = useState(null);

    const [grid, setGrid] = useState(false);

    const header = () => {
        return (
            <>
                <div className=" bg-light w-100 p-2">
                    {/* Search and select START */}
                    <div className="d-flex justify-content-between">
                        {/* Search bar */}
                        <div className=" d-flex justify-content-between">
                            <button className="btn btn-sm btn-success mb-0 mr-2" onClick={() => exportExcel()}><i className="fa fa-file mr-2"></i> Excel</button>
                            <button className="btn btn-sm btn-secondary mb-0 mr-2" onClick={() => exportCSV()}><i className="fa fa-file mr-2"></i> CSV</button>
                            <button className="btn btn-sm btn-warning mb-0 mr-2" onClick={() => exportPDF()}><i className="fa fa-file mr-2"></i> PDF</button>
                            <button className="btn btn-sm btn-danger mb-0" onClick={() => { (selectedHomeMessages && selectedHomeMessages.length) > 0 && setopenDeleteSelected(true) }}><i className="fa fa-trash mr-2" ></i> Supprimer plusieurs sélections </button>
                        </div>
                        <div className="">
                            <div className="rounded position-relative">
                                <input className="form-control bg-body" type="search" placeholder="Recherche..." aria-label="Search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* Search and select END */}
                </div>
            </>
        );
    };


    const actionBodyTemplate = (rowData) => {
        return (<>
            <div className="d-flex justify-content-end">
                {/* <button className="btn btn-info btn-round mr-2 mb-0" onClick={() => handleViewHomeMessage(rowData)}><i className="far fa-eye"></i></button> */}
                <button className="btn btn-primary btn-round mr-2 mb-0" onClick={() => handleEditHomeMessage(rowData)}><i className="far fa-edit"></i></button>
                <button className="btn btn-danger btn-round mb-0" onClick={() => handleDeleteHomeMessageDialog(rowData)} ><i className="fas fa-trash"></i></button>
            </div>
        </>)
    };


    const statusTemplate = (rowData) => {
        return (<>
            <div>
                {!rowData?.status ? <span class="badge badge-danger">Désactiver</span> : <span class="badge badge-primary">Activer</span>}
            </div>
        </>)
    }

    const descriptionTemplate = (rowData) => {
        return(<>
       {srtSubDescription(rowData?.description)} 
        </>)
    }

    return (<>
        {homeMessages?.length > 0 && <>
            {!grid ? <>
                <DataTable
                    ref={dt}
                    value={homeMessages}
                    selection={selectedHomeMessages}
                    onSelectionChange={(e) => setSelectedHomeMessages(e.value)}
                    dataKey="code"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    globalFilter={globalFilter}
                    header={header}
                    tableStyle={{ minWidth: '50rem' }}
                    globalFilterFields={['name']}
                >
                    <Column selectionMode="multiple" exportable={false}></Column>
                    <Column field="code" header="Code" sortable style={{ width: '25%' }}></Column>
                    <Column field="title" header="Titre" sortable style={{ width: '25%' }}></Column>
                    <Column header="Description" sortable style={{ width: '25%' }} body={descriptionTemplate} ></Column>
                    <Column header="Status" sortable style={{ width: '25%' }} body={statusTemplate} ></Column>
                    <Column header="Actions" headerStyle={{ textAlign: 'right' }} bodyStyle={{ textAlign: 'end', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>
            </> : <>
                <div className="row">
                    {homeMessages.map((homeMessage) => {
                        return (<>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <img src="" />
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
            </>}
        </>
        }
    </>)
}