import axios from "axios"
import { apiRequestBase, tokenAdminJwt } from "../../../../constants"
import { getSecureLocalVariable } from "../../../local/SecureLocalStorageVariable"
import { adminAxiosInstance } from "../../config/axiosConfig"

export const adminLogin = async(admin) => {
    try{
        const {email,password} = admin

        let response = await adminAxiosInstance.post(`/admin-login`,{
            email:email,
            password:password,
        }).then(res => res) 

        if(response.status == 200) {
            console.log("Reponse de connexion administrateur",response.data)
            return response.data
        }else{
        }

        
        // await adminAxiosInstance.post(`/admin-login`,{
        //     email:email,
        //     password:password,
        // }).then((response) => {
        //     console.log(response.data)
        //     setSecureLocalStorageVariable(tokenAdminJwt,response.data.token)
        //     setSecureLocalStorageVariable(userAdmin,response.data.user)
        //     setState(response.data)
        // }
        // )
    } catch (error) {
        console.log(error.message)
    }
}

export const logOutAdminService = async(setState) => {
    try{
        // const tokenAdmin = getSecureLocalVariable(tokenAdminJwt)
        // const adminAxiosInstance1 = axios.create({
        //     baseURL: apiRequestBase,
        //     headers:tokenAdmin ? {
        //         "Access-Control-Allow-Origin": "*",
        //         "Content-Type": "multipart/form-data",
        //         'Authorization': `Bearer ${ tokenAdmin}`
        //     }:{}
        // })
        let response = await adminAxiosInstance.post(`/logout`).then(res => res) 

        if(response.status == 200) {
            console.log("Reponse de connexion",response.data)
            setState(response.data) 
        }else{
        }

        
        // await adminAxiosInstance.post(`/admin-login`,{
        //     email:email,
        //     password:password,
        // }).then((response) => {
        //     console.log(response.data)
        //     setSecureLocalStorageVariable(tokenAdminJwt,response.data.token)
        //     setSecureLocalStorageVariable(userAdmin,response.data.user)
        //     setState(response.data)
        // }
        // )
    } catch (error) {
        console.log()
    }
}

