import { adminAxiosInstance } from "../../config/axiosConfig"



export const getFiberOptics = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/fiber-cables`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log("reponse ===>",response.data.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addFiberOptic = async (fiberOptic, setState) => {
    try {
        console.log('Localites ===>', fiberOptic)
        const {locality_start_id,locality_end_id,distance, description} = fiberOptic

        const response = await adminAxiosInstance.post(`/fiber-cables`, {
            locality_start_id:locality_start_id,
            locality_end_id: locality_end_id,
            distance: distance,
            description: description,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Trançon enregistré :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const editFiberOptic = async (fiberOptic, setState) => {

    try {
        console.log('Localites à mettre à jour ===>', fiberOptic)
        const {locality_satrt_id,locality_end_id,distance, description} = fiberOptic

        const response = await adminAxiosInstance.put('/fiber-cables/'+fiberOptic.id, {
            locality_start_id:locality_satrt_id,
            locality_end_id: locality_end_id,
            distance: distance,
            description: description,
        }).then((res => res))
        if(response.status == 201) {
                console.log(response.data)
                console.log("Trançon modifié :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }

}

export const deleteFiberOptic = async (fiberOptic, setState) => {
    try {

        await adminAxiosInstance.delete('/fiber-cables/'+fiberOptic.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}