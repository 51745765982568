import { useEffect, useState } from "react";
import { AdminHeader } from "../../../components/layout/header/AdminHeader"
import { localVariables } from "../../../services/local/SecureLocalStorageVariable";
import { baseFileUrl } from "../../../constants";
import { AdminFooter } from "../../../components/layout/footer/AdminFooter";





export const OperatorMainPageContainer = ({ children,user,title,subTitle }) => {

    const [levelExport, setLevelExport] = useState();
    const [showError4O4, setShowError4O4] = useState(false);
    const [showExportDialog, setShowExportDialog] = useState(false);



    const handleInitExportData = (level) => {
        if (level != 5) {
            setShowExportDialog(true);
        }
        setLevelExport(level);
    }

    return (<>
        <div className="app-container app-theme-white body-tabs-shadow fixed-header">
            <AdminHeader setShowError4O4={setShowError4O4} handleInitExportData={handleInitExportData} />
            <main className="mt-5">
                <div className="m-3">
                    <div class="app-page-title bg-premium-dark text-white">
                        <div class="page-title-wrapper">
                            <div class="page-title-heading">
                                <div class="page-title-icon p-1 d-flex justify-content-center">
                                    <img src={baseFileUrl + user?.operator?.image_path} height={50} width={50} alt="" />
                                </div>
                                <div>ESPACE / {user?.operator?.name} --&raquo; {title}
                                    <div class="page-title-subheading"></div>
                                </div>
                            </div>
                            <div class="page-title-actions">
                                <button type="button" data-toggle="tooltip" title="" data-placement="bottom" class="btn-shadow mr-3 btn btn-dark" data-original-title="Example Tooltip">
                                    <i class="fa fa-star"></i>
                                </button>
                                <div class="d-inline-block dropdown">
                                    <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn-shadow dropdown-toggle btn btn-info">
                                        <span class="btn-icon-wrapper pr-2 opacity-7">
                                            <i class="fa fa-business-time fa-w-20"></i>
                                        </span>
                                        Messages
                                    </button>
                                    <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu dropdown-menu-right">
                                        <ul class="nav flex-column">
                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <i class="nav-link-icon fa fa-inbox"></i>
                                                    <span> Boite de réception</span>
                                                    <div class="ml-auto badge badge-pill badge-secondary">86</div>
                                                </a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link">
                                                    <i class="nav-link-icon fa fa-plus"></i>
                                                    <span> Ecrire un nouveau messages</span>
                                                    <div class="ml-auto badge badge-pill badge-danger">5</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {children}
            </main>
            <AdminFooter />
        </div>
    </>)
}