import { useEffect, useState } from "react"
import { DashboardBanner } from "../../../../components/layout/banner/DashboardBanner"
import { OperatorMainPageContainer } from "../OperatorMainPageContainer"
import { Link } from "react-router-dom";
import { localVariables } from "../../../../services/local/SecureLocalStorageVariable";



export const OperatorDashBoardPage = () => {
  const [generaleData, setGeneraleData] = useState();
  const [user, setUser] = useState();


  useEffect(() => {
      setUser(localVariables.getItem('operator'));
  }, []);

  return (<>
    <OperatorMainPageContainer
    user={user}
    title={"TABLEAU DE BORD"}
      children={
        <>
          <div className="container-fluid">
            <div>
              <h4>Administrer les données de couverture sur le territoire national</h4>
              <hr />
            </div>
            <h5>Opérations</h5>
            <div className="card-shadow-primary card-border mb-2 profile-responsive card">
              <ul className="list-group list-group-flush">
                <li className="p-0 list-group-item">
                  <div className="grid-menu grid-menu-2col overflow-hidden">
                    <div className="no-gutters row">
                      <div className="col-md-3 border-left">
                        <Link to="/operator-coverage" className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-info">
                          <i className="fas fa-database btn-icon-wrapper btn-icon-lg mb-3"></i>
                          Données de couverture des localités
                        </Link>
                      </div>
                      <div className="col-md-3">
                        <Link to="/operator-coverage-excel" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-success">
                          <div>
                            <img src="../images/excel.png" height={60} alt="" />
                          </div>
                          <div>
                            Mise à partir d'un fichier Excel
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-3 border border-left">
                        <button className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link">
                          <div>
                            <img src="../images/hand.png" height={60} alt="" />
                          </div>
                          <div>
                            Mise à jour manuelle
                          </div>
                        </button>
                      </div>
                      <div className="col-md-3 border-left">
                        <button className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-warning">
                          <i className="fas fa-star btn-icon-wrapper btn-icon-lg mb-3"></i>
                          Activités
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
            <div className="container-fluid" >
              <h2 className="text-center bwd">STATISTIQUES</h2>
              <DashboardBanner generaleData={generaleData} />
            </div>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">

                    </div>
                    <div className="card-body">

                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header">

                    </div>
                    <div className="card-body">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    />
  </>)
}