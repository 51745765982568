import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { handleNumThousand } from "../../../../../services/tools/convertions";
import { getSubPrefecturesByGeoData } from "../../../../../services/api/admin/adminEntities/subPrefecture/subPrefectureApiService";
import { getOperators } from "../../../../../services/api/admin/operators/operatorApiService";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";
import ReactToPrint from "react-to-print";
import { baseFileUrl } from "../../../../../constants";


export const AdminSubPrefectureVIewDialog = ({ display, setDisplay, subPrefecture }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [localSubPrefecture, setLocalSubPrefecture] = useState();
    const [operators, setOperators] = useState();
    const [technologies, setTechnologies] = useState();

    const [isPrinting, setIsPrinting] = useState(false);


    const contentRef = useRef();
    const tableRef = useRef();


    useEffect(() => {
        getOperators(setOperators)
        getTechnologies(setTechnologies)
    }, []);


    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        handleGetSubPrefecturesData(subPrefecture?.id)
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        setLocalSubPrefecture()
        // handleInitVar()
    };

    const handleGetSubPrefecturesData = async (geo_id) => {
        await getSubPrefecturesByGeoData(setLocalSubPrefecture,geo_id)
    }


    return (<>
        <Dialog
            header="DETAILS SOUS-PREFECTURE"
            visible={display}
            modal
            style={{ width: "90vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
        <div className="mb-2">
            <ReactToPrint
                trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                content={() => contentRef.current}
                onBeforePrint={()=> {!subPrefecture?.description?.length > 0 && setIsPrinting(true)}}
                onAfterPrint={() => setIsPrinting(false)}
                pageStyle={`@media print {body {
                    padding: 20px; /* Adjust the padding as needed */
                  } @page { size: 450mm 250mm;}};`}
            />
            <hr />
        </div>
            <div className="row" ref={contentRef}>
                <div className="col-md-12 p-0">
                <div>
                        <h3>SOUS-PREFECTURE : <b>{subPrefecture?.name}</b></h3>
                        <div>
                            <h5>Coordonnées du centre:</h5>
                            <div className="pl-3">
                                <div>
                                    <p>Latitude : {subPrefecture?.center_lat} </p>
                                    <p>Longitude : {subPrefecture?.center_lng} </p>
                                </div>
                            </div>
                        </div>
                        {!isPrinting && <>
                                {subPrefecture?.description?.length > 0 && <>
                            <div className="bg-light">
                                    <p>{subPrefecture?.description} </p>
                            </div>
                                </>}
                        </>}
                    
                    </div>
                    <div>
                    </div>
                </div>
                <div className="col-md-12 bg-light">
                    <div class="">
                        <div class="no-gutters row">
                            <div class="col-md-6">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"> {localSubPrefecture?.nbloc} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning">{localSubPrefecture?.nbcouvloc}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités couvertes</div>
                                            <div class="widget-subheading">{localSubPrefecture?.txcouvloc.toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger">{localSubPrefecture?.nbnocouvloc} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localité non couverte</div>
                                            <div class="widget-subheading">{(100 - localSubPrefecture?.txcouvloc).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"><small>{handleNumThousand(localSubPrefecture?.population) }</small></div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning"><small>{handleNumThousand(localSubPrefecture?.popcouv) }</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population couverte</div>
                                            <div class="widget-subheading">{localSubPrefecture?.txpopcouv.toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger"><small>{handleNumThousand(localSubPrefecture?.nopopcouv)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population non couverte</div>
                                            <div class="widget-subheading">{(100 - localSubPrefecture?.txpopcouv).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                {localSubPrefecture && <>
                <div className="mt-3 w-100">
                            <table className="table table-bordered table-responsive table-striped table-hover table-width">
                                <thead>
                                    <tr>
                                        <th>Opérateur</th>
                                        <th colSpan={technologies?.length * 2} className="text-center">Localités</th>
                                        <th colSpan={technologies?.length * 2} className="text-center">Population</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t1"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t2"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t3"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {operators?.length > 0 && operators?.map((operator,index) => {
                                        return(
                                            <tr key={"ot"+index}>
                                                <td>
                                                    <img src={baseFileUrl+operator.image_path} className="mr-2" height={30} alt="" />
                                                     <b>{operator?.name}</b> </td>
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt1"+index}> { handleNumThousand(localSubPrefecture["locCouv"+operator.name+technology.name])} <br />
                                                        <small> -> {(localSubPrefecture["locCouv"+operator.name+technology.name] > 0 ? (localSubPrefecture["locCouv"+operator.name+technology.name]*100 /localSubPrefecture?.nbloc ).toFixed(1) : 0 )} %</small>
                                                         </td>
                                                    )
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt2"+index}> { handleNumThousand(localSubPrefecture.nbloc -localSubPrefecture["locCouv"+operator.name+technology.name])} <br />
                                                        <small> -> {(localSubPrefecture["locCouv"+operator.name+technology.name] > 0 ? ((localSubPrefecture.nbloc -localSubPrefecture["locCouv"+operator.name+technology.name]) *100 /localSubPrefecture?.nbloc ).toFixed(1) : 0 )} %</small>
                                                        </td>
                                                    )
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt3"+index}> { handleNumThousand(localSubPrefecture["pop"+operator.name+technology.name])} <br />
                                                        <small> -> {(localSubPrefecture["pop"+operator.name+technology.name] > 0 ? (localSubPrefecture["pop"+operator.name+technology.name]*100 /localSubPrefecture?.population ).toFixed(1) : 0 )} %</small>
                                                        </td>
                                                    )
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt4"+index}> { handleNumThousand(localSubPrefecture.population -localSubPrefecture["pop"+operator.name+technology.name])}<br />
                                                        <small> -> {(localSubPrefecture["pop"+operator.name+technology.name] > 0 ? ( (localSubPrefecture.population -localSubPrefecture["pop"+operator.name+technology.name]) *100 /localSubPrefecture?.population ).toFixed(1) : 0 )} %</small> 
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        
                                    </tr>
                                </tbody>
                            </table>
                </div>
                </>}
            </div>
        </Dialog>
    </>)
}