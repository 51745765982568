//SOURCES

export const sources = {
    map: {
        districtId: "districts",
        regionId: "regions",
        departmentId: "departement",
        subPrefectureId: "sousprefecture",
        stateId: "stateid",
        cityId: "cities",
    },
    fiber:{
        ansutData:"ansut",
    },
    coverage: {
        allDataId: "alldata",
        all2GData: "data2G",
        all3GData: "data3G",
        all4GData: "data4G",

        //Orange
        allOrangeDataId: "dataOrange",
        orange2GData: "dataOrange2G",
        orange3GData: "dataOrange3G",
        orange4GData: "dataOrange4G",

        //MTN
        allMtnDataId: "dataMtn",
        mtn2GData: "dataMtn2G",
        mtn3GData: "dataMtn3G",
        mtn4GData: "dataMtn4G",

        //Moov
        allMoovDataId: "dataMoov",
        moov2GData: "dataMoov2G",
        moov3GData: "dataMoov3G",
        moov4GData: "dataMoov4G",

        //Other
        nodataId: "nodata",
        whiteAreaDataId: "areawhitedata",

        //Antenna
        orangeAntenna2GDataId: "orangeAntenna2g",
        mtnAntenna2GDataId: "mtnAntenna2g",
        moovAntenna2GDataId: "moovAntenna2g",
        data:'data',
        phone:'phone',


    }

}
//END SOURCES

//LAYERS
export const layers = {
    fill: {
        districtId: "district-fills",
        regionId: "region-fills",
        departmentId:"department-fills",
        subPrefectureId: "sub-prefecture-fills",
    },
    line: {
        stateId: "state-fill",
        districtId: "district-line",
        regionId: "region-line",
        departmentId:"department-line",
        subPrefectureId: "sub-prefecture-line",
        fiberAnsut:"ansut",
    },
    circle: {
        locality: "locality",
        allData: "allData",
        allData2G: "allData2G",
        allData3G: "allData3G",
        allData4G: "allData4G",
        allNoData2G: "allNoData2G",
        allNoData3G: "allNoData3G",
        allNoData4G: "allNoData4G",
        allOrangeData: "allOrangeData",
        orange2GData: "orange2GData",
        orange3GData: "orange3GData",
        orange4GData: "orange4GData",
        allMtnData: "allMtnData",
        mtn2GData: "mtn2GData",
        mtn3GData: "mtn3GData",
        mtn4GData: "mtn4GData",
        allMoovData: "allMoovData",
        moov2GData: "moov2GData",
        moov3GData: "moov3GData",
        moov4GData: "moov4GData",
        noData: "nodata",
        whiteAreaData: "areaWhiteData",
        data:'data',
        phone:'phone',
    },
    label: {
        districtId: "district-label",
        regionId: "region-label",
        departmentId:"department-label",
        subPrefectureId:"sub-prefecture-label",
        locality: "locality-label",
    }
}

//COLOR
export const colors = {
    map: {
        line: {
            stateId: "#000000",
            districtId: "#000000",
            regionId: "#933902",
            departmentId: "#7400FF",
            subPrefectureId: "#F905B6",
            ansutData: "#FF0000" //"#1AD807",
        },
        fill: {
            districtId: "rgba(7, 2, 88,0.5)",
            regionId: "rgba(147,57, 2, 0.3)",
            departmentId: "rgba(116,0,225,0.2)",
            subPrefectureId: "rgba(249,5,182,0.1)",
        },
    },
    coverage: {
        allData: "#00b09b",
        orangeData: "#FF7901",
        mtnData: "#fffc37",
        moovData: "#075bf7",
        noData: "red",
        whiteAreaData: "white",
    },
}

//DISTRICTS

export const generaleStats = "general-stasts"
export const tokenKey = "access_token_netschool"
export const localStorePrefix= "carto"
export const secretKeyValue = "secret-key-value"
export const tokenAdminJwt = "token-admin"
export const tokenUserJwt = "token-user"
export const tokenOperatorJwt = "token-user"
export const tokenActuatorJwt = "token-actuator"
export const newItemValue="veaw-item"
export const userAdmin="admin-user"
export const user="user"
export const userOperator="operator"
export const userActuator="actuator-user"
export const showOnAdminBar="show-a"   
// export const httpRequestFileBase = "http://localhost:3333/"
// export const apiRequestBase = "http://localhost:3333/api/"

// export const apiRequestBase = "http://localhost:8000/api/"
// export const baseGeojsonDataUrl="http://localhost:8000/api/get-data-file/geojson/"   
// export const baseImagesUrl = "http://localhost:8000/api/get-file/"
// export const baseFileUrl = "http://localhost:8000/api/get-file/"

//Server
// export const httpRequestFileBase = "https://cartodonneesbackend.yaoparfait48.ci/"
export const apiRequestBase = "https://cartodonneesbackend.yaoparfait48.ci/api/"
export const baseGeojsonDataUrl="https://cartodonneesbackend.yaoparfait48.ci/api/get-data-file/geojson/"   
// export const baseImagesUrl = "https://cartodonneesbackend.yaoparfait48.ci/api/get-file/images/"
export const baseFileUrl = "https://cartodonneesbackend.yaoparfait48.ci/api/get-file/"

export const baseUrlVideosTest = "https://tekeye.uk/html/images/Joren_Falls_Izu_Japan.ogv"
// export const baseFileUrl = "http://localhost:8000/api/get-file/"
