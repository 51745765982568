export const AdminFooter = () => {
    return (<>
        <footer className="ng-tns-c143-0">
            <div className="app-footer">
                <div className="app-footer__inner">
                    <div className="app-footer-right">
                        <app-footer-menu>
                            <ul className="header-megamenu nav">
                                <li className="nav-item">
                                    <button
                                        placement="top-right"
                                        type="button"
                                        className="btn btn-link"
                                    >
                                        <span>
                                            <i className="nav-link-icon pe-7s-gift"></i> Cartodonnées ARTCI
                                        </span>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <div
                                        ngbdropdown=""
                                        placement="top-right"
                                        className="btn-group dropup"
                                        ng-reflect-placement="top-right"
                                    >
                                        <button
                                            aria-haspopup="true"
                                            type="button"
                                            ngbdropdowntoggle=""
                                            className=" btn btn-link"
                                            aria-expanded="false"
                                        >
                                            <span> ® 2023 </span>
                                        </button>
                                        <div
                                            ngbdropdownmenu=""
                                            className="dropdown-menu-lg dropdown-menu"
                                            x-placement="top-right"
                                        >
                                            <div className="dropdown-menu-header">
                                                <div className="dropdown-menu-header-inner bg-tempting-azure">
                                                    <div className="menu-header-image dd-header-bg-1"></div>
                                                    <div className="menu-header-content text-dark">
                                                        <h5 className="menu-header-title">
                                                            Two Column Grid
                                                        </h5>
                                                        <h6 className="menu-header-subtitle">
                                                            Easy grid navigation inside popovers
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid-menu grid-menu-2col">
                                                <div className="no-gutters row">
                                                    <div className="col-sm-6">
                                                        <button className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-dark">
                                                            <i className="lnr-lighter text-dark opacity-7 btn-icon-wrapper mb-2"></i>
                                                            Automation
                                                        </button>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <button className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                                                            <i className="lnr-construction text-danger opacity-7 btn-icon-wrapper mb-2"></i>
                                                            Reports
                                                        </button>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <button className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-success">
                                                            <i className="lnr-bus text-success opacity-7 btn-icon-wrapper mb-2"></i>
                                                            Activity
                                                        </button>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <button className="btn-icon-vertical btn-transition-text btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-focus">
                                                            <i className="lnr-gift text-focus opacity-7 btn-icon-wrapper mb-2"></i>
                                                            Settings
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="nav flex-column">
                                                <li className="nav-item-divider nav-item"></li>
                                                <li className="nav-item-btn clearfix nav-item">
                                                    <div className="float-left">
                                                        <button className="btn btn-link btn-sm">
                                                            Link Button
                                                        </button>
                                                    </div>
                                                    <div className="float-right">
                                                        <button className="btn-shadow btn btn-info btn-sm">
                                                            Info Button
                                                        </button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </app-footer-menu>
                    </div>
                </div>
            </div>
        </footer>
    </>)
}