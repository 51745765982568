import { useEffect, useState } from 'react'
import './Header.css'
import { Link } from 'react-router-dom';
import { tokenAdminJwt, tokenUserJwt } from '../../../constants';
import { logOutAdminService } from '../../../services/api/auth/login/adminLoginService';
import { logOutUserService } from '../../../services/api/auth/login/userLoginService';
import { localVariables } from '../../../services/local/SecureLocalStorageVariable';
import { toastWarning } from '../../notification/notification';

export const AdminHeader = ({setShowError4O4, handleInitExportData}) => {
    const [responseLogout, setResponseLogout] = useState();

    const [user, setUser] = useState();


    useEffect(() => {
        setUser(localVariables.getItem('admin'))
    }, []);




    const handleLogOut = () => {
      const _token = localVariables.getItem(tokenAdminJwt);
      if (_token) {
        logOutAdminService(setResponseLogout);
        
      } else {
        const _token_u = localVariables.getItem(tokenUserJwt);
        if (_token_u) {
          logOutUserService(setResponseLogout);
        }
      }
      localVariables.setItem(tokenAdminJwt,null)
      localVariables.setItem(tokenUserJwt,null)
      localVariables.setItem('user',null)
      setUser()
      
      toastWarning('Vous etes deconnecté !')
      window.location.href = '/login';
    }

    return (<>
        <div className="app-header header-shadow">
            <div className="app-header__logo">
                <div className="logo-src">
                    <a href="https://www.artci.ci" target="_blank">
                        <img src="../images/logo-artci.png" className='logo-img' />
                    </a>
                </div>
            </div>
            <div className="app-header__mobile-menu">
                <div>
                    <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div className="app-header__menu">
                <span>
                    <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                        <span className="btn-icon-wrapper">
                            <i className="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>
            <div className="app-header__content">
                <div className="app-header-left">
                    <ul className="header-megamenu nav">
                        <li className=" nav-item">
                <div href="#" className="h-dropdown nav-link">
                  <i className="fas fa-database mr-2"></i>
                  <a className="tdply h-dropbtn">
                    Extraction de données
                    <i className="fa-solid fa-chevron-down ml-2"></i>
                  </a>
                  <div className="h-dropdown-content"><div className="dropdown-menu-header">
                    <div className="p-2 bg_mdsuccess">
                      <div className="menu-header-image opacity-1 bg-image1"></div>
                      <div className="menu-header-content text-left p-0">
                        <h5 className="menu-header-title">Extraction de données</h5>
                        <h6 className="menu-header-subtitle">Sélectionnez les données à extraire</h6>
                      </div>
                    </div>
                  </div>
                    <a href="#" onClick={() => handleInitExportData(1)}><i className="fas fa-circle mr-2 color0" ></i>Toute les localités</a>
                    <a href="#" onClick={() => handleInitExportData(2)}><i className="fas fa-circle mr-2 color1" ></i>Localités couvertes</a>
                    <a href="#" onClick={() => handleInitExportData(3)}><i className="fas fa-circle mr-2 color2"></i>Localités non couvertes</a>
                    <a href="#" onClick={() => setShowError4O4(true)}><i className="fa-regular fa-circle mr-2 color3"></i>Localités blanches</a>
                  </div>
                </div>
                        </li>
                        <li className=" nav-item">
                            <a className="nav-link" onClick={() => setShowError4O4(true)}>
                                <i className="fa-solid fa-square-rss mr-2"></i>
                                <div className="tdply">Flux RSS</div>
                            </a>
                        </li>
                    </ul>


                </div>
                <div className="app-header-right">
                <div className="header-dots">
              {user ? <>
                <ul className="header-megamenu nav">
                  <li className="nav-item">
                    <div href="#" className="h-dropdown nav-link">
                      <div className="row">
                        <div>
                          <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                            <img width="30" src="../images/avatars/user.png" alt="" className="rounded-circle" /></div>
                        </div>
                        <div className="">
                          <div className="widget-heading">{user?.last_name} </div>
                          <div className="widget-subheading">{user?.first_name}</div>
                        </div>
                      </div>
                      <div className="h-dropdown-content drop-setting">
                        <div className="dropdown-menu-header">
                        </div>
                        <a href="#" onClick={() => setShowError4O4(true)}><i className="fas fa-user mr-2 "></i>Profil</a>
                        <a href="#" onClick={() => handleLogOut()}><i className="fas fa-right-from-bracket mr-2"></i>Deconnexion</a>
                      </div>
                    </div>
                  </li>
                </ul>
              </> : <>
                <div className="dropdown">
                  <div className="p-0 mr-2 badge-light">
                    <Link
                      to="/login"
                      className="btn btn-secondary text-light mr-2"
                    >
                      Connexion
                    </Link>
                    {/* @if (Route::has('register')) */}
                    <Link to="/register" className="btn btn-success text-light">
                      Inscription
                    </Link>
                    {/* @endif */}
                  </div>
                </div>
              </>}
            </div>
                </div >
            </div >

        </div >

        <div className="d-none">
            <button type="button" id="ext" data-toggle="modal" data-target="#createCommentModal">
            </button>
        </div>
    </>)
}