import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

import '@watergis/mapbox-gl-export/css/styles.css';
import { colors, layers, sources } from '../../constants';
import { dataToExport, year } from '../../services/constants/map/mapDataConstants';
import { addCircleLayer, addCircleLayer1, addCircleLayerWithFilter, addCircleLayerWithFilterIcon, addFillLayer, addLineLayer, removeLayer, setLayerInvisible, setLayerVisible } from '../dataController/dataController';
import { localVariables } from '../../services/local/SecureLocalStorageVariable';
import { toastError, toastWarning } from '../../components/notification/notification';
import { adminAxiosInstance, userAxiosInstance } from '../../services/api/config/axiosConfig';
import { MapboxExportControlCustom, Size, PageOrientation, Format, DPI } from '../../services/export/index.js'


export const addControllerOnMap = (map, geolocate, marker) => {

  map.addControl(new mapboxgl.FullscreenControl()); //Full Screen
  map.addControl(new MapboxExportControlCustom({
    PageSize: Size.A4,
    PageOrientation: PageOrientation.Portrait,
    Format: Format.PDF,
    DPI: DPI[96],
    Crosshair: true,
    PrintableArea: true,
    Local: 'fr'
  }), 'top-right');
  map.addControl(geolocate);
  map.addControl(new mapboxgl.NavigationControl(), 'top-right'); // Navigation


  map.addControl(new mapboxgl.ScaleControl({ maxWidth: 80, unit: 'metric' })); // Add scale

  addHomeIconController(map, marker)
  //addInfoIconController(map, displayInfo)
}


export const addSearchInput = (map, type) => {
  //Add geocoder
  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    countries: 'ci',
    language: 'fr-FR', // Specify the language as German.
    marker: {
      color: 'orange'
    },
    flyTo: {
      bearing: 0,
      // Control the flight curve, making it move slowly and
      // zoom out almost completely before starting to pan.
      speed: 0.8, // Make the flying slow.
      curve: 1, // Change the speed at which it zooms out.
      // This can be any easing function: it takes a number between
      // 0 and 1 and returns another number between 0 and 1.
      easing: function (t) {
        return t;
      }
    },
    render: function (item) {
      console.log(item);
      // extract the item's maki icon or use a default
      const maki = item.properties.maki || 'marker';
      return `<div class='geocoder-dropdown-item'>
            <span class='geocoder-dropdown-text'>
            ${item.text} - ${item?.context[0]?.text}
            </span>
            </div>`;
    },
  });
  geocoder.options.placeholder = "Rechercher...";
  document.getElementById('geocoder').appendChild(geocoder.onAdd(map));
}

export const addHomeIconController = (map, marker) => {
  class MyControl {
    constructor() {
      this.onClick = this._onClick.bind(this);
    }
    onAdd(map) {
      this.map = map;
      var el = this.container = document.createElement('div');
      el.className = 'mapboxgl-mycontrol mapboxgl-ctrl mapboxgl-ctrl-group';

      var button = document.createElement('button');
      button.setAttribute('type', 'button');
      button.addEventListener('click', this.onClick, false);
      el.appendChild(button);

      var icon = document.createElement('i');
      icon.className = 'fas fa-home';
      icon.style.fontSize = '18px';
      button.appendChild(icon);
      return el;
    }
    onRemove(map) {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
    _onClick() {
      // mon action
      resetMap(map, marker);
    }
  }

  map.addControl(new MyControl(), 'top-right');
}


export const addInfoIconController = (map, setDisplay) => {
  class MyControl {
    constructor() {
      this.onClick = this._onClick.bind(this);
    }
    onAdd(map) {
      this.map = map;
      var el = this.container = document.createElement('div');
      el.className = 'mapboxgl-mycontrol mapboxgl-ctrl mapboxgl-ctrl-group mb-5 ';

      var button = document.createElement('button');
      button.setAttribute('type', 'button');
      button.addEventListener('click', this.onClick, false);
      button.className = 'hamburger hamburger--elastic open-right-drawer';
      button.title = "Afficher la légende";
      el.appendChild(button);

      var icon = document.createElement('i');
      icon.className = 'fas fa-l';
      icon.style.fontSize = '18px';
      button.appendChild(icon);
      return el;
    }
    onRemove(map) {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
    _onClick() {
      // mon action
      //resetMap();
      setDisplay(true)
    }
  }

  map.addControl(new MyControl(), 'bottom-right');
}


export const flyMap = (map, lng, lat, zoom) => {
  map.flyTo({
    center: [
      lng, lat,
    ],
    zoom: zoom,
    speed: 0.8,
    curve: 1,
    essential: true,
  });
}


//Renitialiser la carte
export const resetMap = (map, marker) => {

  if (marker) {
    marker.remove()
  }

  map.setPitch(0);
  map.setBearing(0);

  const districtLockId = localVariables.getItem("districtLockId")
  const regionLockId = localVariables.getItem("regionLockId")
  const departmentLockId = localVariables.getItem("departmentLockId")
  const subPrefectureLockId = localVariables.getItem("subPrefectureLockId")

  if (districtLockId != null) {
    map.setFeatureState({ source: sources.map.districtId, id: districtLockId }, { hover: false });
  }
  if (regionLockId != null) {
    map.setFeatureState({ source: sources.map.regionId, id: regionLockId }, { hover: false });
  }
  if (departmentLockId != null) {
    map.setFeatureState({ source: sources.map.departmentId, id: departmentLockId }, { hover: false });
  }
  if (subPrefectureLockId != null) {
    map.setFeatureState({ source: sources.map.subPrefectureId, id: subPrefectureLockId }, { hover: false });
  }

  localVariables.setItem('districtLockId', null)
  localVariables.setItem('regionLockId', null)
  localVariables.setItem('departmentLockId', null)
  localVariables.setItem('subPrefectureLockId', null)

  localVariables.setItem(dataToExport, null)

  flyMap(map, -5.891926847184704, 7.41331470697536, 5);

  //Renitialiser les statistiques
}

export const changeMapStyle = () => {

  return (<>
  </>)
}

export const setMapLanguage = (map, language) => {
  // map.setLayoutProperty('country-label', 'text-field', [
  //     'get',
  //     `name_`+language,
  // ]);
}


export const flyToCordsWithMarker = (map, lng, lat, zoom, marker, setMarker) => {
  const lg = Number(lng)
  const lt = Number(lat)

  if (marker) {
    marker.remove()
  }
  const _marker = new mapboxgl.Marker({ color: 'black', rotation: 0 }).setLngLat([lg, lt]).addTo(map);
  setMarker(_marker)
  flyMap(map, lg, lt, zoom);

}
export const flyToCords = (map, lng, lat, zoom, marker) => {
  const lg = Number(lng)
  const lt = Number(lat)
  if (marker) {
    marker.remove()
  }

  flyMap(map, lg, lt, zoom);

}


export const filterDadaToDisplay = (map) => {

  let _oprator = ">="
  const filters1 = ["==", ['get', 'covMOOV4G'], 1];
  const filters2 = ["==", ['get', 'covORANGE4G'], 1];

  map.setFilter(layers.circle.allData, filters1);
  map.setPaintProperty(layers.circle.allData, 'circle-color', [
    'case',
    filters1,
    'blue',
    filters2,
    'orange',
    colors.coverage.allData,
  ]);

  // map.setFilter(map,layers.circle.allData,filters);
}




export const handleLoadDataOnMap = async (showDataOnMap, currentMap) => {
  if (currentMap) {

    if (showDataOnMap?.highway) {
      setLayerVisible(currentMap, "road-motorway-trunk");
    } else {
      setLayerInvisible(currentMap, "road-motorway-trunk")
    }
    if (showDataOnMap?.nationalRoad) {
      setLayerVisible(currentMap, "road-secondary-tertiary");
    } else {
      setLayerInvisible(currentMap, "roaroad-secondary-tertiary")
    }
    if (showDataOnMap?.track) {
      setLayerVisible(currentMap, "road-minor");
    } else {
      setLayerInvisible(currentMap, "road-minor")
    }
    if (showDataOnMap?.railway) {
      setLayerVisible(currentMap, "rails");
    } else {
      setLayerInvisible(currentMap, "rails");
    }

    if (showDataOnMap?.showFiberCable) {
      setLayerVisible(currentMap, "fiber-layer");
    } else {
      setLayerInvisible(currentMap, "fiber-layer");
    }

    if (showDataOnMap?.showLocalityLimit) {
      addCircleLayer1(currentMap, layers.circle.locality, sources.coverage.allDataId, 'black');
    } else {
      removeLayer(currentMap, layers.circle.locality);
    }


    //Coverage Locality
    if (showDataOnMap?.showLocalities) {
      if (showDataOnMap?.showLocalities2G) {
        //Select 2G
        addCircleLayerWithFilter(currentMap, layers.circle.allData2G,
          sources.coverage.allDataId, colors.coverage.allData, ["==", ['get', 'cov2G'],
          1], "rectangle-red-2")
      } else {
        removeLayer(currentMap, layers.circle.allData2G);
      }
      if (showDataOnMap?.showLocalities3G) {
        addCircleLayerWithFilter(currentMap, layers.circle.allData3G,
          sources.coverage.allDataId, colors.coverage.allData, ["==", ['get', 'cov3G'],
          1],)
      } else {
        removeLayer(currentMap, layers.circle.allData3G);
      }
      if (showDataOnMap?.showLocalities4G) {
        addCircleLayerWithFilter(currentMap, layers.circle.allData4G,
          sources.coverage.allDataId, colors.coverage.allData, ["==", ['get', 'cov4G'],
          1])
      } else {
        removeLayer(currentMap, layers.circle.allData4G);
      }
      if (showDataOnMap?.showLocalitiesData) {
        // console.log("Bien reçu ===========>")
        // addCircleLayerWithFilterIcon(currentMap, layers.circle.orange2GData, sources.coverage.allDataId, 0.4, ["==", ['get', 'covORANGE2G'], 1], 'or_data')
        addCircleLayerWithFilterIcon(currentMap, layers.circle.data, sources.coverage.allDataId, 0.4, ["==", ['get', 'covData'], 1], 'data')
      } else {
        removeLayer(currentMap, layers.circle.data);
      }
    } else {
      removeLayer(currentMap, layers.circle.allData2G);
      removeLayer(currentMap, layers.circle.allData3G);
      removeLayer(currentMap, layers.circle.allData4G);
    }

    //Localités non couverte
    if (showDataOnMap?.showNoLocalities) {
      if (showDataOnMap?.showNoLocalities2G) {
        addCircleLayerWithFilter(currentMap, layers.circle.allNoData2G, sources.coverage.allDataId, colors.coverage.noData, ["==", ['get', 'cov2G'], 0])
      } else {
        removeLayer(currentMap, layers.circle.allNoData2G);
      }
      if (showDataOnMap?.showNoLocalities3G) {
        addCircleLayerWithFilter(currentMap, layers.circle.allNoData3G, sources.coverage.allDataId, colors.coverage.noData, ["==", ['get', 'cov3G'], 0])
      } else {
        removeLayer(currentMap, layers.circle.allNoData3G);
      }
      if (showDataOnMap?.showNoLocalities4G) {
        addCircleLayerWithFilter(currentMap, layers.circle.allNoData4G, sources.coverage.allDataId, colors.coverage.noData, ["==", ['get', 'cov4G'], 0])
      } else {
        removeLayer(currentMap, layers.circle.allNoData4G);
      }
    } else {
      removeLayer(currentMap, layers.circle.allNoData2G);
      removeLayer(currentMap, layers.circle.allNoData3G);
      removeLayer(currentMap, layers.circle.allNoData4G);
    }

    if (showDataOnMap?.showWhiteAreaLocalities) {
      addCircleLayerWithFilter(currentMap, layers.circle.whiteAreaData, sources.coverage.allDataId, colors.coverage.whiteAreaData, ['all', ["==", ['get', 'cov2G'], 0], ["==", ['get', 'cov3G'], 0], ["==", ['get', 'cov4G'], 0]])
    } else {
      removeLayer(currentMap, layers.circle.whiteAreaData);
    }

    if (showDataOnMap?.showORANGE) {
      if (showDataOnMap?.showORANGE2G) {
        //Select 2G
        addCircleLayerWithFilterIcon(currentMap, layers.circle.orange2GData, sources.coverage.allDataId, 0.4, ["==", ['get', 'covORANGE2G'], 1], 'or_data')
        // setLayerVisible(currentMap, layers.circle.orange2GData)
      } else {
        removeLayer(currentMap, layers.circle.orange2GData);
      }
      if (showDataOnMap?.showORANGE3G) {
        addCircleLayerWithFilterIcon(currentMap, layers.circle.orange3GData, sources.coverage.allDataId, 0.4, ["==", ['get', 'covORANGE3G'], 1], 'or_data')
      } else {
        removeLayer(currentMap, layers.circle.orange3GData);
      }
      if (showDataOnMap?.showORANGE4G) {
        addCircleLayerWithFilterIcon(currentMap, layers.circle.orange4GData, sources.coverage.allDataId, 0.4, ["==", ['get', 'covORANGE4G'], 1], 'or_data')
      } else {
        removeLayer(currentMap, layers.circle.orange4GData);
      }
    } else {
      removeLayer(currentMap, layers.circle.orange2GData);
      removeLayer(currentMap, layers.circle.orange3GData);
      removeLayer(currentMap, layers.circle.orange4GData);
      removeLayer(currentMap, layers.circle.allOrangeData);
    }

    if (showDataOnMap?.showMTN) {
      if (showDataOnMap?.showMTN2G) {
        //Select 2G
        addCircleLayerWithFilterIcon(currentMap, layers.circle.mtn2GData, sources.coverage.allDataId, 0.6, ["==", ['get', 'covMTN2G'], 1], 'mt_data')
        // setLayerVisible(currentMap, layers.circle.mtn2GData)
      } else {
        removeLayer(currentMap, layers.circle.mtn2GData);
      }
      if (showDataOnMap?.showMTN3G) {
        addCircleLayerWithFilterIcon(currentMap, layers.circle.mtn3GData, sources.coverage.allDataId, 0.6, ["==", ['get', 'covMTN3G'], 1], 'mt_data')
      } else {
        removeLayer(currentMap, layers.circle.mtn3GData);
      }
      if (showDataOnMap?.showMTN4G) {
        addCircleLayerWithFilterIcon(currentMap, layers.circle.mtn4GData, sources.coverage.allDataId, 0.6, ["==", ['get', 'covMTN4G'], 1], 'mt_data')
      } else {
        removeLayer(currentMap, layers.circle.mtn4GData);
      }
    } else {
      removeLayer(currentMap, layers.circle.mtn2GData);
      removeLayer(currentMap, layers.circle.mtn3GData);
      removeLayer(currentMap, layers.circle.mtn4GData);
      removeLayer(currentMap, layers.circle.allMtnData);
    }

    if (showDataOnMap?.showMOOV) {
      if (showDataOnMap?.showMOOV2G) {
        //Select 2G
        addCircleLayerWithFilterIcon(currentMap, layers.circle.moov2GData, sources.coverage.allDataId, 0.7, ["==", ['get', 'covMOOV2G'], 1], 'mo_data')
        // setLayerVisible(currentMap, layers.circle.moov2GData)
      } else {
        removeLayer(currentMap, layers.circle.moov2GData);
      }
      if (showDataOnMap?.showMOOV3G) {
        addCircleLayerWithFilterIcon(currentMap, layers.circle.moov3GData, sources.coverage.allDataId, 0.7, ["==", ['get', 'covMOOV3G'], 1], 'mo_data')
      } else {
        removeLayer(currentMap, layers.circle.moov3GData);
      }
      if (showDataOnMap?.showMOOV4G) {
        addCircleLayerWithFilterIcon(currentMap, layers.circle.moov4GData, sources.coverage.allDataId, 0.7, ["==", ['get', 'covMOOV4G'], 1], 'mo_data')
      } else {
        removeLayer(currentMap, layers.circle.moov4GData);
      }
    } else {
      removeLayer(currentMap, layers.circle.moov2GData);
      removeLayer(currentMap, layers.circle.moov3GData);
      removeLayer(currentMap, layers.circle.moov4GData);
      removeLayer(currentMap, layers.circle.allMoovData);
    }

      if(showDataOnMap?.showDistrictLimit){
        addFillLayer(currentMap, layers.fill.districtId, sources.map.districtId, 1, 8, colors.map.fill.districtId, 0.1);
        addLineLayer(currentMap, layers.line.districtId, sources.map.districtId, 1, 8, colors.map.line.districtId, 3);
      }else{
        removeLayer(currentMap, layers.fill.districtId);
        removeLayer(currentMap, layers.line.districtId);
      }
      if(showDataOnMap?.showRegionLimit){
        addFillLayer(currentMap, layers.fill.regionId, sources.map.regionId, 6, 8.3, colors.map.fill.regionId, 0.2);
        addLineLayer(currentMap, layers.line.regionId, sources.map.regionId, 6, 8.5, colors.map.line.regionId, 2.5);
      }else{
        removeLayer(currentMap, layers.fill.regionId);
        removeLayer(currentMap, layers.line.regionId);
      }
      if(showDataOnMap?.showDepartmentLimit){
        addFillLayer(currentMap, layers.fill.departmentId, sources.map.departmentId, 8, 9, colors.map.fill.departmentId, 0.2);
        addLineLayer(currentMap, layers.line.departmentId, sources.map.departmentId, 8, 11, colors.map.line.departmentId, 2);
      }else{
        removeLayer(currentMap, layers.fill.departmentId);
        removeLayer(currentMap, layers.line.departmentId);
      }
      if(showDataOnMap?.showSubPrefectureLimit){
        addLineLayer(currentMap, layers.fill.subPrefectureId, sources.map.subPrefectureId, 9, 20, colors.map.fill.subPrefectureId, 0.2);
        addLineLayer(currentMap, layers.line.subPrefectureId, sources.map.subPrefectureId, 9, 20, colors.map.line.subPrefectureId, 1);
      }else{
        removeLayer(currentMap, layers.fill.subPrefectureId);
        removeLayer(currentMap, layers.line.subPrefectureId);
      }


  }

}



export const handleCreateLocationPopup = async (map, coordinates) => {
  try {
    let _data;
    let _entities;

    const y = localVariables.getItem(year);
    const response = await userAxiosInstance.get('/get-data-file/geojson/all_data'+y+'.geojson').then((res => res))

    console.log("RETOUR =================>>>>>>>>>", response)

    if (response?.status == 200) {
      // console.log("RETOUR =================>>>>>>>>>", response)
      _data = response?.data;
      const location = localVariables.getItem('userLocationAdresse')
      console.log("RES 1 =================>>>>>>>>>",location?.f_name)
      _entities = _data.features.filter(feature => (
        feature.properties?.name == location?.f_name.toUpperCase()
      ))
      console.log("RES 2 =================>>>>>>>>>", _entities)
      // console.log("RRRRRESPONSE =================>>>>>>>>>", _entities)

      if (_entities[0]?.properties) {
        console.log("COORDONNEES =================>>>>>>>>>", coordinates)
        console.log("Données =================>>>>>>>>>", _entities[0])
        const usrLoc = localVariables.getItem("userLocationAdresse");
        console.log("Données utilisateur =================>>>>>>>>>", _entities[0])
        const popup = new mapboxgl.Popup({ className: 'pop-back' })
          .setLngLat(coordinates)
          .setHTML(
            '<div style="font-size:8px!important;" >' +
            '<div class="text-center text-info"><b>' + location?.f_name + '</b></br></div>' +
            '<div><b> Population totale couverte :</b>' + _entities[0]?.properties?.popCov + ' / ' + _entities[0]?.properties?.pop + '</br>' +
            '<div><b>Population couverte : </b></br>' +
            '<div><b>2G : </b>' + _entities[0]?.properties?.pop2G + ' => ' + (_entities[0]?.properties?.pop2G > 0 ? (_entities[0]?.properties?.pop2G * 100 / _entities[0]?.properties?.pop).toFixed(1) : 0) + '% /' + ((_entities[0]?.properties?.popORANGE2G > 0) ? "ORANGE-" :"") + ((_entities[0]?.properties?.popMTN2G > 0) ? "MTN-" : "") + ((_entities[0]?.properties?.popMOOV2G > 0) ? "MOOV" :"") + '</br>' +
            '<div><b>3G : </b>' + _entities[0]?.properties?.pop3G + ' => ' + (_entities[0]?.properties?.pop3G > 0 ? (_entities[0]?.properties?.pop3G * 100 / _entities[0]?.properties?.pop).toFixed(1) : 0) + '% /' + ((_entities[0]?.properties?.popORANGE3G > 0) ? "ORANGE-" : "") + ((_entities[0]?.properties?.popMTN3G > 0) ? "MTN-" : "") + ((_entities[0]?.properties?.popMOOV3G > 0) ? "MOOV" : "") + '</br>' +
            '<div><b>4G : </b>' + _entities[0]?.properties?.pop4G + ' => ' + (_entities[0]?.properties?.pop4G > 0 ? (_entities[0]?.properties?.pop4G * 100 / _entities[0]?.properties?.pop).toFixed(1) : 0) + '% /' + ((_entities[0]?.properties?.popORANGE4G > 0) ? "ORANGE-" : "") + ((_entities[0]?.properties?.popMTN4G > 0) ? "MTN-" : "") + ((_entities[0]?.properties?.popMOOV4G > 0) ? "MOOV" : "") + '</br>' +
            '</div>'
          )
          .addTo(map);
      } else {
        toastError("Aucune localité du nom de " + location.f_name + " trouvée")
      }
    } else {
      toastError("Aucune localité correspondante trouvée")
    }

  } catch (error) {

  }


}