import logo from './logo.svg';
import './App.css';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primereact/resources/primereact.min.css";     
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';                               

import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";
import PageContainer from './pages/main/MainPageContainer';
import { AdminDashboardPage } from './pages/admin/admin/dashboard/AdminDashboardPage';
import { UserLoginPage } from './pages/auth/login/UserLoginPage';
import { UserRegisterPage } from './pages/auth/register/UserRegisterPage';
import { UserSuccessVerifyPage } from './pages/auth/verify/UserSuccessVerifyPage';
import { PasswordForgotPage } from './pages/auth/verify/PasswordForgotPage';
import { AdminFiberOpticPage } from './pages/admin/admin/fiberOptic/AdminFiberOpticPage';

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdminDistrictPage } from './pages/admin/admin/adminEntities/district/AdminDistrictPage';
import { AdminRegionPage } from './pages/admin/admin/adminEntities/region/AdminRegionPage';
import { AdminDepartmentPage } from './pages/admin/admin/adminEntities/departement/AdminDepartmentPage';
import { AdminSubPrefecturePage } from './pages/admin/admin/adminEntities/subPrefecture/AdminSubPrefecturePage';
import { AdminLocalityPage } from './pages/admin/admin/adminEntities/locality/AdminLocalityPage';
import { AdminOperatorPage } from './pages/admin/admin/operartor/AdminOperatorPage';
import { AdminTechnologyPage } from './pages/admin/admin/technology/AdminTechnologyPage';
import { AdminPrivateRoutes } from './services/provider/root/adminRootProvider';
import { AdminLoginPage } from './pages/auth/login/AdminLogin';
import { GoOnAdmin } from './pages/auth/goOn/GoOnAdmin';
import { AdminProblemPage } from './pages/admin/admin/problems/AdminProblemPage';
import { AdminAppSignagePage } from './pages/admin/admin/problems/appSignage/AdminAppSignagePage';
import { AdminNetworkSignagePage } from './pages/admin/admin/problems/networkSignage/AdminNetworkSignagePage';
import { AdminCategoryPage } from './pages/admin/admin/newsLetters/categories/AdminCategoryPage';
import { AdminNewsLetterPage } from './pages/admin/admin/newsLetters/AdminNewsLetterPage';
import { AdminHomeMessagePage } from './pages/admin/admin/newsLetters/homeMessages/AdminHomeMessagePage';
import { AdminBufferLimitePage } from './pages/admin/admin/geoData/adminBufferLimite/AdminBufferLimitePage';
import { AdminCoveragePage } from './pages/admin/admin/geoData/adminCoverage/AdminCoveragePage';
import { AdminCoverageExcelPage } from './pages/admin/admin/geoData/adminCoverage/AdminCoverageExcelPage';
import { OperatorPrivateRoutes } from './services/provider/root/operatorRootProvider';
import { OperatorDashBoardPage } from './pages/user/operator/dashboard/OperatorDashBoardPage';
import { GoOnOperator } from './pages/auth/goOn/GoOnOperator';
import { OperatorCoveragePage } from './pages/user/operator/coverage/OperatorCoveragePage';
import { OperatorExcelPage } from './pages/user/operator/coverage/excel/OperatorExcelPage';



function App() {
  return (
    <div className="">
    <Router>
      <Routes>
        <Route path='/*' element={<PageContainer />} ></Route>
        <Route path='/login' element={<UserLoginPage/>} ></Route>
        <Route path='/login-admin' element={<AdminLoginPage/>} ></Route>
        <Route path='/register' element={<UserRegisterPage/>} ></Route>
        <Route path='/pass-forget' element={<PasswordForgotPage/>} ></Route>
        <Route path='/verify-account' element={<UserSuccessVerifyPage/>} ></Route>

        {/* ADMIN */}
        <Route path='/go-on-admin' element={<AdminPrivateRoutes children={ <GoOnAdmin />} />} ></Route>
        <Route path='/admin' element={<AdminPrivateRoutes children={ <AdminDashboardPage />} />} ></Route>
        <Route path='/problem' element={<AdminPrivateRoutes children={ <AdminProblemPage />} />} ></Route>
        <Route path='/app-signages' element={<AdminPrivateRoutes children={ <AdminAppSignagePage />} />} ></Route>
        <Route path='/network-signages' element={<AdminPrivateRoutes children={ <AdminNetworkSignagePage />} />} ></Route>
        <Route path='/admin-fiber-optical-manage-page' element={<AdminPrivateRoutes children={ <AdminFiberOpticPage />} />} ></Route>
        <Route path='/admin-district' element={<AdminPrivateRoutes children={ <AdminDistrictPage />} />} ></Route>
        <Route path='/admin-region' element={<AdminPrivateRoutes children={ <AdminRegionPage />} />} ></Route>
        <Route path='/admin-department' element={<AdminPrivateRoutes children={ <AdminDepartmentPage />} />} ></Route>
        <Route path='/admin-sub-prefecture' element={<AdminPrivateRoutes children={ <AdminSubPrefecturePage />} />} ></Route>
        <Route path='/admin-localities' element={<AdminPrivateRoutes children={ <AdminLocalityPage />} />} ></Route>
        <Route path='/admin-test' element={<AdminPrivateRoutes children={<AdminLocalityPage />} />} ></Route>
        <Route path='/admin-coverage' element={<AdminPrivateRoutes children={ <AdminCoveragePage />} />} ></Route>
        <Route path='/admin-coverage-update-by-excel' element={<AdminPrivateRoutes children={ <AdminCoverageExcelPage />} />} ></Route>
        <Route path='/admin-limites-entities' element={<AdminPrivateRoutes children={ <AdminBufferLimitePage />} />} ></Route>
        <Route path='/admin-operator' element={<AdminPrivateRoutes children={ <AdminOperatorPage />} />} ></Route>
        <Route path='/admin-technology' element={<AdminPrivateRoutes children={<AdminTechnologyPage />} />} ></Route>

        <Route path='/categories' element={<AdminPrivateRoutes children={<AdminCategoryPage />} />} ></Route>
        <Route path='/newsLetters' element={<AdminPrivateRoutes children={<AdminNewsLetterPage />} />} ></Route>
        <Route path='/home-messages' element={<AdminPrivateRoutes children={<AdminHomeMessagePage />} />} ></Route>
        {/* END ADMIN */}

        {/* OPERATOR */}
        <Route path='/go-on-operator' element={<OperatorPrivateRoutes children={ <GoOnOperator />} />} ></Route>
        <Route path='/operator-dashboard' element={<OperatorPrivateRoutes children={<OperatorDashBoardPage />} />} ></Route>
        <Route path='/operator-coverage' element={<OperatorPrivateRoutes children={<OperatorCoveragePage />} />} ></Route>
        <Route path='/operator-coverage-excel' element={<OperatorPrivateRoutes children={<OperatorExcelPage />} />} ></Route>

        
      </Routes>
    </Router>
    <ToastContainer />
    </div>
      
  );
}

export default App;
