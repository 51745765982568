import { userOperator } from "../../../../constants";
import { year } from "../../../constants/map/mapDataConstants";
import { localVariables } from "../../../local/SecureLocalStorageVariable";
import { adminAxiosInstance, operatorAxiosInstance, userAxiosInstance } from "../../config/axiosConfig";

export const getAdminOperators = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/operators`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            // console.log(response.data.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const getUserOperators = async (setState) => {
    try {
        const y = localVariables.getItem(year);
        const operateur = localVariables.getItem(userOperator)
        if(operateur != null && operateur != undefined){
            setState(operateur)
        }else{
            let response = null;
            if(y == 2023){
                response = await userAxiosInstance.get(`/operators`).then((res => res))
                console.log("liste des OPERATEUR ===> :", response)
            }
            else if(y == 2021){
                response = await userAxiosInstance.get(`/operators2021`).then((res => res))
            }
            // => {
            //     console.log("Données sur la liste des categories", response.data)
            //     setState(response.data)
            // }
            setState([]);
    
            if (response?.status == 200) {
                // console.log(response.data.data)
                localVariables.setItem(userOperator,response.data.data)
                setState(response.data.data)
            }
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}