import { AdminMainPageContainer } from "../../AdminMainPageContainer";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AdminNewsLetterList } from "./AdminNewsLetterList";
import {
  DeleteNewsLetterDialog, DeleteSNewsLetterDialog
} from "./AdminNewsLetterDeleteDialog";
import {
  addNewsLetter,
  deleteNewsLetter,
  editNewsLetter,
  getNewsLetters,
} from "../../../../services/api/admin/newsLetter/newsLetterApiService";
import { AdminNewsLetterDialog } from "./AdminNewsLetterDialog";
import { getLocalities } from "../../../../services/api/admin/adminEntities/locality/localityApiService";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";
import { toastError, toastSuccess } from "../../../../components/notification/notification";
import { getCategories } from "../../../../services/api/admin/newsLetter/categories/categoryApiService";
import { AdminNewsLetterViewDialog } from "./AdminNewsLetterViewDialog";

export const AdminNewsLetterPage = () => {
  const [openNewsLetterDialog, setOpenNewsLetterDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [newsLetters, setNewsLetters] = useState();
  const [newsLetter, setNewsLetter] = useState();
  const [openNewsletterViewDialog, setOpenNewsletterViewDialog] = useState(false);

  const [categories, setCategories] = useState();

  const [selectedNewsLetters, setSelectedNewsLetters] = useState(null);

  const [newsLetterDialogTitle, setNewsLettersDialogTitle] = useState(
    "CREATION D'UN ARTICLE"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const [NewsLetterDialogTitle, setNewsLetterDialogTitle] = useState(
    "CREATION D'UN ARTICLE"
  );

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getNewsLetters(setNewsLetters);
    getCategories(setCategories)
  }, []);

  useEffect(() => {
  }, [newsLetters]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement du newsletter effectué avec succès!");
      getNewsLetters(setNewsLetters);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du newsletter !");
    }
    setOpenNewsLetterDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du newsletter effectué avec succès!");
      getNewsLetters(setNewsLetters);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du trançon !");
    }
    setOpenNewsLetterDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression de la nouvelle effectuée avec succès!");
      getNewsLetters(setNewsLetters);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression de la nouvelle !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  const handleViewNewsletter = (newl) => {
    setNewsLetter({ ...newl });
    console.log("Details newsletter =======>", newl);
    setOpenNewsletterViewDialog(true);
  };

  //INITIALISATION
  const handleInitVar = () => {
    setNewsLetter();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setNewsLetterDialogTitle("CREATION D'UN ARTICLE");
    setSelectedNewsLetters();
    setOpenNewsLetterDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newsLetter = { ...newsLetter };

    _newsLetter[`${name}`] = val;

    setNewsLetter(_newsLetter);
  };

  const handleSubmitNewsLetter = async (e) => {
    e.preventDefault();
    if (newsLetter.id) {
      await editNewsLetter(newsLetter, setEditResponse);
    } else {
      await addNewsLetter(newsLetter, setCreateResponse);
    }
  };

  //EDITION
  const handleEditNewsLetter = (fiber) => {
    setNewsLetterDialogTitle("MODIFICATION D'UN ARTICLE");
    setNewsLetter({ ...fiber });
    setOpenNewsLetterDialog(true);
  };

  //SUPPRESSION
  const handleDeleteNewsLetterDialog = (fiber) => {
    setNewsLetter({ ...fiber });
    setOpenDeleteDialog(true);
  };

  const handleDeleteNewsLetter = async () => {
    await deleteNewsLetter(newsLetter, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteNewsLetters = async () => {
    try {
      selectedNewsLetters.forEach(async (newsLetter) => {
        await deleteNewsLetter(newsLetter, setDeleteResponse);
      });
    } catch (error) {
      toastError(
        "Erreur lors de la suppression de la nouvelle selectionnés !"
      );
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "name", header: "nom" },
    { field: "code", header: "code" },
    { field: "description", header: "description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, newsLetters);
        doc.save("artci-cartodonnees-extract-newsletter.pdf");
      });
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    { field: "code", header: "Code" },
    { field: "title", header: "Titre" },
    { field: "description", header: "description" },
  ];

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-draw-polygon"
        title="Newsletters"
        subTitle="Gestion des nouvelles du bulletin d'information"
        description="Creer, modifier, alerter ou supprimer des nouvelles du bulletin d'information "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des nouvelles du bulletin d'information
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenNewsLetterDialog(true);
                        setNewsLetter();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer une nouvelle
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {newsLetters ? <>
                        <AdminNewsLetterList
                          newsLetters={newsLetters}
                          handleEditNewsLetter={handleEditNewsLetter}
                          handleDeleteNewsLetterDialog={
                            handleDeleteNewsLetterDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          dt={dt}
                          selectedNewsLetters={selectedNewsLetters}
                          setSelectedNewsLetters={setSelectedNewsLetters}
                          handleViewNewsletter={handleViewNewsletter}
                        />
                      </> : <>
                        <div className="container px-1 px-md-5 px-lg-1 mbody">
                          <DataLoaderTailSpin />
                        </div>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminNewsLetterViewDialog
              display={openNewsletterViewDialog}
              setDisplay={setOpenNewsletterViewDialog}
              newsLetter={newsLetter}
            />

            <AdminNewsLetterDialog
              display={openNewsLetterDialog}
              setDisplay={setOpenNewsLetterDialog}
              handleSumitNewsLetter={handleSubmitNewsLetter}
              newsLetter={newsLetter}
              title={newsLetterDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setNewsLetter={setNewsLetter}
              newsLetters={newsLetters}
              categories={categories}
            />
            <DeleteNewsLetterDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeletenewsLetters={handleDeleteNewsLetters}
              newsletter={newsLetter}
            />
            <DeleteSNewsLetterDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeleteNewsLetter={handleDeleteNewsLetter}
              newsLetter={newsLetter}
            />
          </>
        }
      />
    </>
  );
};
