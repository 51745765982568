import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { baseFileUrl, generaleStats } from "../../../../constants";
import { handleNumThousand } from "../../../../services/tools/convertions";
import { localVariables } from "../../../../services/local/SecureLocalStorageVariable";
import { getGeneraleStatistics } from "../../../../services/api/map/statisticApiService";
import ReactToPrint from "react-to-print";


export const AdminOperatorVIewDialog = ({ display, setDisplay, operator, technologies }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);
    const [opr, setOpr] = useState();
    const [generaleData, setGeneraleData] = useState();
    const contentRef = useRef();

    const [isPrinting, setIsPrinting] = useState(false);

    const [load, setLoad] = useState(false);

    const onClick = (position) => {
        if (operator?.name == "ORANGE") {
            setOpr('or')
        } else if (operator?.name == "MTN") {
            setOpr('mt')
        } else if (operator?.name == "MOOV") {
            setOpr('mo')
        }

        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    useEffect(() => {
        handleGetData()
    }, []);

    const handleGetData = async () => {
        await getGeneraleStatistics();
        setLoad(true)
    }

    useEffect(() => {
        if(load){
        const _data = localVariables.getItem(generaleStats);
        console.log("DONNEES GENRALES ========+>", _data)
        setGeneraleData(_data)
        }
    }, [load]);

    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);


    return (<>
        <Dialog
            header="DETAILS OPERATEUR"
            visible={display}
            modal
            style={{ width: "80vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
        <div className="mb-2">
            <ReactToPrint
                trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                content={() => contentRef.current}
                // onBeforePrint={()=> {!subPrefecture?.description?.length > 0 && setIsPrinting(true)}}
                onAfterPrint={() => setIsPrinting(false)}
                pageStyle={`@media print { @page { size: 450mm 250mm;}}`}
            />
            <hr />
        </div>
            <div ref={contentRef}>

            </div>
            <div className="row">
                <div className="col-md-4 text-center">
                    <h3 className=""><b>{operator?.name}</b></h3>
                    <img src={baseFileUrl + operator?.image_path} height={200} width={200} alt="" />
                    <img src="../.." alt="" />
                </div>
                <div className="col-md-8">
                    <div>
                        <div className="bg-light">
                            <p>{operator?.description}</p>
                        </div>
                        {generaleData && <>
                            <div class="no-gutters row bg-light mb-2">
                                <div class="col-md-6">
                                    <div class="widget-content">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-right ml-0 mr-3">
                                                <div class="widget-numbers text-dark">{handleNumThousand(generaleData["nb_loc"])}</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Localités</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-right ml-0 mr-3">
                                                <div class="widget-numbers text-success">{handleNumThousand(generaleData["nb_loc_cov_" + opr])}</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Localités couvertes</div>
                                                <div class="widget-subheading">{generaleData["per_loc_cov_" + opr]?.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-right ml-0 mr-3">
                                                <div class="widget-numbers text-danger">{handleNumThousand(generaleData["nb_loc_no_cov_" + opr])}</div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Localité non couverte</div>
                                                <div class="widget-subheading">{(100 - generaleData["per_loc_cov_" + opr])?.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="widget-content">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-right ml-0 mr-3">
                                                <div class=" text-info"><h4><b>{handleNumThousand(generaleData["nb_pop"])}</b></h4> </div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Population</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-right ml-0 mr-3">
                                                <div class=" text-warning"><h4><b>{handleNumThousand(generaleData["nb_pop_cov_" + opr])}</b></h4> </div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Population couverte</div>
                                                <div class="widget-subheading">{generaleData["per_pop_cov_" + opr]?.toFixed(2)}% </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-content">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-right ml-0 mr-3">
                                                <div class=" text-danger"><h4><b>{handleNumThousand(generaleData["nb_pop_cov_no_" + opr])}</b> </h4> </div>
                                            </div>
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Population non couverte</div>
                                                <div class="widget-subheading">{(100 - generaleData["per_pop_cov_" + opr])?.toFixed(2)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                    <div>
                    </div>
                </div>
                <div className="col-md-12 rounded">
                    <div class="">
                        <div>
                            <table className="table table-bordered table-striped table-hover table-width table-warning">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th colSpan={2} className="text-center">Localités</th>
                                        <th colSpan={2} className="text-center">Population</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th className="text-center">Couverte</th>
                                        <th className="text-center">Non couverte</th>
                                        <th className="text-center">Couverte</th>
                                        <th className="text-center">Non couverte</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {technologies?.length > 0 && technologies?.map((techno, index) => {
                                        return (
                                            <tr key={"ot" + index}>
                                                <td>
                                                    <b>{techno?.name}</b>
                                                </td>
                                                <td key={"tt1" + index}> {handleNumThousand(generaleData["nb_loc_cov_" + opr + "_" + techno.name.toLowerCase()])} <br />
                                                    <small> -> {(generaleData["nb_loc_cov_" + opr+ "_" + techno.name.toLowerCase()] > 0 ? (generaleData["nb_loc_cov_" + opr+ "_" + techno.name.toLowerCase()] * 100 / generaleData?.nb_loc).toFixed(1) : 0)} %</small>
                                                </td>
                                                <td key={"tt2" + index}> {handleNumThousand(generaleData["nb_loc"] - generaleData["nb_loc_cov_" + opr + "_" + techno.name.toLowerCase()])} <br />
                                                    <small> -> {((generaleData["nb_loc"] - generaleData["nb_loc_cov_" + opr + "_" + techno.name.toLowerCase()]) > 0 ? ((generaleData["nb_loc"] - generaleData["nb_loc_cov_" + opr + "_" + techno.name.toLowerCase()]) * 100 / generaleData?.nb_loc).toFixed(1) : 0)} %</small>
                                                </td>
                                                <td key={"tt3" + index}> {handleNumThousand(generaleData["nb_pop_cov_" + opr + "_" + techno.name.toLowerCase()])} <br />
                                                    <small> -> {(generaleData["nb_pop_cov_" + opr+ "_" + techno.name.toLowerCase()] > 0 ? (generaleData["nb_pop_cov_" + opr+ "_" + techno.name.toLowerCase()] * 100 / generaleData?.nb_pop).toFixed(1) : 0)} %</small>
                                                </td>
                                                <td key={"tt4" + index}> {handleNumThousand(generaleData["nb_loc_no_cov_" + opr + "_" + techno.name.toLowerCase()])} <br />
                                                    <small> -> {((generaleData["nb_pop"] - generaleData["nb_loc_no_cov_" + opr + "_" + techno.name.toLowerCase()]) > 0 ? ((generaleData["nb_pop"] - generaleData["nb_loc_no_cov_" + opr + "_" + techno.name.toLowerCase()]) * 100 / generaleData?.nb_pop).toFixed(1) : 0)} %</small>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}