
import { useState, useEffect, useRef } from "react";//them 
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import { userRegisterService } from "../../../services/api/auth/register/userRegisterService";
import './register.css'

export const UserRegisterPage = () => {
    const toast = useRef(null);

    const toastSuccess = (message) => {
        toast.current.show({ severity: 'success', summary: 'Info', detail: message });
    };

    const toastError = (message) => {
        toast.current.show({ severity: 'warn', summary: 'Info', detail: message });
    };



    const navigate = useNavigate()

    const [user, setUser] = useState();
    const [registerResponse, setregisterResponse] = useState();


    useEffect(() => {
        if (registerResponse?.error == false) {
            toastSuccess("Inscription réalisée avec success. veuillez vous connecté")
             navigate('/register', { replace: true })
        // } else {
        //     toastError("Authentification impossible")
         }
    }, [registerResponse]);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };

        _user[`${name}`] = val;

        setUser(_user);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (user?.username.trim() && user?.password.trim()) {
            if(user?.password == user?.confirmed_password){
                let response = await userRegisterService(user);
            }

        }



    }
    return (
        <>
            <div class="h-100">
                <div class="row h-100 no-gutters">
                    <div class="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center col-md-12 col-lg-7">
                        <div class="mx-auto app-login-box col-sm-12 col-md-10 col-lg-9"><div class="app-logo">
                        </div>
                            <h4>
                                <h1 className='title-login mb-2'>Cartodonnees inscription</h1>
                                <span>Cela ne prend que 
                                    <span class="text-success"> quelques secondes </span>
                                    pour créer votre compte</span>
                                    <div>Remplir le formulaire</div>
                            </h4>
                            <div>
                                <form novalidate="" class="ng-untouched ng-pristine ng-valid">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <fieldset id="__BVID__134" class="form-group">
                                                <div tabindex="-1" role="group">
                                                    <label for="exampleName">Prénoms</label>
                                                    <input id="exampleName" name="text" type="text" placeholder="Entrez votre prénoms ici..." class="form-control" onChange={(e) => onInputChange(e, "first_name")} />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-md-4">
                                            <fieldset id="__BVID__134" class="form-group">
                                                <div tabindex="-1" role="group">
                                                    <label for="exampleName">Nom</label>
                                                    <input id="exampleName" name="text" type="text" placeholder="Entrez votre nom ici..." class="form-control" onChange={(e) => onInputChange(e, "last_name")} required/>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-md-12">
                                            <fieldset id="__BVID__132" class="form-group">
                                                <div tabindex="-1" role="group">
                                                    <label for="exampleEmail">
                                                        <span class="text-danger">*</span> Email </label>
                                                    <input id="exampleEmail" name="email" type="email" placeholder="Entrez votre email ici..." class="form-control" onChange={(e) => onInputChange(e, "email")} />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-md-6">
                                            <fieldset id="__BVID__136" class="form-group">
                                                <div tabindex="-1" role="group">
                                                    <label for="examplePassword"><span class="text-danger">*</span> Mot de passe </label>
                                                    <input id="examplePassword" name="password" type="password" placeholder="Entrer un mot de passe..." class="form-control" onChange={(e) => onInputChange(e, "password")} />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div class="col-md-6">
                                            <fieldset id="__BVID__138" class="form-group">
                                                <div tabindex="-1" role="group">
                                                    <label for="examplePasswordRep">
                                                        <span class="text-danger">*</span> Confirmer le mot de passe </label>
                                                    <input id="examplePasswordRep" name="passwordrep" type="password" placeholder="Confirmer le mot de passe..." class="form-control" onChange={(e) => onInputChange(e, "confirmed_password")} />
                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input id="exampleCheck" type="checkbox" name="check" autocomplete="off" value="true" class="custom-control-input" />
                                        <label for="exampleCheck" class="custom-control-label"> Accept our <Link href="/architectui-angular-pro/pages/register">Termes and Conditions</Link>. </label>
                                    </div>
                                    <div class="mt-4 d-flex align-items-center">
                                        <h5 class="mb-0"> Vous avez deja un compte ? <Link to="/login" class="text-primary" href="/architectui-angular-pro/pages/register">Se connecter</Link></h5>
                                        <div class="ml-auto">
                                            <button type="button" class="btn btn-wide btn-pill btn-shadow btn-hover-shine btn-secondary btn-lg">Creer un compte </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="d-lg-flex d-xs-none col-lg-5">
                        <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-plum-plate w-100">
                            <div className="container d-flex justify-content-center">
                                <div>
                                    <div>
                                        <Link href="https://www.artci.ci" target="_blank">
                                            <img src="../images/logo-artci.png" className='' width={400} />
                                        </Link>

                                        <h1 className='text-center'><i className="fa fa-map"></i></h1>
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <Link to="/" className="btn btn-dark"><i className="fa fa-map mr-2"></i> Retour à la carte</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}