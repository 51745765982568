import { useState } from "react";
import { getGeneraleStatistics } from "../api/map/statisticApiService";
import { colors } from "../../constants";
import { dataToExport } from "../constants/map/mapDataConstants";
import { localVariables } from "../local/SecureLocalStorageVariable";
import { getUserTechnologies } from "../api/user/technologies/technologyApiService";
import { getUserOperators } from "../api/user/operators/operatorApiService";

export const handleDrawLocalitiesChart = async (
  setLocsStats,
  setpopStats,
  setForecastStats,
  showDataOnMap,
) => {


  try {

    let data = [];
    let _data_orange = [];
    let _data_mtn = [];
    let _data_moov = [];
    let _data_l = [];
    let _no_data = []

    let _data_pop = [];
    let _no_data_pop = [];
    let _data_pop_orange = [];
    let _data_pop_mtn = [];
    let _data_pop_moov = [];

    let _foreacst_data = []


    let _labels = []


    const toExport = localVariables.getItem(dataToExport)

    if (toExport != null) {
      data = toExport.data;
      console.log("DONNEES A EXPORTER ===========++>", data)
      _foreacst_data = [data.popORANGE2G, data.popMTN2G, data.popMOOV2G]
      if (showDataOnMap?.showLocalities2G && showDataOnMap?.showLocalities3G && showDataOnMap?.showLocalities4G) {
        _labels = ["2G", "3G", "4G"];
        _data_l = [data.locCouv2G, data.locCouv3G, data.locCouv4G];
        _data_pop = [data.pop2G, data.pop3G, data.pop4G];
      }
      else if (showDataOnMap?.showLocalities2G && showDataOnMap?.showLocalities3G) {
        _labels = ["2G", "3G"]
        _data_l = [data.locCouv2G, data.locCouv3G]
        _data_pop = [data.pop2G, data.pop3G];
      }
      else if (showDataOnMap?.showLocalities2G && showDataOnMap?.showLocalities4G) {
        _labels = ["2G", "4G"]
        _data_l = [data.locCouv2G,0,data.locCouv4G]
        _data_pop = [data.pop2G, 0, data.pop4G];
      }
      else if (showDataOnMap?.showLocalities3G && showDataOnMap?.showLocalities4G) {
        _labels = ["3G", "4G"]
        _data_pop = [0, data.pop3G, data.pop4G];
      } else if (showDataOnMap?.showLocalities2G) {
        _labels = ["2G"]
        _data_l = [data.locCouv2G]
        _data_pop = [data.pop2G];
      } else if (showDataOnMap?.showLocalities3G) {
        _labels = ["3G"]
        _data_l = [0, data.locCouv3G]
        _data_pop = [0, data.pop3G];
      } else if (showDataOnMap?.showLocalities4G) {
        _labels = ["4G"]
        _data_l = [0, 0, data.locCouv4G]
        _data_pop = [0, 0, data.pop4G];
      }


      if (showDataOnMap?.showNoLocalities2G && showDataOnMap?.showNoLocalities3G && showDataOnMap?.showNoLocalities4G) {
         _labels = getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [data.nbloc - data.locCouv2G, data.nbloc - data.locCouv3G, data.nbloc - data.locCouv4G];
        _no_data_pop = [data.population - data.pop2G, data.population - data.pop3G, data.population - data.pop4G];
      }
      else if (showDataOnMap?.showNoLocalities2G && showDataOnMap?.showNoLocalities3G) {
        _labels = getLabel(_labels,["2G", "3G"])
        _no_data = [data.nbloc - data.locCouv2G, data.nbloc - data.locCouv3G]
        _no_data_pop = [data.population - data.pop2G, data.population - data.pop3G,];
      }
      else if (showDataOnMap?.showNoLocalities2G && showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [data.nbloc - data.locCouv2G, 0, data.nbloc - data.locCouv4G]
        _no_data_pop = [data.population - data.pop2G, 0, data.population - data.pop4G];
      }
      else if (showDataOnMap?.showNoLocalities3G && showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [0, data.nbloc - data.locCouv3G, data.nbloc - data.locCouv4G]
        _no_data_pop = [0, data.population - data.pop3G, data.population - data.pop4G];
      } else if (showDataOnMap?.showNoLocalities2G) {
        _labels = getLabel(_labels,["2G"])
        _no_data = [data.nbloc - data.locCouv2G]
        _no_data_pop = [data.population - data.pop2G];
      } else if (showDataOnMap?.showNoLocalities3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _no_data = [0, data.nbloc - data.locCouv3G]
        _no_data_pop = [0, data.population - data.pop3G];
      } else if (showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [0, 0, data.nbloc - data.locCouv4G]
        _no_data_pop = [0, 0, data.population - data.pop4G];
      }

      if (showDataOnMap?.showORANGE2G && showDataOnMap?.showORANGE3G && showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [data.locCouvORANGE2G, data.locCouvORANGE3G, data.locCouvORANGE4G]
        _data_pop_orange = [data.popORANGE2G, data.popORANGE3G, data.popORANGE4G]
      } else if (showDataOnMap?.showORANGE2G && showDataOnMap?.showORANGE3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_orange = [data.locCouvORANGE2G, data.locCouvORANGE3G]
        _data_pop_orange = [data.popORANGE2G, data.popORANGE3G]
      } else if (showDataOnMap?.showORANGE2G && showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [data.locCouvORANGE2G, 0, data.locCouvORANGE4G]
        _data_pop_orange = [data.popORANGE2G, 0, data.popORANGE4G]
      } else if (showDataOnMap?.showORANGE3G && showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [0, data.locCouvORANGE3G, data.locCouvORANGE4G]
        _data_pop_orange = [0, data.popORANGE3G, data.popORANGE4G]
      } else if (showDataOnMap?.showORANGE2G) {
        _labels =  getLabel(_labels,["2G"])
        _data_orange = [data.locCouvORANGE2G]
        _data_pop_orange = [data.popORANGE2G]
      } else if (showDataOnMap?.showORANGE3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_orange = [0, data.locCouvORANGE3G]
        _data_pop_orange = [0, data.popORANGE3G]
      } else if (showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [0, 0, data.locCouvORANGE4G]
        _data_pop_orange = [0, 0, data.popORANGE4G]
      }

      if (showDataOnMap?.showMTN2G && showDataOnMap?.showMTN3G && showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [data.locCouvMTN2G, data.locCouvMTN3G, data.locCouvMTN4G]
        _data_pop_mtn = [data.popMTN2G, data.popMTN3G, data.popMTN4G]
      } else if (showDataOnMap?.showMTN2G && showDataOnMap?.showMTN3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_mtn = [data.locCouvMTN2G, data.locCouvMTN3G]
        _data_pop_mtn = [data.popMTN2G, data.popMTN3G]
      } else if (showDataOnMap?.showMTN2G && showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [data.locCouvMTN2G, 0, data.locCouvMTN4G]
        _data_pop_mtn = [data.popMTN2G, 0, data.popMTN4G]
      } else if (showDataOnMap?.showMTN3G && showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [0, data.locCouvMTN3G, data.locCouvMTN4G]
        _data_pop_mtn = [0, data.popMTN3G, data.popMTN4G]
      } else if (showDataOnMap?.showMTN2G) {
        _labels =  getLabel(_labels,["2G"])
        _data_mtn = [data.locCouvMTN2G]
        _data_pop_mtn = [data.popMTN2G]
      } else if (showDataOnMap?.showMTN3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_mtn = [0, data.locCouvMTN3G]
        _data_pop_mtn = [0, data.popMTN3G]
      } else if (showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [0, 0, data.locCouvMTN4G]
        _data_pop_mtn = [0, 0, data.popMTN4G]
      }

      if (showDataOnMap?.showMOOV2G && showDataOnMap?.showMOOV3G && showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [data.locCouvMOOV2G, data.locCouvMOOV3G, data.locCouvMOOV4G]
        _data_pop_mtn = [data.popMOOV2G, data.popMOOV3G, data.popMOOV4G]
      } else if (showDataOnMap?.showMOOV2G && showDataOnMap?.showMOOV3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_moov = [data.locCouvMOOV2G, data.locCouvMOOV3G]
        _data_pop_mtn = [data.popMOOV2G, data.popMOOV3G]
      } else if (showDataOnMap?.showMOOV2G && showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [data.locCouvMOOV2G, 0, data.locCouvMOOV4G]
        _data_pop_mtn = [data.popMOOV2G, 0, data.popMOOV4G]
      } else if (showDataOnMap?.showMOOV3G && showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [0, data.locCouvMOOV3G, data.locCouvMOOV4G]
        _data_pop_mtn = [0, data.popMOOV3G, data.popMOOV4G]
      } else if (showDataOnMap?.showMOOV2G) {
        _labels =  getLabel(_labels,["2G"])
        _data_moov = [data.locCouvMOOV2G]
        _data_pop_moov = [data.popMOOV2G]
      } else if (showDataOnMap?.showMOOV3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_moov = [0, data.locCouvMOOV3G]
        _data_pop_moov = [0, data.popMOOV3G]
      } else if (showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [0, 0, data.locCouvMOOV4G]
        _data_pop_moov = [0, 0, data.popMOOV4G]
      }
    } else {

      data = await getGeneraleStatistics();
      _foreacst_data = [data.pop_cov_orange, data.pop_cov_mtn, data.pop_cov_moov]
     
      console.log('Statistiques =============>', data);

      if (showDataOnMap?.showLocalities2G && showDataOnMap?.showLocalities3G && showDataOnMap?.showLocalities4G) {
        _labels = ["2G", "3G", "4G"]
        _data_l = [data.locs_cov_2G, data.locs_cov_3G, data.locs_cov_4G]
        _data_pop = [data.pop_cov_2G, data.pop_cov_3G, data.pop_cov_4G];
      } else if (showDataOnMap?.showLocalities2G && showDataOnMap?.showLocalities3G) {
        _labels = ["2G", "3G"]
        _data_l = [data.locs_cov_2G, data.locs_cov_3G];
        _data_pop = [data.pop_cov_2G, data.pop_cov_3G];
      } else if (showDataOnMap?.showLocalities2G && showDataOnMap?.showLocalities4G) {
        _labels = ["2G","3G", "4G"]
        _data_l = [data.locs_cov_2G, 0, data.locs_cov_4G];
        _data_pop = [data.pop_cov_2G, 0, data.pop_cov_4G];
      } else if (showDataOnMap?.showLocalities3G && showDataOnMap?.showLocalities4G) {
        _labels = ["2G", "3G", "4G"]
        _data_l = [0, data.locs_cov_3G, data.locs_cov_4G];
        _data_pop = [0, data.pop_cov_3G, data.pop_cov_4G];
      } else if (showDataOnMap?.showLocalities2G) {
        _labels = ["2G"]
        _data_l = [data.locs_cov_2G];
        _data_pop = [data.pop_cov_2G];
      } else if (showDataOnMap?.showLocalities3G) {
        _labels = ["2G","3G"]
        _data_l = [data.locs_cov_3G];
        _data_pop = [0, data.pop_cov_3G];
      } else if (showDataOnMap?.showLocalities4G) {
        _labels = ["2G","3G","4G"]
        _data_l = [0,0,data.locs_cov_4G];
        _data_pop = [0, 0, data.pop_cov_4G];
      }

      
      if (showDataOnMap?.showNoLocalities2G && showDataOnMap?.showNoLocalities3G && showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [data.locs_no_cov_2G, data.locs_no_cov_3G, data.locs_no_cov_4G]
        _no_data_pop = [data.pop - data.pop_cov_2G, data.pop - data.pop_cov_3G, data.pop - data.pop_cov_4G];
      } else if (showDataOnMap?.showNoLocalities2G && showDataOnMap?.showNoLocalities3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _no_data = [data.locs_no_cov_2G, data.locs_no_cov_3G]
        _no_data_pop = [data.pop - data.pop_cov_2G, data.pop - data.pop_cov_3G];
      } else if (showDataOnMap?.showNoLocalities2G && showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [data.locs_no_cov_2G, 0, data.locs_no_cov_4G]
        _no_data_pop = [data.pop - data.pop_cov_2G, 0, data.pop - data.pop_cov_4G];
      } else if (showDataOnMap?.showNoLocalities3G && showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [0, data.locs_no_cov_3G, data.locs_no_cov_4G]
        _no_data_pop = [0, data.pop - data.pop_cov_3G, data.pop - data.pop_cov_4G];
      } else if (showDataOnMap?.showNoLocalities2G) {
        _labels =  getLabel(_labels,["2G"])
        _no_data = [data.locs_no_cov_2G]
        _no_data_pop = [data.pop - data.pop_cov_2G];
      } else if (showDataOnMap?.showNoLocalities3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _no_data = [data.locs_no_cov_3G]
        _no_data_pop = [0, data.pop - data.pop_cov_3G];
      } else if (showDataOnMap?.showNoLocalities4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _no_data = [data.locs_no_cov_4G]
        _no_data_pop = [0, 0, data.pop - data.pop_cov_4G];
      }


      if (showDataOnMap?.showORANGE2G && showDataOnMap?.showORANGE3G && showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [data.locs_cov_2G_orange, data.locs_cov_3G_orange, data.locs_cov_4G_orange]
        _data_pop_orange = [data.pop_cov_2G_orange, data.pop_cov_3G_orange, data.pop_cov_4G_orange]
      } else if (showDataOnMap?.showORANGE2G && showDataOnMap?.showORANGE3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_orange = [data.locs_cov_2G_orange, data.locs_cov_3G_orange]
        _data_pop_orange = [data.pop_cov_2G_orange, data.pop_cov_3G_orange]
      } else if (showDataOnMap?.showORANGE2G && showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [data.locs_cov_2G_orange, 0, data.locs_cov_3G_orange]
        _data_pop_orange = [data.pop_cov_2G_orange, 0, data.pop_cov_4G_orange]
      } else if (showDataOnMap?.showORANGE3G && showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [0, data.locs_cov_3G_orange, data.locs_cov_4G_orange]
        _data_pop_orange = [0, data.pop_cov_3G_orange, data.pop_cov_4G_orange]
      } else if (showDataOnMap?.showORANGE2G) {
        _labels =  getLabel(_labels,["2G"])
        _data_orange = [data.locs_cov_2G_orange]
        _data_pop_orange = [data.pop_cov_2G_orange]
      } else if (showDataOnMap?.showORANGE3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_orange = [0, data.locs_cov_3G_orange]
        _data_pop_orange = [0, data.pop_cov_3G_orange]
      } else if (showDataOnMap?.showORANGE4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_orange = [0, 0, data.locs_cov_4G_orange]
        _data_pop_orange = [0, 0, data.pop_cov_4G_orange]
      }

      if (showDataOnMap?.showMTN2G && showDataOnMap?.showMTN3G && showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [data.locs_cov_2G_mtn, data.locs_cov_3G_mtn, data.locs_cov_4G_mtn]
        _data_pop_mtn = [data.pop_cov_2G_mtn, data.pop_cov_3G_mtn, data.pop_cov_4G_mtn]
      } else if (showDataOnMap?.showMTN2G && showDataOnMap?.showMTN3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_mtn = [data.locs_cov_2G_mtn, data.locs_cov_3G_mtn]
        _data_pop_mtn = [data.pop_cov_2G_mtn, data.pop_cov_3G_mtn]
      } else if (showDataOnMap?.showMTN2G && showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [data.locs_cov_2G_mtn, 0, data.locs_cov_3G_mtn]
        _data_pop_mtn = [data.pop_cov_2G_mtn, 0, data.pop_cov_4G_mtn]
      } else if (showDataOnMap?.showMTN3G && showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [0, data.locs_cov_3G_mtn, data.locs_cov_4G_mtn]
        _data_pop_mtn = [0, data.pop_cov_3G_mtn, data.pop_cov_4G_mtn]
      } else if (showDataOnMap?.showMTN2G) {
        _labels =  getLabel(_labels,["2G"])
        _data_mtn = [data.locs_cov_2G_mtn]
        _data_pop_mtn = [data.pop_cov_2G_mtn]
      } else if (showDataOnMap?.showMTN3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_mtn = [0, data.locs_cov_3G_mtn]
        _data_pop_mtn = [0, data.pop_cov_3G_mtn]
      } else if (showDataOnMap?.showMTN4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_mtn = [0, 0, data.locs_cov_4G_mtn]
        _data_pop_mtn = [0, 0, data.pop_cov_4G_mtn]
      }

      if (showDataOnMap?.showMOOV2G && showDataOnMap?.showMOOV3G && showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [data.locs_cov_2G_moov, data.locs_cov_3G_moov, data.locs_cov_4G_moov]
        _data_pop_moov = [data.pop_cov_2G_moov, data.pop_cov_3G_moov, data.pop_cov_4G_moov]
      } else if (showDataOnMap?.showMOOV2G && showDataOnMap?.showMOOV3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_moov = [data.locs_cov_2G_moov, data.locs_cov_3G_moov]
        _data_pop_moov = [data.pop_cov_2G_moov, data.pop_cov_3G_moov]
      } else if (showDataOnMap?.showMOOV2G && showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [data.locs_cov_2G_moov, 0, data.locs_cov_3G_moov]
        _data_pop_moov = [data.pop_cov_2G_moov, 0, data.pop_cov_4G_moov]
      } else if (showDataOnMap?.showMOOV3G && showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [0, data.locs_cov_3G_moov, data.locs_cov_4G_moov]
        _data_pop_moov = [0, data.pop_cov_3G_moov, data.pop_cov_4G_moov]
      } else if (showDataOnMap?.showMOOV2G) {
        _labels =  getLabel(_labels,["2G"])
        _data_moov = [data.locs_cov_2G_moov]
        _data_pop_moov = [data.pop_cov_2G_moov]
      } else if (showDataOnMap?.showMOOV3G) {
        _labels =  getLabel(_labels,["2G", "3G"])
        _data_moov = [0, data.locs_cov_3G_moov]
        _data_pop_moov = [0, data.pop_cov_3G_moov, data.pop_cov_4G_moov]
      } else if (showDataOnMap?.showMOOV4G) {
        _labels =  getLabel(_labels,["2G", "3G", "4G"])
        _data_moov = [0, 0, data.locs_cov_4G_moov]
        _data_pop_moov = [0, 0, data.pop_cov_4G_moov]
      }

    }


    let _loc_datasets = []
    let _pop_datasets = []
    let _forecast_op_datasets = []


    if (showDataOnMap?.showLocalities) {
      _loc_datasets.push(
        {
          label: "Localités couvertes",
          backgroundColor: colors?.coverage.allData,
          data: _data_l,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        }
      )
      _pop_datasets.push(
        {
          label: "Population couvertes",
          backgroundColor: colors?.coverage.allData,
          data: _data_pop,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        }
      )
    }
    if (showDataOnMap?.showNoLocalities) {
      _loc_datasets.push(
        {
          label: "Localités non couvertes",
          backgroundColor: colors.coverage.noData,
          data: _no_data,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        }
      )
      _pop_datasets.push(
        {
          label: "population non couvertes",
          backgroundColor: colors.coverage.noData,
          data: _no_data_pop,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        }
      )
    }
    if (showDataOnMap?.showORANGE) {
      if (showDataOnMap?.showORANGE2G || showDataOnMap?.showORANGE3G || showDataOnMap?.showORANGE4G) {
        _loc_datasets.push({
          label: "Localités couvertes par Orange",
          backgroundColor: colors.coverage.orangeData,
          data: _data_orange,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        })
        _pop_datasets.push({
          label: "Population couverte par Orange",
          backgroundColor: colors.coverage.orangeData,
          data: _data_pop_orange,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        })
      }
    }
    if (showDataOnMap?.showMTN) {
      if (showDataOnMap?.showMTN2G || showDataOnMap?.showMTN3G || showDataOnMap?.showMTN4G) {
        _loc_datasets.push({
          label: "Localités couvertes par MTN",
          backgroundColor: colors.coverage.mtnData,
          data: _data_mtn,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        })
        _pop_datasets.push({
          label: "Population couverte par MTN",
          backgroundColor: colors.coverage.mtnData,
          data: _data_pop_mtn,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        })
      }
    }
    if (showDataOnMap?.showMOOV) {
      if (showDataOnMap?.showMOOV2G || showDataOnMap?.showMOOV3G || showDataOnMap?.showMOOV4G) {
        _loc_datasets.push({
          label: "Localités couvertes par Moov",
          backgroundColor: colors.coverage.moovData,
          data: _data_moov,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        })
        _pop_datasets.push({
          label: "Population couvertes par Moov",
          backgroundColor: colors.coverage.moovData,
          data: _data_pop_moov,
          borderWidth: 1,
          barPercentage: 0.5,
          barThickness: 10,
          maxBarThickness: 20,
        })
      }
    }


    const _localitiesData = {
      labels: _labels,
      datasets: _loc_datasets,
    }
    const _populationData = {
      labels: _labels,
      datasets: _pop_datasets,
    }

    const _forecastDada = {
      labels: ["Orange","MTN","Moov"],
      datasets: [
        {
          backgroundColor: [colors.coverage.orangeData, colors.coverage.mtnData, colors.coverage.moovData],
          data: _foreacst_data,
        },
      ],
    }




    setLocsStats(_localitiesData);
    setpopStats(_populationData);
    setForecastStats(_forecastDada);

  } catch (error) {
    console.log(error.messahe)
  }


}


const getLabel = (label, labelTo) => {
  let _label = [];

  if (label?.length == 3 || labelTo?.length == 3) {
    _label = ["2G", "3G", "4G"]
  }
  else if (label?.length == 2) { // ["2G", "3G"] // ["2G", "4G"] // ["3G", "4G"]
    if (label == labelTo) { // ["2G", "3G"] - ["2G", "3G"] // ["3G", "4G"] - ["3G", "4G"] // ["2G", "4G"] - // ["2G", "4G"]
      _label = label;
    }
    else { // ["2G", "3G"] - ["3G", "4G"] // ["2G", "4G"] - ["3G", "4G"] //
      _label = ["2G", "3G", "4G"]
    }
  }
  else if (label?.length == 1) {
    const _isL2G = label[0] == "2G";
    const _isLT2G = labelTo.filter(l => l == "2G");

    const _isL3G = label[0] == "3G";
    const _isLT3G = labelTo.filter(l => l == "3G");

    const _isL4G = label[0] == "4G";
    const _isLT4G = labelTo.filter(l => l == "4G");

    if (_isL2G) {
      if (_isLT3G?.length > 0 && _isLT4G?.length > 0) {
        _label = ["2G", "3G", "4G"]
      }
      else if (_isLT3G?.length > 0) {
        _label = ["2G", "3G"]
      }
      else if (_isLT4G?.length > 0) {
        _label = ["2G", "4G"]
      } else {
        _label = ["2G"]
      }
    }
    else if (_isL3G) {
      if (_isLT2G?.length > 0 && _isLT4G?.length > 0) {
        _label = ["2G", "3G", "4G"]
      } else if (_isLT2G?.length > 0) {
        _label = ["2G", "3G"]
      } else if (_isLT4G?.length > 0) {
        _label = ["3G", "4G"]
      } else {
        _label = ["3G"]
      }
    }
    else if (_isL4G) {
      if (_isLT2G?.length > 0 && _isLT3G?.length > 0) {
        _label = ["2G", "3G", "4G"]
      } else if (_isLT2G?.length > 0) {
        _label = ["2G", "4G"]
      } else if (_isLT3G?.length > 0) {
        _label = ["3G", "4G"]
      } else {
        _label = ["4G"]
      }
    }
  }else{
    _label = labelTo
  }


  return _label;
}