import React, { useState } from "react";


import { Editor } from 'primereact/editor';


export const EditorComponent = ({value, setValue, height,placeholder}) => {

    return (
        <div className="card">
            <Editor value={value} onTextChange={(e) => setValue(e.htmlValue)} style={{ height: height }} placeholder={placeholder} />
        </div>
    )
}