
import { Link, useNavigate } from 'react-router-dom';
import './login.css'
import { useEffect, useRef, useState } from 'react';
import { tokenAdminJwt, tokenOperatorJwt, tokenUserJwt } from '../../../constants';
import { user as us } from '../../../constants';
import { userLoginService } from '../../../services/api/auth/login/userLoginService';
import { toastSuccess } from '../../../components/notification/notification';
import { localVariables } from '../../../services/local/SecureLocalStorageVariable';

export const UserLoginPage = () => {

    const toast = useRef(null);


    const navigate = useNavigate()

    const [user, setUser] = useState();
    const [loginResponse, setLoginResponse] = useState();

    const [showPassword, setShowPassword] = useState(false);


    useEffect(() => {
        if (loginResponse) {
            if (loginResponse?.error == false) {
                if (loginResponse?.user.profile.id == 1 || loginResponse?.user.profile.id == 2 ) {
                    console.log("Administratueur :", loginResponse.user)
                    localVariables.setItem(tokenAdminJwt, loginResponse.authorisation.token)
                    localVariables.setItem("admin", loginResponse.user)
                    localVariables.setItem("user", loginResponse.user)
                    localVariables.setItem("profile", loginResponse.user.profile)
                    navigate('/go-on-admin', { replace: true })
                }else if(loginResponse?.user.profile.id == 3){
                    console.log("Opérateur :", loginResponse.user)
                    localVariables.setItem(tokenOperatorJwt, loginResponse.authorisation.token)
                    localVariables.setItem("operator", loginResponse.user)
                    localVariables.setItem("user", loginResponse.user)
                    localVariables.setItem("profile", loginResponse.user.profile)
                    navigate('/go-on-operator', { replace: true })
                }
                 else {
                    console.log("Utilisateur :", loginResponse.user)
                    localVariables.setItem(tokenUserJwt, loginResponse.authorisation.token)
                    localVariables.setItem("user", loginResponse.user)
                    localVariables.setItem("profile", loginResponse.user.profile)
                    navigate('/', { replace: true })
                }
                toastSuccess('Vous etes connecté');
                // toastSuccess("Vous êtes connecté")
                // navigate('/', { replace: true })
            } else {
            }
        }
    }, [loginResponse]);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };

        _user[`${name}`] = val;

        setUser(_user);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (user?.email.trim() && user?.password.trim()) {
            let response = await userLoginService(user)

            setLoginResponse(response)
        }



    }

    return (
        <>
            <div className="h-100">
                <div className="row h-100 no-gutters">
                    <div className="d-none d-lg-block col-lg-4">
                        <div className="position-relative h-100 d-flex  align-items-center bg-plum-plate">
                            <div className="container d-flex justify-content-center">
                                <div className=''>
                                    <div>
                                        <Link href="https://www.artci.ci" target="_blank">
                                            <img src="../images/logo-artci.png" className='' width={400} />
                                        </Link>

                                        <h1 className='text-center'><i className="fa fa-map"></i></h1>
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <Link to="/" className="btn btn-dark"><i className="fa fa-map mr-2"></i> Retour à la carte</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
                        <div className="mx-auto div-box col-sm-12 col-md-10 col-lg-9">
                            <div className="app-logo"></div>
                            <h1 className='title-login mb-5'>Cartodonnees connexion</h1>
                            <h4 className="mb-0">
                                <span>Veuillez vous connecter à votre compte..</span>
                            </h4>
                            <h6 className="mt-3"> Pas encore de compte? <Link className="text-primary" to="/register">inscrivez-vous</Link></h6>
                            <div className="divider"></div>
                            <div>
                                <form onSubmit={handleSubmit} className="ng-untouched ng-pristine ng-valid">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div tabIndex="-1" role="group"><label htmlFor="exampleEmail">Email</label>
                                                    <input id="exampleEmail" name="email" type="email" placeholder="Entrer votre email ici..." className="form-control" onChange={(e) => onInputChange(e, "email")} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="examplePassword">Mot de passe</label>
                                            <div className="input-group">
                                                    <input id="examplePassword" name="password" type={!showPassword ? "password":"text"} placeholder="Entrer votre mot de passe ici..." className="form-control" onChange={(e) => onInputChange(e, "password")} />
                                                <div className="input-group-append" onClick={()=> setShowPassword(!showPassword) }>
                                                    <span className={!showPassword ? "input-group-text bg-primary text-white":"input-group-text"}><i className="fa fa-eye"></i></span>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input id="exampleCheck" type="checkbox" name="check" autoComplete="off" value="true" className="custom-control-input" />
                                        <label htmlFor="exampleCheck" className="custom-control-label"> Se souvenir moi </label>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="d-flex align-items-center">
                                        <div className="ml-auto">
                                            <Link to="/pass-forget" className="btn-lg btn btn-link" >Mot de passe oublié</Link>
                                            <button type="submit" className="btn btn-primary btn-lg">Se connecter</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}