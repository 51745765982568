import { year } from "../../constants/map/mapDataConstants";
import { localVariables } from "../../local/SecureLocalStorageVariable";
import { adminAxiosInstance } from "../config/axiosConfig";




export const findByLocalityName = async (setState,name) => {

    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/search-locality/'+name).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/search-locality2021/'+name).then((res => res) )
        }
        console.log('Nom recherché ====>', name)

        setState([]);
        
        if(response.status == 201) {
            console.log("reponse localités trouvés ##### ===>",response.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getInitLocalities = async (setState) => {

    try {
        
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/initLocalities').then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/initLocalities2021').then((res => res) )
        }

        setState([]);

        // console.log("Reponse API ====>", response);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            //console.log("liste des categories :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportAllLocalities = async (setState) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-all-localities').then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-all-localities2021').then((res => res) )
        }

        setState([]);

        console.log("Reponse API ====>", response);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            console.log("La reponse =====> :", response.data.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportCoverageLocalities = async (setState) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-localities-coverage').then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-localities-coverage2021').then((res => res) )
        }

        setState([]);

        console.log("Reponse API ====>", response);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            console.log("La reponse =====> :", response.data.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportNoCoverageLocalities = async (setState) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-localities-no-coverage').then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-localities-no-coverage2021').then((res => res) )
        }

        setState([]);

        console.log("Reponse API ====>", response);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            console.log("La reponse =====> :", response.data.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getLocalityIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-localities-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-localities-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);

        console.log("Reponse API EXPORT LOCALITES ====>", response);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

