import { ColorRing, TailSpin } from 'react-loader-spinner'
export function DataLoaderColorRing() {
    return (
        <>
            <div className='d-flex justify-content-center'>
                <div>
                    <ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#f19742', '#f19742', '#f19742', '#209109d8', '#209109d8']}
                    />
                </div>
            </div>
        </>
    )
} 

export function DataLoaderTailSpin() {
    return (
        <>
            <div className='d-flex justify-content-center'>
                <div>
                    <div className='d-flex justify-content-center'>
                        <TailSpin
                            height="60"
                            width="60"
                            color="#f19742"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                    <div className='text-center'>
                        <h5>Récupération des données...</h5>
                        <p>Veuillez patienter.</p>
                    </div>
                </div>
            </div>
        </>
    )
}