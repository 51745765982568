import { useEffect, useRef, useState } from "react";
import { OperatorMainPageContainer } from "../../OperatorMainPageContainer"
import { localVariables } from "../../../../../services/local/SecureLocalStorageVariable";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { strDate } from "../../../../../services/conversion/stringConversion";
import { getExcelByOperatoreCoverage } from "../../../../../services/api/admin/bufferCoverages/bufferCoverageApiService";
import { ImportLocalityExcelComponent } from "../../../../../components/Excel/ImportLocalityExcelComponent";
import { Column } from "primereact/column";
import { operatorExcelTemplate } from "../../../../../services/excel/exportTemplate";
import { OperatorExcelLoadDialog } from "./OperatorExcelLoadDialog";
import { addOperatorCoveragesList } from "../../../../../services/api/user/operators/coverage/operatorCoverageApiService";





export const OperatorExcelPage = () => {
    const [user, setUser] = useState(localVariables.getItem('operator'));


    const [openCoverageExcelDialog, setOpenCoverageExcelDialog] = useState(false);
    const [displayEcelLoadingDialog, setDisplayEcelLoadingDialog] = useState(false);
    const [displayViewDialog, setDisplayViewDialog] = useState(false);
    const [excelData, setExcelData] = useState(null);

    const [globalFilter, setGlobalFilter] = useState(null);

    const [openExcelSubmitDialog, setOpenExcelSubmitDialog] = useState(false);
    const [data, setData] = useState(null);
    const [response, setResponse] = useState(null);
    const [selectedExcelData, setSelectedExcelData] = useState(null);

    const dt = useRef(null);

    useEffect(() => {
        getExcelByOperatoreCoverage(setExcelData, user?.operator?.id);
    }, []);

    useEffect(() => {
      if(data != null){
        addOperatorCoveragesList(data,setResponse);
        setData(null)
      }
    }, [data]);

    useEffect(() => {
      if(response != null){
        if(response?.error == false){
          
        }
      }
    }, [response]);

    const fields = [
        {
          // Visible in table header and when matching columns.
          label: "Code Localité",
          // This is the key used for this field when we call onSubmit.
          key: "locality_code",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["code localité", "code", "CODE_LOCALITE"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "CI00001",
          // Can have multiple validations that are visible in Validation Step table.
          validations: [
            {
              // Can be "required" / "unique" / "regex"
              rule: "required",
              errorMessage: "Le code est obligatoire",
              // There can be "info" / "warning" / "error" levels. Optional. Default "error".
              level: "error",
            },
          ],
        },
        {
          // Visible in table header and when matching columns.
          label: "Localité",
          // This is the key used for this field when we call onSubmit.
          key: "locality_name",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["LOCALITE","LOCALITE v2"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "BOUAKE",
          // Can have multiple validations that are visible in Validation Step table.
        },
        {
          // Visible in table header and when matching columns.
          label: "Sous-préfecture",
          // This is the key used for this field when we call onSubmit.
          key: "sub_pref",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["sous-prefecture", "SOUS-PREFECTURE", "sous-préfecture", "Sous-prefecture", "Sous-préfecture"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "KASSERE",
          // Can have multiple validations that are visible in Validation Step table.
        },
        {
          // Visible in table header and when matching columns.
          label: "Département",
          // This is the key used for this field when we call onSubmit.
          key: "department",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["départment", "DEPARTEMENT", "department", "épartment", "department"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "BOUAKE",
        },
        {
          // Visible in table header and when matching columns.
          label: "Région",
          // This is the key used for this field when we call onSubmit.
          key: "region",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["région", "REGION", "region"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "GBEKE",
        },
        {
          // Visible in table header and when matching columns.
          label: "District",
          // This is the key used for this field when we call onSubmit.
          key: "district",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["district", "DISTRICT"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "VALLEE DU BANDAMA",
        },
        {
          // Visible in table header and when matching columns.
          label: "Population",
          // This is the key used for this field when we call onSubmit.
          key: "pop",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["POPULATION", "POPULATION"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1000",
        },
    
        //2G
        //2G ORANNGE
        {
          // Visible in table header and when matching columns.
          label: "Couverture 2G",
          // This is the key used for this field when we call onSubmit.
          key: "coverage_2G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["COUVERTURE 2G", "COUVERTURE_2G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
        {
          // Visible in table header and when matching columns.
          label: "Presence 2G",
          // This is the key used for this field when we call onSubmit.
          key: "presence_2G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["PRESENCE 2G", "PRESENCE_2G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
        {
          // Visible in table header and when matching columns.
          label: "Population 2G",
          // This is the key used for this field when we call onSubmit.
          key: "pop_cov_2G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["population couverte 2G","POPULATION COUVERTE 2G", "POPULATION COUVERTE", "POPULATION_COUVERTE_2G_ORANGE"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1000",
        },
    
    
        //3G
        //3G
        {
          // Visible in table header and when matching columns.
          label: "Couverture 3G",
          // This is the key used for this field when we call onSubmit.
          key: "coverage_3G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["COUVERTURE 3G", "COUVERTURE_3G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
        {
          // Visible in table header and when matching columns.
          label: "Presence 3G",
          // This is the key used for this field when we call onSubmit.
          key: "presence_3G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["PRESENCE 3G", "PRESENCE_3G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
        {
          // Visible in table header and when matching columns.
          label: "Population 3G",
          // This is the key used for this field when we call onSubmit.
          key: "pop_cov_3G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["population couverte 3G", "POPULATION COUVERTE 3G", "POPULATION_COUVERTE_3G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1000",
        },
    
    
        //4G
        {
          // Visible in table header and when matching columns.
          label: "Couverture 4G",
          // This is the key used for this field when we call onSubmit.
          key: "coverage_4G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["COUVERTURE 4G", "COUVERTURE_4G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
        {
          // Visible in table header and when matching columns.
          label: "Presence 4G",
          // This is the key used for this field when we call onSubmit.
          key: "presence_4G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["PRESENCE 4G", "PRESENCE_4G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
        {
          // Visible in table header and when matching columns.
          label: "Population 4G",
          // This is the key used for this field when we call onSubmit.
          key: "pop_cov_4G_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["population couverte 4G", "POPULATION COUVERTE 4G", "POPULATION_COUVERTE_4G"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1000",
        },
    
        //COUVERTURE DATA
        {
          // Visible in table header and when matching columns.
          label: "Couverture data",
          // This is the key used for this field when we call onSubmit.
          key: "coverage_data_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["COUVERTURE SERVICE DATA","COUVERTURE DATA", "COUVERTURE DATA", "COUVERTURE_DATA"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
    
        //POPULATION DATA
        {
          // Visible in table header and when matching columns.
          label: "Population Data",
          // This is the key used for this field when we call onSubmit.
          key: "pop_cov_data_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["POPULATION COUVERTE SERVICE DATA","POPULATION COUVERTE DATA", "POPULATION DATA", "POPULATION_DATA_ORANGE"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1000",
        },
        //COUVERTURE TELEPHONIE
        {
          // Visible in table header and when matching columns.
          label: "Couverture telephonie",
          // This is the key used for this field when we call onSubmit.
          key: "coverage_phone_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["COUVERTURE EN SERVICE DE TELEPHONIE", "COUVERTURE SERVICE TELEPHHONIE", "COUVERTURE TELEPHHONIE", "COUVERTURE_TELEPHHONIE"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1",
        },
    
        //POPULATION TELEPHONIE
        {
          // Visible in table header and when matching columns.
          label: "Population telephonie",
          // This is the key used for this field when we call onSubmit.
          key: "pop_cov_phone_operator",
          // Allows for better automatic column matching. Optional.
          alternateMatches: ["POPULATION COUVERTE EN SERVICE DE TELEPHONIE", "POPULATION COUVERTE TELEPHONIE", "POPULATION COUVERTE SERVICE TELEPHONIE", "POPULATION TELEPHONIE", "POPULATION_TELEPHONIE"],
          // Used when editing and validating information.
          fieldType: {
            // There are 3 types - "input" / "checkbox" / "select".
            type: "input",
          },
          // Used in the first step to provide an example of what data is expected in this field. Optional.
          example: "1000",
        },
    
      ]

    const handleDisplayExcelDialog = () => {
        setOpenCoverageExcelDialog(true)
    }
    
    const handleSumit = (d) => {
        console.log("DONNEES EXCELS ================>,", d);
        // console.log("FICHHIER EXCEL ================>",file);
        setData(d);
      }
    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const cols = [
        { field: "code", header: "Code" },
        { field: "name", header: "Nom" },
        { field: "nb_locs", header: "Localités" },
        { field: "nb_locs_cov", header: "Localité couvertes" },
        { field: "nb_locs_not_cov", header: "Localités non couvertes" },
        { field: "nb_pop", header: "Population" },
        { field: "nb_cov_pop", header: "Population couverte" },
        { field: "nb_pop_not_cov", header: "Population non couverte" },
        { field: "description", header: "Description" },
    ];

    const exportColumns = cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
    }));
    const exportPdf = () => {
        import("jspdf").then((jsPDF) => {
            import("jspdf-autotable").then(() => {
                const doc = new jsPDF.default({
                    format: "a4",
                    orientation: "landscape",
                    unit: "cm",
                    compressPdf: true,
                });

                doc.autoTable(exportColumns, excelData, {
                    headStyles: { fillColor: [255, 140, 0] },
                });
                doc.save("artci-cartodonnees-extract-loc-optical.pdf");
            });
        });
    };

    const exportExcel = () => {
        import("xlsx").then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(excelData);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });

            saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-bufferCoverages");
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import("file-saver").then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE =
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                let EXCEL_EXTENSION = ".xlsx";
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE,
                });

                module.default.saveAs(
                    data,
                    fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
                );
            }
        });
    };

    const header = () => {
        return (
            <>
                <div className=" bg-light w-100 p-2">
                    {/* Search and select START */}
                    <div className="d-flex justify-content-between">
                        <div className=" d-flex justify-content-between">
                            <button className="btn btn-sm btn-success mb-0 mr-2" onClick={() => exportExcel()}><i className="fa fa-file mr-2"></i> Excel</button>
                            <button className="btn btn-sm btn-secondary mb-0 mr-2" onClick={() => exportCSV()}><i className="fa fa-file mr-2"></i> CSV</button>
                            <button className="btn btn-sm btn-warning mb-0 mr-2" onClick={() => exportPdf()}><i className="fa fa-file mr-2"></i> PDF</button>
                        </div>
                        <div className="">
                            <div className="rounded position-relative">
                                <input className="form-control bg-body" type="search" placeholder="Recherche..." aria-label="Search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* Search and select END */}
                </div>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (<>
            <div className="d-flex justify-content-end">
                <button className="btn btn-info btn-round mr-2 mb-0" onClick={() => { }}><i className="far fa-eye"></i></button>
            </div>
        </>)
    };

    const ownerTemplate = (rowData) => {
        return (<>
            <div>
                <div>{rowData?.owner?.first_name + " " + rowData?.owner?.last_name} </div>
                <div><a href="#"> {rowData?.owner?.email} </a></div>
            </div>
        </>)
    }

    const dateTemplate = (rowData) => {
        return (<>
            <div>
                <b>{strDate(rowData?.created_at)}</b>
                <div>
                    <small>{rowData?.created_at}</small>
                </div>
            </div>
        </>)
    }

    const statusTemplate = (rowData) => {
        return (<>
            <div className="text-center text-dark">
                <div> {rowData?.status} </div>
            </div>
        </>)
    }

    return (<>
        <OperatorMainPageContainer
            user={user}
            title="MISE A JOUR DE DONNEES DE COUVERTURE PAR FICHIER EXCEL"
            children={<>
                <div className="container-fluid">
                    <div className="d-flex justify-content-between">
                        <div>
                            <Link to="/operator-dashboard" className="btn btn-dark" >  <h6><i className="fa fa-home mr-2"></i>Tableau de bord</h6> </Link>
                        </div>
                        <div role="alert" class=" alert alert-info alert-dismissible fade show p-1">
                            <div>
                                <small className="">
                                    La gestion des données de couverture est un pilier essentiel de l'analyse géospatiale moderne, offrant des perspectives significatives pour la compréhension et la gestion de notre monde en constante évolution. <br />
                                    Modifer, Créer des demande de mise à jours, consulter vos données de couverture.
                                </small>
                                <div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-shadow-primary card-border mb-2 profile-responsive card">

                        <ul className="list-group list-group-flush">
                            <li className="p-0 list-group-item">
                                <div className="grid-menu grid-menu-2col overflow-hidden">
                                    <div className="no-gutters row">
                                        <div className="col-md-6">
                                            <button href="/admin-coverage-update-by-excel" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-success" onClick={() => handleDisplayExcelDialog()} >
                                                <i className="fas fa-upload btn-icon-wrapper btn-icon-lg mb-3"></i>
                                                Initier une mise à jour
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-info" onClick={() => operatorExcelTemplate()}>
                                                <div>
                                                    <i className="fas fa-download btn-icon-lg mb-3"></i>
                                                    <i className="fas fa-file btn-icon-lg mb-3"></i>
                                                </div>
                                                Télécherger le model de fichier
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="no-gutters">
                            <div className="mb-3 card">
                                <div className="card-header-tab card-header">
                                    <div className="card-header-title font-size-lg font-weight-normal">
                                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                                        Historques de soumission de fichiers excel
                                    </div>
                                </div>
                                <div className="card-body">
                                    {excelData?.length > 0 ? <>
                                        <DataTable
                                            ref={dt}
                                            value={excelData}
                                            selection={selectedExcelData}
                                            onSelectionChange={(e) => setSelectedExcelData(e.value)}
                                            dataKey="code"
                                            paginator
                                            rows={10}
                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                            currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                            globalFilter={globalFilter}
                                            header={header}
                                            tableStyle={{ minWidth: '50rem' }}
                                            globalFilterFields={['locality_name']}
                                            stripedRows
                                            small
                                            size="small"
                                            showGridlines
                                        >
                                            <Column field="code" header="Code" sortable style={{ width: '25%' }}></Column>
                                            <Column header="Demandeur" sortable style={{ width: '25%' }} body={ownerTemplate}></Column>
                                            <Column header="Date Soumission" sortable style={{ width: '25%' }} body={dateTemplate} ></Column>
                                            {/* <Column header="Status" sortable style={{ width: '25%' }} body={statusTemplate} ></Column> */}
                                            <Column header="Actions" headerStyle={{ textAlign: 'end' }} style={{ width: '25%' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                                        </DataTable>
                                    </> : <>
                                        {excelData != null ? <>
                                            <h4 className="p-2 text-center">Aucune donnée disponible !</h4>
                                        </> : <>
                                            <div className="container px-1 px-md-5 px-lg-1 mbody">
                                                <DataLoaderTailSpin />
                                            </div>
                                        </>}

                                    </>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <OperatorExcelLoadDialog display={displayEcelLoadingDialog} setDisplay={setDisplayEcelLoadingDialog} />
                {/* <OperatorExcelViewDialog /> */}
                <ImportLocalityExcelComponent isOpen={openCoverageExcelDialog} setIsOpen={setOpenCoverageExcelDialog} handleSubmit={handleSumit} fields={fields} />
            </>}
        />
    </>)
}