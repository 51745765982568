import { Dialog } from "primereact/dialog"
import { useEffect, useState } from "react";




export const OperatorExcelLoadDialog = ({display,setDisplay}) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const onClick = (position) => {
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    return(<>
        <Dialog
            // header={title}
            visible={display}
            modal
            style={{ width: "60vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >

        </Dialog>
    </>)

}