import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { baseFileUrl } from "../../../../constants";

export const AdminOperatorDialog = ({ display, setDisplay, operator, setOperator, title, handleSumitOperator, onInputChange }) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);

  const [imageFile, setImageFile] = useState();
  const [previewImageFile, setPreviewImageFile] = useState()
  const [imgUrl, setImgUrl] = useState();

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    setOperator()
    setPreviewImageFile()
    // handleInitVar()
  };

  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);




  // PREVIEW IMAGE
  useEffect(() => {
    console.log("Image de previsualisation", imageFile)
    if (!imageFile) {
      setPreviewImageFile(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(imageFile)
    setPreviewImageFile(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [imageFile])

  const onSelectImageFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setImageFile(undefined)
      return
    }
    // I've kept this example simple by using the first imageFile instead of multiple

    let _operator = { ...operator };
    _operator["imageFile"] = e.target.files[0];

    setImgUrl(e.target.files[0].filename)

    setOperator(_operator);

    setImageFile(e.target.files[0])
  }

  return (
    <>
      <Dialog
        header={title}
        visible={display}
        modal
        style={{ width: "60vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
        <form onSubmit={handleSumitOperator}>
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Nom de l'opérateur</label>
                  <input type="text" className="form-control bwd" placeholder="Entrer le nom du operator" value={operator?.name} onChange={(e) => onInputChange(e, "name")} required />
                </div>
              </div>
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Description</label>
                  <textarea name="" id="" cols="30" rows="5" className="form-control" value={operator?.description} placeholder="Entrer une description du operator" onChange={(e) => onInputChange(e, "description")}></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {(previewImageFile || operator?.image_path) && <>
                <div>
                  <img src={previewImageFile ? previewImageFile : baseFileUrl + operator?.image_path } height={200} style={{ width: "100%" }} alt="" />
                </div>
              </>}
              <label htmlFor="logo" className="form-label">Logo de l'opérateur</label>
              <div class="input-group mb-3">
                <input type="file" className="form-control" name="image" accept="image/*" onChange={onSelectImageFile} />
                {(previewImageFile || operator?.image_path) && <>
                  <div class="input-group-append" onClick={() => { setImageFile(); setImgUrl() }} >
                    <span class="input-group-text" id="basic-addon2"><i className="fa fa-xmark"></i></span>
                  </div>
                </>}
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer pt-3 d-flex justify-content-between">
              <button type="button" className="btn btn-dark my-0" onClick={() => onHide()}>Fermer</button>
              <button type="submit" className="btn btn-primary my-0" ><i className="fa fa-save mr-2"></i> Enregistrer l'opérateur</button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};
