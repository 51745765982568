import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminProblemList } from "./AdminProblemList";
import {
  DeleteProblemDialog,
  DeleteProblemsDialog,
  DeleteSProblemsDialog,
} from "./AdminProblemDeleteDialog";
import {
  addProblem,
  deleteProblem,
  editProblem,
  getProblems,
} from "../../../../services/api/admin/problems/problemApiService";
import { AdminProblemDialog } from "./AdminProblemDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../AdminMainPageContainer";
import { AdminProblemVIewDialog } from "./AdminProblemViewDialog";
import { AdminProblemExcelDialog } from "./AdminProblemExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";

export const AdminProblemPage = () => {
  const [openProblemViewDialog, setOpenProblemViewDialog] = useState(false);
  const [openProblemDialog, setOpenProblemDialog] = useState(false);
  const [openProblemExcelDialog, setOpenProblemExcelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [problems, setProblems] = useState();
  const [problem, setProblem] = useState();

  const [selectedProblems, setSelectedProblems] = useState(null);

  const [problemDialogTitle, setProblemDialogTitle] = useState(
    "CREATION D'UN PROBLÈME"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getProblems(setProblems);
  }, []);

  useEffect(() => {
    console.log("Les problems :", problem);
  }, [problem]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de problem effectué avec succès!");
      getProblems(setProblems);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du problem !");
    }
    setOpenProblemDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du problem effectué avec succès!");
      getProblems(setProblems);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du problem !");
    }
    setOpenProblemDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du problem effectué avec succès!");
      getProblems(setProblems);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du problem !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setProblem();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setProblemDialogTitle("CREATION D'UN PROBLÈME");
    setSelectedProblems();
    setOpenProblemDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _problem = { ...problem };

    _problem[`${name}`] = val;

    setProblem(_problem);
  };

  const handleSubmitProblem = async (e) => {
    e.preventDefault();

    if (problem.title.trim()) {
      problem?.description?.trim();
      if (problem.id) {
        await editProblem(problem, setEditResponse);
      } else {
        await addProblem(problem, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewProblem = (dist) => {
    setProblem({ ...dist });
    setOpenProblemViewDialog(true);
  };

  //EDITION
  const handleEditProblem = (dist) => {
    setProblemDialogTitle("MODIFICATION D'UN PROBLÈME");
    console.log("AZERTY", dist);
    setProblem({ ...dist });
    setOpenProblemDialog(true);
  };

  //SUPPRESSION
  const handleDeleteProblemDialog = (dist) => {
    setProblem({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteProblem = async () => {
    await deleteProblem(problem, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteProblems = async () => {
    try {
      selectedProblems.forEach(async (problem) => {
        await deleteProblem(problem, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du problems selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "type", header: "Type" },
    { field: "title", header: "Titre" },
    { field: "level", header: "Niveau d'alerte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, problems, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(problems);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-problems");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-satellite-dish"
                title="Gestion des problèmes"
                subTitle="Tableau de bord"
                description="Gestion des problèmes. "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des problèmes
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenProblemDialog(true);
                        setProblem();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un problème
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {problems?.length > 0 ? (
                        <AdminProblemList
                          problems={problems}
                          handleEditProblem={handleEditProblem}
                          handleDeleteProblemDialog={
                            handleDeleteProblemDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedProblems={selectedProblems}
                          setSelectedProblems={setSelectedProblems}
                          handleViewProblem={handleViewProblem}
                        />
                      ) : (
                        <>
                        {problems != null ? <>
                        <div>
                          <h6 className="text-center">Aucun résultat trouvé !</h6>
                        </div>
                        </> : <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminProblemVIewDialog
              display={openProblemViewDialog}
              setDisplay={setOpenProblemViewDialog}
              problem={problem}
            />

            <AdminProblemDialog
              display={openProblemDialog}
              setDisplay={setOpenProblemDialog}
              handleSumitProblem={handleSubmitProblem}
              problem={problem}
              title={problemDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setProblem={setProblem}
            />

            <DeleteProblemDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeleteproblem={handleDeleteProblem}
              problem={problem}
            />
            <DeleteSProblemsDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedProblem={handleDeleteProblems}
            />
          </>
        }
      />
    </>
  );
};
