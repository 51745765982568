import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";


export const AdminTechnologyVIewDialog = ({ display, setDisplay, technology }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const onClick = (position) => {
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);


    return (<>
        <Dialog
            header="DETAILS DISTRICTS"
            visible={display}
            modal
            style={{ width: "60vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
            <div className="row">
                <div className="col-md-12">
                <div>
                        <h3>Nom : <b>{technology?.name}</b></h3>
                    <div className="bg-light">
                        <p>{technology?.description} Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed quo recusandae officia reprehenderit? Nulla animi ratione sint voluptatum laudantium ab! Autem amet nesciunt asperiores, ducimus sapiente dolore ipsam cupiditate atque.</p>
                    </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div className="col-md-12 bg-light rounded">
                    <div class="">
                        <div class="no-gutters row">
                            <div class="col-md-6 col-xl-4">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">10</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Régions</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">200</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Departemtns</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">200</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Sous préfecture</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">200</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">{technology?.nb_locs}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning">{technology?.nb_locs_cov}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités couvertes</div>
                                            <div class="widget-subheading">{technology?.per_locs_cov}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger">{technology?.nb_locs_not_cov}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localité non couverte</div>
                                            <div class="widget-subheading">{100 - technology?.per_locs_cov}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">{technology?.nb_pop}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning">{technology?.nb_cov_pop}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population couverte</div>
                                            <div class="widget-subheading">{technology?.per_pop_cov}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger">{technology?.nb_locs}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population non couverte</div>
                                            <div class="widget-subheading">{100 - technology?.per_pop_cov}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}