import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminBufferCoverageList } from "./AdminCoverageList";
import {
  DeleteCoverageDialog,
  DeleteCoveragesDialog,
  DeleteSCoveragesDialog,
} from "./AdminCoverageDeleteDialog";
import {
  addCoverage,
  deleteCoverage,
  editCoverage,
  getCoverages,
} from "../../../../../services/api/admin/bufferCoverages/bufferCoverageApiService";
import { AdminCoverageDialog } from "./AdminCoverageDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { AdminCoverageVIewDialog } from "./AdminCoverageViewDialog";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { getOperators } from "../../../../../services/api/admin/operators/operatorApiService";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";
import { getLocalities, getLocalityById } from "../../../../../services/api/admin/adminEntities/locality/localityApiService";
import { AdminCoverageLocalityList } from "./AdminCoverageLocalityList";
import { ImportLocalityExcelComponent } from "../../../../../components/Excel/ImportLocalityExcelComponent";

export const AdminCoveragePage = () => {
  const [openCoverageViewDialog, setOpenCoverageViewDialog] = useState(false);
  const [openCoverageDialog, setOpenCoverageDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [bufferCoverages, setBufferCoverage] = useState(null);

  const [selectedCoverages, setSelectedCoverages] = useState(null);

  const [localityDialogTitle, setLocalityDialogTitle] = useState(
    "CREATION D'UNE MISE A JOUR"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const [operators, setOperators] = useState();
  const [technologies, setTechnologies] = useState();
  const [localities, setLocalities] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  const [globalFilter, setGlobalFilter] = useState(null);


  const [selectedLocalities, setSelectedLocalities] = useState(null);
  const [locality, setLocality] = useState(null);

  useEffect(() => {
    getCoverages(setBufferCoverage);
    getOperators(setOperators);
    getTechnologies(setTechnologies);
    getLocalities(setLocalities);
  }, []);

  useEffect(() => {
    console.log("Les bufferCoverages :", locality);
  }, [locality]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de locality effectué avec succès!");
      getCoverages(setBufferCoverage);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du locality !");
    }
    setOpenCoverageDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du locality effectué avec succès!");
      getCoverages(setBufferCoverage);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du locality !");
    }
    setOpenCoverageDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du locality effectué avec succès!");
      getCoverages(setBufferCoverage);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du locality !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setLocality();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setLocalityDialogTitle("CREATION D'UNE MISE A JOUR");
    setSelectedCoverages();
    setOpenCoverageDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _locality = { ...locality };

    _locality[`${name}`] = val;

    setLocality(_locality);
  };

  const onInputCheckChange = (e, name) => {
    const val = (e.target && e.target.checked) || "";
    let _locality = { ...locality };

    _locality[`${name}`] = val;

    setLocality(_locality);
  };

  const handleSubmitCoverage = async (e) => {
    e.preventDefault();
    await addCoverage(locality, setCreateResponse);
  };

  //VISUALISATION
  const handleViewCoverage = (loc) => {
    setLocality({ ...loc });
    setOpenCoverageViewDialog(true);
  };

  //EDITION
  const handleEditCoverage = (loc) => {
    setLocalityDialogTitle("MODIFICATION D'UNE MISE A JOUR");
    console.log("AZERTY", loc);
    setLocality({ ...loc });
    setOpenCoverageDialog(true);
  };

  //SUPPRESSION
  const handleDeleteCoverageDialog = (loc) => {
    setLocality({ ...loc });
    setOpenDeleteDialog(true);
  };

  const handleDeleteCoverage = async () => {
    await deleteCoverage(locality, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteCoverages = async () => {
    try {
      selectedCoverages.forEach(async (locality) => {
        await deleteCoverage(locality, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du bufferCoverages selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, bufferCoverages, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-loc-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(bufferCoverages);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-bufferCoverages");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const handleEditLocality = async (l) => {
    setOpenCoverageDialog(true)
    await getLocalityById(l?.id, setLocality);
  }

  const handleViewLocality = () => {
  }


  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-satellite-dish"
        title="Gestion des bufferCoverages"
        subTitle="Gestion des données de couverture"
        description="Gerer toutes les données de couverture. "
        children={
          <>
            <div className="">
              <div className="card-shadow-primary card-border mb-2 profile-responsive card">

                <ul className="list-group list-group-flush">
                  <li className="p-0 list-group-item">
                    <div className="grid-menu grid-menu-2col overflow-hidden">
                      <div className="no-gutters row">
                        <div className="col-md-4">
                          <Link to="/admin-coverage-update-by-excel" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-success">
                            <div>
                              <img src="../images/excel.png" height={60} alt="" />
                            </div>
                            <div>
                              Mise à par fichier Excel
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-4">
                          <button className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-warning">
                            <i className="fas fa-repeat btn-icon-wrapper btn-icon-lg mb-3"></i>
                            Syncronisation du ficier de carte
                          </button>
                        </div>
                        <div className="col-md-4 border-left">
                          <button className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-info">
                            <i className="fas fa-download btn-icon-wrapper btn-icon-lg mb-3"></i>
                            Télécharger
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <nav className=" mt-3">
                <div className="nav nav-tabs " id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-history-tab"
                    data-toggle="tab"
                    data-target="#nav-history"
                    type="button"
                    role="tab"
                    aria-controls="nav-history"
                    aria-selected="false"
                  >
                    Demandes de mise à jours
                  </button>
                  <button
                    className="nav-link "
                    id="nav-control-tab"
                    data-toggle="tab"
                    data-target="#nav-control"
                    type="button"
                    role="tab"
                    aria-controls="nav-control"
                    aria-selected="true"
                  >
                    Données de couverture sur les localités
                  </button>
                  <button
                    className="nav-link"
                    id="sync-history-tab"
                    data-toggle="tab"
                    data-target="#sync-history"
                    type="button"
                    role="tab"
                    aria-controls="sync-history"
                    aria-selected="false"
                  >
                    Historiques de synchronisation
                  </button>
                </div>
              </nav>
              <div className="tab-content pt-0 m-0" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-history"
                  role="tabpanel"
                  aria-labelledby="nav-history-tab"
                >
                  <div className="mb-3 card">
                    <div className="card-header-tab card-header">
                      <div className="card-header-title font-size-lg font-weight-normal">
                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                        Historique des mises à jours
                      </div>
                    </div>
                    <div className="no-gutters row">
                      <div className="card w-100">
                        <div className="card-body">
                          {bufferCoverages?.length > 0 ? (
                            <AdminBufferCoverageList
                              bufferCoverages={bufferCoverages}
                              handleEditBufferCoverage={handleEditCoverage}
                              handleDeleteBufferCoverageDialog={
                                handleDeleteCoverageDialog
                              }
                              setopenDeleteSelected={setopenDeleteSelected}
                              exportCSV={exportCSV}
                              exportPDF={exportPdf}
                              exportExcel={exportExcel}
                              dt={dt}
                              selectedBufferCoverages={selectedCoverages}
                              setSelectedBufferCoverages={setSelectedCoverages}
                              handleViewBufferCoverage={handleViewCoverage}
                            />
                          ) : (
                            <>
                            {bufferCoverages != null ? <>
                            <h5 className="text-center">Aucune donnnée !</h5>
                            </> : <>
                              <div className="container px-1 px-md-5 px-lg-1 mbody">
                                <DataLoaderTailSpin />
                              </div>
                            </>}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-control"
                  role="tabpanel"
                  aria-labelledby="nav-control-tab"
                >
                  <div className="card">
                    <div className="card-header-tab card-header">
                      <div className="card-header-title font-size-lg font-weight-normal">
                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                        Données de couverture des localités
                      </div>
                    </div>
                    <div className="no-gutters row">
                      <div className="card w-100">
                        <div className="card-body">
                          {localities?.length > 0 ? (
                            <AdminCoverageLocalityList
                              localities={localities}
                              handleEditLocality={handleEditLocality}
                              setopenDeleteSelected={setopenDeleteSelected}
                              exportCSV={exportCSV}
                              exportPDF={exportPdf}
                              exportExcel={exportExcel}
                              dt={dt}
                              selectedLocalities={selectedLocalities}
                              setSelectedLocalities={setSelectedLocalities}
                              handleViewLocality={handleViewLocality}
                            />
                          ) : (
                            <>
                              <div className="container px-1 px-md-5 px-lg-1 mbody">
                                <DataLoaderTailSpin />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="sync-history"
                  role="tabpanel"
                  aria-labelledby="sync-history-tab"
                >
                  <div className="mb-3 card">
                    <div className="card-header-tab card-header">
                      <div className="card-header-title font-size-lg font-weight-normal">
                        <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                        Historique des synchronisations
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminCoverageVIewDialog
              display={openCoverageViewDialog}
              setDisplay={setOpenCoverageViewDialog}
              coverage={locality}
            />

            <AdminCoverageDialog
              display={openCoverageDialog}
              setDisplay={setOpenCoverageDialog}
              handleSumitCoverage={handleSubmitCoverage}
              locality={locality}
              title={localityDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              onInputCheckChange={onInputCheckChange}
              setLocality={setLocality}
              localities={localities}
              technologies={technologies}
              operators={operators}
            />

            <DeleteCoverageDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletecoverage={handleDeleteCoverage}
              coverage={locality}
            />
            <DeleteSCoveragesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedCoverage={handleDeleteCoverages}
            />
          </>
        }
      />
    </>
  );
};
