import { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { handleNumThousand } from "../../../services/tools/convertions";
import { getRRegionsByGeoData } from "../../../services/api/admin/adminEntities/region/regionApiService";
import { getDistritcsByGeoData } from "../../../services/api/admin/adminEntities/districts/districtApiService";
import { getDDepartmentsByGeoData } from "../../../services/api/admin/adminEntities/department/departmentApiService";
import { getSSubprefecturesByGeoData } from "../../../services/api/admin/adminEntities/subPrefecture/subPrefectureApiService";


export const StatisticsListComponent = ({ type, data, technologies, generalData, handleSelectEntity,exportExcel }) => {
    const contentRefDistict = useRef();

    return (<>
        <div className="d-flex justify-content-between">
            <div className="">
                <h4 className="bwd"><em>STATISTIQUES
                    {type == 0 && " DISTRICT"}
                    {type == 1 && " REGION"}
                    {type == 2 && " DEPARTEMENT"}
                    {type == 3 && " SOUS-PREFECTURE"} :</em></h4>
            </div>
            <div>
                <button className="btn btn-success mr-2" onClick={() => exportExcel()}> <i className="fa fa-file mr-1"></i> EXCEL</button>
                <ReactToPrint
                    trigger={() => <button className="btn btn-dark mb-0 mr-2"><i className="fa fa-print mr-2"></i>IMPRIMER LE TABLEAU</button>}
                    content={() => contentRefDistict.current}
                    // onBeforePrint={()=> {!d?.description?.length > 0 && setIsPrinting(true)}}
                    // onAfterPrint={() => setIsPrinting(false)}
                    copyStyles={true}
                    pageStyle={`@media print {body {
                    padding: 20px; /* Adjust the padding as needed */
                } @page { size: landscape }};`}
                />
            </div>
        </div>
        <div className=" mt-2">
            <div>
                <div className="scroll-area">
                    <div className="" ref={contentRefDistict}>
                        <table className="table w-100 table-sm table-bordered  table-striped table-head-fixed">
                            <thead className="bg-dark text-white">
                                <tr>
                                    <th rowSpan={4} className="text-center" ><small><b>
                                        {type == 0 && "DISTRICT"}
                                        {type == 1 && "REGION"}
                                        {type == 2 && "DEPARTEMENT"}
                                        {type == 3 && "SOUS-PREFECTURE"}
                                    </b></small> </th>
                                </tr>
                                <tr>
                                    <th rowSpan={2} className="text-center"><small><b>EFFECTIF <br/>TOTAL LOCALITES</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small> <b>LOCALITE COUVERTE</b> </small></th>
                                    <th rowSpan={2} className="text-center"><small><b>EFFECTIF <br/> TOTAL POPULATION</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"> <small><b>POPULATION COUVERTE</b></small> </th>
                                    {/* <th colSpan={technologies?.length * 2 + 3} className="text-center"><small> <b>LOCALITE</b> </small></th>
                                    <th colSpan={technologies?.length * 2 + 3} className="text-center"> <small><b></b></small> POPULATION</th> */}
                                </tr>
                                {/* <tr>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>COUVERTE</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>NON-COUVERTE</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>COUVERTE</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>NON-COUVERTE</b></small></th>
                                </tr> */}
                                <tr>
                                    {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th className="text-center"><small><b>SYNTHESE</b></small></th>
                                    {/* {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th><small style={{ fontSize: 10 }}><b>SYNTHESE</b></small></th> */}
                                    {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th className="text-center"><small><b>SYNTHESE</b></small></th>
                                    {/* {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th><small style={{ fontSize: 10 }}><b>SYNTHESE</b></small></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((d, index) => {
                                    return (<tr key={"d1" + index}>
                                        <td className="border bwd" style={{ width: 230 }}>
                                            {type == 0 && <a href="#" onClick={() => handleSelectEntity(1, d?.properties)}> <small><b>{d?.properties?.ADM0_FR}</b></small> </a>}
                                            {type == 1 && <a href="#" onClick={() => handleSelectEntity(2, d?.properties)}> <small><b>{d?.properties?.ADM1_FR}</b></small> </a>}
                                            {type == 2 && <a href="#" onClick={() => handleSelectEntity(3, d?.properties)}> <small><b>{d?.properties?.ADM2_FR}</b></small> </a>}
                                            {type == 3 && <small><b>{d?.properties?.ADM3_FR}</b></small>}
                                        </td>
                                        <td className="border text-center"> <small>{handleNumThousand(d?.properties?.nbloc)}</small>  </td>
                                        {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt2' + index} className="border text-center">
                                                    <small><b>{d?.properties?.['locCouv' + technology?.name]}</b></small>
                                                    <hr className="p-0 m-0" />
                                                    <div>
                                                        <small>
                                                            {((d?.properties?.['locCouv' + technology?.name] * 100) / d?.properties?.nbloc).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.nbcouvloc)}</b></small>
                                            <hr className="p-0 m-0" />
                                            <div>
                                                <small>
                                                    {d?.properties?.txcouvloc.toFixed(2)} %
                                                </small>
                                            </div>
                                        </td>
                                        {/* {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt3' + index} className="text-center">
                                                    <small><b>{d?.properties?.nbloc - d?.properties?.['locCouv' + technology?.name]}</b></small>
                                                    <div>
                                                        <small>
                                                            {(((d?.properties?.nbloc - d?.properties?.['locCouv' + technology?.name]) * 100) / d?.properties?.nbloc).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.nbnocouvloc)}</b></small>
                                            <div>
                                                <small>
                                                    {((d?.properties?.nbnocouvloc * 100) / d?.properties?.nbloc).toFixed(2)} %
                                                </small>
                                            </div>
                                        </td> */}
                                        <td className="border text-center"><small>{handleNumThousand(d?.properties?.population)}</small>   </td>
                                        {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt2' + index} className="border text-center">
                                                    <small><b>{handleNumThousand(d?.properties?.['pop' + technology?.name])}</b></small>
                                                    <hr className="p-0 m-0" />
                                                    <div>
                                                        <small>
                                                            {((d?.properties?.['pop' + technology?.name] * 100) / d?.properties?.population).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.popcouv)}</b></small>
                                            <hr className="p-0 m-0" />
                                            <div>
                                                <small>
                                                    {d?.properties?.txpopcouv.toFixed(2)} %
                                                </small>
                                            </div>
                                        </td>
                                        {/* {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt3' + index} className="border text-center">
                                                    <small><b>{d?.properties?.population - d?.properties?.['pop' + technology?.name]}</b></small>
                                                    <div>
                                                        <small>
                                                            {(((d?.properties?.population - d?.properties?.['pop' + technology?.name]) * 100) / d?.properties?.population).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.nopopcouv)}</b></small>
                                            <div>
                                                <small>
                                                    {((d?.properties?.nopopcouv * 100) / d?.properties?.population).toFixed(2)} %
                                                </small>
                                            </div>
                                        </td> */}
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </>)
}