import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
//icons
import "primeicons/primeicons.css";

export const AdminDistrictExcelDialog = ({ display, setDisplay }) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);
  const [districts, setdistricts] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    // handleInitVar()
  };

  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);

  const handleSumit = () => {
    
  }
  const fields = [
    {
      // Visible in table header and when matching columns.
      label: "Code",
      // This is the key used for this field when we call onSubmit.
      key: "code",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["first name", "first"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Stephanie",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Name is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
  ]

  return (
    <>
      <Dialog
        header={<><div className="bg-success p-2 text-white">Ajout de district par fichier Excel</div></>}
        visible={display}
        modal
        style={{ width: "40vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
        <form onSubmit={handleSumit}>
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Selectionner une fichier Excel</label>
                </div>
              </div>
        </form>
      </Dialog>
    </>
  );
};
