import { useEffect, useRef, useState } from "react";
import { Toast } from 'primereact/toast';
import { Link } from "react-router-dom";
import { AdminRegionList } from "./AdminRegionList";
import { DeleteRegionDialog, DeleteRegionsDialog, DeleteSRegionsDialog } from "./AdminRegionDeleteDialog";
import { addRegion, deleteRegion, editRegion, getRegions } from "../../../../../services/api/admin/adminEntities/region/regionApiService";
import { AdminRegionDialog } from "./AdminRegionDialog";
import { toast } from 'react-toastify';
import { toastError, toastSuccess, toastWarning } from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { getDistricts } from "../../../../../services/api/admin/adminEntities/districts/districtApiService";
import { AdminRegionVIewDialog } from "./AdminRegionViewDialog";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";


export const AdminRegionPage = () => {
  const [openRegionViewDialog, setOpenRegionViewDialog] = useState(false);
  const [openRegionDialog, setOpenRegionDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [regions, setRegions] = useState();
  const [region, setRegion] = useState();
  const [districts, setDistricts] = useState();

  const [selectedRegions, setSelectedRegions] = useState(null);

  const [regionDialogTitle, setRegionDialogTitle] = useState("CREATION D'UNE REGION");
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);




  useEffect(() => {
    getDistricts(setDistricts)
    getRegions(setRegions)
  }, []);

  useEffect(() => {
    console.log("Les regions :", region);
  }, [region]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de region effectué avec succès!")
      getRegions(setRegions)
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du region !")
    }
    setOpenRegionDialog(false)
    handleInitVar()
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du region effectué avec succès!")
      getRegions(setRegions)
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du region !")
    }
    setOpenRegionDialog(false)
    handleInitVar()
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du region effectué avec succès!")
      getRegions(setRegions)
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du region !")
    }
    setOpenDeleteDialog(false)
    setopenDeleteSelected(false)
    handleInitVar()
  }, [deleteResponse]);



  //INITIALISATION
  const handleInitVar = () => {
    setRegion()
    setCreateResponse()
    setEditResponse()
    setDeleteResponse()
    setRegionDialogTitle("CREATION D'UNE REGION")
    setSelectedRegions()
    setOpenRegionDialog(false)
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _region = { ...region };

    _region[`${name}`] = val;

    setRegion(_region);
  };

  const handleSubmitRegion = async (e) => {
    e.preventDefault();

    if (region.name.trim()) {
      region?.description?.trim()
      if (region.id) {
        await editRegion(region, setEditResponse);
      } else {
        await addRegion(region, setCreateResponse);
      }
    }
    else {
      toastWarning("Un champs n'est pas valide !")
    }
  };
  

  //VISUALISATION
  const handleViewRegion = (reg) => {
    console.log("Region à affichier :", reg)
    setRegion({ ...reg });
    setOpenRegionViewDialog(true);
  }

  //EDITION
  const handleEditRegion = (reg) => {
    setRegionDialogTitle("MODIFICATION D'UNE REGION");
    console.log('AZERTY', reg);
    setRegion({ ...reg });
    setOpenRegionDialog(true);
  };

  //SUPPRESSION
  const handleDeleteRegionDialog = (reg) => {
    setRegion({ ...reg });
    setOpenDeleteDialog(true);
  };

  const handleDeleteRegion = async () => {
    await deleteRegion(region, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteRegions = async () => {
    try {
      selectedRegions.forEach(async (region) => {
        await deleteRegion(region, setDeleteResponse);
      });
    } catch (error) {
      toastError(
        "Erreur lors de la suppression du regions selectionnés !"
      );
    }
  };


  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nbloc", header: "Localités" },
    { field: "nbcouvloc", header: "Localité couvertes" },
    { field: "perLocCov", header: "Pourcentage localité couverte" },
    { field: "locCouv2G", header: "Localités couverte 2G" },
    { field: "perLocCov2G", header: "Pourcentage localités couverte 2G" },
    { field: "locCouv3G", header: "Localités couverte 3G" },
    { field: "perLocCov3G", header: "Pourcentage localités couverte 3G" },
    { field: "locCouv4G", header: "Localités couverte 4G" },
    { field: "perLocCov4G", header: "Pourcentage localités couverte 4G" },
    { field: "population", header: "Population" },
    { field: "perPopCov2G", header: "Pourcentage population couverte 2G" },
    { field: "perPopCov3G", header: "Pourcentage population couverte 3G" },
    { field: "perPopCov4G", header: "Pourcentage population couverte 4G" },
    { field: "perPopCov", header: "Pourcentage population couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({ format: 'a4', orientation: 'landscape', unit: 'cm', compressPdf: true });

        doc.autoTable(exportColumns, regions, { headStyles: { fillColor: [255, 140, 0] } });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {

      const worksheet = xlsx.utils.json_to_sheet(regions);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      saveAsExcelFile(excelBuffer, 'artci-cartodonnees-extract-regions');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-draw-polygon"
                title="Gestion des regions"
                subTitle="Tableau de bord"
                description="Gestion des regions. "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des regions
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenRegionDialog(true);
                        setRegion();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un region
                    </button>
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-success btn-sm"
                      onClick={() => {
                        // setOpenDepartmentDialog(true);
                        // setDepartment();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Ajouter par fichier excel
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {regions?.length > 0 ? 
                      <AdminRegionList
                        regions={regions}
                        handleEditRegion={handleEditRegion}
                        handleDeleteRegionDialog={handleDeleteRegionDialog}
                        setopenDeleteSelected={setopenDeleteSelected}
                        exportCSV={exportCSV}
                        exportPDF={exportPdf}
                        exportExcel={exportExcel}
                        dt={dt}
                        selectedRegions={selectedRegions}
                        setSelectedRegions={setSelectedRegions}
                        handleViewRegion={handleViewRegion}
                      />: <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminRegionVIewDialog
              display={openRegionViewDialog}
              setDisplay={setOpenRegionViewDialog}
              region={region}
            />

            <AdminRegionDialog
              display={openRegionDialog}
              setDisplay={setOpenRegionDialog}
              handleSumitRegion={handleSubmitRegion}
              region={region}
              districts={districts}
              title={regionDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setRegion={setRegion}
            />
            <DeleteRegionDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeleteregion={handleDeleteRegion}
              region={region}
            />
            <DeleteSRegionsDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedRegion={handleDeleteRegions}
            />
          </>
        }
      />
    </>
  );
};
