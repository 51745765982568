
import { ProgressBar } from 'primereact/progressbar';
import { handleNumThousand } from '../../../services/tools/convertions';
import './banner.css'
import { useEffect, useState } from 'react';



export const DashboardBanner = ({generaleData}) => {
    // const [data, setData] = useState({});

    // useEffect(() => {
    //     const _data = {
    //         locs:generaleData?.locs,
    //         locs_cov:generaleData?.locs_cov,
    //         per_locs_cov:generaleData?.per_locs_cov.toFixed
    //     }
    // }, []);

    return(<>
        <div class="card mb-3">
            <div class="no-gutters row">
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="pt-0 pb-0 card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <div class="widget-content p-0">
                                    <div class="widget-content-outer">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Localités couvertes</div>
                                                <div class="widget-numbers text-success">{handleNumThousand(generaleData?.locs) } </div>
                                            </div>
                                        </div>
                                        <div class="widget-progress-wrapper pt-0 mt-0">
                                            <div class="progress-sub-label">
                                                <div class="sub-label-left">Totale localité</div>
                                                <div class="sub-label-right">{handleNumThousand(generaleData?.locs_cov) }</div>
                                            </div>
                                            <ProgressBar style={{ height: '15px' }} color='#3AC47D' value={generaleData?.per_locs_cov.toFixed(2)}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="pt-0 pb-0 card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <div class="widget-content p-0">
                                    <div class="widget-content-outer">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Total localités non couverte</div>
                                                <div class="widget-numbers text-danger">{handleNumThousand(generaleData?.locs_no_cov) } </div>
                                            </div>
                                        </div>
                                        <div class="widget-progress-wrapper pt-0 mt-0">
                                            <div class="progress-sub-label">
                                                <div class="sub-label-left">Totale localité</div>
                                                <div class="sub-label-right">{handleNumThousand(generaleData?.locs)}</div>
                                            </div>
                                            <ProgressBar style={{ height: '15px',borderRadius:5 }} color='#D82450' value={generaleData?.per_locs_no_cov.toFixed(2)}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="pt-0 pb-0 card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <div class="widget-content p-0">
                                    <div class="widget-content-outer">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Population couverte</div>
                                                <div class="widget-numbers text-info">{handleNumThousand(generaleData?.pop_cov) }</div>
                                            </div>
                                        </div>
                                        <div class="widget-progress-wrapper pt-0 mt-0">
                                            <div class="progress-sub-label">
                                                <div class="sub-label-left">Totale population</div>
                                                <div class="sub-label-right">{handleNumThousand(generaleData?.pop)}</div>
                                            </div>
                                            <ProgressBar style={{ height: '15px' }} color='#18A9FF' value={generaleData?.per_pop_cov.toFixed(2)}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-3">
                    <div class="pt-0 pb-0 card-body">
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <div class="widget-content p-0">
                                    <div class="widget-content-outer">
                                        <div class="widget-content-wrapper">
                                            <div class="widget-content-left">
                                                <div class="widget-heading">Population non couverte</div>
                                                <div class="widget-numbers text-focus">{handleNumThousand(generaleData?.pop_no_cov) }</div>
                                            </div>
                                        </div>
                                        <div class="widget-progress-wrapper pt-0 mt-0">
                                            <div class="progress-sub-label">
                                                <div class="sub-label-left">Totale population</div>
                                                <div class="sub-label-right">{handleNumThousand(generaleData?.pop)}</div>
                                            </div>
                                            <ProgressBar style={{ height: '15px' }} color='#434054' value={generaleData?.per_pop_no_cov.toFixed(2)}></ProgressBar>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
