import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { localVariables } from "../../../services/local/SecureLocalStorageVariable";
import { showOnAdminBar } from "../../../constants";
import './sidebar.css'


export const AdminSideBar = ({setShowError4O4}) => {
    const [showCovGest, setShowCovGest] = useState(true);
    const [showOpticGest, setShowOpticGest] = useState(false);
    const [showSetting, setShowSetting] = useState(false);
    const [showEntities, setShowEntities] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showNews, setShowNews] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showOperator, setShowOperator] = useState(false);
    const [showOther, setShowOther] = useState(false);
    const [showOnSide, setShowOnSide] = useState()

    useEffect(() => {
        let _show = localVariables.getItem(showOnAdminBar)
        if(!_show){
            let __show = { ...showOnSide };
            __show["showCovGest"] = true;
            setShowOnSide(__show)
        }else{
            setShowOnSide(_show)
        }
    }, []);


    const handleShowOnSid = (name) => {
        let _show = { ...showOnSide };
        if(_show[name] == true){
            _show[name] = false;
            localVariables.setItem(showOnAdminBar,_show)
        }else{
            _show[name] = true;
            localVariables.setItem(showOnAdminBar,_show)
        }
        setShowOnSide(_show);
    }

    return (<>
        <div className="app-s sidebar-shadow">
            <div className="app-header__logo">
                <div className="logo-src"></div>
                <div className="header__pane ml-auto">
                    <div>
                        <button type="button" className="hamburger close-sidebar-btn hamburger--elastic">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="scrollbar-sidebar pb-5">
                <div className="">
                    <h4 className="text-center">CARTODONNEES</h4>
                </div>
                <a href="/" className=" m-0">
                    <div className="p-2 bg-carte">
                        <h5 className="text-center text-warning">
                            <b><i className="fas fa-map mr-2"></i>Retour à la carte</b>
                        </h5>
                    </div>
                </a>
                <Link to="/admin" className="btn bg-dark btn-block br-none">
                    <div className="">
                        <h4 className="text-center text-white"><i className="fas fa-table mr-2"></i>Tableau de bord</h4>
                    </div>
                </Link>
                <div className="app-sidebar__inner scroll-content-1 pb-5">
                    <ul className="vertical-nav-menu">
                        <li className="app-sidebar__heading mm-active">
                            <Link className="text-primary p-0 m-title"><i className="fas fa-upload mr-2"></i>MISE A JOUR
                                <i className="fas fa-chevron-down ml-2"></i>
                            </Link>
                            <ul>
                                <li>
                                    <Link className={"mm-active mt-2"} onClick={() => handleShowOnSid("showCovGest")}>
                                        <i className="fas fa-satellite-dish mr-2"></i>Données géographiques
                                        <i className="fas fa-chevron-right ml-2"></i>
                                    </Link>
                                    {showOnSide?.showCovGest && <>
                                        <ul>
                                            <li>
                                                <Link to="/admin-coverage"  className=" mt-2">
                                                    <i className="fas fa-satellite-dish mr-2"></i>Données de couvertures
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-coverage-update-by-excel"  className=" mt-2">
                                                    <i className="fas fa-satellite-dish mr-2"></i>Opération par Excel
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-limites-entities"  className=" mt-2">
                                                    <i className="fas fa-draw-polygon mr-2"></i>Limites cartographiques
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                    <i className="fas fa-chart-simple mr-2"></i>Statistique couverture
                                                </Link>
                                            </li>
                                        </ul>
                                    </>}
                                </li>
                                <li>
                                    <Link className="mm-active mt-2" onClick={() => handleShowOnSid("showOpticGest")}>
                                        <i className="fas fa-lines-leaning mr-2"></i>Fibre optique
                                        <i className="fas fa-chevron-right ml-2"></i>
                                    </Link>
                                    {showOnSide?.showOpticGest && <>
                                        <ul>
                                            <li>
                                                <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                    <i className="fas fa-lines-leaning mr-2"></i>Axes
                                                </Link>
                                                <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                    <i className="fas fa-lines-leaning mr-2"></i>Tronçon
                                                </Link>
                                                <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                    <i className="fas fa-chart-simple mr-2"></i>Statistiques fibres
                                                </Link>
                                            </li>
                                        </ul>
                                    </>}
                                </li>
                                <li>
                                    <Link className="mm-active mt-2" onClick={() => handleShowOnSid("showSetting")}>
                                        <i className="fas fa-gear mr-2"></i>Paramètres
                                        <i className="fas fa-chevron-right ml-2"></i>
                                    </Link>
                                    {showOnSide?.showSetting && <>
                                        <ul>
                                            <li>
                                                <Link className=" mt-2" to="/admin-technology">
                                                    <i className="fas fa-microchip mr-2"></i>Technologies
                                                </Link>
                                            </li>
                                        </ul>
                                    </>}
                                </li>
                                <li>
                                    <Link className="mm-active mt-2" onClick={() => handleShowOnSid("showEntities")}>
                                        <i className="fas fa-draw-polygon mr-2"></i>Entités administratives
                                        <i className="fas fa-chevron-right ml-2"></i>
                                    </Link>
                                    {showOnSide?.showEntities && <>
                                        <ul>
                                            <li>
                                                <Link to="/admin-district" className=" mt-2">
                                                    <i className="fas fa-draw-polygon mr-2"></i>Districts
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-region" className=" mt-2">
                                                    <i className="fas fa-draw-polygon mr-2"></i>Regions
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-department" className=" mt-2">
                                                    <i className="fas fa-draw-polygon mr-2"></i>Departements
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-sub-prefecture" className=" mt-2">
                                                    <i className="fas fa-draw-polygon mr-2"></i>Sous-préfectures
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/admin-localities" className=" mt-2">
                                                    <i className="fas fa-draw-polygon mr-2"></i>Localités
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                    <i className="fas fa-chart-simple mr-2"></i>Statistiques
                                                </Link>
                                            </li>
                                        </ul>
                                    </>}
                                </li>
                            </ul>
                        </li>
                        <li className="app-sidebar__heading" >
                            <Link className="text-primary p-0 m-title" onClick={() => handleShowOnSid("showReport")}><i className="fas fa-exclamation-triangle mr-2"></i>SIGNALEMENTS
                                <i className="fas fa-chevron-down ml-2"></i>
                            </Link>
                            {showOnSide?.showReport && <>
                                <ul>
                                    <li>
                                        <Link to="/problem" className=" mt-2">
                                            <i className="fas fa-satellite-dish mr-2"></i>Problèmes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/app-signages" className="text-left mt-2">
                                            <i className="fas fa-satellite-dish mr-2"></i>Signalément plateformes
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/network-signages" className=" mt-2">
                                            <i className="fas fa-satellite-dish mr-2"></i>Signalément réseaux
                                        </Link>
                                    </li>
                                </ul>
                            </>}
                        </li>
                        <li className="app-sidebar__heading">
                            <Link className="text-primary p-0 m-title" onClick={() => handleShowOnSid("showNews")}><i className="fas fa-project-diagram mr-2"></i>MESSAGES
                                <i className="fas fa-chevron-down ml-2"></i>
                            </Link>
                            {showOnSide?.showNews && <>
                                <ul>
                                    <li>
                                        <Link to="/newsLetters" className=" mt-2">
                                            <i className="fas fa-newspaper mr-2"></i>Boite aux lettres
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home-messages" className=" mt-2">
                                            <i className="fas fa-newspaper mr-2"></i>Message accueil
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/categories" className=" mt-2">
                                            <i className="fas fa-newspaper mr-2"></i>Categories
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/newsLetters" className=" mt-2">
                                            <i className="fas fa-newspaper mr-2"></i>Newsletters
                                        </Link>
                                    </li>
                                </ul>
                            </>}
                        </li>
                        <li className="app-sidebar__heading">
                            <Link className="text-primary p-0 m-title" onClick={() => handleShowOnSid("showUser")}><i className="fas fa-users mr-2"></i>UTILISATEURS
                                <i className="fas fa-chevron-down ml-2"></i>
                            </Link>
                            {showOnSide?.showUser && <>
                                <ul>
                                    <li>
                                        <Link  className="mm-active mt-2" onClick={() => handleShowOnSid("showOperator")}>
                                            <i className="fas fa-satellite-dish mr-2"></i>Opérateurs
                                            <i className="fas fa-chevron-right ml-2"></i>
                                        </Link>
                                        {showOnSide?.showOperator && <>
                                            <ul>
                                                <li>
                                                    <Link to="/admin-operator" className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                        <i className="fas fa-satellite-dish mr-2"></i>Gestion opérateurs
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                        <i className="fas fa-satellite-dish mr-2"></i>Statistiques
                                                    </Link>
                                                </li>
                                            </ul>
                                        </>}
                                    </li>
                                    <li>
                                        <Link className="mm-active mt-2" onClick={() => handleShowOnSid("showOther")}>
                                            <i className="fas fa-satellite-dish mr-2"></i>Utilisateurs
                                            <i className="fas fa-chevron-right ml-2"></i>
                                        </Link>
                                        {showOnSide?.showOther && <>
                                            <ul>
                                                <li>
                                                    <Link className=" mt-2" onClick={() => setShowError4O4(true)}>
                                                        <i className="fas fa-satellite-dish mr-2"></i>Gestion des utilisateurs
                                                    </Link>
                                                </li>
                                            </ul>
                                        </>}
                                    </li>
                                </ul>
                            </>}
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </>);
}