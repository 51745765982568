import { Link } from 'react-router-dom'
import './goOn.css'



export const GoOnOperator = () => {

    return(<>
        <div class="h-100">
            <div class="row h-100 no-gutters">
                <div class="d-none d-lg-block col-lg-6">
                    <div class="position-relative h-100 d-flex  align-items-center bg-plum-plate">
                        <div className="container d-flex justify-content-center">
                            <div className=''>
                                <div>
                                <Link href="https://www.artci.ci" target="_blank">
                                    <img src="../images/logo-artci.png" className='' width={400} />
                                </Link>

                                <h1 className='text-center'><i className="fa fa-map"></i></h1>
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                <Link to="/" className="btn btn-dark"><i className="fa fa-map mr-2"></i> Retour à la carte</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-6">
                    <div class="mx-auto div-box col-sm-12 col-md-10 col-lg-9">
                        <div class="app-logo"></div>
                        <h4 class="mb-0">
                            <h2 className='title-login mb-2'>Accéder à l'espace Opérateur</h2>
                            <div className='text-primary'>Vous êtes désormais connecté.</div>
                            <span>À présent, veuillez cliquer sur continuer pour avoir accès à l'espace opérateur.</span>
                        </h4>
                        <div className='container d-flex justify-content-center pt-5 '>
                        <Link to="/operator-dashboard" type="button" class="btn btn-lg btn-dark btn-lg"><i className="fa fa-table mr-2"></i> Continuer vers l'espace d'administration Opérateur</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
    
}