import { adminAxiosInstance } from "../../config/axiosConfig"



export const getProblems = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/problems`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addProblem = async (problem, setState) => {
    try {
        const {type,level,title, description } = problem

        const response = await adminAxiosInstance.post(`/problems`, {
            type: type,
            level: level,
            title: title,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            console.log(response.data)
            console.log("Problem enregistré :", response.data)
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addProblemsList = async (problems, setState) => {
    try {
        let isDone;
        problems.forEach(async dist => {
            const { name, description } = dist

            const response = await adminAxiosInstance.post(`/problems`, {
                name: name,
                description: description,
            }).then((res => res))

            if (response.status != 201) {
                return
            }

        });

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editProblem = async (problem, setState) => {
    try {
        const {type,level,title, description } = problem

        await adminAxiosInstance.put('/problems/' + problem.id, {
            type: type,
            level: level,
            title: title,
            description: description,
        }).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteProblem = async (problem, setState) => {
    try {

        await adminAxiosInstance.delete('/problems/' + problem.id).then((response) => {
            setState(response.data);
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}