import { userAxiosInstance } from "../config/axiosConfig";
import { generaleStats } from "../../../constants";
import { localVariables } from "../../local/SecureLocalStorageVariable";
import { year } from "../../constants/map/mapDataConstants";

export const getGeneraleStatistics = async (st) => {
    try {

        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await userAxiosInstance.get('generale-statistics').then((res => res))
        }
        else if(y == 2021){
            response = await userAxiosInstance.get('generale-statistics2021').then((res => res) )
        }

        // setState([]);

        
        if(response.status == 200) {
            // localVariables.setItem(generaleStats,response.data)
            return response.data;
        }
    } catch (error) {
        console.error(error)
        // setState({error:true})
    }
}