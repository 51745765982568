import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminDepartmentList } from "./AdminDepartmentList";
import {
  DeleteDepartmentDialog,
  DeleteDepartmentsDialog,
  DeleteSDepartmentsDialog,
} from "./AdminDepartmentDeleteDialog";
import {
  addDepartment,
  addDepartmentList,
  deleteDepartment,
  editDepartment,
  getDepartments,
} from "../../../../../services/api/admin/adminEntities/department/departmentApiService";
import { AdminDepartmentDialog } from "./AdminDepartmentDialog";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { getRegions } from "../../../../../services/api/admin/adminEntities/region/regionApiService";
import { AdminDepartmentVIewDialog } from "./AdminDepartmentViewDialog";
// import { ImportExcelFileComponent } from "../../../../../components/Excel/ImportExcelFileComponent";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";

export const AdminDepartmentPage = () => {
  const [openDepartmentViewDialog, setOpenDepartmentViewDialog] =
    useState(false);
  const [openDepartmentDialog, setOpenDepartmentDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [departments, setDepartments] = useState();
  const [department, setDepartment] = useState();
  const [regions, setRegions] = useState();

  const [selectedDepartments, setSelectedDepartments] = useState(null);

  const [departmentDialogTitle, setDepartmentDialogTitle] = useState(
    "CREATION D'UN DEPARTMENT"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  const [excelResponse, setExcelResponse] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getRegions(setRegions);
    getDepartments(setDepartments);
  }, []);

  useEffect(() => {
    console.log("Les departments :", department);
  }, [department]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de department effectué avec succès!");
      getDepartments(setDepartments);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du department !");
    }
    setOpenDepartmentDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du department effectué avec succès!");
      getDepartments(setDepartments);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du department !");
    }
    setOpenDepartmentDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du department effectué avec succès!");
      getDepartments(setDepartments);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du department !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setDepartment();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setDepartmentDialogTitle("CREATION D'UN DEPARTMENT");
    setSelectedDepartments();
    setOpenDepartmentDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _department = { ...department };

    _department[`${name}`] = val;

    setDepartment(_department);
  };

  const handleSubmitDepartment = async (e) => {
    e.preventDefault();

    if (department.name.trim()) {
      department?.description?.trim();
      if (department.id) {
        await editDepartment(department, setEditResponse);
      } else {
        await addDepartment(department, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewDepartment = (dep) => {
    console.log("Department à affichier :", dep);
    setDepartment({ ...dep });
    setOpenDepartmentViewDialog(true);
  };

  //EDITION
  const handleEditDepartment = (dep) => {
    setDepartmentDialogTitle("MODIFICATION D'UN DEPARTMENT");
    console.log("AZERTY", dep);
    setDepartment({ ...dep });
    setOpenDepartmentDialog(true);
  };

  //SUPPRESSION
  const handleDeleteDepartmentDialog = (dep) => {
    setDepartment({ ...dep });
    setOpenDeleteDialog(true);
  };

  const handleDeleteDepartment = async () => {
    await deleteDepartment(department, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteDepartments = async () => {
    try {
      selectedDepartments.forEach(async (department) => {
        await deleteDepartment(department, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du departments selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, departments, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(departments);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-departments");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  const fields = [
    {
      // Visible in table header and when matching columns.
      label: "Code",
      // This is the key used for this field when we call onSubmit.
      key: "code",
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "C000",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Name is required",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Nom",
      // This is the key used for this field when we call onSubmit.
      key: "name",
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Le nom du district ...",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Le nom est obligatoire",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "erreur",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Description",
      // This is the key used for this field when we call onSubmit.
      key: "description",
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Une description du district",
    },
  ];

  const handleSumitExcel = (data) => {
    if (data?.length > 0) {
      data.forEach((dist) => {
        let _district = {
          name: dist.name,
          description: dist.description,
        };
        addDepartmentList(_district, setExcelResponse);
      });
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-draw-polygon"
                title="Gestion des départements"
                subTitle="Tableau de bord"
                description="Gestion des départements. "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des departments
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenDepartmentDialog(true);
                        setDepartment();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un department
                    </button>
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-success btn-sm"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Ajouter par fichier
                      excel
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {departments?.length > 0 ? (
                        <AdminDepartmentList
                          departments={departments}
                          handleEditDepartment={handleEditDepartment}
                          handleDeleteDepartmentDialog={
                            handleDeleteDepartmentDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedDepartments={selectedDepartments}
                          setSelectedDepartments={setSelectedDepartments}
                          handleViewDepartment={handleViewDepartment}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminDepartmentVIewDialog
              display={openDepartmentViewDialog}
              setDisplay={setOpenDepartmentViewDialog}
              department={department}
            />

            <AdminDepartmentDialog
              display={openDepartmentDialog}
              setDisplay={setOpenDepartmentDialog}
              handleSumitDepartment={handleSubmitDepartment}
              department={department}
              regions={regions}
              title={departmentDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setDepartment={setDepartment}
            />

            {/* <ImportExcelFileComponent
              fields={fields}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleSubmit={handleSumitExcel}
            /> */}

            <DeleteDepartmentDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletedepartment={handleDeleteDepartment}
              department={department}
            />
            <DeleteSDepartmentsDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedDepartment={handleDeleteDepartments}
            />
          </>
        }
      />
    </>
  );
};
