import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { TailSpin } from 'react-loader-spinner'





export const AdminCoverageExcelDialog = ({
    display,
    setDisplay,
    data,
    setData,
    handleSubmit,
    isLoadDing,
    showError,
    response
}) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);
    const [showData, setShowData] = useState(false);

    const onClick = (position) => {
        setTimeout(() => {
            setShowData(true)
        }, 2000);
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        setData(null)
        setShowData(false)
        // handleInitVar()
    };

    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);


    return (<>
        <Dialog
            header="SOUMISSION DE MISE A JOUR"
            visible={display}
            modal
            style={{ width: "80vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
        <div className="mt-2">
            <button className="btn btn-success btn-block" onClick={() => handleSubmit()}>SOUMETRE LA MISE A JOUR</button>
        </div>
    <div className="mt-4">
        <button className="btn btn-lg btn-secondary btn-block" onClick={() => onHide()}>ANNULER</button>
    </div>
            {!showError ? <>
                {!isLoadDing ? <>
                    {/* <div role="alert" class=" alert alert-warning alert-dismissible fade show p-1 text-center">
                        <h5>Données retenues</h5>
                        <div>
                            <small className="">
                                Vous trouverez dans cette liste les données qui respecte les validations réquises à l'initialisation de la mise à jour<br />
                            </small>
                            <div>
                            </div>
                        </div>
                    </div> */}
                    <hr />
                    <div>
                        {/* {(showData && data?.length > 0) ? <>
                        </> : <>
                            <div className="container px-1 px-md-5 px-lg-1 mbody">
                                <DataLoaderTailSpin />
                            </div>
                        </>} */}
                    </div>
                </> : <>
                    <div className='d-flex justify-content-center p-5'>
                        <div>
                            <div className='d-flex justify-content-center p-5'>
                                <TailSpin
                                    height="60"
                                    width="60"
                                    color="#f19742"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div>
                            <div className='text-center'>
                                <h5>Enregistrements des données...</h5>
                                <p>Veuillez patienter.</p>
                            </div>
                        </div>
                    </div>
                </>}
            </> : <>
                <div>
                    <div className="taxt-center">
                        <h4> {response?.message} </h4>
                    </div>
                </div>
            </>}
        </Dialog>
    </>)


}