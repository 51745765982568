
import { Link, useNavigate } from 'react-router-dom';
import './verify.css'

export const UserSuccessVerifyPage = () => {

    return (
        <>
            <div class="h-100">
                <div class="row h-100 no-gutters">
                    <div class="d-none d-lg-block col-lg-4">
                        <div class="position-relative h-100 d-flex  align-items-center bg-plum-plate">
                            <div className="container d-flex justify-content-center">
                                <div className=''>
                                    <div>
                                    <Link href="https://www.artci.ci" target="_blank">
                                        <img src="../images/logo-artci.png" className='' width={400} />
                                    </Link>
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                    <Link to="/" className="btn btn-dark">Retour à l'accueil</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
                        <div class="mx-auto div-box col-sm-12 col-md-10 col-lg-9">
                            <div class="app-logo"></div>
                            <h4 class="mb-0">
                                <h2 className='title-login mb-2'>Cartodonnees véfication de compte</h2>
                                <div className='text-primary'>Votre compte a été vérifier avec succès.</div>
                                <span>A présent, veuillez vous connecter à votre compte.</span>
                            </h4>
                            <div className='container d-flex justify-content-center pt-5 '>
                            <Link to="/login" type="button" class="btn btn-primary btn-lg">Se connecter</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}