import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { getOperators } from "../../../../../services/api/admin/operators/operatorApiService";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";
import { handleNumThousand } from "../../../../../services/tools/convertions";
import ReactToPrint from "react-to-print";
import { baseFileUrl } from "../../../../../constants";
import { getRegionsByGeoData } from "../../../../../services/api/admin/adminEntities/region/regionApiService";


export const AdminRegionVIewDialog = ({ display, setDisplay, region }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [localRegion, setLocalRegion] = useState();
    const [operators, setOperators] = useState();
    const [technologies, setTechnologies] = useState();

    const [isPrinting, setIsPrinting] = useState(false);


    const contentRef = useRef();
    const tableRef = useRef();


    useEffect(() => {
        getOperators(setOperators)
        getTechnologies(setTechnologies)
    }, []);


    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        handleGetRegionsData(region?.id)
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        setLocalRegion()
        // handleInitVar()
    };

    const handleGetRegionsData = async (geo_id) => {
        await getRegionsByGeoData(setLocalRegion, geo_id)
    }


    return (<>
        <Dialog
            header="DETAILS REGION"
            visible={display}
            modal
            style={{ width: "80vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
            <div className="mb-2">
                <ReactToPrint
                    trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                    content={() => contentRef.current}
                    onBeforePrint={() => { !region?.description?.length > 0 && setIsPrinting(true) }}
                    onAfterPrint={() => setIsPrinting(false)}
                    pageStyle={`@media print {body {
                        padding: 20px; /* Adjust the padding as needed */
                      } @page { size: 450mm 250mm;}};`}
                />
                <hr />
            </div>
            <div className="row" ref={contentRef}>
                <div className="col-md-12 p-0">
                    <div>
                        <h3>REGION : <b>{region?.name}</b></h3>
                        <div>
                            <h5>Coordonnées du centre:</h5>
                            <div className="pl-3">
                                <div>
                                    <p>Latitude : {region?.center_lat} </p>
                                    <p>Longitude : {region?.center_lng} </p>
                                </div>
                            </div>
                        </div>
                        {!isPrinting && <>
                                {region?.description?.length > 0 && <>
                            <div className="bg-light">
                                    <p>{region?.description} </p>
                            </div>
                                </>}
                        </>}

                    </div>
                    <div>
                    </div>
                </div>
                <div className="col-md-12 bg-light">
                    <div class="">
                        <div class="no-gutters row">
                            <div class="col-md-6 col-xl-3">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success">{region?.nb_depart} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Departemtns</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"> {region?.nb_sub_pref} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Sous - préfectures</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"> {localRegion?.nbloc} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning">{localRegion?.nbcouvloc}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités couvertes</div>
                                            <div class="widget-subheading">{localRegion?.txcouvloc.toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger">{localRegion?.nbnocouvloc} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localité non couverte</div>
                                            <div class="widget-subheading">{(100 - localRegion?.txcouvloc).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-5">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"><small>{handleNumThousand(localRegion?.population)}</small></div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning"><small>{handleNumThousand(localRegion?.popcouv)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population couverte</div>
                                            <div class="widget-subheading">{localRegion?.txpopcouv.toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger"><small>{handleNumThousand(localRegion?.nopopcouv)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population non couverte</div>
                                            <div class="widget-subheading">{(100 - localRegion?.txpopcouv).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                {localRegion && <>
                    <div className="mt-3 w-100">
                        <table className="table table-bordered table-striped table-hover table-width">
                            <thead>
                                <tr>
                                    <th>Opérateur</th>
                                    <th colSpan={technologies?.length * 2} className="text-center">Localités</th>
                                    <th colSpan={technologies?.length * 2} className="text-center">Population</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                    <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                    <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                    <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    {technologies?.length > 0 && technologies?.map((technology, index) => {
                                        return (
                                            <th key={"t" + index} className="text-center"> {technology?.name} </th>
                                        )
                                    })}
                                    {technologies?.length > 0 && technologies?.map((technology, index) => {
                                        return (
                                            <th key={"t1" + index} className="text-center"> {technology?.name} </th>
                                        )
                                    })}
                                    {technologies?.length > 0 && technologies?.map((technology, index) => {
                                        return (
                                            <th key={"t2" + index} className="text-center"> {technology?.name} </th>
                                        )
                                    })}
                                    {technologies?.length > 0 && technologies?.map((technology, index) => {
                                        return (
                                            <th key={"t3" + index} className="text-center"> {technology?.name} </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {operators?.length > 0 && operators?.map((operator, index) => {
                                    return (
                                        <tr key={"ot" + index}>
                                            <td>
                                                <img src={baseFileUrl + operator.image_path} className="mr-2" height={30} alt="" />
                                                <b>{operator?.name}</b> </td>
                                            {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                return (
                                                    <td key={"tt1" + index}> {handleNumThousand(localRegion["locCouv" + operator.name + technology.name])} <br />
                                                        <small> -> {(localRegion["locCouv" + operator.name + technology.name] > 0 ? (localRegion["locCouv" + operator.name + technology.name] * 100 / localRegion?.nbloc).toFixed(1) : 0)} %</small>
                                                    </td>
                                                )
                                            })}
                                            {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                return (
                                                    <td key={"tt2" + index}> {handleNumThousand(localRegion.nbloc - localRegion["locCouv" + operator.name + technology.name])} <br />
                                                        <small> -> {(localRegion["locCouv" + operator.name + technology.name] > 0 ? ((localRegion.nbloc - localRegion["locCouv" + operator.name + technology.name]) * 100 / localRegion?.nbloc).toFixed(1) : 0)} %</small>
                                                    </td>
                                                )
                                            })}
                                            {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                return (
                                                    <td key={"tt3" + index}> {handleNumThousand(localRegion["pop" + operator.name + technology.name])} <br />
                                                        <small> -> {(localRegion["pop" + operator.name + technology.name] > 0 ? (localRegion["pop" + operator.name + technology.name] * 100 / localRegion?.population).toFixed(1) : 0)} %</small>
                                                    </td>
                                                )
                                            })}
                                            {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                return (
                                                    <td key={"tt4" + index}> {handleNumThousand(localRegion.population - localRegion["pop" + operator.name + technology.name])}<br />
                                                        <small> -> {(localRegion["pop" + operator.name + technology.name] > 0 ? ((localRegion.population - localRegion["pop" + operator.name + technology.name]) * 100 / localRegion?.population).toFixed(1) : 0)} %</small>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                                <tr>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>}
            </div>
        </Dialog>
    </>)
}