export const dataToExport = "export-data";

export const districtLockId = "district-lock-id"
export const regionLockId = "region-lock-id"
export const departmentLockId = "department-lock-id"
export const subPrefLockId = "sub-pref-lock-id"

export const hoveredDistrictId = 'hovered-district-id'
export const hoveredRegionId = 'hovered-rgion-id'
export const hoveredDepartmentId = 'hovered-department-id'
export const hoveredSubPrefId = 'hovered-sub-pref-id'
export const showMapData = "swhow-map-data"


export const covData = "cov_data";
export const popData = "pop_data";

export const covPhone = "cov_phone";
export const popPhone = "pop_phone";

export const statLocCanvas = "stat-loc-canvas"
export const statPopCanvas = "stat-pop-canvas"

export const year = "year-in"
export const userTechnologies = 'technologies'
export const messagesAlert = "messages"