import { adminAxiosInstance } from "../../../config/axiosConfig"



export const getRegions = async (setState) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/region_limites2023.geojson').then((res => res))
        // const response = await adminAxiosInstance.get(`/regions`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log("RREGIONNNNNN ===>",response.data.features)
            let tab =[]
            response.data.features.forEach(element => {
                tab.push(element.properties)  
            });
            console.log("TAAAAAAB", tab);
            setState(tab)
            //console.log("liste des categories :", response.data)
            // setState(response.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addRegion = async (region, setState) => {
    try {
        const {district_id,name,description} = region

        const response = await adminAxiosInstance.post(`/regions`, {
            district_id:district_id,
            name: name,
            description: description,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Region enregistré :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const editRegion = async (region, setState) => {
    try {
        const {district_id,name,description} = region

        await adminAxiosInstance.put('/regions/'+region.id, {
            district_id: district_id,
            name: name,
            description: description,
        }).then((response) => {
            console.log('Vérification de l\'edition', response.data)
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const deleteRegion = async (region, setState) => {
    try {

        await adminAxiosInstance.delete('/regions/'+region.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}


export const getRegionsByGeoData = async(setState,id) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/region_limites2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data;
            const _districts = _data.features.filter(feature => feature.id == id)
            const _properties = _districts[0]?.properties;
            setState(_properties)
        }

    } catch (error) {
        
    }
}

export const getRRegionsByGeoData = async(setState) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/region_limites2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data.features;
            setState(_data)
        }

    } catch (error) {
        
    }
}