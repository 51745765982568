
import { Link, useNavigate } from 'react-router-dom';
import './login.css'
import { useEffect, useRef, useState } from 'react';
import { tokenAdminJwt } from '../../../constants';
import { adminLogin } from '../../../services/api/auth/login/adminLoginService';
import { toastError, toastSuccess } from '../../../components/notification/notification';
import { localVariables } from '../../../services/local/SecureLocalStorageVariable';

export const AdminLoginPage = () => {

    const toast = useRef(null);

    const navigate = useNavigate()

    const [admin, setAdmin] = useState();
    const [loginResponse, setLoginResponse] = useState();


    useEffect(() => {
        if(loginResponse){
            if(loginResponse?.error == false){
                console.log("Administratueur :", loginResponse.admin)
                localVariables.setItem(tokenAdminJwt,loginResponse.authorisation.token)
                localVariables.setItem("admin",loginResponse.admin)
                localVariables.setItem("profile",loginResponse.admin.profile)
                toastSuccess('Connexion reussi');
    
    
                if(loginResponse?.admin.profile.id == 1){
                    navigate('/admin', { replace: true })
                }
                // toastSuccess("Vous êtes connecté")
                // navigate('/', { replace: true })
            }else{
                toastError("Non d'utilisateur ou mot de passe incorrect")
            }
        }
    }, [loginResponse]);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _admin = { ...admin };

        _admin[`${name}`] = val;

        setAdmin(_admin);
    };
    
    const handleSubmit = async(e) => {
        e.preventDefault()
        
        if(admin?.email.trim() && admin?.password.trim()){
            let response = await adminLogin(admin)

            setLoginResponse(response)
        }
        
        

    }

    return (
        <>
            <div className="h-100">
                <div className="row h-100 no-gutters">
                    <div className="d-none d-lg-block col-lg-4">
                        <div className="position-relative h-100 d-flex  align-items-center bg-plum-plate">
                            <div className="container d-flex justify-content-center">
                                <div className=''>
                                    <div>
                                    <Link href="https://www.artci.ci" target="_blank">
                                        <img src="../images/logo-artci.png" className='' width={400} />
                                    </Link>
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                    <Link to="/" className="btn btn-dark">Retour à l'accueil</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-100 d-flex bg-white justify-content-center align-items-center col-md-12 col-lg-8">
                        <div className="mx-auto div-box col-sm-12 col-md-10 col-lg-9">
                            <div className="app-logo"></div>
                                <h1 className='title-login mb-5'>Cartodonnees connexion (Admin) </h1>
                            <h4 className="mb-0">
                                <span>Veuillez vous connecter à votre compte..</span>
                            </h4>
                            <h6 className="mt-3"> Pas encore de compte? <Link className="text-primary" to="/register">inscrivez-vous</Link></h6>
                            <div className="divider"></div>
                            <div>
                                <form onSubmit={handleSubmit} className="ng-untouched ng-pristine ng-valid">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <fieldset className="form-group">
                                                <div tabIndex="-1" role="group"><label htmlFor="exampleEmail">Email</label>
                                                    <input id="exampleEmail" name="email" type="email" placeholder="Entrer votre email ici..." className="form-control" onChange={(e) => onInputChange(e, "email")} />
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-6">
                                            <fieldset className="form-group">
                                                <div tabIndex="-1" role="group"><label htmlFor="examplePassword">Mot de passe</label>
                                                    <input id="examplePassword" name="password" type="password" placeholder="Entrer votre mot de passe ici..." className="form-control" onChange={(e) => onInputChange(e, "password")} />

                                                </div>
                                            </fieldset>
                                        </div>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <input id="exampleCheck" type="checkbox" name="check" autoComplete="off" value="true" className="custom-control-input" />
                                        <label htmlFor="exampleCheck" className="custom-control-label"> Se souvenir moi </label>
                                    </div>
                                    <div className="divider"></div>
                                    <div className="d-flex align-items-center">
                                        <div className="ml-auto">
                                            <Link to="/pass-forget" className="btn-lg btn btn-link" >Mot de passe oublié</Link>
                                            <button type="submit" className="btn btn-primary btn-lg">Se connecter</button></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}