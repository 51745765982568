import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";


export const AdminProblemVIewDialog = ({ display, setDisplay, problem }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const onClick = (position) => {
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);


    return (<>
        <Dialog
            header="DETAILS PROBLÈME"
            visible={display}
            modal
            style={{ width: "60vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
            <div className="row">
                <div className="col-md-12">
                <div>
                        <h4>Type : <b>{problem?.type}</b></h4>
                        <hr />
                        <h5>Code : <b>{problem?.code}</b></h5>
                        <h3>Titre : <b>{problem?.title}</b></h3>
                        <h5>Niveau : <b>{problem?.level}</b></h5>

                    <div className="bg-light mt-2 p-2">
                        <div>DESCCRIPTION :</div>
                        <p>{problem?.description}</p>
                    </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}