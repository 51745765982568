
export const adminExcelTemplate = () => {
    const excelOperatorTemplate = [{
        'CODE LOCALITE':'',
        'LOCALITE v2':'',
        'SOUS-PREFECTURE':'',
        'DEPARTEMENT':'',
        'REGION':'',
        'DISTRICT':'',
        'LONGITUDE':'',
        'LATITUDE':'',
        'POPULATION':'',
        'COUVERTURE 2G ORANGE':'',
        'PRESENCE 2G ORANGE':'',
        'POPULATION COUVERTE 2G ORANGE':'',
        'COUVERTURE 2G MTN' : '',
        'PRESENCE 2G MTN' : '',
        'POPULATION COUVERTE 2G MTN' : '',
        'COUVERTURE 2G MOOV Africa CI' : '',
        'PRESENCE 2G MOOV Africa CI':'',
        'POPULATION COUVERTE 2G MOOV Africa CI' : '',
        'COUVERTURE 3G ORANGE' : '',
        'PRESENCE 3G ORANGE' : '',
        'POPULATION COUVERTE 3G ORANGE':'',
        'COUVERTURE 3G MTN' :'',
        'PRESENCE 3G MTN' : '',
        'POPULATION COUVERTE 3G MTN':'',
        'COUVERTURE 3G MOOV Africa CI':'',
        'PRESENCE 3G MOOV Africa CI' : '',
        'POPULATION COUVERTE 3G MOOV Africa CI':'',
        'COUVERTURE 4G ORANGE':'',
        'PRESENCE 4G ORANGE':'',
        'POPULATION COUVERTE 4G ORANGE':'',
        'COUVERTURE 4G MTN':'',
        'PRESENCE 4G MTN':'',
        'POPULATION COUVERTE 4G MTN':'',
        'COUVERTURE 4G MOOV Africa CI':'',
        'PRESENCE 4G MOOV Africa CI':'',
        'POPULATION COUVERTE 4G MOOV Africa CI':'',
        'COUVERTURE SERVICE DATA ORANGE':'',
        'COUVERTURE EN SERVICE DE TELEPHONIE ORANGE':'',
        'POPULATION COUVERTE EN SERVICE DATA ORANGE':'',
        'POPULATION COUVERTE EN SERVICE DE TELEPHONIE ORANGE':'',
        'COUVERTURE EN SERVICE DATA MTN':'',
        'COUVERTURE EN SERVICE DE TELEPHONIE MTN':'',
        'POPULATION COUVERTE EN SERVICE DATA MTN':'',
        'POPULATION COUVERTE EN SERVICE DE TELEPHONIE MTN':'',
        'COUVERTURE EN SERVICE DATA MOOV Africa CI':'',
        'COUVERTURE EN SERVICE DE TELEPHONIE MOOV Africa CI':'',
        'POPULATION COUVERTE EN SERVICE DATA MOOV Africa CI':'',
        'POPULATION COUVERTE EN SERVICE DE TELEPHONIE MOOV Africa CI':'',
        'SYNTHESE COUVERTURE EN SERVICE DATA':'',
        'SYNTHESE COUVERTURE EN SERVICE DE TELEPHONIE':'',
        'SYNTHESE POPULATION COUVERTE EN SERVICE DATA':'',
        'SYNTHESE POPULATION COUVERTE EN SERVICE DE TELEPHONIE':'',
    }];

    import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(excelOperatorTemplate);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        saveAsExcelFile(excelBuffer, "artci-operator-excel-update-template");
    });
    
}

export const operatorExcelTemplate = () => {
    const excelOperatorTemplate = [{
        'CODE LOCALITE':'',
        'LOCALITE':'',
        'SOUS-PREFECTURE':'',
        'DEPARTEMENT':'',
        'REGION':'',
        'DISTRICT':'',
        'POPULATION':'',
        'COUVERTURE 2G':'',
        'PRESENCE 2G':'',
        'POPULATION COUVERTE 2G':'',
        'COUVERTURE 3G':'',
        'PRESENCE 3G':'',
        'POPULATION COUVERTE 3G':'',
        'COUVERTURE 4G':'',
        'PRESENCE 4G':'',
        'POPULATION COUVERTE 4G':'',
        'COUVERTURE SERVICE DATA':'',
        'POPULATION COUVERTE SERVICE DATA':'',
        'COUVERTURE SERVICE TELEPHHONIE':'',
        'POPULATION COUVERTE SERVICE TELEPHONIE':'',
    }];

    import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(excelOperatorTemplate);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
            bookType: "xlsx",
            type: "array",
        });

        saveAsExcelFile(excelBuffer, "artci-operator-excel-update-template");
    });
    
}

const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            let EXCEL_EXTENSION = ".xlsx";
            const data = new Blob([buffer], {
                type: EXCEL_TYPE,
            });

            module.default.saveAs(
                data,
                fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
            );
        }
    });
};