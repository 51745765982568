import axios from "axios";

import { apiRequestBase, tokenActuatorJwt, tokenAdminJwt, tokenOperatorJwt, tokenUserJwt } from "../../../constants";
import { localVariables } from "../../local/SecureLocalStorageVariable";

const tokenAdmin = localVariables.getItem(tokenAdminJwt)
const tokenUser = localVariables.getItem(tokenUserJwt)
const tokenActuator = localVariables.getItem(tokenActuatorJwt)
const tokenOperator= localVariables.getItem(tokenOperatorJwt)
const maxRetries = 3;
const initialDelay = 1000; // 1 second


// console.log("Token de l'administrateur", tokenAdmin)

export const adminAxiosInstance = axios.create({
    baseURL: apiRequestBase,
    headers:tokenAdmin ? {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "multipart/form-data",
        'Authorization': `Bearer ${ tokenAdmin}`
    }:{}
});

export const operatorAxiosInstance = axios.create({
    baseURL: apiRequestBase,
    headers:tokenOperator ? {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "multipart/form-data",
        'Authorization': `Bearer ${ tokenAdmin}`
    }:{}
})

export const userAxiosInstance = axios.create({
    baseURL: apiRequestBase,
    headers:tokenUser ? {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "multipart/form-data",
        'Authorization': `Bearer ${ tokenUser}`
    }:{}
})

userAxiosInstance.interceptors.response.use(
    response => response,
    error => {
      let retryDelay = initialDelay;
  
      if (error.response.status === 429) {
        // Implement exponential backoff
        return new Promise(resolve => {
          const retry = () => {
            setTimeout(() => {
              resolve(userAxiosInstance(error.config));
            }, retryDelay);
  
            retryDelay *= 2;
          };
  
          if (error.config && error.config.headers && error.config.headers['X-Retry-Count']) {
            const retryCount = error.config.headers['X-Retry-Count'];
            if (retryCount < maxRetries) {
              retry();
            } else {
              return Promise.reject(error);
            }
          } else {
            error.config.headers = { ...error.config.headers, 'X-Retry-Count': 1 };
            retry();
          }
        });
      }
      return Promise.reject(error);
    }
);

export const actuatorAxiosInstance = axios.create({
    baseURL: apiRequestBase,
    headers:tokenActuator ? {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${ tokenActuator}`
    }:{}
})

export const axiosInstance = axios.create({
    baseURL: apiRequestBase,
    headers:{
        "Content-Type": "multipart/form-data",
    }
})