import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";

export const AdminProblemDialog = ({ display, setDisplay, problem, setProblem, title, handleSumitProblem, onInputChange }) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    // handleInitVar()
  };

  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);

  return (
    <>
      <Dialog
        header={title}
        visible={display}
        modal
        style={{ width: "60vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
        <form onSubmit={handleSumitProblem}>
          <div className="row bg-light">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8 p-0 pr-1">
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail"> Type de problème <span className="text-danger">*</span></label>
                  <select name="" id=""  className="form-control" value={problem?.type} onChange={(e) => onInputChange(e, "type")} required>
                    <option value="">Selectionner une type de problème</option>
                    <option>Problème plateforme</option>
                    <option>Problème réseau</option>
                  </select>
                </div>
              </div>
                </div>
                <div className="col-md-4 p-0">
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Niveau</label>
                  <select name="" id="" className="form-control" value={problem?.level} onChange={(e) => onInputChange(e, "level")}>
                    <option value="">Selectionner un niveau d'alerte</option>
                    <option >Bas</option>
                    <option >Normal</option>
                    <option >Elévé</option>
                    <option >Appel</option>
                    <option >SMS</option>
                    <option >Internet</option>
                  </select>
                </div>
              </div>
                  
                </div>
              </div>
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Titre du problème <span className="text-danger">*</span></label>
                  <input type="text" className="form-control bwd" placeholder="Entrer le titre du problème" value={problem?.title} onChange={(e) => onInputChange(e, "title")} required />
                </div>
              </div>
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Description</label>
                  <textarea name="" id="" cols="30" rows="5" className="form-control" value={problem?.description} placeholder="Entrer une description du problème" onChange={(e) => onInputChange(e, "description")}></textarea>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer pt-3 d-flex justify-content-between">
              <button type="button" className="btn btn-dark my-0" onClick={() => onHide()}>Fermer</button>
              <button type="submit" className="btn btn-primary my-0" ><i className="fa fa-save mr-2"></i> Enregistrer le problème</button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};
