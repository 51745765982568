import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { strUppercCase } from "../../../../services/conversion/stringConversion";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";
import { EditorComponent } from "../../../../components/editor/EditorComponent";
import { strUppercCaase } from "../../../../services/tools/convertions";
import { baseFileUrl, baseImagesUrl } from "../../../../constants";

export const AdminNewsLetterDialog = ({
  display,
  setDisplay,
  newsLetter,
  newsLetters,
  setNewsLetter,
  title,
  handleSumitNewsLetter,
  onInputChange,
  categories,
}) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);

  const [imageFile, setImageFile] = useState();
  const [previewImageFile, setPreviewImageFile] = useState()
  const [imgUrl, setImgUrl] = useState();

  const [pdfFile, setPdfFile] = useState();
  const [previewPdfFile, setPreviewPdfFile] = useState()

  const [description, setDescription] = useState();

  const [preview, setPreview] = useState(false);


  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);

  useEffect(() => {

    let _newsLetter = { ...newsLetter };

    _newsLetter["description"] = description;

    setNewsLetter(_newsLetter);

  }, [description]);

  const onClick = (position) => {
    console.log("News =========>",newsLetter)
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    setPreviewImageFile()
    setPreviewImageFile()
    setPreviewPdfFile()
    setPdfFile()
    setPreview(false)
    setDescription("")
    // handleInitVar()
  };


  // PREVIEW IMAGE
  useEffect(() => {
    console.log("Image de previsualisation", imageFile)
    if (!imageFile) {
      setPreviewImageFile(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(imageFile)
    setPreviewImageFile(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [imageFile])

  const onSelectImageFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setImageFile(undefined)
      return
    }
    // I've kept this example simple by using the first imageFile instead of multiple

    let _newsLetter = { ...newsLetter };
    _newsLetter["imageFile"] = e.target.files[0];

    setImgUrl(e.target.files[0].filename)

    setNewsLetter(_newsLetter);

    setImageFile(e.target.files[0])
  }


  // PREVIEW FILE
  useEffect(() => {
    console.log("PDF de previsualisation", pdfFile)
    if (!pdfFile) {
      setPreviewPdfFile(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(pdfFile)
    setPreviewPdfFile(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [pdfFile])

  const onSelectPdfFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setPdfFile(undefined)
      return
    }
    // I've kept this example simple by using the first pdfFile instead of multiple

    let _newsLetter = { ...newsLetter };
    _newsLetter["pdfFile"] = e.target.files[0];

    setNewsLetter(_newsLetter);

    setPdfFile(e.target.files[0])
  }



  return (
    <>
      <Dialog
        header={title}
        visible={display}
        modal
        style={{ width: "80vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
        <form onSubmit={handleSumitNewsLetter}>
          {preview ? <>
          <div className="">
            <div>
              <a className="btn btn-block btn-info text-white mb-2" onClick={() => setPreview(false)} ><i className="fa fa-edit mr-2"></i>Retour à la mdification de la nouvelle</a>
            </div>
            <div>
              <div className="row p-1 border">
                <div className="col-md-8">
                  {(previewImageFile || newsLetter?.image_link) && <>
                    <div>
                      <img src={previewImageFile} height={400} style={{ width: "100%" }} alt="" />
                    </div>
                  </>}
                  {newsLetter?.title && <>
                    <div className="pt-2">
                      <h2>{strUppercCaase(newsLetter?.title ? newsLetter?.title : "")}</h2>
                    </div>
                  </>}
                </div>
                <div className="col-md-4">
                  {newsLetter?.category_id && <>
                    <h5>CATEGORIE: {strUppercCaase(categories?.filter((item) => item.id == newsLetter?.category_id)[0].name) } </h5>
                  </>}
                  {newsLetter?.video_link && <>
                  <div>Vidéo associé : <a href="#"></a></div>
                    <div className="position-relative">
                      <video controls preload="metadata" className="w-100" >
                        <source src={newsLetter?.video_link} type="video/*" />
                        Video non supportée.
                      </video>
                    </div>
                  </>}
                  <p>Date de publication: {new Date().toLocaleDateString()} </p>
                  {(previewPdfFile || newsLetter?.file_link )&& <>
                  <a href={previewPdfFile ? previewImageFile : baseFileUrl+newsLetter?.file_link} target="_blank" className="btn btn-sm btn-block btn-warning p-1">Lire le fichier joint</a>
                  </>}
                </div>
                <div className="col-md-12">
                  <div>
                    <div className='mt-3'>
                      <div dangerouslySetInnerHTML={{ __html: newsLetter?.description }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </> : <>
            <div>
              <a className="btn btn-block btn-dark text-white mb-2" onClick={() => setPreview(true)} ><i className="fa fa-eye mr-2"></i>Aperçu de la nouvelle</a>
            </div>
            <div className="row">
              <div className="col-md-8">
                <div className="form-group">
                  <div tabIndex="-1" role="group">
                    <label htmlFor="title">Catégorie<span className="text-danger">*</span></label>
                    <select name="" id="" className="form-control" value={newsLetter?.category_id} onChange={(e) => onInputChange(e, "category_id")} required>
                      <option value="">Selectionner une catégorie de nouvelle</option>
                      {categories && categories.map((category, index) => {
                        return (
                          <option value={category?.id} key={"cat-" + index}>{category?.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <div tabIndex="-1" role="group">
                    <label htmlFor="title">Titre<span className="text-danger">*</span> </label>
                    <input type="text" id="title" value={newsLetter?.title} className="form-control" placeholder="Entrer le titre de la nouvelle" onChange={(e) => onInputChange(e, "title")} required/>
                  </div>
                </div>
                <div className='form-group'>
                  <label className="form-label">Contenu</label>
                  <EditorComponent value={description ? description : newsLetter?.description} setValue={setDescription} placeholder="Entrer le contenue de la nouvelle..." />
                </div>
              </div>
              <div className="col-md-4 bg-light">
                <div className="form-group">
                  { (previewImageFile  || newsLetter?.image_link) && <>
                    <p className="">Aperçu de l'image de couverture</p>
                    <div className="position-relative">
                      <img src={previewImageFile ? previewImageFile : baseFileUrl+newsLetter?.image_link} className="rounded-4 newsLetter-previez-img w-100" alt="" height="250" />
                    </div>
                  </>}
                  <div tabIndex="-1" role="group">
                    <label htmlFor="image">Images de couverture</label>
                    <div class="input-group mb-3">
                      <input type="file" className="form-control" name="image" accept="image/*" onChange={onSelectImageFile} />
                      {(previewImageFile || newsLetter?.file_link) && <>
                        <div class="input-group-append" onClick={() => { setImageFile(); setImgUrl() }} >
                          <span class="input-group-text" id="basic-addon2"><i className="fa fa-xmark"></i></span>
                        </div>
                      </>}
                    </div>
                  </div>
                </div>
                <hr />
                <div className="form-group">
                  {(previewPdfFile || newsLetter?.file_link) && <>
                    <p className="">Aperçu du fichier joint</p>
                    <div className="position-relative">
                      <iframe src={previewPdfFile ? previewPdfFile : baseFileUrl+newsLetter?.file_link} frameborder="0" height="250" style={{ width: "100%" }}></iframe>
                    </div>
                  </>}
                  <div tabIndex="-1" role="group">
                    <label htmlFor="file">Joindre un fichier <span className="text-warning" >(PDF)</span> </label>
                    <input type="file" className="form-control" name="file" accept=".pdf" onChange={onSelectPdfFile} />
                  </div>
                </div>
                <hr />
                <div className="form-group">
                  {newsLetter?.video_link && <>
                    <p className="">Aperçu de la vidéo</p>
                    <div className="position-relative">
                    <video controls src={newsLetter?.video_link} size="360" className='w-100'>
                    </video>
                      
                    </div>
                  </>}
                  <div tabIndex="-1" role="group">
                    <label htmlFor="exampleEmail">Joindre un lien de vidéo</label>
                    <input type="text" placeholder="Entrer un lien vers une vidéo en ligne" value={newsLetter?.video_link} className="form-control" onChange={(e) => onInputChange(e, "video_link")} />
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </>}
          <div className="modal-footer pt-3 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-dark my-0"
              onClick={() => onHide()}
            >
              Fermer
            </button>
            <button type="submit" className="btn btn-primary my-0">
              <i className="fa fa-save mr-2"></i> Enregistrer la nouvelle
            </button>
          </div>
        </form>
      </Dialog>
    </>
  );
};
