import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { getCoveragesByGeoData, getDistricsByGeoData } from "../../../../../services/api/admin/bufferCoverages/bufferCoverageApiService";
import { handleNumThousand, strLowerCase, strNLetter } from "../../../../../services/tools/convertions";
import { getOperators } from "../../../../../services/api/admin/operators/operatorApiService";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";
import { baseFileUrl } from "../../../../../constants";
import ReactToPrint from "react-to-print";


export const AdminCoverageVIewDialog = ({ display, setDisplay, coverage }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [localCoverage, setLocalCoverage] = useState();
    const [operators, setOperators] = useState();
    const [technologies, setTechnologies] = useState();

    const [isPrinting, setIsPrinting] = useState(false);


    const contentRef = useRef();
    const tbRef = useRef();


    useEffect(() => {
        getOperators(setOperators)
        getTechnologies(setTechnologies)

    }, []);


    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        // handleGetCoveragesData(coverage?.id)
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        setLocalCoverage()
        // handleInitVar()
    };

    const TemplateImageSuccess = () => {
        return (<>
            <div>
                <img src="../../images/icons/success.png" height={20} alt="" />
            </div>
        </>)
    }
    const TemplateImageNo = () => {
        return (<>
            <div>
                <img src="../../images/icons/cancel.png" height={20} alt="" />
            </div>
        </>)
    }
    const TemplatePoint = () => {
        return (<>
            <div>
                <img src="../../images/icons/point.png" height={20} alt="" />
            </div>
        </>)
    }

    return (<>
        <Dialog
            header="VISUALISATION DE DONNEES SUR UNE LOCALITE"
            visible={display}
            modal
            style={{ width: "90vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
            <div className="mb-2">
                <ReactToPrint
                    trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                    content={() => contentRef.current}
                    // onBeforePrint={()=> {!coverage?.description?.length > 0 && setIsPrinting(true)}}
                    // onAfterPrint={() => setIsPrinting(false)}
                    copyStyles={true}
                    pageStyle={`@media print {body {
                    padding: 20px; /* Adjust the padding as needed */
                  } @page { size: 450mm 250mm;}};`}
                />
                <hr />
            </div>
            <div className="border p-2">
                <div ref={contentRef}>
                    <h4 className="">Localité : <b> {coverage?.name || coverage?.locality_name} </b></h4>
                    <h4>Population totale :<b> {coverage?.pop} </b></h4>
                    <div className="row p-0">
                        <div className="mt-3 w-100 col-md-12">
                            <table className="table table-bordered table-striped table-sm table-hover table-width table-">
                                <thead>
                                    <tr>
                                        <th colSpan={technologies?.length * 3 + 1} className="text-center">SYNTHESE</th>
                                        <th className="text-center">Zone Blace</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={technologies?.length + 1} className="text-center">Couverture</th>
                                        <th colSpan={technologies?.length * 2} className="text-center">Population</th>
                                    </tr>
                                    <tr>
                                        <th colSpan={technologies?.length}></th>
                                        <th className="text-center" >Total</th>
                                        <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                    </tr>
                                    <tr>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <th key={"at1" + index} className="text-center"> {technology?.name} </th>
                                                )
                                            }
                                        })}
                                        <th className="text-center">Data</th>
                                        <th className="text-center">Tel</th>
                                        <th></th>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <th key={"at2" + index} className="text-center">
                                                        {technology?.name}
                                                    </th>
                                                )
                                            }
                                        })}
                                        <th className="text-center">Data</th>
                                        <th className="text-center">Tel</th>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <th key={"at2" + index} className="text-center"> {technology?.name} </th>
                                                )
                                            }
                                        })}
                                        <th className="text-center">Data</th>
                                        <th className="text-center">Tel</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <td className="text-center" key={"att2" + index}>
                                                        {coverage?.["forecast_cov_" + technology?.name] > 0 ? <TemplateImageSuccess /> : <TemplateImageNo />}
                                                    </td>
                                                )
                                            }
                                        })}
                                        <td className="text-center">{coverage?.forecast_cov_data ? <TemplateImageSuccess /> : coverage?.forecast_cov_data != null && <TemplateImageNo />}  </td>
                                        <td className="text-center">{coverage?.forecast_cov_phone ? <TemplateImageSuccess /> : coverage?.forecast_cov_phone != null && <TemplateImageNo />}  </td>
                                        <td className="text-center"> {coverage?.pop} </td>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <td key={"att1" + index} className="text-center">
                                                        {handleNumThousand(coverage?.["forecast_pop_cov_" + technology?.name])}  <br />
                                                        <small> -> {(coverage?.["forecast_pop_cov_" + technology?.name] > 0 ? (coverage?.["forecast_pop_cov_" + technology?.name] * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                    </td>
                                                )
                                            } else {
                                                if (technology?.code == "00100") {
                                                    return (
                                                        <td key={"att1" + index} className="text-center">
                                                            {handleNumThousand(coverage?.["forecast_pop_cov_" + strLowerCase(technology?.name)])}  <br />
                                                            <small> -> {(coverage?.["forecast_pop_cov_" + strLowerCase(technology?.name)] > 0 ? (coverage?.["forecast_pop_cov_" + strLowerCase(technology?.name)] * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                        </td>
                                                    )
                                                } else if (technology?.code == "00101") {
                                                    return (
                                                        <td key={"att1" + index} className="text-center">
                                                            {handleNumThousand(coverage?.["forecast_pop_cov_phone"])}  <br />
                                                            <small> -> {(coverage?.["forecast_pop_cov_phone"] > 0 ? (coverage?.["forecast_pop_cov_phone"] * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                        </td>
                                                    )
                                                }
                                            }
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <td className="text-center" key={"att2" + index}>
                                                        {handleNumThousand(coverage?.pop - coverage?.["forecast_pop_cov_" + technology?.name])}<br />
                                                        <small> -> {((coverage?.pop - coverage?.["forecast_pop_cov_" + technology?.name]) > 0 ? ((coverage?.pop - coverage?.["forecast_pop_cov_" + technology?.name]) * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                    </td>
                                                )
                                            } else {
                                                if (technology?.code == "00100") {
                                                    return (
                                                        <td className="text-center" key={"att2" + index}>
                                                            {handleNumThousand(coverage?.pop - coverage?.["forecast_pop_cov_" + strLowerCase(technology?.name)])}<br />
                                                            <small> -> {((coverage?.pop - coverage?.["forecast_pop_cov_" + strLowerCase(technology?.name)]) > 0 ? ((coverage?.pop - coverage?.["forecast_pop_cov_" + strLowerCase(technology?.name)]) * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                        </td>
                                                    )
                                                } else if (technology?.code == "00101") {
                                                    return (
                                                        <td className="text-center" key={"att2" + index}>
                                                            {handleNumThousand(coverage?.pop - coverage?.["forecast_pop_cov_phone"])}<br />
                                                            <small> -> {((coverage?.pop - coverage?.["forecast_pop_cov_phone"]) > 0 ? ((coverage?.pop - coverage?.["forecast_pop_cov_phone"]) * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                        </td>
                                                    )
                                                }
                                            }
                                        })}
                                        <td className="text-center">  </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-3 w-100 col-md-12">
                            <table className="table table-bordered table-striped table-sm table-hover table-width table-warning">
                                <thead>
                                    <tr>
                                        <th>Opérateur</th>
                                        <th colSpan={technologies?.length * 2 + 1} className="text-center">Population</th>
                                        <th colSpan={technologies?.length - 2} className="text-center">Présence</th>
                                        <th colSpan={technologies?.length - 2} className="text-center">Prévision</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>Totale</th>
                                        <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                        <th colSpan={technologies?.length}></th>
                                        <th colSpan={technologies?.length}></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            return (
                                                <th key={"t1" + index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            return (
                                                <th key={"t2" + index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <th key={"t3" + index} className="text-center"> {technology?.name} </th>
                                                )
                                            }
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            if (technology?.code != "00100" && technology?.code != "00101") {
                                                return (
                                                    <th key={"t4" + index} className="text-center"> {technology?.name} </th>
                                                )
                                            }
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {operators?.length > 0 && operators?.map((operator, index) => {
                                        return (
                                            <tr key={"ot" + index}>
                                                <td>
                                                    <img src={baseFileUrl + operator.image_path} className="mr-2" height={30} alt="" />
                                                    <b>{operator?.name}</b>
                                                </td>
                                                <td>
                                                    {handleNumThousand(coverage?.["forecast_pop_cov_" + strLowerCase(operator?.name)])}
                                                </td>
                                                {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                    if (technology?.code != "00100" && technology?.code != "00101") {
                                                        return (
                                                            <td key={"tt3" + index} className="text-center">
                                                                {handleNumThousand(coverage?.["pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name)])}  <br />
                                                                <small> -> {(coverage?.["pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name)] > 0 ? (coverage?.["pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name)] * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                            </td>
                                                        )
                                                    } else if (technology?.code == "00100") {
                                                        return (
                                                            <td key={"tt3" + index} className="text-center">
                                                                {coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)] && <>
                                                                    {handleNumThousand(coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)])}  <br />
                                                                    <small> -> {(coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)] > 0 ? (coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)] * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                                </>}
                                                            </td>
                                                        )
                                                    } else if (technology?.code == "00101") {
                                                        return (
                                                            <td key={"tt3" + index} className="text-center">
                                                                {coverage?.["pop_cov_phone" + "_" + strLowerCase(operator?.name)] && <>
                                                                    {handleNumThousand(coverage?.["pop_cov_phone" + "_" + strLowerCase(operator?.name)])}  <br />
                                                                    <small> -> {(coverage?.["pop_cov_phone" + "_" + strLowerCase(operator?.name)] > 0 ? (coverage?.["pop_cov_phone" + "_" + strLowerCase(operator?.name)] * 100 / coverage?.pop).toFixed(1) : 0)} %</small>
                                                                </>}
                                                            </td>
                                                        )
                                                    }
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                    if (technology?.code != "00100" && technology?.code != "00101") {
                                                        return (
                                                            <td key={"tt4" + index} className="text-center">
                                                                {handleNumThousand(coverage?.pop - coverage?.["pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name)])} <br />
                                                                <small> -> {(coverage?.pop > 0 ? (((coverage?.pop - coverage?.["pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name)]) * 100) / coverage?.pop).toFixed(1) : 0)} %</small>
                                                            </td>
                                                        )
                                                    } else if (technology?.code == "00100") {
                                                        return (
                                                            <td key={"tt4" + index} className="text-center">
                                                                {coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)] && <>
                                                                    {handleNumThousand(coverage?.pop - coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)])} <br />
                                                                    <small> -> {(coverage?.pop > 0 ? (((coverage?.pop - coverage?.["pop_cov_" + strLowerCase(technology?.name) + "_" + strLowerCase(operator?.name)]) * 100) / coverage?.pop).toFixed(1) : 0)} %</small>
                                                                </>}
                                                            </td>
                                                        )
                                                    } else if (technology?.code == "00101") {
                                                        return (
                                                            <td key={"tt4" + index} className="text-center">
                                                                {coverage?.["pop_cov_phone_" + "_" + strLowerCase(operator?.name)] && <>
                                                                    {handleNumThousand(coverage?.pop - coverage?.["pop_cov_phone_" + "_" + strLowerCase(operator?.name)])} <br />
                                                                    <small> -> {(coverage?.pop > 0 ? (((coverage?.pop - coverage?.["pop_cov_phone_" + "_" + strLowerCase(operator?.name)]) * 100) / coverage?.pop).toFixed(1) : 0)} %</small>
                                                                </>}
                                                            </td>
                                                        )
                                                    }
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                    if (technology?.code != "00100" && technology?.code != "00101") {
                                                        return (
                                                            <td className="text-center" key={"tt5" + index}>
                                                                {coverage?.["presence_2G_" + technology?.name + "_" + strLowerCase(operator?.name)] != null && <>
                                                                    {coverage?.["presence_2G_" + technology?.name + "_" + strLowerCase(operator?.name)] ? <TemplateImageSuccess /> : <TemplateImageNo />}
                                                                </>}
                                                            </td>
                                                        )
                                                    }
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology, index) => {
                                                    if (technology?.code != "00100" && technology?.code != "00101") {
                                                        return (
                                                            <td className="text-center" key={"tt6" + index}>
                                                                {coverage?.["prevision_" + technology?.name + "_" + strLowerCase(operator?.name)] != null && <>
                                                                {coverage?.["prevision_" + technology?.name + "_" + strLowerCase(operator?.name)] > 0 ? <TemplateImageSuccess /> : <TemplateImageNo />}
                                                                </>}
                                                            </td>
                                                        )

                                                    }
                                                })}
                                            </tr>
                                        )
                                    })}
                                    <tr>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    </>)
}