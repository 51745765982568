import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { getCategories } from "../../../../services/api/admin/newsLetter/categories/categoryApiService";
import { baseFileUrl } from "../../../../constants";
import { strUppercCaase } from "../../../../services/tools/convertions";


export const AdminNewsLetterViewDialog = ({ display, setDisplay, newsLetter }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [categories, setCategories] = useState();
    const [isPrinting, setIsPrinting] = useState(false);

    const [imageFile, setImageFile] = useState();
    const [previewImageFile, setPreviewImageFile] = useState()


    const contentRef = useRef();
    const tbRef = useRef();


    useEffect(() => {
        getCategories(setCategories)
    }, []);


    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        console.log("Detzails newsLetter =======>", newsLetter);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    


    return (<>
        <Dialog
            header="DETAIL ARTICLE"
            visible={display}
            modal
            style={{ width: "80vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
        <div className="mb-2">
            <ReactToPrint
                trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                content={() => contentRef.current}
                onBeforePrint={()=> {!newsLetter?.description?.length > 0 && setIsPrinting(true)}}
                onAfterPrint={() => setIsPrinting(false)}
                pageStyle={`@media print { @page { size: 250mm 450mm;}}`}
            />
            <hr />
        </div>
            <div className="row p-1 border" ref={contentRef}>
                <div className="col-md-8">
                  {(newsLetter?.image_link) && <>
                    <div>
                      <img src={baseFileUrl+newsLetter?.image_link} height={400} style={{ width: "100%" }} alt="" />
                    </div>
                  </>}
                  {newsLetter?.title && <>
                    <div className="pt-2">
                      <h2>{strUppercCaase(newsLetter?.title ? newsLetter?.title : "")}</h2>
                    </div>
                  </>}
                </div>
                <div className="col-md-4">
                  {newsLetter?.category_id && <>
                    <h5>CATEGORIE: {strUppercCaase(categories?.filter((item) => item.id == newsLetter?.category_id)[0].name) } </h5>
                  </>}
                  {newsLetter?.video_link && <>
                  <div>Vidéo associé : <a href="#"></a></div>
                    <div className="position-relative">
                      <video controls preload="metadata" className="w-100" >
                        <source src={newsLetter?.video_link} type="video/*" />
                        Video non supportée.
                      </video>
                    </div>
                  </>}
                  <p>Date de publication: {new Date().toLocaleDateString()} </p>
                  {(newsLetter?.file_link )&& <>
                  <a href={baseFileUrl+newsLetter?.file_link} target="_blank" className="btn btn-sm btn-block btn-warning p-1">Lire le fichier joint</a>
                  </>}
                </div>
                <div className="col-md-12">
                  <div>
                    <div className='mt-3'>
                      <div dangerouslySetInnerHTML={{ __html: newsLetter?.description }}></div>
                    </div>
                  </div>
                </div>
              
            </div>
        </Dialog>
    </>)
}