import { AdminMainPageContainer } from "../../AdminMainPageContainer";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AdminFiberOpticList } from "./AdminFiberOpticList";
import {DeleteFiberOpticDialog, DeleteSFiberOpticDialog 
} from "./AdminFiberOpticDeleteDialog";
import {
  addFiberOptic,
  deleteFiberOptic,
  editFiberOptic,
  getFiberOptics,
} from "../../../../services/api/admin/fiberOptics/fiberOpticApiService";
import { AdminFiberOpticDialog } from "./AdminFiberOpticDialog";
import { getLocalities } from "../../../../services/api/admin/adminEntities/locality/localityApiService";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";
import { toastError, toastSuccess } from "../../../../components/notification/notification";

export const AdminFiberOpticPage = () => {
  const [openFiberOpticDialog, setOpenFiberOpticDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [fiberOptics, setFiberOptics] = useState();
  const [fiberOptic, setFiberOptic] = useState();
  const [localities, setLocalities] = useState();

  const [selectedFiberOptics, setSelectedFiberOptics] = useState(null);

  const [fiberOpticDialogTitle, setFiberOpticsDialogTitle] = useState(
    "CREATION D'UN TRANCON DE FIBRE"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const [FiberOpticDialogTitle, setFiberOpticDialogTitle] = useState(
    "CREATION D'UN TRANCON DE FIBRE"
  );

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getLocalities(setLocalities);
    getFiberOptics(setFiberOptics);
  }, []);

  useEffect(() => {
    console.log("Données des trançons ======>", fiberOptics)
  }, [fiberOptics]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement du trançon effectué avec succès!");
      getFiberOptics(setFiberOptics);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du trançon !");
    }
    setOpenFiberOpticDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du trançon effectué avec succès!");
      getFiberOptics(setFiberOptics);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du trançon !");
    }
    setOpenFiberOpticDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du trançon effectué avec succès!");
      getFiberOptics(setFiberOptics);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du trançon !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setFiberOptic();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setFiberOpticDialogTitle("CREATION D'UNE CATEGORIE");
    setSelectedFiberOptics();
    setOpenFiberOpticDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _fiberOptic = { ...fiberOptic };

    _fiberOptic[`${name}`] = val;

    setFiberOptic(_fiberOptic);
  };

  const handleSubmitFiberOptic = async (e) => {
    e.preventDefault();
    if (fiberOptic.id) {
      await editFiberOptic(fiberOptic, setEditResponse);
    } else {
      await addFiberOptic(fiberOptic, setCreateResponse);
    }
  };

  //EDITION
  const handleEditFiberOptic = (fiber) => {
    setFiberOpticDialogTitle("MODIFICATION D'UN TRANCON");
    setFiberOptic({ ...fiber });
    setOpenFiberOpticDialog(true);
  };

  //SUPPRESSION
  const handleDeleteFiberOpticDialog = (fiber) => {
    setFiberOptic({ ...fiber });
    setOpenDeleteDialog(true);
  };

  const handleDeleteFiberOptic = async () => {
    await deleteFiberOptic(fiberOptic, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteFiberOptics = async () => {
    try {
      selectedFiberOptics.forEach(async (fiberOptic) => {
        await deleteFiberOptic(fiberOptic, setDeleteResponse);
      });
    } catch (error) {
      toastError(
        "Erreur lors de la suppression des fonctionnalités selectionnés !"
      );
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "name", header: "nom" },
    { field: "code", header: "code" },
    { field: "description", header: "description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, fiberOptics);
        doc.save("artci-cartodonnees-extract-fiber-optical.pdf");
      });
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    { field: "code", header: "Code" },
    { field: "name", header: "Name" },
    { field: "fiberOptic", header: "FiberOptic" },
    { field: "quantity", header: "Quantity" },
  ];

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-draw-polygon"
                title="Gestion des tronçons de fibre optique"
                subTitle="Tableau de bord"
                description="Creer, modifier, alerter ou supprimer des trançons de la fibre optique "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des tronçons de la fibre optique
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-focus btn-sm"
                      onClick={() => {
                        setOpenFiberOpticDialog(true);
                        setFiberOptic();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un trançon
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {fiberOptics ? <>
                      <AdminFiberOpticList
                        fiberOptics={fiberOptics}
                        handleEditFiberOptic={handleEditFiberOptic}
                        handleDeleteFiberOpticDialog={
                          handleDeleteFiberOpticDialog
                        }
                        setopenDeleteSelected={setopenDeleteSelected}
                        exportCSV={exportCSV}
                        exportPDF={exportPdf}
                        dt={dt}
                        selectedFiberOptics={selectedFiberOptics}
                        setSelectedFiberOptics={setSelectedFiberOptics}
                      />
                      </> : <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminFiberOpticDialog
              display={openFiberOpticDialog}
              setDisplay={setOpenFiberOpticDialog}
              handleSumitFiberOptic={handleSubmitFiberOptic}
              fiberOptic={fiberOptic}
              title={fiberOpticDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setFiberOptic={setFiberOptic}
              fiberOptics={fiberOptics}
              localities={localities}
            />
            <DeleteFiberOpticDialog
              display={openDeleteSelected}
              setDisplay={ setopenDeleteSelected}
              handleDeletefiberOptics={handleDeleteFiberOptics}
              fiberOptic={fiberOptic}
            />
            <DeleteSFiberOpticDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeleteFiberOptic={handleDeleteFiberOptic}
            />
          </>
        }
      />
    </>
  );
};
