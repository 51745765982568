import { lazy, useEffect, useRef, useState } from "react";

import "./mainPageContainer.css";
import { handleDrawLocalitiesChart } from "../../services/statistics/statisticsServices";
import { getGeneraleStatistics } from "../../services/api/map/statisticApiService";
import { colors, generaleStats, layers, sources } from "../../constants";
import { addCircleLayer, addCircleLayerWithFilter, addCircleLayerWithFilterIcon, addLayerLabel, removeLayer } from "../../controller/dataController/dataController";
// import { MenuMap } from "../../components/menuMap/MenuMap";
// import { ExportCustomComponent } from "../../components/modals/export/ExportCustomComponent";
import { dataToExport, departmentLockId, districtLockId, hoveredDepartmentId, hoveredDistrictId, hoveredRegionId, hoveredSubPrefId, regionLockId, showMapData, statLocCanvas, statPopCanvas, subPrefLockId, year } from "../../services/constants/map/mapDataConstants";
import { getUserOperators } from "../../services/api/user/operators/operatorApiService";
import { getUserTechnologies } from "../../services/api/user/technologies/technologyApiService";
import { handleLoadDataOnMap } from "../../controller/mapController/MapController";
// import { ReportModalComponent } from "../../components/modals/report/ReportModalComponent";
// import { NewsLetter } from "../../components/modals/newsLetter/NewsLetter";
import { localVariables } from "../../services/local/SecureLocalStorageVariable";
// import { ViewNewsletter } from "../../components/modals/newsLetter/ViewNewsletter";
import html2canvas from 'html2canvas';
import { Chart } from "primereact/chart";
// import { StatisticsTablesModalComponent } from "../../components/modals/statistic/StatisticsTablesModalComponent";
// import { MessageModalComponent } from "../../components/modals/message/MessageModalComponent";
import { getActiveHomeMessages } from "../../services/api/admin/newsLetter/homeMessages/homeMessageApiService";
import { useLocation } from 'react-router-dom';

const Header = lazy(()=>import('../../components/layout/header/Header'))
const Sidebar = lazy(()=>import('../../components/layout/sidebar/Sidebar'))
const AboutModalComponent = lazy(()=> import('../../components/modals/about/AboutModalComponent'))
const MapPage = lazy(()=> import('./map/MapPage'))
const LegendModalComponent = lazy(()=> import('../../components/modals/legend/LegendModalComponent'))
const HomeStatisticsModalComponent = lazy(()=> import('../../components/modals/statistic/HomeStatisticsModalComponent'))
const VoiceModalComponnent = lazy(()=> import('../../components/modals/voice/VoiceModalComponnent'))
const MenuMap = lazy(()=> import('../../components/menuMap/MenuMap'))
const Error404ModalComponent = lazy(()=> import('../../components/modals/error/Error404ModalComponent'))
const ExportComponent = lazy(()=> import('../../components/modals/export/ExportComponent'))
const ExportCustomComponent = lazy(()=> import('../../components/modals/export/ExportCustomComponent'))
const ReportModalComponent = lazy(()=> import('../../components/modals/report/ReportModalComponent'))
const NewsLetter = lazy(()=> import('../../components/modals/newsLetter/NewsLetter'))
const ViewNewsletter = lazy(()=> import('../../components/modals/newsLetter/ViewNewsletter'))
const StatisticsTablesModalComponent = lazy(()=> import('../../components/modals/statistic/StatisticsTablesModalComponent'))
const MessageModalComponent = lazy(()=> import('../../components/modals/message/MessageModalComponent'))

// const Header = lazy(()=>import('../../components/layout/header/Header'))
// const Header = lazy(()=>import('../../components/layout/header/Header'))

export default function PageContainer() {
  const location = useLocation();
  // const [ans, setAns] = useState();
  const currentURL = window.location.href
  // console.log("LA DATE ======>", currentURL)
  const parts = currentURL.split('/');
  let _yDate = new Date().getFullYear() -1;

  let _year =parseInt(parts[parts?.length -1]);
  if(!Number.isNaN(_year)){
    if(2021 <= _year && _yDate >= _year){
      _year =parseInt(parts[parts?.length -1]);
    }else{
      _year = new Date().getFullYear() -1
    }
  }else{
    _year = new Date().getFullYear() -1
  }
  localVariables.setItem(year,_year)
  const ans = _year;

  const [init, setInit] = useState(true);
  const [operators, setOperators] = useState(null);
  const [technologies, setTechnologies] = useState(null);

  const [messages, setMessages] = useState(null);

  const [isLoading, setisLoading] = useState(true);

  //MOADAL VARS
  const [showAboutModal, setshowAboutModal] = useState(false);
  const [showLegendModal, setshowLegendModal] = useState(true);
  const [displayReportModal, setDisplayReportModal] = useState(false);
  const [displayNewsletterModal, setDisplayNewsletterModal] = useState(false);
  const [newsletter, setNewsletter] = useState();
  const [openNewsletterViewDialog, setOpenNewsletterViewDialog] = useState(false);
  const [onpenStatisticsTables, setOnpenStatisticsTables] = useState(false);
  const [displayMessageModal, setDisplayMessageModal] = useState(false);

  const [sideState, setSideState] = useState(245);

  const [generalData, setGeneralData] = useState(null);

  //CHARTS
  const [
    chartDataForAllLocalitiesCoverage,
    setchartDataForAllLocalitiesCoverage,
  ] = useState();
  const [
    chartDataOptionsForAllLocalitiesCoverage,
    setChartDataOptionsForAllLocalitiesCoverage,
  ] = useState();

  const [
    chartDataForAllPopulationsCoverage,
    setchartDataForAllPopulationsCoverage,
  ] = useState();
  const [
    chartDataOptionsForAllPopulationsCoverage,
    setChartDataOptionsForAllPopulationsCoverage,
  ] = useState();
  const [chartDataOptionForForecast, setChartDataOptionForForecast] = useState();
  const [chartDataForForecast, setChartDataForForecast] = useState();

  // END CHARTS

  const [showDataOnMap, setShowDataOnMap] = useState();

  //CONTROLLER VARS
  const [showGlobalMobileCoverage, setShowGlobalMobileCoverage] = useState(
    true
  );
  const [showOperatorCoverage, setShowOperatorCoverage] = useState(true);

  const [showLimite, setshowLimite] = useState(true);
  const [showStats, setShowStats] = useState(true);
  const [showVoiceQuery, setShowVoiceQuery] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showCustomExportDialog, setShowCustomExportDialog] = useState(false);


  const [mapOn, setmapOn] = useState();
  const [mapLoad, setMapLoad] = useState(false);

  const [showSideBar, setShowSideBar] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [levelExport, setLevelExport] = useState();


  const [searchMarker, setSearchMarker] = useState();


  //STYLE DE CARTE
  const [styleSelected, setStyleSelected] = useState('origin');

  //GLOBAL COVERAGE

  const [_dataExport, set_dataExport] = useState();

  const [confirmChangeStyle, setConfirmChangeStyle] = useState(false);

  const optionsForAllLocalitiesCoverage = {
    aspectRatio: 1,
    title: {
      display: true,
      text: "Localités couvertes par technologie",
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          fontSize: 6,
          color: "white",
        },
        position: "bottom",
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
          font: {
            size: 10,
          },
          color: "white",
        },
        grid: {
          color: "#9D9B9A",
        }
      },
      y: {
        ticks: {
          display: true,
          font: {
            size: 10,
          },
          color: "white",
        },
        grid: {
          color: "#9D9B9A"
        }
      },
    },
  };

  const optionsForAllPopulationsCoverage = {
    aspectRatio: 1,
    title: {
      display: true,
      text: "Population couverte par technologie",
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          fontSize: 6,
          color: "white",
        },
        position: "bottom",
      },
    },
    scales: {
      x: {
        ticks: {
          display: true,
          font: {
            size: 10,
          },
          color: "white",
        },
        grid: {
          color: "#9D9B9A"
        }
      },
      y: {
        ticks: {
          display: true,
          font: {
            size: 10,
          },
          color: "white",
        },
        grid: {
          color: "#9D9B9A"
        }
      },
    },
  };

  const optionForForecastOfOperator = {
    tooltips: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    plugins: {
      legend: {
        labels: {
          boxWidth: 10,
          fontSize: 6,
          color: "white",
        },
        position: "bottom",
        color: "white"
      },
    }
  }

  //Exporrt chart 

  const statsLoc = useRef(null);
  const statsPop = useRef(null);

  useEffect(() => {
    if(generalData !=null){
      setisLoading(false)
    }
  }, [generalData]);


  useEffect(() => {
    if(!showSideBar){
      setSideState(0);
    }else{
      setSideState(245);
    }
  }, [showSideBar]);

  useEffect(() => {
    if (chartDataForAllLocalitiesCoverage) {
      let _d = statsLoc?.current?.children;
      if(_d){
        if(Array.from(_d)[0]?.children[0]){
          setTimeout(() => {
            exportAsImage(Array.from(_d)[0],1)
          }, 6000);
        }
      }
      
    }
  }, [chartDataForAllLocalitiesCoverage]);

  useEffect(() => {
    if (chartDataForAllPopulationsCoverage) {
      if(Array.from(statsPop?.current?.children)[0]?.children[0]){
        setTimeout(() => {
          exportAsImage(Array.from(statsPop.current.children)[0],2)
        }, 6000);
      }
    }
  }, [chartDataForAllPopulationsCoverage]);


  const exportAsImage = (element,type) => {
   html2canvas(element, { backgroundColor: ' #272626AF'}).then((canvas) => {
        const croppedCanvas = document.createElement('canvas')
        const croppedCanvasContext = croppedCanvas.getContext('2d',{ preserveDrawingBuffer: true })
        const cropPositionTop = 0
        const cropPositionLeft = 0
        const cropWidth = 100
        const cropHeight = 100
  
        croppedCanvas.width = cropWidth
        croppedCanvas.height = cropHeight
  
        croppedCanvasContext.drawImage(
          canvas,
          cropPositionLeft,
          cropPositionTop,
        )
  
        const base64Image = canvas.toDataURL('image/png', 1.0);
        if(type == 1){
          localVariables.setItem(statLocCanvas, base64Image);
        }
        else if(type == 2){
          localVariables.setItem(statPopCanvas, base64Image);
        }

    });
    // download the image
  };

  useEffect(() => {
    if(init){
      let _showData = { ...showDataOnMap };
  
      _showData['showLocalities'] = true;
      _showData['showLocalities2G'] = true;
      _showData['showLocalities3G'] = true;
      _showData['showLocalities4G'] = true;
  
      _showData['showDistrictLimit'] = true;
      _showData['showRegionLimit'] = true;
      _showData['showDepartmentLimit'] = true;
      _showData['showSubPrefectureLimit'] = true;
  
      _showData['highway'] = true;
      _showData['nationalRoad'] = true;
      _showData['track'] = true;
      _showData['railway'] = true;
      setShowDataOnMap(_showData)
  
      getUserOperators(setOperators);
      getUserTechnologies(setTechnologies)
      getActiveHomeMessages(setMessages)

  
      localVariables.setItem(showMapData, null)
      localVariables.setItem(districtLockId, null)
      localVariables.setItem(hoveredDistrictId, null)
      localVariables.setItem(regionLockId, null)
      localVariables.setItem(hoveredRegionId, null)
      localVariables.setItem(departmentLockId, null)
      localVariables.setItem(hoveredDepartmentId, null)
      localVariables.setItem(subPrefLockId, null)
      localVariables.setItem(hoveredSubPrefId, null)
      localVariables.setItem(dataToExport, null)
      loadGenealData();
      // setchartDataForAllLocalitiesCoverage(dataForAllLocalitiesCoverage);
  
      setChartDataOptionsForAllLocalitiesCoverage(
        optionsForAllLocalitiesCoverage
      );
  
      setChartDataOptionsForAllPopulationsCoverage(
        optionsForAllPopulationsCoverage
      );
      setChartDataOptionForForecast(optionForForecastOfOperator);
      setInit(false)
    }
  }, [init]);

  useEffect(() => {
    if (showDataOnMap) {

      if (mapOn) {
        if (mapLoad) {
          handleLoadDataOnMap(showDataOnMap, mapOn)
          // handleDrawLocalitiesChart(setchartDataForAllLocalitiesCoverage, setchartDataForAllPopulationsCoverage, setChartDataForForecast, showDataOnMap);
          const dExport = localVariables.getItem(dataToExport)
          if (dExport != null) {
            set_dataExport(dExport)
          }
          if (mapOn.getLayer(layers.label.locality)) {
            removeLayer(mapOn, layers.label.locality);
            addLayerLabel(mapOn, layers.label.locality, sources.coverage.allDataId, "name", 8, 20, 12)
          } else {
            addLayerLabel(mapOn, layers.label.locality, sources.coverage.allDataId, "name", 8, 20, 12)
          }
        }
      }
      handleDrawLocalitiesChart(setchartDataForAllLocalitiesCoverage, setchartDataForAllPopulationsCoverage, setChartDataForForecast, showDataOnMap);
      localVariables.setItem(showMapData, showDataOnMap)
      handleStopLoading()

    }
  }, [showDataOnMap]);


  useEffect(() => {
    if (mapOn) {
      handleSetMapStyle()
    }
  }, [styleSelected]);

  const loadGenealData = async () => {
    let data = await getGeneraleStatistics();
    setGeneralData(data);
    localVariables.setItem(generaleStats, data)
  }

  const handleStopLoading = () => {
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }


  const onShowDataInputChange = (e, name) => {
    const val = (e.target && e.target.checked) || false;
    let _showData = { ...showDataOnMap };

    _showData[`${name}`] = val;

    setShowDataOnMap(_showData);
    if (val == true) {
      setisLoading(true)
    }
  };

  const handleShowCheckTechnoOnChangeData = (e, name) => {
    const val = (e.target && e.target.checked) || false;
    let _showData = { ...showDataOnMap };
    _showData[`${name}`] = val;

    if (_showData[`${name}`]) {
      if (technologies?.length > 0) {
        technologies.forEach(technology => {
          _showData[name + technology.name] = true
        })
      }
    }
    setShowDataOnMap(_showData);
    if (val == true) {
      setisLoading(true)
    }
  }


  const handleSetMapStyle = () => {
    if (styleSelected != 'origin') {
      mapOn.setStyle('mapbox://styles/mapbox/' + styleSelected);
    } else {
      mapOn.setStyle('mapbox://styles/yaoparfait/clo31siyn00b701qx2ltq1rsc');
    }
  }

  const handleDrawChartForStatistics = () => {
    const _showData = localVariables.getItem(showMapData)

    handleDrawLocalitiesChart(setchartDataForAllLocalitiesCoverage, setchartDataForAllPopulationsCoverage, setChartDataForForecast, _showData);
    const dExport = localVariables.getItem(dataToExport)
    if (dExport != null) {
      set_dataExport(dExport)
    }

  };

  const handleInitExportData = (level) => {
    if (level != 5) {
      setShowExportDialog(true);
    } else {
      setShowCustomExportDialog(true);
    }
    setLevelExport(level);
  }
  return (
    <>
      <div className="main-app">
        <main className="map-content">
          <div className="header-content">
            <Header
              setDisplayReportModal={setDisplayReportModal}
              setDisplayNewsletterModal={setDisplayNewsletterModal}
              setshowAboutModal={setshowAboutModal}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              showErrorModal={showErrorModal}
              setShowErrorModal={setShowErrorModal}
              handleInitExportData={handleInitExportData}
              setOnpenStatisticsTables={setOnpenStatisticsTables}
            />
          </div>
          <MapPage
            isLoading={isLoading}
            setisLoading={setisLoading}
            handleStopLoading={handleStopLoading}
            handleDrawChartForStatistics={handleDrawChartForStatistics}
            mapOn={mapOn}
            setmapOn={setmapOn}
            showVoiceQuery={showVoiceQuery}
            setShowVoiceQuery={setShowVoiceQuery}
            setMapLoad={setMapLoad}
            searchMarker={searchMarker}
            setSearchMarker={setSearchMarker}
            sideState={sideState}
            showSideBar={showSideBar}
            messages={messages}
            displayMessageModal={displayMessageModal}
            setDisplayMessageModal={setDisplayMessageModal}
            year={ans}
          />
          {showSideBar && (
            <div className="sidebar-content">
              <Sidebar
                handleStopLoading={handleStopLoading}
                isLoading={isLoading}
                setisLoading={setisLoading}
                operators={operators}
                technologies={technologies}
                showDataOnMap={showDataOnMap}
                mapOn={mapOn}
                handleShowCheckTechnoOnChangeData={handleShowCheckTechnoOnChangeData}
                onShowDataInputChange={onShowDataInputChange}
                showGlobalMobileCoverage={showGlobalMobileCoverage}
                setShowGlobalMobileCoverage={setShowGlobalMobileCoverage}
                showOperatorCoverage={showOperatorCoverage}
                setShowOperatorCoverage={setShowOperatorCoverage}
                showLimite={showLimite}
                setshowLimite={setshowLimite}
              />
            </div>
          )}


        {/* About */}
        <AboutModalComponent
          display={showAboutModal}
          setDisplay={setshowAboutModal}
        />

        {/* Legende */}
        <LegendModalComponent
          display={showLegendModal}
          setDisplay={setshowLegendModal}
          styleSelected={styleSelected}
        />

        <HomeStatisticsModalComponent
          display={showStats}
          setDisplay={setShowStats}
          _dataExport={_dataExport}
          chartDataOptionsForAllLocalitiesCoverage={
            chartDataOptionsForAllLocalitiesCoverage
          }
          chartDataForAllLocalitiesCoverage={chartDataForAllLocalitiesCoverage}
          chartDataOptionsForAllPopulationsCoverage={
            chartDataOptionsForAllPopulationsCoverage
          }
          chartDataForAllPopulationsCoverage={
            chartDataForAllPopulationsCoverage
          }
          chartDataOptionForForecast={chartDataOptionForForecast}
          chartDataForForecast={chartDataForForecast}
          statsLoc={statsLoc}
          statsPop={statsPop}
        />
        <VoiceModalComponnent display={showVoiceQuery} setDisplay={setShowVoiceQuery}
          mapOn={mapOn}
          handleDrawChartForStatistics={handleDrawChartForStatistics}
          showDataOnMap={showDataOnMap}
          setShowDataOnMap={setShowDataOnMap}
          searchMarker={searchMarker}
          setSearchMarker={setSearchMarker}
        />

        <Error404ModalComponent display={showErrorModal} setDisplay={setShowErrorModal} />

        <ExportComponent display={showExportDialog} setDisplay={setShowExportDialog} level={levelExport}
        />
        <ExportCustomComponent display={showCustomExportDialog} setDisplay={setShowCustomExportDialog} />

        <ReportModalComponent display={displayReportModal} setDisplay={setDisplayReportModal} operators={operators} />

        <NewsLetter setNewsletter={setNewsletter} display={displayNewsletterModal} setDisplay={setDisplayNewsletterModal} setOpenNewsletterViewDialog={setOpenNewsletterViewDialog} />

        <ViewNewsletter display={openNewsletterViewDialog}
          setDisplay={setOpenNewsletterViewDialog}
          newsLetter={newsletter}
        />
        <StatisticsTablesModalComponent display={onpenStatisticsTables} 
        setDisplay={setOnpenStatisticsTables} 
        technologies={technologies}
        operators={operators} 
        generalData={generalData}
         />
         <MessageModalComponent
         display={displayMessageModal}
         setDisplay={setDisplayMessageModal}
         messages={messages}
          />

          <MenuMap map={mapOn}
            styleSelected={styleSelected}
            setStyleSelected={setStyleSelected}
          />
        </main>


        {/* <AlertChangeStyle confirmChangeStyle={confirmChangeStyle} setConfirmChangeStyle={setConfirmChangeStyle} handleChangeStyle={handleSetMapStyle} /> */}

        <div className="other-btn">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div className="d-flex justify-content-center">
                {!showLegendModal && (
                  <>
                    <button className="btn-oht btn" onClick={() => setshowLegendModal(true)} >
                      <i className="fa fa-book mr-2"></i> Afficher la legende
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex justify-content-end ">
                {!showStats && (
                  <>
                    <button className="btn-oht btn" onClick={() => setShowStats(true)} >
                      <i className="fa-solid fa-chart-simple mr-2"></i> Afficher les statistiques
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
