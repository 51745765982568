import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";
import { strLowerCase } from "../../../../services/tools/convertions";
import { baseFileUrl } from "../../../../constants";

export const OperatorCoverageDialog = ({
    display,
    setDisplay,
    title,
    handleSumitCoverage,
    onInputChange,
    onInputCheckChange,
    localities,
    technologies,
    operator,
    locality,
}) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const onClick = (position) => {
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    useEffect(() => {

        console.log("LOC ============>", locality)
    }, [locality]);


    return (
        <>
            <Dialog
                header={title}
                visible={display}
                modal
                style={{ width: "60vw" }}
                onHide={() => onHide("displayMaximizable")}
                position="center"
                maximizable={max}
            >
                {localities?.length > 0 ? <>
                    <form onSubmit={handleSumitCoverage}>
                        <h4>Localité :<b> {locality?.name || locality?.locality_name} </b></h4>

                        <div className="row p-1 pt-3 border">
                            <div className="col-md-12 row mb-2-">
                                <div className="col-md-4">
                                    <label htmlFor="" className="form-label">
                                        Population total
                                    </label>
                                    <input type="number" value={locality?.pop} className="form-control" disabled />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="" className="form-label">
                                        Longitude
                                    </label>
                                    <input type="number" className="form-control" value={locality?.longitude} disabled />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="" className="form-label">
                                        Latitude
                                    </label>
                                    <input type="number" className="form-control" value={locality?.latitude} disabled />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>
                            <div className={"col-md-12 pb-3 "} key={"op-"}>
                                <div className="text-center p-2">
                                    <img src={baseFileUrl + operator?.image_path} className="mr-2" height={30} alt="" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor={"pop"} className="form-label">
                                        Population {operator?.name}
                                    </label>
                                    <input type="number" id={"pop"} className="form-control" value={locality?.["forecast_pop_cov_" + strLowerCase(operator?.name)]} onChange={(e) => onInputChange(e, "forecast_pop_cov_" + strLowerCase(operator?.name))} />
                                </div>
                                <div className="bg-white p-1">
                                    <table className="table table-bordered table-striped table-sm table-hover table-width mb-1">
                                        <thead>
                                            <tr>
                                                <th>COUVERTURE</th>
                                                <th>POPULATION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {technologies && technologies.map((technology, index0) => {
                                                if (technology?.code != '00100' && technology?.code != '00101') {
                                                    return (<>
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={locality?.["coverage_" + technology?.name + "_" + strLowerCase(operator?.name)]}
                                                                            id={"cov_tech" + index0}
                                                                            onChange={(e) => onInputCheckChange(e, "coverage_" + technology?.name + "_" + strLowerCase(operator?.name))}
                                                                        />
                                                                        <label className="form-check-label"
                                                                            htmlFor={"cov_tech" + index0}>
                                                                            {technology?.name}
                                                                        </label>
                                                                    </div>
                                                                    {!locality?.["coverage_" + technology?.name + "_" + strLowerCase(operator?.name)] && <>
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={locality?.["prevision_" + technology?.name + "_" + strLowerCase(operator?.name)]}
                                                                                id={"cov_pre" + index0}
                                                                                onChange={(e) => onInputCheckChange(e, "prevision_" + technology?.name + "_" + strLowerCase(operator?.name))}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={"cov_pre" + index0}
                                                                            >
                                                                                Prévision{" "} {technology?.name}
                                                                            </label>
                                                                        </div>
                                                                    </>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {locality?.["coverage_" + technology?.name + "_" + strLowerCase(operator?.name)] ? <>
                                                                    <input type="number" className="form-control form-control-sm" value={locality?.["pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name)]} onChange={(e) => onInputChange(e, "pop_cov_" + technology?.name + "_" + strLowerCase(operator?.name))} />
                                                                </> : 0}
                                                            </td>
                                                        </tr></>)
                                                } else {
                                                    if (technology?.code == "00100") {
                                                        return (<>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={locality?.["forecast_cov_data"]}
                                                                                id={"cov_tech" + index0}
                                                                                onChange={(e) => onInputCheckChange(e, "forecast_cov_data")}
                                                                            />
                                                                            <label className="form-check-label"
                                                                                htmlFor={"cov_tech" + index0}>
                                                                                {technology?.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {locality?.["forecast_cov_data"] ? <>
                                                                        <input type="number" className="form-control form-control-sm" value={locality?.["forecast_pop_data"]} onChange={(e) => onInputChange(e, "forecast_pop_data")} />
                                                                    </> : 0}
                                                                </td>
                                                            </tr></>)
                                                    }
                                                    else if(technology?.code == "00101"){
                                                        return (<>
                                                            <tr>
                                                                <td>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="form-check">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={locality?.["forecast_cov_phone"]}
                                                                                id={"cov_tech" + index0}
                                                                                onChange={(e) => onInputCheckChange(e, "forecast_cov_phone")}
                                                                            />
                                                                            <label className="form-check-label"
                                                                                htmlFor={"cov_tech" + index0}>
                                                                                {technology?.name}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {locality?.["forecast_cov_phone"] ? <>
                                                                        <input type="number" className="form-control form-control-sm" value={locality?.["forecast_pop_phone"]} onChange={(e) => onInputChange(e, "forecast_pop_data")} />
                                                                    </> : 0}
                                                                </td>
                                                            </tr></>)
                                                    }
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                        <div>
                            <div className="modal-footer pt-3 d-flex justify-content-between">
                                <button type="button" className="btn btn-dark my-0" onClick={() => onHide()}>Fermer</button>
                                <button type="submit" className="btn btn-primary my-0" ><i className="fa fa-save mr-2"></i> Enregistrer la couverture</button>
                            </div>
                        </div>
                    </form></> : <>
                    <div className="container px-1 px-md-5 px-lg-1 mbody">
                        <DataLoaderTailSpin />
                    </div>
                </>}
            </Dialog>
        </>
    );
};
