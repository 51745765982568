import { adminAxiosInstance } from "../../../config/axiosConfig"



export const getSubPrefectures = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/sub-prefectures`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log(response.data.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addSubPrefecture = async (subPrefecture, setState) => {
    try {
        const {region_id,name,description} = subPrefecture

        const response = await adminAxiosInstance.post(`/sub-prefectures`, {
            region_id:region_id,
            name: name,
            description: description,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Sous-prefecture enregistré :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const editSubPrefecture = async (subPrefecture, setState) => {
    try {
        const {region_id,name,description} = subPrefecture

        await adminAxiosInstance.put('/sub-prefectures/'+subPrefecture.id, {
            region_id: region_id,
            name: name,
            description: description,
        }).then((response) => {
            console.log('Vérification de l\'edition', response.data)
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const deleteSubPrefecture = async (subPrefecture, setState) => {
    try {

        await adminAxiosInstance.delete('/sub-prefectures/'+subPrefecture.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getSubPrefecturesByGeoData = async(setState,id) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/sub_prefecture_limites2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data;
            const _districts = _data.features.filter(feature => feature.id == id)
            const _properties = _districts[0]?.properties;
            setState(_properties)
        }

    } catch (error) {
        
    }
}



export const getSSubprefecturesByGeoData = async(setState) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/sub_prefecture_limites2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data.features;
            setState(_data)
        }
    } catch (error) {
        
    }
}