import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { getOperators } from "../../../../../services/api/admin/operators/operatorApiService";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";
import { getDepartmentsByGeoData } from "../../../../../services/api/admin/adminEntities/department/departmentApiService";
import { handleNumThousand } from "../../../../../services/tools/convertions";
import ReactToPrint from "react-to-print";
import { baseFileUrl } from "../../../../../constants";


export const AdminDepartmentVIewDialog = ({ display, setDisplay, department }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [localDepartment, setLocalDepartment] = useState();
    const [operators, setOperators] = useState();
    const [technologies, setTechnologies] = useState();

    const [isPrinting, setIsPrinting] = useState(false);


    const contentRef = useRef();
    const tableRef = useRef();


    useEffect(() => {
        getOperators(setOperators)
        getTechnologies(setTechnologies)
    }, []);


    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        handleGetDepartmentsData(department?.id)
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        setLocalDepartment()
        // handleInitVar()
    };

    const handleGetDepartmentsData = async (geo_id) => {
        await getDepartmentsByGeoData(setLocalDepartment,geo_id)
    }


    return (<>
        <Dialog
            header="DETAILS DEPARTEMENT"
            visible={display}
            modal
            style={{ width: "80vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
        <div className="mb-2">
            <ReactToPrint
                trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                content={() => contentRef.current}
                onBeforePrint={()=> {!department?.description?.length > 0 && setIsPrinting(true)}}
                onAfterPrint={() => setIsPrinting(false)}
                pageStyle={`@media print {body {
                    padding: 20px; /* Adjust the padding as needed */
                  } @page { size: 450mm 250mm;}};`}
            />
            <hr />
        </div>
            <div className="row" ref={contentRef}>
                <div className="col-md-12 p-0">
                <div>
                        <h3>DEPARTEMENT : <b>{department?.name}</b></h3>
                        <div>
                            <h5>Coordonnées du centre:</h5>
                            <div className="pl-3">
                                <div>
                                    <p>Latitude : {department?.center_lat} </p>
                                    <p>Longitude : {department?.center_lng} </p>
                                </div>
                            </div>
                        </div>
                        {!isPrinting && <>
                                {department?.description?.length > 0 && <>
                            <div className="bg-light">
                                    <p>{department?.description} </p>
                            </div>
                                </>}
                        </>}
                    
                    </div>
                    <div>
                    </div>
                </div>
                <div className="col-md-12 bg-light">
                    <div class="">
                        <div class="no-gutters row">
                            <div class="col-md-6 col-xl-3">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"> {department?.nb_sub_pref} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Sous - préfectures</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-4">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"> {localDepartment?.nbloc} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning">{localDepartment?.nbcouvloc}</div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localités couvertes</div>
                                            <div class="widget-subheading">{localDepartment?.txcouvloc.toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger">{localDepartment?.nbnocouvloc} </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Localité non couverte</div>
                                            <div class="widget-subheading">{(100 - localDepartment?.txcouvloc).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xl-5">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-success"><small>{handleNumThousand(localDepartment?.population) }</small></div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning"><small>{handleNumThousand(localDepartment?.popcouv) }</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population couverte</div>
                                            <div class="widget-subheading">{localDepartment?.txpopcouv.toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger"><small>{handleNumThousand(localDepartment?.nopopcouv)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population non couverte</div>
                                            <div class="widget-subheading">{(100 - localDepartment?.txpopcouv).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                {localDepartment && <>
                <div className="mt-3 w-100">
                            <table className="table table-bordered table-striped table-hover table-width">
                                <thead>
                                    <tr>
                                        <th>Opérateur</th>
                                        <th colSpan={technologies?.length * 2} className="text-center">Localités</th>
                                        <th colSpan={technologies?.length * 2} className="text-center">Population</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                        <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t1"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t2"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology,index) => {
                                            return(
                                                <th key={"t3"+index} className="text-center"> {technology?.name} </th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {operators?.length > 0 && operators?.map((operator,index) => {
                                        return(
                                            <tr key={"ot"+index}>
                                                <td>
                                                    <img src={baseFileUrl+operator.image_path} className="mr-2" height={30} alt="" />
                                                     <b>{operator?.name}</b> </td>
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt1"+index}> { handleNumThousand(localDepartment["locCouv"+operator.name+technology.name])} <br />
                                                        <small> -> {(localDepartment["locCouv"+operator.name+technology.name] > 0 ? (localDepartment["locCouv"+operator.name+technology.name]*100 /localDepartment?.nbloc ).toFixed(1) : 0 )} %</small>
                                                         </td>
                                                    )
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt2"+index}> { handleNumThousand(localDepartment.nbloc -localDepartment["locCouv"+operator.name+technology.name])} <br />
                                                        <small> -> {(localDepartment["locCouv"+operator.name+technology.name] > 0 ? ((localDepartment.nbloc -localDepartment["locCouv"+operator.name+technology.name]) *100 /localDepartment?.nbloc ).toFixed(1) : 0 )} %</small>
                                                        </td>
                                                    )
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt3"+index}> { handleNumThousand(localDepartment["pop"+operator.name+technology.name])} <br />
                                                        <small> -> {(localDepartment["pop"+operator.name+technology.name] > 0 ? (localDepartment["pop"+operator.name+technology.name]*100 /localDepartment?.population ).toFixed(1) : 0 )} %</small>
                                                        </td>
                                                    )
                                                })}
                                                {technologies?.length > 0 && technologies?.map((technology,index) => {
                                                    return(
                                                        <td key={"tt4"+index}> { handleNumThousand(localDepartment.population -localDepartment["pop"+operator.name+technology.name])}<br />
                                                        <small> -> {(localDepartment["pop"+operator.name+technology.name] > 0 ? ( (localDepartment.population -localDepartment["pop"+operator.name+technology.name]) *100 /localDepartment?.population ).toFixed(1) : 0 )} %</small> 
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        
                                    </tr>
                                </tbody>
                            </table>
                </div>
                </>}
            </div>
        </Dialog>
    </>)
}