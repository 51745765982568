import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminNetworkSignageList } from "./AdminNetworkSignageList";
import {
  DeleteNetworkSignageDialog,
  DeleteSignagesDialog,
  DeleteSNetworkSignagesDialog,
} from "./AdminNetworkSignageDeleteDialog";
import {
  addNetworkSignage,
  deleteNetworkSignage,
  editNetworkSignage,
  getNetworkSignages,
} from "../../../../../services/api/admin/problems/signages/signageNetworkApiService";
import { AdminNetworkSignageDialog } from "./AdminNetworkSignageDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { AdminNetworkSignageVIewDialog } from "./AdminNetworkSignageViewDialog";
import { AdminNetworkSignageExcelDialog } from "./AdminNetworkSignageExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";

export const AdminNetworkSignagePage = () => {
  const [openSignageViewDialog, setOpenSignageViewDialog] = useState(false);
  const [openSignageDialog, setOpenSignageDialog] = useState(false);
  const [openSignageExcelDialog, setOpenSignageExcelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [signages, setSignages] = useState([]);
  const [signage, setSignage] = useState();

  const [selectedSignages, setSelectedSignages] = useState(null);

  const [signageDialogTitle, setSignageDialogTitle] = useState(
    "CREATION D'UN PROBLÈME"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getNetworkSignages(setSignages);
  }, []);

  useEffect(() => {
    console.log("Les signages :", signage);
  }, [signage]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de signage effectué avec succès!");
      getNetworkSignages(setSignages);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du signage !");
    }
    setOpenSignageDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du signage effectué avec succès!");
      getNetworkSignages(setSignages);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du signage !");
    }
    setOpenSignageDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du signage effectué avec succès!");
      getNetworkSignages(setSignages);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du signage !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setSignage();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setSignageDialogTitle("CREATION D'UN PROBLÈME");
    setSelectedSignages();
    setOpenSignageDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _signage = { ...signage };

    _signage[`${name}`] = val;

    setSignage(_signage);
  };

  const handleSubmitSignage = async (e) => {
    e.preventDefault();

    if (signage.title.trim()) {
      signage?.description?.trim();
      if (signage.id) {
        await editNetworkSignage(signage, setEditResponse);
      } else {
        await addNetworkSignage(signage, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewSignage = (dist) => {
    setSignage({ ...dist });
    setOpenSignageViewDialog(true);
  };

  //EDITION
  const handleEditSignage = (dist) => {
    setSignageDialogTitle("MODIFICATION D'UN PROBLÈME");
    console.log("AZERTY", dist);
    setSignage({ ...dist });
    setOpenSignageDialog(true);
  };

  //SUPPRESSION
  const handleDeleteSignageDialog = (dist) => {
    setSignage({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteSignage = async () => {
    await deleteNetworkSignage(signage, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteSignages = async () => {
    try {
      selectedSignages.forEach(async (signage) => {
        await deleteNetworkSignage(signage, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du signages selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "type", header: "Type" },
    { field: "title", header: "Titre" },
    { field: "level", header: "Niveau d'alerte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, signages, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(signages);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-signages");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-satellite-dish"
                title="Gestion des signalisation liées à la plateforme"
                subTitle="Tableau de bord"
                description="Repondre, traiter les signalisation des utilisateurs concernant la plateforme. "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des signalisations concernant la plate-forme
                  </div>
                  {/* <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenSignageDialog(true);
                        setSignage();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un problème
                    </button>
                  </div> */}
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      
                      {signages == [] || signages?.length > 0 ? ( <>
                        <AdminNetworkSignageList
                          signages={signages}
                          handleEditSignage={handleEditSignage}
                          handleDeleteSignageDialog={
                            handleDeleteSignageDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedSignages={selectedSignages}
                          setSelectedSignages={setSelectedSignages}
                          handleViewSignage={handleViewSignage}
                        />
                      </>
                      ) : (
                        <>
                        {signages != null ? <>
                        <div>
                          <h6 className="text-center">Aucun résultat trouvé !</h6>
                        </div>
                        </> : <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminNetworkSignageVIewDialog
              display={openSignageViewDialog}
              setDisplay={setOpenSignageViewDialog}
              signage={signage}
            />

            <AdminNetworkSignageDialog
              display={openSignageDialog}
              setDisplay={setOpenSignageDialog}
              handleSumitSignage={handleSubmitSignage}
              signage={signage}
              title={signageDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setSignage={setSignage}
            />

            <DeleteNetworkSignageDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletesignage={handleDeleteSignage}
              signage={signage}
            />
            <DeleteSNetworkSignagesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedSignage={handleDeleteSignages}
            />
          </>
        }
      />
    </>
  );
};
