import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog } from 'primereact/dialog';

export const DeleteSProblemsDialog = ({ display, setDisplay, handleDeleteSelectedProblem }) => {
    const [position, setPosition] = useState('center');

    const onClick = (position) => {
        setDisplay(true)
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        setDisplay(false)
    }

    useEffect(() => {
        if (display) {
            onClick()
        } else {
            onHide()
        }
    }, [display]);


    return (
        <>
            <Dialog header="Confirmation" visible={display} modal style={{ width: '32rem' }} onHide={() => onHide('displayMaximizable')} position="center">
                <div className="confirmation-content text-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-danger" style={{ fontSize: '2rem' }} />
                        <div>
                            Etes-vous sûr que vous voulez supprimer le(s) problèmes selectionné(s)?
                        </div>
                </div>
                    <div className="modal-footer pt-3 d-flex justify-content-between">
                        <button type="button" className="btn btn-outline-primary my-0">Non</button>
                        <button type="submit" className="btn btn-danger my-0" onClick={() => handleDeleteSelectedProblem()}>Oui</button>
                    </div>
            </Dialog>
        </>
    )
}


export const DeleteProblemDialog = ({ display, setDisplay, problem, handleDeleteproblem }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState('center');

    const onClick = (position) => {
        setDisplay(true)
        if (position) {
            setPosition(position);
        }
    }

    const onHide = (name) => {
        setDisplay(false)
    }

    useEffect(() => {
        if (display) {
            onClick()
        } else {
            onHide()
        }
    }, [display]);


    return (
        <>
            <Dialog header="Confirmation" visible={display} modal style={{ width: '32rem' }} onHide={() => onHide('displayMaximizable')} position="center">
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {problem && (
                        <span>
                            Etes-vous sûr que vous voulez supprimer le problème <b>{problem.title}</b>?
                        </span>
                    )}
                </div>
                    <div className="modal-footer pt-3 d-flex justify-content-between">
                        <button type="button" className="btn btn-outline-primary my-0">Non</button>
                        <button type="submit" className="btn btn-danger my-0" onClick={() => handleDeleteproblem()}>Oui</button>
                    </div>
            </Dialog>
        </>
    )
}