import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminHomeMessageList } from "./AdminHomeMessageList";
import {
  DeleteHomeMessageDialog,
  DeleteHomeMessagesDialog,
  DeleteSHomeMessagesDialog,
} from "./AdminHomeMessageDeleteDialog";
import { addHomeMessage,
  deleteHomeMessage,
  editHomeMessage,
  getActiveHomeMessages,
  getHomeMessages } from "../../../../../services/api/admin/newsLetter/homeMessages/homeMessageApiService";
import { AdminHomeMessageDialog } from "./AdminHomeMessageDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { AdminHomeMessageVIewDialog } from "./AdminHomeMessageViewDialog";
import { AdminHomeMessageExcelDialog } from "./AdminHomeMessageExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";

export const AdminHomeMessagePage = () => {
  const [openHomeMessageViewDialog, setOpenHomeMessageViewDialog] = useState(false);
  const [openHomeMessageDialog, setOpenHomeMessageDialog] = useState(false);
  const [openHomeMessageExcelDialog, setOpenHomeMessageExcelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [homeMessages, setHomeMessages] = useState();
  const [homeMessage, setHomeMessage] = useState();
  const [technologies, setTechnologies] = useState();

  const [activeHomeMessages, setActiveHomeMessages] = useState();

  const [generaleData, setGeneraleData] = useState();

  const [selectedHomeMessages, setSelectedHomeMessages] = useState(null);

  const [homeMessageDialogTitle, setHomeMessageDialogTitle] = useState(
    "CREATION D'UN MESSAGE D'ACCUEIL"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getHomeMessages(setHomeMessages);
    getActiveHomeMessages(setActiveHomeMessages)
  }, []);

  useEffect(() => {
    console.log("Les messages d'accueil :", homeMessage);
  }, [homeMessage]);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement du message effectué avec succès!");
      getHomeMessages(setHomeMessages);
      getActiveHomeMessages(setActiveHomeMessages)
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du message !");
    }
    setOpenHomeMessageDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification de l' operateur effectuée avec succès!");
      getHomeMessages(setHomeMessages);
      getActiveHomeMessages(setActiveHomeMessages)
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du message !");
    }
    setOpenHomeMessageDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du message effectué avec succès!");
      getHomeMessages(setHomeMessages);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du homeMessage !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setHomeMessage();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setHomeMessageDialogTitle("CREATION D'UN MESSAGE D'ACCUEIL");
    setSelectedHomeMessages();
    setOpenHomeMessageDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _homeMessage = { ...homeMessage };

    _homeMessage[`${name}`] = val;

    setHomeMessage(_homeMessage);
  };

  const handleSubmitHomeMessage = async (e) => {
    e.preventDefault();

    if (homeMessage.title.trim()) {
      homeMessage?.description?.trim();
      if (homeMessage.id) {
        await editHomeMessage(homeMessage, setEditResponse);
      } else {
        await addHomeMessage(homeMessage, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewHomeMessage = (dist) => {
    setHomeMessage({ ...dist });
    setOpenHomeMessageViewDialog(true);
  };

  //EDITION
  const handleEditHomeMessage = (dist) => {
    setHomeMessageDialogTitle("MODIFICATION D'UN MESSAGE D'ACCUEIL");
    console.log("AZERTY", dist);
    setHomeMessage({ ...dist });
    setOpenHomeMessageDialog(true);
  };

  //SUPPRESSION
  const handleDeleteHomeMessageDialog = (dist) => {
    setHomeMessage({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteHomeMessage = async () => {
    await deleteHomeMessage(homeMessage, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteHomeMessages = async () => {
    try {
      selectedHomeMessages.forEach(async (homeMessage) => {
        await deleteHomeMessage(homeMessage, setDeleteResponse);
      });
    } catch (error) {
      toastError("Erreur lors de la suppression du homeMessages selectionnés !");
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, homeMessages, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(homeMessages);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-homeMessages");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-satellite-dish"
        title="Gestion des messages d'accueil"
        subTitle="Gestion des messages d'accueil"
        description="Creer, modifier, alerter ou supprimer des messages d'accueil."
        children={
          <>
            <div className="">
            <div className="p-2">

              <div role="alert" class=" alert alert-warning alert-dismissible fade show p-2">
                <div>
                  <h5>MESSAGE D'ACCUEIL</h5>
                  <small className="">
                    <marquee behavior="" direction="" >
                      {activeHomeMessages?.length >0 && activeHomeMessages.map((message,index) => {
                        return(<>
                        <b>{message.title } >> </b>{message.description}
                        {(activeHomeMessages?.length > 1 && activeHomeMessages?.length != index+1) &&  " <-------//-------> "}
                        </>)
                      })}
                    </marquee>
                  </small>
                </div>
              </div>
            </div>
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des messages d'accueil
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenHomeMessageDialog(true);
                        setHomeMessage();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer un operateur
                    </button>
                  </div>
                </div>
                <div className="no-gutters row"> 
                  <div className="card w-100">
                    <div className="card-body">
                      {homeMessages?.length > 0 ? (
                        <AdminHomeMessageList
                          homeMessages={homeMessages}
                          handleEditHomeMessage={handleEditHomeMessage}
                          handleDeleteHomeMessageDialog={
                            handleDeleteHomeMessageDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedHomeMessages={selectedHomeMessages}
                          setSelectedHomeMessages={setSelectedHomeMessages}
                          handleViewHomeMessage={handleViewHomeMessage}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminHomeMessageVIewDialog
              display={openHomeMessageViewDialog}
              setDisplay={setOpenHomeMessageViewDialog}
              homeMessage={homeMessage}
              generaleData={generaleData}
              technologies={technologies}
            />

            <AdminHomeMessageDialog
              display={openHomeMessageDialog}
              setDisplay={setOpenHomeMessageDialog}
              handleSumitHomeMessage={handleSubmitHomeMessage}
              homeMessage={homeMessage}
              title={homeMessageDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setHomeMessage={setHomeMessage}
            />

            <DeleteHomeMessageDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletehomeMessage={handleDeleteHomeMessage}
              homeMessage={homeMessage}
            />
            <DeleteSHomeMessagesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedHomeMessage={handleDeleteHomeMessages}
            />
          </>
        }
      />
    </>
  );
};
