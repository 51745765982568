import { user } from "../../../../constants";
import { localVariables } from "../../../local/SecureLocalStorageVariable";
import { adminAxiosInstance } from "../../config/axiosConfig";



export const getCoverages = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/buffer-coverages`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            console.log("liste des buffer_coverages :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addCoverage = async (coverages, setState) => {
    try {
        const {
            code,
            district,
            region,
            depart,
            sub_pref,
            name,

            pop,


            pop_cov_2G_orange,
            pop_cov_3G_orange,
            pop_cov_4G_orange,

            pop_cov_2G_mtn,
            pop_cov_3G_mtn,
            pop_cov_4G_mtn,

            pop_cov_2G_moov,
            pop_cov_3G_moov,
            pop_cov_4G_moov,

            coverage_2G_orange,
            coverage_3G_orange,
            coverage_4G_orange,

            coverage_2G_mtn,
            coverage_3G_mtn,
            coverage_4G_mtn,

            coverage_2G_moov,
            coverage_3G_moov,
            coverage_4G_moov,

            presence_2G_orange,
            presence_3G_orange,
            presence_4G_orange,
            presence_5G_orange,

            presence_2G_mtn,
            presence_3G_mtn,
            presence_4G_mtn,
            presence_5G_mtn,

            presence_2G_moov,
            presence_3G_moov,
            presence_4G_moov,
            presence_5G_moov,

            prevision_2G_orange,
            prevision_3G_orange,
            prevision_4G_orange,
            prevision_5G_orange,

            prevision_2G_mtn,
            prevision_3G_mtn,
            prevision_4G_mtn,
            prevision_5G_mtn,

            prevision_2G_moov,
            prevision_3G_moov,
            prevision_4G_moov,
            prevision_5G_moov,

            longitude,
            latitude,
            fialbility,
            description,
            status,

        } = coverages

        const us = localVariables.getItem(user);

        console.log("Utilisateur connecté", us);

        const response = await adminAxiosInstance.post(`/buffer-coverages`, {
            user_id: us?.id,
            district: district,
            region: region,
            depart: depart,
            sub_pref: sub_pref,

            code: code,
            name: name,

            pop: pop,

            pop_cov_2G_orange: pop_cov_2G_orange,
            pop_cov_3G_orange: pop_cov_3G_orange,
            pop_cov_4G_orange: pop_cov_4G_orange,

            pop_cov_2G_mtn: pop_cov_2G_mtn,
            pop_cov_3G_mtn: pop_cov_3G_mtn,
            pop_cov_4G_mtn: pop_cov_4G_mtn,

            pop_cov_2G_moov: pop_cov_2G_moov,
            pop_cov_3G_moov: pop_cov_3G_moov,
            pop_cov_4G_moov: pop_cov_4G_moov,

            coverage_2G_orange: coverage_2G_orange,
            coverage_3G_orange: coverage_3G_orange,
            coverage_4G_orange: coverage_4G_orange,

            coverage_2G_mtn: coverage_2G_mtn,
            coverage_3G_mtn: coverage_3G_mtn,
            coverage_4G_mtn: coverage_4G_mtn,

            coverage_2G_moov: coverage_2G_moov,
            coverage_3G_moov: coverage_3G_moov,
            coverage_4G_moov: coverage_4G_moov,


            presence_2G_orange: presence_2G_orange,
            presence_3G_orange: presence_3G_orange,
            presence_4G_orange: presence_4G_orange,
            presence_5G_orange: presence_5G_orange,

            presence_2G_mtn: presence_2G_mtn,
            presence_3G_mtn: presence_3G_mtn,
            presence_4G_mtn: presence_4G_mtn,
            presence_5G_mtn: presence_5G_mtn,

            presence_2G_moov: presence_2G_moov,
            presence_3G_moov: presence_3G_moov,
            presence_4G_moov: presence_4G_moov,
            presence_5G_moov: presence_5G_moov,

            prevision_2G_orange: prevision_2G_orange,
            prevision_3G_orange: prevision_3G_orange,
            prevision_4G_orange: prevision_4G_orange,
            prevision_5G_orange: prevision_5G_orange,

            prevision_2G_mtn: prevision_2G_mtn,
            prevision_3G_mtn: prevision_3G_mtn,
            prevision_4G_mtn: prevision_4G_mtn,
            prevision_5G_mtn: prevision_5G_mtn,

            prevision_2G_moov: prevision_2G_moov,
            prevision_3G_moov: prevision_3G_moov,
            prevision_4G_moov: prevision_4G_moov,
            prevision_5G_moov: prevision_5G_moov,

            longitude: longitude,
            latitude: latitude,
            fialbility: fialbility,
            description: description,
            status: status,

        }).then((res => res))

        if (response.status == 201) {
            console.log("RESPONSE BUFFER LIMITE ==============>", response)
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addOperatorCoverage = async (cov, setState) => {
    try {
        const {
            district,
            region,
            department,
            sub_pref,

            locality_code,
            locality_name,

            pop,

            pop_cov_2G_operator,
            pop_cov_3G_operator,
            pop_cov_4G_operator,

            pop_cov_data_operator,
            pop_cov_phone_operator,

            coverage_2G_operator,
            coverage_3G_operator,
            coverage_4G_operator,

            coverage_data_operator,
            coverage_phone_operator,

            presence_2G_operator,
            presence_3G_operator,
            presence_4G_operator,

            forecast_cov_data,
            forecast_cov_phone,

            forecast_pop_cov_data,
            forecast_pop_cov_phone,

            longitude,
            latitude,

            fialbility,
            description,
            status, } = cov;
        
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addCoveragesList = async (coverages, setState) => {
    try {
        const us = localVariables.getItem(user);
        const response1 = await adminAxiosInstance.post('excel-files',{
            owner_id: us?.id,
        }).then((res => res));

        let errors = [];

        if (response1.status == 201) {
            if (response1?.data.error == false) {
                const e_data = response1?.data;
                try {
                    await coverages.forEach(async cov => {
                        console.log('OK6 ====>',  cov)
                        const {
                            district,
                            region,
                            department,
                            sub_pref,

                            locality_code,
                            locality_name,

                            pop,

                            pop_cov_2G_orange,
                            pop_cov_3G_orange,
                            pop_cov_4G_orange,

                            pop_cov_2G_mtn,
                            pop_cov_3G_mtn,
                            pop_cov_4G_mtn,

                            pop_cov_2G_moov,
                            pop_cov_3G_moov,
                            pop_cov_4G_moov,

                            pop_cov_data_orange,
                            pop_cov_phone_orange,

                            pop_cov_data_mtn,
                            pop_cov_phone_mtn,

                            pop_cov_data_moov,
                            pop_cov_phone_moov,

                            coverage_2G_orange,
                            coverage_3G_orange,
                            coverage_4G_orange,

                            coverage_2G_mtn,
                            coverage_3G_mtn,
                            coverage_4G_mtn,

                            coverage_2G_moov,
                            coverage_3G_moov,
                            coverage_4G_moov,

                            coverage_data_orange,
                            coverage_phone_orange,

                            coverage_data_mtn,
                            coverage_phone_mtn,

                            coverage_data_moov,
                            coverage_phone_moov,

                            presence_2G_orange,
                            presence_3G_orange,
                            presence_4G_orange,

                            presence_2G_mtn,
                            presence_3G_mtn,
                            presence_4G_mtn,

                            presence_2G_moov,
                            presence_3G_moov,
                            presence_4G_moov,

                            forecast_cov_data,
                            forecast_cov_phone,

                            forecast_pop_cov_data,
                            forecast_pop_cov_phone,

                            longitude,
                            latitude,

                            fialbility,
                            description,
                            status, } = cov;

                        const response = await adminAxiosInstance.post(`/buffer-coverages`, {
                            user_id: us?.id,
                            district: district,
                            region: region,
                            depart: department,
                            sub_pref: sub_pref,

                            e_code_id: e_data?.id,

                            locality_code: locality_code,
                            locality_name: locality_name,

                            pop: pop,

                            pop_cov_2G_orange: pop_cov_2G_orange,
                            pop_cov_3G_orange: pop_cov_3G_orange,
                            pop_cov_4G_orange: pop_cov_4G_orange,

                            pop_cov_2G_mtn: pop_cov_2G_mtn,
                            pop_cov_3G_mtn: pop_cov_3G_mtn,
                            pop_cov_4G_mtn: pop_cov_4G_mtn,

                            pop_cov_2G_moov: pop_cov_2G_moov,
                            pop_cov_3G_moov: pop_cov_3G_moov,
                            pop_cov_4G_moov: pop_cov_4G_moov,

                            coverage_2G_orange: coverage_2G_orange,
                            coverage_3G_orange: coverage_3G_orange,
                            coverage_4G_orange: coverage_4G_orange,

                            coverage_2G_mtn: coverage_2G_mtn,
                            coverage_3G_mtn: coverage_3G_mtn,
                            coverage_4G_mtn: coverage_4G_mtn,

                            coverage_2G_moov: coverage_2G_moov,
                            coverage_3G_moov: coverage_3G_moov,
                            coverage_4G_moov: coverage_4G_moov,

                            forecast_cov_data_orange: coverage_data_orange,
                            forecast_cov_phone_orange: coverage_phone_orange,

                            pop_cov_data_orange: pop_cov_data_orange,
                            pop_cov_phone_orange: pop_cov_phone_orange,

                            forecast_cov_data_mtn: coverage_data_mtn,
                            forecast_cov_hone_mtn: coverage_phone_mtn,

                            forecast_pop_cov_data_mtn: pop_cov_data_mtn,
                            forecast_pop_cov_phone_mtn: pop_cov_phone_mtn,

                            forecast_cov_data_moov: coverage_data_moov,
                            forecast_cov_phone_moov: coverage_phone_moov,

                            forecast_pop_cov_data_moov: pop_cov_data_moov,
                            forecast_pop_cov_phone_moov: pop_cov_phone_moov,


                            presence_2G_orange: presence_2G_orange,
                            presence_3G_orange: presence_3G_orange,
                            presence_4G_orange: presence_4G_orange,

                            presence_2G_mtn: presence_2G_mtn,
                            presence_3G_mtn: presence_3G_mtn,
                            presence_4G_mtn: presence_4G_mtn,

                            presence_2G_moov: presence_2G_moov,
                            presence_3G_moov: presence_3G_moov,
                            presence_4G_moov: presence_4G_moov,

                            forecast_cov_data: forecast_cov_data,
                            forecast_cov_phone: forecast_cov_phone,

                            forecast_pop_cov_data: forecast_pop_cov_data,
                            forecast_pop_cov_phone: forecast_pop_cov_phone,

                            longitude: longitude,
                            latitude: latitude,
                            fialbility: fialbility,
                            description: description,
                            status: status,
                        }).then((res => res))
                        console.log('OK7 ====>', response)
                    });

                    // setState({
                    //     error:false
                    // })
                    
                } catch (error) {
                    console.error(error)
                    setState({ error: true })
                }
            }
        } else {
            console.log("EEEEEEEEEEE==========>", response1)
        }

    } catch (error) {

    }


}

export const editCoverage = async (coverages, setState) => {
    try {
        const { type, file, description } = coverages

        const response = await adminAxiosInstance.post('/buffer-coverages-update/' + coverages.id, {
            type: type,
            file: file,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteCoverage = async (coverages, setState) => {
    try {
        console.log("Etat de couverture ===========+>", coverages.id)
        await adminAxiosInstance.delete('/buffer-coverages/' + coverages.id).then((response) => {
            setState(response.data);
        })
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const getExcelCoverage = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/excel-files`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            console.log("liste des enregistrement de miseà jour par excel :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }

}
export const getExcelByOperatoreCoverage = async (setState,id) => {
    try {
        const response = await adminAxiosInstance.get(`/excel-files-operator/`+id).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            console.log("liste des enregistrement de miseà jour par excel :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }

}

export const getCoverageByOperator = async (setState,id) => {
    try {
        const response = await adminAxiosInstance.get(`/buffer-operator-coverages/`+id).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            console.log("liste des buffer_coverages :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}