import { year } from "../../constants/map/mapDataConstants";
import { localVariables } from "../../local/SecureLocalStorageVariable";
import { adminAxiosInstance } from "../config/axiosConfig";


export const findByDistrictName = async (setState,name) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/search-district/'+name).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/search-district2021/'+name).then((res => res) )
        }

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse districts trouvés ##### ===>",response.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.districts)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}


export const findByRegionName = async (setState,name) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/search-region/'+name).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/search-region2021/'+name).then((res => res) )
        }
        // console.log('Nom recherché ====>', name)

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse region trouvés ##### ===>",response.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.regions)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}


export const findByDepartmentName = async (setState,name) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/search-department/'+name).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/search-department2021/'+name).then((res => res) )
        }
        // console.log('Nom recherché ====>', name)

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse departements trouvés ##### ===>",response.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.dapartments)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const findBySubPrefectureName = async (setState,name) => {
    try {
        // console.log('Nom recherché ====>', name)
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/search-sub-prefecture/'+name).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/search-sub-prefecture2021/'+name).then((res => res) )
        }

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse sous-prefecture trouvés ##### ===>",response.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.subPrefectures)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportDistrictLocalities = async (setState,id) => {
    try {
        // console.log("ID de la zone ====>", id);
        // console.log('Nom recherché ====>', name)
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-district-localities/'+id).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-district-localities2021/'+id).then((res => res) )
        }

        setState([]);

        // console.log("Reponse API EXPORT ====>", response.data.localities);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportRegionLocalities = async (setState,id) => {
    try {
        // console.log("ID de la zone ====>", id);
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-region-localities/'+id).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-region-localities2021/'+id).then((res => res) )
        }

        setState([]);

        // console.log("Reponse API EXPORT ====>", response);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportDepartmentLocalities = async (setState,id) => {
    try {
        // console.log("ID de la zone ====>", id);
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-department-localities/'+id).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-department-localities2021/'+id).then((res => res) )
        }

        setState([]);

        // console.log("Reponse API EXPORT ====>", response);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getExportSubPrefectureLocalities = async (setState,id) => {
    try {
        // console.log("ID de la zone ====>", id);
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/export-sub-prefecture-localities/'+id).then((res => res) )
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/export-sub-prefecture-localities2021/'+id).then((res => res) )
        }

        setState([]);

        // console.log("Reponse API EXPORT ====>", response);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getDistrictsIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-district-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-district-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);

        // console.log("Reponse API EXPORT REGION ====>", response.data.data);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getRegionIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-region-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-region-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);

        // console.log("Reponse API EXPORT REGION ====>", response);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getDeparmentIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-department-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-department-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);

        // console.log("Reponse API EXPORT DEPARTEMENTS ====>", response);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getSubPrefectureIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-sub-prefecture-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-sub-prefecture-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);
        
        if(response.status == 200) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getDistrictsLocalitiesIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-district-localities-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-district-localities-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getRegionsLocalitiesIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-region-localities-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-region-localities-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getDepartmentsLocalitiesIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-department-localities-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-department-localities-in2021`, {
                tab:tab
            }).then((res => res))
        }
        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getSubPrefecturesLocalitiesIn = async (setState,tab) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.post(`/export-sub-prefecture-localities-in`, {
                tab:tab
            }).then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.post(`/export-sub-prefecture-localities-in2021`, {
                tab:tab
            }).then((res => res))
        }

        setState([]);
        
        if(response.status == 201) {
            // console.log("reponse localités trouvés ===>",response.data.localities)  
            // console.log("La reponse =====> :", response.data)
            setState(response.data.localities)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getDistrictRegions = async (setResponse,code) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/get-data-file/geojson/region_limites2023.geojson').then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/get-data-file/geojson/region_limites2021.geojson').then((res => res))
        }

        if (response.status == 200){
            const _data = response.data;
            console.log("DONNEES ==========>",_data)
            const _regions = _data.features.filter(feature => feature.properties.ADM0_PCODE == code);
            console.log("REGION ========>", _regions)
            setResponse(_regions)
        }

    } catch (error) {
        
    }
}

export const getRegionDepartments = async (setResponse,code) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/get-data-file/geojson/department_limites2023.geojson').then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/get-data-file/geojson/department_limites2021.geojson').then((res => res))
        }

        if (response.status == 200){
            const _data = response.data;
            console.log("DONNEES ==========>",_data)
            const _regions = _data.features.filter(feature => feature.properties.ADM1_PCODE == code);
            console.log("REGION ========>", _regions)
            setResponse(_regions)
        }

    } catch (error) {
        
    }
}

export const getDepartmentSubPrefectures = async (setResponse,code) => {
    try {
        const y = localVariables.getItem(year);
        let response = null;
        if(y == null || y == undefined || y == 2023 ){
            response = await adminAxiosInstance.get('/get-data-file/geojson/sub_prefecture_limites2023.geojson').then((res => res))
        }
        else if(y == 2021){
            response = await adminAxiosInstance.get('/get-data-file/geojson/sub_prefecture_limites2023.geojson').then((res => res))
        }

        if (response.status == 200){
            const _data = response.data;
            console.log("DONNEES ==========>",_data)
            const _regions = _data.features.filter(feature => feature.properties.ADM2_PCODE == code);
            console.log("REGION ========>", _regions)
            setResponse(_regions)
        }

    } catch (error) {
        
    }
}