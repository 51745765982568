import React, { useState, useEffect, useCallback, useRef } from "react";
import { ImportLocalityExcelComponent } from "../../../../../components/Excel/ImportLocalityExcelComponent";
import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { AdminCoverageExcelDialog } from "./AdminCoverageExcelDialog";
import { Link } from "react-router-dom";
import { addCoveragesList, getCoverages, getExcelCoverage } from "../../../../../services/api/admin/bufferCoverages/bufferCoverageApiService";
import { toastError, toastSuccess, toastWarning } from "../../../../../components/notification/notification";
import { Column } from "primereact/column";
import { strDate } from "../../../../../services/conversion/stringConversion";
import { DataTable } from "primereact/datatable";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { adminExcelTemplate } from "../../../../../services/excel/exportTemplate";

export const AdminCoverageExcelPage = () => {
  const [openCoverageExcelDialog, setOpenCoverageExcelDialog] = useState(false);
  const [openExcelSubmitDialog, setOpenExcelSubmitDialog] = useState(false);
  const [data, setData] = useState(null);
  const [response, setResponse] = useState(null);
  const [showError, setShowError] = useState(false);
  const [isLoadDing, setisLoadDing] = useState(false);

  const [globalFilter, setGlobalFilter] = useState(null);

  const [selectedExcelData, setSelectedExcelData] = useState(null);


  const dt = useRef(null);

  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    getExcelCoverage(setExcelData);
  }, []);


  useEffect(() => {
    if (response?.error == false) {
      toastSuccess("Enregistrement des données effectué avec succès!");
      setOpenExcelSubmitDialog(false);
      getExcelCoverage(setExcelData);
      // getCoverages(setBufferCoverage);
    } else if (response?.error != null && response?.error == false && response?.message == null) {
      toastError("Echec de l'enregistrement des données !");
    } else if (response?.error != null && response?.error == false && response?.message != null) {
      toastWarning("Echec de l'enregistrement d'une donnée !");
      setShowError(true);
    }
    setisLoadDing(false)
  }, [response]);

  const handleSumit = (d) => {
    console.log("DONNEES EXCELS ================>,", d);
    // console.log("FICHHIER EXCEL ================>",file);
    setData(d);
    setOpenExcelSubmitDialog(true);
  }

  const onClose = () => {
    setOpenCoverageExcelDialog(false);

  }
  const fields = [
    {
      // Visible in table header and when matching columns.
      label: "Code Localité",
      // This is the key used for this field when we call onSubmit.
      key: "locality_code",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["code localité", "code","CODE LOCALITE", "CODE_LOCALITE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "CI00001",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Le code est obligatoire",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      // Visible in table header and when matching columns.
      label: "Localité",
      // This is the key used for this field when we call onSubmit.
      key: "locality_name",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["LOCALITE v2",],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "BOUAKE",
      // Can have multiple validations that are visible in Validation Step table.
    },
    {
      // Visible in table header and when matching columns.
      label: "Sous-préfecture",
      // This is the key used for this field when we call onSubmit.
      key: "sub_pref",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["sous-prefecture", "SOUS-PREFECTURE", "sous-préfecture", "Sous-prefecture", "Sous-préfecture"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "KASSERE",
      // Can have multiple validations that are visible in Validation Step table.
    },
    {
      // Visible in table header and when matching columns.
      label: "Département",
      // This is the key used for this field when we call onSubmit.
      key: "department",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["départment", "DEPARTEMENT", "department", "épartment", "department"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "BOUAKE",
    },
    {
      // Visible in table header and when matching columns.
      label: "Région",
      // This is the key used for this field when we call onSubmit.
      key: "region",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["région", "REGION", "region"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "GBEKE",
    },
    {
      // Visible in table header and when matching columns.
      label: "District",
      // This is the key used for this field when we call onSubmit.
      key: "district",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["district", "DISTRICT"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "VALLEE DU BANDAMA",
    },
    {
      // Visible in table header and when matching columns.
      label: "Longitude",
      // This is the key used for this field when we call onSubmit.
      key: "longitude",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["longitude", 'Longitude (Décimal)', "LONGITUDE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "0,55",
      // Can have multiple validations that are visible in Validation Step table.
    },
    {
      // Visible in table header and when matching columns.
      label: "Latitude",
      // This is the key used for this field when we call onSubmit.
      key: "latitude",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["latitude", "Latitude (Décimal)", "LATITUDE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "0,55",
      // Can have multiple validations that are visible in Validation Step table.
    },


    {
      // Visible in table header and when matching columns.
      label: "Population",
      // This is the key used for this field when we call onSubmit.
      key: "pop",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION", "POPULATION"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //2G
    //2G ORANNGE
    {
      // Visible in table header and when matching columns.
      label: "Couverture 2G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_2G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 2G ORANGE", "COUVERTURE_2G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 2G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "presence_2G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 2G ORANGE", "PRESENCE_2G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 2G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_2G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["population couverte 2G", "POPULATION COUVERTE 2G ORANGE", "POPULATION_COUVERTE_2G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //2G MTN
    {
      // Visible in table header and when matching columns.
      label: "Couverture 2G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_2G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 2G MTN", "COUVERTURE_2G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 2G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "presence_2G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 2G MTN", "PRESENCE_2G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 2G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_2G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["population couverte 2G", "POPULATION COUVERTE 2G MTN", "POPULATION_COUVERTE_2G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //2G MOOV
    {
      // Visible in table header and when matching columns.
      label: "Couverture 2G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_2G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 2G MOOV Africa CI", "COUVERTURE 2G MOOV", "COUVERTURE_2G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 2G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "presence_2G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 2G MOOV Africa CI", "PRESENCE 2G MOOV", "PRESENCE_2G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 2G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_2G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE 2G MOOV Africa CI", "population couverte 2G", "POPULATION COUVERTE 2G MOOV", "POPULATION_COUVERTE_2G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },


    //3G
    //3G ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Couverture 3G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_3G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 3G ORANGE", "COUVERTURE_3G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 3G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "presence_3G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 3G ORANGE", "PRESENCE_3G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 3G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_3G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["population couverte 3G", "POPULATION COUVERTE 3G ORANGE", "POPULATION_COUVERTE_3G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //3G MTN
    {
      // Visible in table header and when matching columns.
      label: "Couverture 3G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_3G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 3G MTN", "COUVERTURE_3G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 3G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "presence_3G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 3G MTN", "PRESENCE_3G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 3G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_3G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["population couverte 3G", "POPULATION COUVERTE 3G MTN", "POPULATION_COUVERTE_3G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //3G MOOV
    {
      // Visible in table header and when matching columns.
      label: "Couverture 3G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_3G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 3G MOOV Africa CI", "COUVERTURE 3G MOOV", "COUVERTURE_3G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 3G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "presence_3G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 3G MOOV Africa CI", "PRESENCE 3G MOOV", "PRESENCE_3G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 3G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_3G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE 3G MOOV Africa CI", "population couverte 3G", "POPULATION COUVERTE 3G MOOV", "POPULATION_COUVERTE_3G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },


    //4G ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Couverture 4G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_4G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 4G ORANGE", "COUVERTURE_4G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 4G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "presence_4G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 4G ORANGE", "PRESENCE_4G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 4G ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_4G_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["population couverte 4G", "POPULATION COUVERTE 4G ORANGE", "POPULATION_COUVERTE_4G_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //4G MTN
    {
      // Visible in table header and when matching columns.
      label: "Couverture 4G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_4G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 4G MTN", "COUVERTURE_4G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 4G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "presence_4G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 4G MTN", "PRESENCE_4G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 4G MTN",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_4G_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["population couverte 4G", "POPULATION COUVERTE 4G MTN", "POPULATION_COUVERTE_4G_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //4G MOOV
    {
      // Visible in table header and when matching columns.
      label: "Couverture 4G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_4G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE 4G MOOV Africa CI", "COUVERTURE 4G MOOV", "COUVERTURE_4G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Presence 4G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "presence_4G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["PRESENCE 4G MOOV Africa CI", "PRESENCE 4G MOOV", "PRESENCE_4G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    {
      // Visible in table header and when matching columns.
      label: "Population couverte 4G MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_4G_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE 4G MOOV Africa CI", "population couverte 4G", "POPULATION COUVERTE 4G MOOV", "POPULATION_COUVERTE_4G_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //COUVERTURE DATA ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Couverture data ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_data_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE SERVICE DATA ORANGE", "COUVERTURE DATA ORANGE", "COUVERTURE_DATA_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    //COUVERTURE TELEPHONIE ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Couverture telephonie ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_phone_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE EN SERVICE DE TELEPHONIE ORANGE", "COUVERTURE SERVICE TELEPHHONIE ORANGE", "COUVERTURE TELEPHHONIE ORANGE", "COUVERTURE_TELEPHHONIE_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },

    //POPULATION DATA ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Population Data ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_data_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE DATA ORANGE", "POPULATION COUVERTE SERVICE DATA ORANGE", "POPULATION DATA ORANGE", "POPULATION_DATA_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //POPULATION TELEPHONIE ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Population telephonie ORANGE",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_phone_orange",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE EN SERVICE DE TELEPHONIE ORANGE", "POPULATION COUVERTE TELEPHONIE ORANGE", "POPULATION COUVERTE SERVICE TELEPHONIE ORANGE", "POPULATION TELEPHONIE ORANGE", "POPULATION_TELEPHONIE_ORANGE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },


    //COUVERTURE DATA MTN
    {
      // Visible in table header and when matching columns.
      label: "Couverture service data MTN",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_data_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE SERVICE DATA MTN", "COUVERTURE SERVICE DATA MTN", "COUVERTURE DATA MTN", "COUVERTURE_DATA_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    //COUVERTURE TELEPHONIE MTN
    {
      // Visible in table header and when matching columns.
      label: "Couverture telephonie MTN",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_phone_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE EN SERVICE DE TELEPHONIE MTN", "COUVERTURE SERVICE TELEPHHONIE MTN", "COUVERTURE TELEPHHONIE MTN", "COUVERTURE_TELEPHHONIE_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },

    //POPULATION DATA MTN
    {
      // Visible in table header and when matching columns.
      label: "Population Data MTN",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_data_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE SERVICE DATA MTN", "POPULATION DATA MTN", "POPULATION_DATA_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //POPULATION TELEPHONIE ORANGE
    {
      // Visible in table header and when matching columns.
      label: "Population telephonie MTN",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_phone_mtn",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE EN SERVICE DE TELEPHONIE MTN", "POPULATION COUVERTE TELEPHONIE MTN", "POPULATION COUVERTE SERVICE TELEPHONIE MTN", "POPULATION TELEPHONIE MTN", "POPULATION_TELEPHONIE_MTN"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },


    //COUVERTURE DATA MOOV
    {
      // Visible in table header and when matching columns.
      label: "Couverture service data MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_data_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE SERVICE DATA MOOV Africa CI", "COUVERTURE SERVICE DATA MOOV", "COUVERTURE DATA MOOV", "COUVERTURE_DATA_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },

    //COUVERTURE TELEPHONIE MOOV
    {
      // Visible in table header and when matching columns.
      label: "Couverture telephonie MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "coverage_phone_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["COUVERTURE EN SERVICE DE TELEPHONIE MOOV Africa CI", "COUVERTURE EN SERVICE DE TELEPHONIE MOOV", "COUVERTURE SERVICE TELEPHHONIE MOOV", "COUVERTURE TELEPHHONIE MOOV", "COUVERTURE_TELEPHHONIE_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },

    //POPULATION DATA MOOV
    {
      // Visible in table header and when matching columns.
      label: "Population Data MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_data_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE SERVICE DATA MOOV Africa CI", "POPULATION COUVERTE SERVICE DATA MOOV", "POPULATION DATA MOOV", "POPULATION_DATA_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },

    //POPULATION TELEPHONIE MOOV
    {
      // Visible in table header and when matching columns.
      label: "Population telephonie MOOV",
      // This is the key used for this field when we call onSubmit.
      key: "pop_cov_phone_moov",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["POPULATION COUVERTE EN SERVICE DE TELEPHONIE MOOV Africa CI", "POPULATION COUVERTE TELEPHONIE MOOV", "POPULATION COUVERTE SERVICE TELEPHONIE MOOV", "POPULATION TELEPHONIE MOOV", "POPULATION_TELEPHONIE_MOOV"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1000",
    },




    //COUVERTURE DATA 
    {
      // Visible in table header and when matching columns.
      label: "Synthese couverture service data",
      // This is the key used for this field when we call onSubmit.
      key: "forecast_cov_data",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["SYNTHESE COUVERTURE DATA",],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    //COUVERTURE TELEPHONIE
    {
      // Visible in table header and when matching columns.
      label: "Synthese couverture service telephonie",
      // This is the key used for this field when we call onSubmit.
      key: "forecast_cov_phone",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["SYNTHESE COUVERTURE EN SERVICE DE TELEPHONIE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },

    //COUVERTURE DATA 
    {
      // Visible in table header and when matching columns.
      label: "Synthèse population service data",
      // This is the key used for this field when we call onSubmit.
      key: "forecast_pop_cov_data",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["SYNTHESE POPULATION COUVERTE DATA",],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
    //COUVERTURE TELEPHONIE MTN
    {
      // Visible in table header and when matching columns.
      label: "Synthèse population service telephonie",
      // This is the key used for this field when we call onSubmit.
      key: "forecast_pop_cov_phone",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["SYNTHESE POPULATION COUVERTE EN SERVICE DE TELEPHONIE"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "1",
    },
  ]



  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, excelData, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-loc-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(excelData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-bufferCoverages");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const handleDisplayExcelDialog = () => {
    setOpenCoverageExcelDialog(true)
  }

  const hahdleSubmitUpdate = async () => {
    setisLoadDing(true)
    await addCoveragesList(data, setResponse)
  }


  const header = () => {
    return (
      <>
        <div className=" bg-light w-100 p-2">
          {/* Search and select START */}
          <div className="d-flex justify-content-between">
            <input className="form-control bg-body" type="search" placeholder="Recherche..." aria-label="Search" onChange={(e) => setGlobalFilter(e.target.value)} />
          </div>
          {/* Search and select END */}
        </div>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (<>
      <div className="d-flex justify-content-end">
        <button className="btn btn-info btn-round mr-2 mb-0" onClick={() => { }}><i className="far fa-eye"></i></button>
        {/* <button className="btn btn-primary btn-round mr-2 mb-0" onClick={() => handleEditLocality(rowData)}><i className="far fa-edit"></i></button> */}
        <button className="btn btn-danger btn-round mb-0" onClick={() => { }} ><i className="fas fa-trash"></i></button>
      </div>
    </>)
  };

  const ownerTemplate = (rowData) => {
    return (<>
      <div>
        <div>{rowData?.owner?.first_name + " " + rowData?.owner?.last_name} </div>
        <div><a href="#"> {rowData?.owner?.email} </a></div>
      </div>
    </>)
  }

  const dateTemplate = (rowData) => {
    return (<>
      <div>
        <b>{strDate(rowData?.created_at)}</b>
        <div>
          <small>{rowData?.created_at}</small>
        </div>
      </div>
    </>)
  }

  const statusTemplate = (rowData) => {
    return (<>
      <div className="text-center">
        <div> {rowData?.status == "examination" ? "En cours d'examination" : ""} </div>
        <div><span className="badge badge-success">Valider</span></div>
        <div><span className="badge badge-danger">Regeter</span></div>
      </div>
    </>)
  }


  return (
    <>
      <AdminMainPageContainer
        icon="fas fa-satellite-dish"
        title="Gestion des données de couvertures par excel "
        subTitle="Gestion des données de couverture par excel"
        description="Gerer toutes les données de couverture."
        children={<>
          <div>
            <div className="card-shadow-primary card-border mb-2 profile-responsive card">

              <ul className="list-group list-group-flush">
                <li className="p-0 list-group-item">
                  <div className="grid-menu grid-menu-2col overflow-hidden">
                    <div className="no-gutters row">
                      <div className="col-md-6">
                        <button href="/admin-coverage-update-by-excel" className="btn-icon-vertical btn-square btn-transition br-br btn btn-outline-link text-success" onClick={() => handleDisplayExcelDialog()} >
                          <i className="fas fa-upload btn-icon-wrapper btn-icon-lg mb-3"></i>
                          Initier une mise à jour
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button className="btn-icon-vertical btn-square btn-transition br-bl btn btn-outline-link text-info" onClick={() => adminExcelTemplate()}>
                          <div>
                            <i className="fas fa-download btn-icon-lg mb-3"></i>
                            <i className="fas fa-file btn-icon-lg mb-3"></i>
                          </div>
                          Télécherger le model de fichier
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              {excelData?.length > 0 ? <>
                <DataTable
                  ref={dt}
                  value={excelData}
                  selection={selectedExcelData}
                  onSelectionChange={(e) => setSelectedExcelData(e.value)}
                  dataKey="code"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  globalFilter={globalFilter}
                  header={header}
                  tableStyle={{ minWidth: '50rem' }}
                  globalFilterFields={['locality_name']}
                  stripedRows
                  small
                  size="small"
                  showGridlines
                >
                  <Column selectionMode="multiple" exportable={false}></Column>
                  <Column field="code" header="Code" sortable style={{ width: '25%' }}></Column>
                  <Column header="Demandeur" sortable style={{ width: '25%' }} body={ownerTemplate}></Column>
                  <Column header="Date Soumission" sortable style={{ width: '25%' }} body={dateTemplate} ></Column>
                  <Column header="Status" sortable style={{ width: '25%' }} body={statusTemplate} ></Column>
                  <Column header="Actions" headerStyle={{ textAlign: 'right' }} bodyStyle={{ textAlign: 'end', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>
              </> : <>
                <div className="container px-1 px-md-5 px-lg-1 mbody">
                  <DataLoaderTailSpin />
                </div>
              </>}

            </div>
            <ImportLocalityExcelComponent isOpen={openCoverageExcelDialog} setIsOpen={setOpenCoverageExcelDialog} handleSubmit={handleSumit} fields={fields} />
            <AdminCoverageExcelDialog display={openExcelSubmitDialog} setDisplay={setOpenExcelSubmitDialog} data={data} setData={setData} handleSubmit={hahdleSubmitUpdate} showError={showError} response={response} isLoadDing={isLoadDing} />
          </div>
        </>} />
    </>
  );
};
