import { toastError } from "../../../../components/notification/notification"
import { userAxiosInstance } from "../../config/axiosConfig"


export const userLoginService = async(user) => {
    try{
        const {email,password} = user

        let response = await userAxiosInstance.post(`/userLogin`,{
            email:email,
            password:password,
        }).then(res => res) 

        if(response.status == 200) {
            console.log("Reponse de connexion",response.data)
            return response.data
        }else{
            toastError('Accès incorrect. Votre email ou mot de passe est incorrrect');
        }

        
        // await adminAxiosInstance.post(`/admin-login`,{
        //     email:email,
        //     password:password,
        // }).then((response) => {
        //     console.log(response.data)
        //     setSecureLocalStorageVariable(tokenAdminJwt,response.data.token)
        //     setSecureLocalStorageVariable(userAdmin,response.data.user)
        //     setState(response.data)
        // }
        // )
    } catch (error) {
        toastError('Veuillez vérifier votre connexion internet');
    }
}
export const logOutUserService = async(setState) => {
    try{

        let response = await userAxiosInstance.post(`/logout`).then(res => res) 

        if(response.status == 200) {
            console.log("Reponse de connexion",response.data)
            setState(response.data) 
        }else{
        }

        
        // await adminAxiosInstance.post(`/admin-login`,{
        //     email:email,
        //     password:password,
        // }).then((response) => {
        //     console.log(response.data)
        //     setSecureLocalStorageVariable(tokenAdminJwt,response.data.token)
        //     setSecureLocalStorageVariable(userAdmin,response.data.user)
        //     setState(response.data)
        // }
        // )
    } catch (error) {
        console.log("Erreur")
    }
}