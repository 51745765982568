import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './export.css'
import { DataLoaderTailSpin } from "../../loader/LoaderComponent";
import { getExportAllLocalities, getExportCoverageLocalities, getExportNoCoverageLocalities } from "../../../services/api/map/localitieApiService";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { dataToExport } from "../../../services/constants/map/mapDataConstants";
import { getExportDepartmentLocalities, getExportDistrictLocalities, getExportRegionLocalities, getExportSubPrefectureLocalities } from "../../../services/api/map/entitiesApiService";
import { localVariables } from "../../../services/local/SecureLocalStorageVariable";

export const ExportComponent = React.memo(({ display, setDisplay, level }) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [beginListen, setbeginListen] = useState(false);
  const [localities, setLocalities] = useState([]);
  const [exportData, setExportData] = useState();
  const [noAreaSelected, setNoAreaSelected] = useState(true);

  const [globalFilter, setGlobalFilter] = useState(null);
  const dt = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);
  const [enablePrint, setEnablePrint] = useState(false);

  const [title, setTitle] = useState();

  const tableRef = useRef();



  useEffect(() => {
    if (display) {
      findDataToExport()
      onClick();
    } else {
      onHide();
    }
  }, [display]);

  useEffect(() => {
    if (finishLoad) {
      setIsLoading(false);
      setFinishLoad(false)
    }
  }, [finishLoad]);

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    setbeginListen(false);
    setLocalities();
    setExportData();
    localVariables.setItem(dataToExport, null)
  };



  const cols = [
    { field: "code", header: "CODE" },//0
    { field: "name", header: "LOCALITE" },//0
    { field: "sub_pref", header: "SOUS-PREFECTURE" },//1
    { field: "depart", header: "DEPARTEMENT" },//2
    { field: "region", header: "REGION" },//3
    { field: "district", header: "DISTRICT" },//4
    { field: "pop", header: "POPULATION" },//4c
    { field: "coverage_2G_orange", header: "COUVERTURE 2G ORANGE" },//5
    { field: "coverage_2G_mtn", header: "COUVERTURE 2G MTN" },//6
    { field: "coverage_2G_moov", header: "COUVERTURE 2G MOOV" },//7
    { field: "coverage_3G_orange", header: "COUVERTURE 3G ORANGE" },//8
    { field: "coverage_3G_mtn", header: "COUVERTURE 3G MTN" },//9
    { field: "coverage_3G_moov", header: "COUVERTURE 3G MOOV" },//10
    { field: "coverage_4G_orange", header: "COUVERTURE 4G ORANGE" },//11
    { field: "coverage_4G_mtn", header: "COUVERTURE 4G MTN" },//12
    { field: "coverage_4G_moov", header: "COUVERTURE 4G MOOV" },//13
    // { field: "forecast_cov_data", header: "COUVERTURE DATA" },//13
    // { field: "forecast_cov_phone", header: "COUVERTURE TELEPHONIE" },//13
  ];



  const exportExcel = () => {
    let _exportLocs = [];
    localities.forEach(loc => {
      _exportLocs.push({
        "CODE":loc.code,
        "LOCALITE": loc.name,
        "SOUS-PREFECTURE":loc.sub_pref,
        "DEPARTEMENT":loc.depart,
        "REGION":loc.region,
        "DISTRICT":loc.district,
        "POPULATION":loc.pop,
        "COUVERTURE 2G ORANGE":loc.coverage_2G_orange,
        "COUVERTURE 2G MTN":loc.coverage_2G_mtn,
        "COUVERTURE 2G MOOV":loc.coverage_2G_moov,
        "COUVERTURE 3G ORANGE":loc.coverage_3G_orange,
        "COUVERTURE 3G MTN":loc.coverage_3G_mtn,
        "COUVERTURE 3G MOOV":loc.coverage_3G_moov,
        "COUVERTURE 4G ORANGE":loc.coverage_4G_orange,
        "COUVERTURE 4G MTN":loc.coverage_4G_mtn,
        "COUVERTURE 4G MOOV":loc.coverage_4G_moov,
      })
    });
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(_exportLocs);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci_cartodonnees_extract_localities");
    });
  };


  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };



  const exportCSV = () => {
    dt.current.exportCSV();
  };


  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const didParseCell = function (data) {
    if (data.column.dataKey == 0) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.name;
      }
    }
    if (data.column.dataKey == 1) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.sub_pref;
      }
    }
    if (data.column.dataKey == 2) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.depart;
      }
    }
    if (data.column.dataKey == 3) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.region;
      }
    }
    if (data.column.dataKey == 4) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.district;
      }
    }
    if (data.column.dataKey == 5) {
      if (data.cell.text == '') {
        data.cell.text = String(data.row.raw.pop);
      }
    }
    if (data.column.dataKey == 6) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_2G_orange === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 7) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_2G_mtn === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 8) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_2G_moov === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 9) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_3G_orange === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 10) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_3G_mtn === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 11) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_3G_moov === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 12) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_4G_orange === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 13) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_4G_mtn === 1 ? 'OUI' : 'NON';
      }
    } else if (data.column.dataKey == 14) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.coverage_4G_moov === 1 ? 'OUI' : 'NON';
      }
    }
    else if (data.column.dataKey == 15) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.forecast_cov_data === 1 ? 'OUI' : 'NON';
      }
    }else if (data.column.dataKey == 16) {
      if (data.cell.text == '') {
        data.cell.text = data.row.raw.forecast_cov_phone === 1 ? 'OUI' : 'NON';
      }
    }
    // if (data.column.dataKey == 5) {
    //   data.cell.text = data.cell.raw === true ? 'OUI' : 'NON';
    // }
  };

  const exportPdf = () => {
    setIsLoading(true);
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(
          {
            head: [cols.map((col) => col.header)],
            body: localities,
            headStyles: {
              fillColor: [255, 140, 0],
              fontSize: 4,
            },
            styles: {
              fontSize: 4,
            },
            didParseCell: didParseCell,
          }
        );
        doc.save("artci_cartodonnees_all.pdf");
        setFinishLoad(true)
      });
    });
  };

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrintTable = () => {
    handlePrint();
    setEnablePrint(true)
  }

  const findDataToExport = () => {
    if (level == 0) {
      setTitle('EXPORTER LES DONNEES DE LA ZONE SELECTIONNEE');
      const toExport = localVariables.getItem(dataToExport)
      if (toExport != null) {
        setNoAreaSelected(true)
        setExportData(toExport)
        if (toExport?.type == 0) {
          getExportDistrictLocalities(setLocalities, toExport?.id)
        } else if (toExport?.type == 1) {
          getExportRegionLocalities(setLocalities, toExport?.id)
        } else if (toExport?.type == 2) {
          getExportDepartmentLocalities(setLocalities, toExport?.id)
        } else if (toExport?.type == 3) {
          getExportSubPrefectureLocalities(setLocalities, toExport?.id)
        }
      }
    }
    else if (level == 1) {
      setTitle('EXPORTER TOUTES LES DONNES DE COUVETURE SUR LES LOCALITES');
      getExportAllLocalities(setLocalities);
    } else if (level == 2) {
      setTitle('EXPORTER UNIQUEMENT LES LOCALITES COUVERTES');
      getExportCoverageLocalities(setLocalities);
    } else if (level == 3) {
      setTitle('EXPORTER UNIQUEMENT LES LOCALITES NON COUVERTES');
      getExportNoCoverageLocalities(setLocalities);
    } else if (level == 4) {
      setTitle('EXPORTER UNIQUEMENT LES LOCALITES NON COUVERTES ET SANS PREVISION DE COUVERTURE');
    }
    else if (level == 5) {
      setTitle('PERSONNALISEZ VOTRE EXPORTATION');
    }
  }



  const header = () => {
    return (
      <>

        <h4><i className="fas fa-database mr-2"></i> {title} </h4>
        {level == 0 && exportData == null ? <>
        </> : <>
          {level == 0 && <>
            <div className="p-2 mt-2 mb-2 bg-light">
              <h5> <b>Zone selectionée / </b>
                {exportData?.data?.ADM0_FR?.length > 0 && <span>District: <b>{exportData?.data.ADM0_FR}</b>  </span>}
                {exportData?.data?.ADM1_FR?.length > 0 && <span> --&gt; Region: <b>{exportData?.data.ADM1_FR}</b> </span>}
                {exportData?.data?.ADM2_FR?.length > 0 && <span> --&gt; Department: <b>{exportData?.data.ADM2_FR}</b> </span>}
                {exportData?.data?.ADM3_FR?.length > 0 && <span> --&gt; Sous-préfecture: <b>{exportData?.data.ADM3_FR}</b> </span>}
              </h5>
            </div>
          </>}
          <div className=" bg-light w-100 p-2 mr-3">
            {/* Search and select START */}
            <div className="d-flex justify-content-between">
              {/* Search bar */}
              <div className=" d-flex justify-content-between">
                <button className="btn btn-sm btn-success mb-0 mr-2" onClick={() => exportExcel()}><i className="fa fa-file mr-2"></i> EXCEL</button>
                <button className="btn btn-sm btn-secondary mb-0 mr-2" onClick={() => exportCSV()}><i className="fa fa-file mr-2"></i> CSV</button>
                <button className="btn btn-sm btn-primary mb-0 mr-2" onClick={() => exportPdf()}><i className="fa fa-file mr-2"></i> PDF</button>
                <ReactToPrint
                  trigger={() => <button className="btn btn-sm btn-dark mb-0 mr-2"><i className="fa fa-file mr-2"></i> Imprimer uniquement la vue affichées </button>}
                  content={() => tableRef.current}
                  onBeforePrint={() => setEnablePrint(true)}
                  pageStyle={`@media print {body {
                      padding: 20px; /* Adjust the padding as needed */
                  } @page { size: 297mm 210mm;}};`}
                />
              </div>
              <div className="">
                <div className="rounded">
                  <input className="form-control bg-body " type="search" placeholder="Recherche..." aria-label="Search" onChange={(e) => setGlobalFilter(e.target.value)} />
                </div>
              </div>
            </div>
            {/* Search and select END */}
          </div>
        </>}
      </>
    );
  };

  const TemplateImageSuccess = () => {
    return (<>
      <div>
        <img src="../../images/icons/success.png" height={20} alt="" />
      </div>
    </>)
  }
  const TemplateImageNo = () => {
    return (<>
      <div>
        <img src="../../images/icons/cancel.png" height={20} alt="" />
      </div>
    </>)
  }

  const tmpCovOr2GOrange = (rowData) => {
    console.log("SELECTION ===========>",rowData)
    if (rowData.pop_cov_2G_orange > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr3GOrange = (rowData) => {
    if (rowData.pop_cov_3G_orange > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr4GOrange = (rowData) => {
    if (rowData.pop_cov_4G_orange > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr2GMtn = (rowData) => {
    if (rowData.pop_cov_2G_mtn > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr3GMtn = (rowData) => {
    if (rowData.pop_cov_3G_mtn > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr4GMtn = (rowData) => {
    if (rowData.pop_cov_4G_mtn > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr2GMoov = (rowData) => {
    if (rowData.pop_cov_2G_moov > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr3GMoov = (rowData) => {
    if (rowData.pop_cov_3G_moov > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovOr4GMoov = (rowData) => {
    if (rowData.pop_cov_4G_moov > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovData = (rowData) => {
    if (rowData.forecast_cov_data > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }
  const tmpCovTel = (rowData) => {
    if (rowData.forecast_cov_phone > 0) {
      return <TemplateImageSuccess />;
    } else {
      return <TemplateImageNo />
    }
  }

  return (
    <>
      <div className="stat">
        <Dialog
          header={header}
          visible={display}
          modal={true}
          onHide={() => onHide("")}
          style={{ width: "95vw", right: -10, backgroundColor: "white" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          position={position}
          className="mb-5"
          draggable={true}
        >
          {level == 0 && exportData == null ? <>
            <div className="">
              <div role="alert" className=" alert alert-info alert-dismissible fade show p-1">
                <div className="text-center">
                  <h1><i className="fa fa-xmark text-danger"></i></h1>
                  <h4>Aucune zone sélectionnée !</h4>
                </div>
              </div>
            </div>
          </> : <>
            {!noAreaSelected ? <>
              <div className="text-center">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i>
              </div>
            </> : <>
            </>}
            {level != 5 && <>
              {(!isLoading && localities?.length > 0) ? (
                <div ref={tableRef}>
                  <DataTable
                    ref={dt}
                    value={localities}
                    dataKey="code"
                    paginator={!enablePrint}
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} à {last} sur  {totalRecords}"
                    globalFilter={globalFilter}
                    tableStyle={{ minWidth: '50rem' }}
                    globalFilterFields={['name']}
                    size="small"
                    stripedRows
                    selectionMode="single"
                  >
                    <Column field="name" header="Localite" sortable style={{ fontSize: 10, }}></Column>
                    <Column field="sub_pref" header="Sous-prefecture" style={{ fontSize: 10, }}></Column>
                    <Column field="depart" header="Département" style={{ fontSize: 10, }}></Column>
                    <Column field="region" header="Region" style={{ fontSize: 10, }}></Column>
                    <Column field="district" header="District" style={{ fontSize: 10, }}></Column>
                    <Column field="pop" header="Population" style={{ fontSize: 10, }}></Column>
                    <Column field="coverage_2G_orange" header="Couverture 2G Orange" style={{ fontSize: 10, }} body={tmpCovOr2GOrange} ></Column>
                    <Column field="coverage_2G_mtn" header="Couverture 2G Mtn" style={{ fontSize: 10, }} body={tmpCovOr2GMtn}></Column>
                    <Column field="coverage_2G_moov" header="Couverture 2G Moov" style={{ fontSize: 10, }} body={tmpCovOr2GMoov}></Column>
                    <Column field="coverage_3G_orange" header="Couverture 3G Orange" style={{ fontSize: 10, }} body={tmpCovOr3GOrange}></Column>
                    <Column field="coverage_3G_orange" header="Couverture 3G Mtn" style={{ fontSize: 10, }} body={tmpCovOr3GMtn}></Column>
                    <Column field="coverage_3G_orange" header="Couverture 3G Moov" style={{ fontSize: 10, }} body={tmpCovOr3GMoov}></Column>
                    <Column field="coverage_4G_orange" header="Couverture 4G Orange" style={{ fontSize: 10, }} body={tmpCovOr4GOrange}></Column>
                    <Column field="coverage_4G_orange" header="Couverture 4G Mtn" style={{ fontSize: 10, }} body={tmpCovOr4GMtn}></Column>
                    <Column field="coverage_4G_orange" header="Couverture 4G Moov" style={{ fontSize: 10, }} body={tmpCovOr4GMoov}></Column>
                    {/* <Column field="coverage_2G_orange" header="Couverture service data" style={{ fontSize: 10, }} body={tmpCovData}></Column>
                    <Column field="coverage_2G_orange" header="Couverture service téléphonie" style={{ fontSize: 10, }} body={tmpCovTel}></Column> */}
                  </DataTable>
                </div>
              ) : (
                <>
                  <div className="container px-1 px-md-5 px-lg-1 mbody">
                    <DataLoaderTailSpin />
                  </div>
                </>
              )}
            </>}

          </>}

        </Dialog>
      </div>
    </>
  );
});

export default ExportComponent;
