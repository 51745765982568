import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { strUppercCase } from "../../../../services/conversion/stringConversion";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";

export const AdminFiberOpticDialog = ({
  display,
  setDisplay,
  fiberOptic,
  fiberOptics,
  localities,
  setFiberOptic,
  title,
  handleSumitFiberOptic,
  onInputChange,
}) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);
  const [locals, setLocals] = useState();

  const [loadData1, setloadData1] = useState(false);
  const [loadData2, setloadData2] = useState(false);
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [search1, setSearch1] = useState();
  const [search2, setSearch2] = useState();
  const [isSelected1, setisSelected1] = useState(false);
  const [isSelected2, setisSelected2] = useState(false);
  const [isBegin, setIsBegin] = useState(false);

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }

    let _fiberOptic = { ...fiberOptic };

    _fiberOptic["locality_satrt_id"] = fiberOptic?.locality_start?.id;
    _fiberOptic["locality_end_id"] = fiberOptic?.locality_end?.id;
    setSearch1(fiberOptic?.locality_start?.name)
    setSearch2(fiberOptic?.locality_end?.name)
    setIsBegin(true)
    setFiberOptic(_fiberOptic);
  };

  const onHide = (name) => {
    setDisplay(false);
    // handleInitVar()
  };

  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);


  useEffect(() => {
    if (localities?.length > 0) {
      let l = Object.entries(localities).map(([key, value]) => ({
        key,
        id: value.id,
        name: strUppercCase(value.name),
      }));
      setData1(l);
      setData2(l);
      setLocals(l);
    }
  }, [localities]);

  const handleSearch1 = (value) => {
    setloadData1(true);
    setSearch1(value);
    setisSelected2(true);
    setIsBegin(false)
  };
  const handleSearch2 = (value) => {
    setloadData2(true);
    setSearch2(value);
    setisSelected1(true);
    setIsBegin(false)
  };

  useEffect(() => {
    if (loadData1 == true) {
      if (search1) {
        if (search1?.length > 2) {
          if (locals?.length > 0) {
            let l = locals.filter((l) =>
              l.name.includes(strUppercCase(search1))
            );
            setData1(l);
          }
        } else {
          setData1();
        }
      }

      setTimeout(() => {
        setloadData1(false);
      }, 500);
    }
  }, [loadData1]);

  useEffect(() => {
    if (loadData2 == true) {
      if (search2) {
        if (search2?.length > 2) {
          if (locals?.length > 0) {
            let l = locals.filter((l) =>
              l.name.includes(strUppercCase(search2))
            );
            setData2(l);
          }
        } else {
          setData2();
        }
      }

      setTimeout(() => {
        setloadData2(false);
      }, 500);
    }
  }, [loadData2]);

  const handleInitSearch1 = () => {
    setSearch1();
  };
  const handleInitSearch2 = () => {
    setSearch2();
  };

  const handleSelected1 = (id, value) => {
    let _fiberOptic = { ...fiberOptic };

    _fiberOptic["locality_satrt_id"] = id;

    setFiberOptic(_fiberOptic);

    setSearch1(value);
    setisSelected1(true);
  };

  const handleSelected2 = (id, value) => {
    let _fiberOptic = { ...fiberOptic };

    _fiberOptic["locality_end_id"] = id;

    setFiberOptic(_fiberOptic);

    setSearch2(value);
    setisSelected2(true);
  };

  return (
    <>
      <Dialog
        header={title}
        visible={display}
        modal
        style={{ width: "50vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
        {locals?.length > 0 ? (
          <>
            <form onSubmit={handleSumitFiberOptic}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div tabIndex="-1" role="group">
                      <label htmlFor="exampleEmail">Depart</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Rechercher une localité..."
                          onChange={(e) => {
                            handleSearch1(e.target.value);
                          }}
                          value={search1}
                        />
                        <a
                          className="input-group-append"
                          onClick={() => handleInitSearch1()}
                        >
                          <span className="input-group-text" id="basic-addon2">
                            {loadData1 && (
                              <ProgressSpinner
                                style={{ width: "50px", height: "26px" }}
                                strokeWidth="8"
                                fill="var(--surface-ground)"
                                animationDuration=".5s"
                              />
                            )}
                            <i className="fas fa-times"></i>
                          </span>
                        </a>
                      </div>
                      {(!isSelected1 && !isBegin) && (
                        <>
                          {search1?.length > 2 && (
                            <ul className="list-group list-search1">
                              {data1?.length > 0 ? (
                                data1.map((l, index) => {
                                  return (
                                    <li
                                      className="list-group-item text-left"
                                      onClick={() => {
                                        handleSelected1(l.id, l.name);
                                      }}
                                      key={"l-" + index}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {l.name}
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="list-group-item text-center text-danger">
                                  Aucune correspondance trouvé !
                                </li>
                              )}
                            </ul>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <div tabIndex="-1" role="group">
                      <label htmlFor="exampleEmail">Arrivée</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Rechercher une localité..."
                          onChange={(e) => {
                            handleSearch2(e.target.value);
                          }}
                          value={search2}
                        />
                        <a
                          className="input-group-append"
                          onClick={() => handleInitSearch2()}
                        >
                          <span className="input-group-text" id="basic-addon2">
                            {loadData2 && (
                              <ProgressSpinner
                                style={{ width: "50px", height: "26px" }}
                                strokeWidth="8"
                                fill="var(--surface-ground)"
                                animationDuration=".5s"
                              />
                            )}
                            <i className="fas fa-times"></i>
                          </span>
                        </a>
                      </div>
                      {(!isSelected2 && !isBegin) && (
                        <>
                          {search2?.length > 2 && (
                            <ul className="list-group list-search1">
                              {data2?.length > 0 ? (
                                data2.map((l, index) => {
                                  return (
                                    <li
                                      className="list-group-item text-left"
                                      onClick={() => {
                                        handleSelected2(l.id, l.name);
                                      }}
                                      key={"l-" + index}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {l.name}
                                    </li>
                                  );
                                })
                              ) : (
                                <li className="list-group-item text-center text-danger">
                                  Aucune correspondance trouvé !
                                </li>
                              )}
                            </ul>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div tabIndex="-1" role="group">
                      <label htmlFor="exampleEmail">Distance</label>
                      <input
                        id="exampleEmail"
                        name="distance"
                        type="number"
                        placeholder="Entrer la distance du trançon..."
                        className="form-control"
                        value={fiberOptic?.distance}
                        onChange={(e) => onInputChange(e, "distance")}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div tabIndex="-1" role="group">
                      <label htmlFor="exampleEmail">Description</label>
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="5"
                        className="form-control"
                        value={fiberOptics?.description}
                        placeholder="Entrer une description du district"
                        onChange={(e) => onInputChange(e, "description")}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="modal-footer pt-3 d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-dark my-0"
                    onClick={() => onHide()}
                  >
                    Fermer
                  </button>
                  <button type="submit" className="btn btn-primary my-0">
                    <i className="fa fa-save mr-2"></i> Enregistrer le trançon
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="container px-1 px-md-5 px-lg-1 mbody">
              <DataLoaderTailSpin />
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};
