import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { getDepartmentSubPrefectures, getDistrictRegions, getRegionDepartments } from "../../../services/api/map/entitiesApiService";
import ReactToPrint from "react-to-print";
import { handleNumThousand } from "../../../services/tools/convertions";
import { DataLoaderTailSpin } from "../../loader/LoaderComponent";




export const StatisticsDetailsModalComponent = ({ display, setDisplay, entity, setEntity, technologies, operators, generalData }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [entityData, setEntityData] = useState(null);

    const entityContentRef = useRef(null)

    let totalData = {
        nbLoc: 0,
        nbLoc2G: 0,
        nbLoc3G: 0,
        nbLoc4G: 0,
        nbNoLoc: 0
    }



    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = () => {
        console.log("ENTITE ++++>", entity);
        if (entity?.type == 1) {
            getDistrictRegions(setEntityData, entity?.data.ADM0_PCODE)
        } else if (entity?.type == 2) {
            getRegionDepartments(setEntityData, entity?.data.ADM1_PCODE)
        }else if(entity?.type == 3){
            getDepartmentSubPrefectures(setEntityData, entity?.data.ADM2_PCODE)
        }
        setDisplay(true);
        if (position) {
            setPosition(position);
        }
    };
    const onHide = (name) => {
        setEntityData(null);
        setEntity(null);
        setEntityData(null);
        setDisplay(false);
        // handleInitVar()
    };

    const header = () => {
        return (
            <>
                <h4><i className="fas fa-table mr-2"></i>Détails tableau statistique {entity?.type == 1 ? <>region: <b>{entity?.name}</b></> : ''}  </h4>
            </>
        );
    };

    return (<>
        <Dialog
            header={header}
            visible={display}
            modal={true}
            onHide={() => onHide("")}
            style={{ width: "90vw" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            position={position}
            className="mb-5"
            draggable={true}
        >
            <div
            >
                {!entityData ? <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                </> : <>
                <div className="d-flex justify-content-end">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-dark mb-0 mr-2"><i className="fa fa-print mr-2"></i>  IMPRIMER LE TABLEAU</button>}
                        content={() => entityContentRef.current}
                        // onBeforePrint={()=> {!district?.description?.length > 0 && setIsPrinting(true)}}
                        // onAfterPrint={() => setIsPrinting(false)}
                        copyStyles={true}
                        pageStyle={`@media print {body {
                            padding: 20px; /* Adjust the padding as needed */
                        } @page { size: 270 210mm;}};`}
                    />
                </div>

                <div className="" ref={entityContentRef}>
                    <div>
                        <div className="pt-3 pb-2 bwd">
                            <h4 className=""><u><em>
                                 {entity?.type == 1 && <>District de {entity?.data?.ADM0_FR} </>}
                                 {entity?.type == 2 && <>Région de {entity?.data?.ADM1_FR} </>}
                                 {entity?.type == 3 && <>Département de {entity?.data?.ADM2_FR} </>}
                            </em></u> </h4>
                        </div>
                        <table className="table w-100 table-sm table-bordered  table-striped table-head-fixed">
                            <thead className="bg-dark text-white">
                                <tr>
                                    <th rowSpan={4} className="text-center" ><small><b>
                                        {entity?.type == 1 && "REGION"}
                                        {entity?.type == 2 && "DEPARTEMENT"}
                                        {entity?.type == 3 && "SOUS-PREFECTURE"}
                                    </b></small> </th>
                                </tr>
                                <tr>
                                    <th colSpan={technologies?.length * 2 + 3} className="text-center"><small> <b>LOCALITE</b> </small></th>
                                    <th colSpan={technologies?.length * 2 + 3} className="text-center"> <small><b></b></small> POPULATION</th>
                                </tr>
                                <tr>
                                    <th rowSpan={2}><small><b>TOTAL</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>COUVERTE</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>NON-COUVERTE</b></small></th>
                                    <th rowSpan={2}><small><b>TOTAL</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>COUVERTE</b></small></th>
                                    <th colSpan={technologies?.length + 1} className="text-center"><small><b>NON-COUVERTE</b></small></th>
                                </tr>
                                <tr>
                                    {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th><small style={{ fontSize: 10 }}><b>SYNTHESE</b></small></th>
                                    {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th><small style={{ fontSize: 10 }}><b>SYNTHESE</b></small></th>
                                    {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th><small style={{ fontSize: 10 }}><b>SYNTHESE</b></small></th>
                                    {technologies && technologies.map((technology, index) => {
                                        return (
                                            <th key={'dt0' + index} className="text-center"><small><b>{technology?.name}</b></small> </th>
                                        )
                                    })}
                                    <th><small style={{ fontSize: 10 }}><b>SYNTHESE</b></small></th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityData && entityData.map((d, index) => {
                                    return (<tr key={"d1" + index}>
                                        <td className="border bwd" style={{ width: 130 }}>
                                            {entity?.type == 1 && <small><b>{d?.properties?.ADM1_FR}</b></small>}
                                            {entity?.type == 2 && <small><b>{d?.properties?.ADM2_FR}</b></small>}
                                            {entity?.type == 3 && <small><b>{d?.properties?.ADM3_FR}</b></small>}
                                        </td>
                                        <td className="border text-center"> <small>{handleNumThousand(d?.properties?.nbloc)}</small>  </td>
                                        {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt2' + index} className="border text-center">
                                                    <small><b>{d?.properties?.['locCouv' + technology?.name]}</b></small>
                                                    <div>
                                                        <small>
                                                            {((d?.properties?.['locCouv' + technology?.name] * 100) / d?.properties?.nbloc).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.nbcouvloc)}</b></small>
                                            <div>
                                                <small>
                                                    {d?.properties?.txcouvloc.toFixed(2)} %
                                                </small>
                                            </div>
                                        </td>
                                        {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt3' + index} className="text-center">
                                                    <small><b>{d?.properties?.nbloc - d?.properties?.['locCouv' + technology?.name]}</b></small>
                                                    <div>
                                                        <small>
                                                            {(((d?.properties?.nbloc - d?.properties?.['locCouv' + technology?.name]) * 100) / d?.properties?.nbloc).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.nbnocouvloc)}</b></small>
                                            <div>
                                                <small>
                                                    {((d?.properties?.nbnocouvloc * 100) / d?.properties?.nbloc).toFixed(2)} %
                                                </small>
                                            </div>
                                        </td>
                                        <td className="border text-center"><small>{handleNumThousand(d?.properties?.population)}</small>   </td>
                                        {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt2' + index} className="border text-center">
                                                    <small><b>{handleNumThousand(d?.properties?.['pop' + technology?.name])}</b></small>
                                                    <div>
                                                        <small>
                                                            {((d?.properties?.['pop' + technology?.name] * 100) / d?.properties?.population).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.popcouv)}</b></small>
                                            <div>
                                                <small>
                                                    {d?.properties?.txpopcouv.toFixed(2)} %
                                                </small>
                                            </div>
                                        </td>
                                        {technologies && technologies.map((technology, index) => {
                                            return (
                                                <td key={'dt3' + index} className="border text-center">
                                                    <small><b>{d?.properties?.population - d?.properties?.['pop' + technology?.name]}</b></small>
                                                    <div>
                                                        <small>
                                                            {(((d?.properties?.population - d?.properties?.['pop' + technology?.name]) * 100) / d?.properties?.population).toFixed(2)} %
                                                        </small>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">
                                            <small><b>{handleNumThousand(d?.properties?.nopopcouv)}</b></small>
                                            <div>
                                                <small>
                                                    {((d?.properties?.nopopcouv * 100) / d?.properties?.population).toFixed(2)} %
                                                </small>
                                            </div>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        {/* <p>*Nombre de localités total : <b>{handleNumThousand(entity?.nbLoc)}</b> </p> */}
                        <p>*Population totale (source RGPH 2014) : <b>{handleNumThousand(entity?.data.population)}</b>  </p>
                    </div>
                </div>
                </>}
            </div>

        </Dialog>
    </>)
}