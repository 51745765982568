import { AdminMainPageContainer } from "../../../AdminMainPageContainer";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AdminCategoryList } from "./AdminCategoryList";
import {DeleteCategoryDialog, DeleteSCategoryDialog 
} from "./AdminCategoryDeleteDialog";
import {
  addCategory,
  deleteCategory,
  editCategory,
  getCategories,
} from "../../../../../services/api/admin/newsLetter/categories/categoryApiService";
import { AdminCategoryDialog } from "./AdminCategoryDialog";
import { getLocalities } from "../../../../../services/api/admin/adminEntities/locality/localityApiService";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";
import { toastError, toastSuccess } from "../../../../../components/notification/notification";

export const AdminCategoryPage = () => {
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(null);

  const [categoryDialogTitle, setCategoriesDialogTitle] = useState(
    "CREATION D'UNE CATEGORIE DE NOUVELLE"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const [CategoryDialogTitle, setCategoryDialogTitle] = useState(
    "CREATION D'UNE CATEGORIE DE NOUVELLES"
  );

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getCategories(setCategories);
  }, []);

  useEffect(() => {
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de la catégorie effectuée avec succès!");
      getCategories(setCategories);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement de la catégorie !");
    }
    setOpenCategoryDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification de la catégorie effectuée avec succès!");
      getCategories(setCategories);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification d'une catégorie !");
    }
    setOpenCategoryDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression de la catégorie effectué avec succès!");
      getCategories(setCategories);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression de la catégorie !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setCategory();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setCategoryDialogTitle("CREATION D'UNE CATEGORIE DE NOUVELLES");
    setSelectedCategories();
    setOpenCategoryDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _category = { ...category };

    _category[`${name}`] = val;

    setCategory(_category);
  };

  const handleSubmitCategory = async (e) => {
    e.preventDefault();
    if (category.id) {
      await editCategory(category, setEditResponse);
    } else {
      await addCategory(category, setCreateResponse);
    }
  };

  //EDITION
  const handleEditCategory = (fiber) => {
    setCategoryDialogTitle("MODIFICATION D'UNE CATEGORIE DE NOUVELLES");
    setCategory({ ...fiber });
    setOpenCategoryDialog(true);
  };

  //SUPPRESSION
  const handleDeleteCategoryDialog = (fiber) => {
    setCategory({ ...fiber });
    setOpenDeleteDialog(true);
  };

  const handleDeleteCategory = async () => {
    await deleteCategory(category, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteCategories = async () => {
    try {
      selectedCategories.forEach(async (category) => {
        await deleteCategory(category, setDeleteResponse);
      });
    } catch (error) {
      toastError(
        "Erreur lors de la suppression des fonctionnalités selectionnés !"
      );
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "code" },
    { field: "name", header: "nom" },
    { field: "description", header: "description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, categories);
        doc.save("artci-cartodonnees-extract-category-news.pdf");
      });
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    { field: "code", header: "Code" },
    { field: "name", header: "Name" },
    { field: "category", header: "Category" },
    { field: "quantity", header: "Quantity" },
  ];

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
                icon="fas fa-newspaper"
                title="Gestion des catégories des nouvelles"
                subTitle="Gestion des catégories de nouvelles"
                description="Creer, modifier, alerter ou supprimer des catégories de nouvelles "
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des catégories de nouvelles
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenCategoryDialog(true);
                        setCategory();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer une categorie de nouvelles
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {categories ? <>
                      <AdminCategoryList
                        categories={categories}
                        handleEditCategory={handleEditCategory}
                        handleDeleteCategoryDialog={
                          handleDeleteCategoryDialog
                        }
                        setopenDeleteSelected={setopenDeleteSelected}
                        exportCSV={exportCSV}
                        exportPDF={exportPdf}
                        dt={dt}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                      />
                      </> : <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                      </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminCategoryDialog
              display={openCategoryDialog}
              setDisplay={setOpenCategoryDialog}
              handleSumitCategory={handleSubmitCategory}
              category={category}
              title={categoryDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
            />
            <DeleteCategoryDialog
              display={openDeleteSelected}
              setDisplay={ setopenDeleteSelected}
              handleDeletecategories={handleDeleteCategories}
              category={category}
            />
            <DeleteSCategoryDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeleteCategory={handleDeleteCategory}
              category={category}
            />
          </>
        }
      />
    </>
  );
};
