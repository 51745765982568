import { useState, useEffect, useRef } from "react";


import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ToggleButton } from "primereact/togglebutton";
import { strLowerCase } from "../../../../services/tools/convertions";

export const OperatorCoverageLocalityList = ({ localities,handleViewLocality, handleEditLocality, exportCSV, exportPDF,exportExcel, dt, selectedLocalities, setSelectedLocalities, setopenDeleteSelected, technologies,operator }) => {

    const [globalFilter, setGlobalFilter] = useState(null);

    const [grid, setGrid] = useState(false);

    const [balanceFrozen, setBalanceFrozen] = useState(false);


    const header = () => {
        return (
            <>
                <div className=" bg-light w-100 p-2">
                    {/* Search and select START */}
                    <div className="d-flex justify-content-between">
                        {/* Search bar */}
                        <div className=" d-flex justify-content-between">
                            <button className="btn btn-sm btn-success mb-0 mr-2" onClick={() => exportExcel()}><i className="fa fa-file mr-2"></i> Excel</button>
                            <button className="btn btn-sm btn-secondary mb-0 mr-2" onClick={() => exportCSV()}><i className="fa fa-file mr-2"></i> CSV</button>
                            <button className="btn btn-sm btn-warning mb-0 mr-2" onClick={() => exportPDF()}><i className="fa fa-file mr-2"></i> PDF</button>
                            {/* <button className="btn btn-sm btn-danger mb-0" onClick={() => { (selectedLocalities && selectedLocalities.length) > 0 && setopenDeleteSelected(true) }}><i className="fa fa-trash mr-2" ></i> Supprimer plusieurs sélections </button> */}
                        </div>
                        <div className="">
                            <div className="rounded position-relative">
                                <input className="form-control bg-body" type="search" placeholder="Recherche..." aria-label="Search" onChange={(e) => setGlobalFilter(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    {/* Search and select END */}
                </div>
            </>
        );
    };


    const actionBodyTemplate = (rowData) => {
        return (<>
            <div className="d-flex justify-content-end">
                {/* <button className="btn btn-info btn-round mr-2 mb-0" onClick={() => handleViewLocality(rowData)}><i className="far fa-eye"></i></button> */}
                <button className="btn btn-primary btn-round mr-2 mb-0" onClick={() => handleEditLocality(rowData)}><i className="far fa-edit"></i></button>
                {/* <button className="btn btn-danger btn-round mb-0" onClick={() => handleDeleteLocalityDialog(rowData)} ><i className="fas fa-trash"></i></button> */}
            </div>
        </>)
    };


    const covTemplate = (rowData) => {
        return(<>
            <div className="text-center">
                {rowData?.["forecast_cov_"+strLowerCase(operator?.name)] ? <>
                <img src="../../images/icons/success.png" height={20} alt="" />
                </> : <>
                <img src="../../images/icons/cancel.png" height={20} alt="" /></>}
            </div>
        </>)
    }
    const cov2GTemplate = (rowData) => {
        return(<>
            <div className="text-center">
                {rowData?.["coverage_2G_"+strLowerCase(operator?.name)] ? <>
                <img src="../../images/icons/success.png" height={20} alt="" />
                </> : <>
                <img src="../../images/icons/cancel.png" height={20} alt="" /></>}
            </div>
        </>)
    }
    const cov3GTemplate = (rowData) => {
        return(<>
            <div className="text-center">
                {rowData?.["coverage_3G_"+strLowerCase(operator?.name)] ? <>
                <img src="../../images/icons/success.png" height={20} alt="" />
                </> : <>
                <img src="../../images/icons/cancel.png" height={20} alt="" /></>}
            </div>
        </>)
    }
    const cov4GTemplate = (rowData) => {
        return(<>
            <div className="text-center">
                {rowData?.["coverage_4G_"+strLowerCase(operator?.name)] ? <>
                <img src="../../images/icons/success.png" height={20} alt="" />
                </> : <>
                <img src="../../images/icons/cancel.png" height={20} alt="" /></>}
            </div>
        </>)
    }

    const dataTemplate = (rowData) => {
        return(<>
            <div className="text-center">
                {rowData?.["coverage_data_"+strLowerCase(operator?.name)] ? <>
                <img src="../../images/icons/success.png" height={20} alt="" />
                </> : <> {rowData?.["coverage_data_"+strLowerCase(operator?.name)] != null ? <><img src="../../images/icons/cancel.png" height={20} alt="" /></> : <></> }
                </>
                }
            </div>
        </>)
    }

    const telTemplate = (rowData) => {
        return(<>
            <div className="text-center">
                {rowData?.["coverage_phone_"+strLowerCase(operator?.name)] ? <>
                <img src="../../images/icons/success.png" height={20} alt="" />
                </> : <>
                {rowData?.["coverage_phone_"+strLowerCase(operator?.name)] != null ? <><img src="../../images/icons/cancel.png" height={20} alt="" /></> : <></>}
                </>}
            </div>
        </>)
    }


    return (<>
        {localities?.length > 0  && <>
                <DataTable
                    ref={dt}
                    value={localities}
                    selection={selectedLocalities}
                    onSelectionChange={(e) => setSelectedLocalities(e.value)}
                    dataKey="code"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    globalFilter={globalFilter}
                    header={header}
                    tableStyle={{ minWidth: '50rem' }}
                    globalFilterFields={['name']}
                    scrollable 
                    size={"small"}
                >
                    <Column field="code" header="Code" sortable></Column>
                    <Column field="name" header="Nom" sortable></Column>
                    <Column field="sub_pref" header="Sous-prefecture" sortable ></Column>
                    <Column field="pop" header="Population" headerStyle={{ textAlign: 'center' }} sortable></Column>
                    <Column field={"forecast_cov_"+strLowerCase(operator?.name)} header="Synthèse couverture" sortable  body={covTemplate}></Column>
                    <Column field={"forecast_pop_cov_"+strLowerCase(operator?.name)} header="Population Couverte" headerStyle={{ textAlign: 'center' }} sortable></Column>
                    <Column field={"coverage_2G_"+strLowerCase(operator?.name)} header={"Couverture 2G"} headerStyle={{ textAlign: 'center' }} style={{minWidth:"170px"}} sortable  body={cov2GTemplate}></Column>
                    <Column field={"coverage_3G_"+strLowerCase(operator?.name)} header={"Couverture 3G"} headerStyle={{ textAlign: 'center' }} style={{minWidth:"170px"}} sortable  body={cov3GTemplate}></Column>
                    <Column field={"coverage_4G_"+strLowerCase(operator?.name)} header={"Couverture 4G"} headerStyle={{ textAlign: 'center' }} style={{minWidth:"170px"}} sortable  body={cov4GTemplate}></Column>
                    <Column field={"coverage_data_"+strLowerCase(operator?.name)} header="Data" headerStyle={{ textAlign: 'center' }} sortable  body={dataTemplate}></Column>
                    <Column field={"coverage_phone_"+strLowerCase(operator?.name)} header="Téléphone" headerStyle={{ textAlign: 'center' }} sortable  body={telTemplate}></Column>
                    <Column field={"pop_cov_2G_"+strLowerCase(operator?.name)} header={"Population 2G"} headerStyle={{ textAlign: 'center' }} style={{minWidth:"170px"}}></Column>
                    <Column field={"pop_cov_3G_"+strLowerCase(operator?.name)} header={"Population 3G"} headerStyle={{ textAlign: 'center' }} style={{minWidth:"170px"}}></Column>
                    <Column field={"pop_cov_4G_"+strLowerCase(operator?.name)} header={"Population 4G"} headerStyle={{ textAlign: 'center' }} style={{minWidth:"170px"}}></Column>
                    <Column header="Actions" alignFrozen="right" frozen={true} headerStyle={{ textAlign: 'right' }} bodyStyle={{ textAlign: 'end', overflow: 'visible' }} body={actionBodyTemplate} />
                    
                </DataTable>
        </>
        }
    </>)
}