import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import { getLocalitiesByGeoData } from "../../../../../services/api/admin/adminEntities/locality/localityApiService";
import { getOperators } from "../../../../../services/api/admin/operators/operatorApiService";
import { getTechnologies } from "../../../../../services/api/admin/technologies/technologyApiService";
import { handleNumThousand } from "../../../../../services/tools/convertions";
import { baseFileUrl } from "../../../../../constants";
import ReactToPrint from "react-to-print";


export const AdminLocalityVIewDialog = ({ display, setDisplay, locality }) => {
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [position, setPosition] = useState("center");
    const [max, setMax] = useState(true);

    const [localLocality, setLocalLocality] = useState();
    const [operators, setOperators] = useState();
    const [technologies, setTechnologies] = useState();

    const [isPrinting, setIsPrinting] = useState(false);


    const contentRef = useRef();
    const tableRef = useRef();

    useEffect(() => {
        getOperators(setOperators)
        getTechnologies(setTechnologies)
    }, []);


    useEffect(() => {
        if (display) {
            onClick();
        } else {
            onHide();
        }
    }, [display]);

    const onClick = (position) => {
        setDisplay(true);
        handleGetLocalitiessData(locality?.id)
        if (position) {
            setPosition(position);
        }
    };

    const onHide = (name) => {
        setDisplay(false);
        // handleInitVar()
    };

    const handleGetLocalitiessData = async (geo_id) => {
        await getLocalitiesByGeoData(setLocalLocality, geo_id)
    }


    return (<>
        <Dialog
            header="DETAILS LOCALITES"
            visible={display}
            modal
            style={{ width: "75vw" }}
            onHide={() => onHide("displayMaximizable")}
            position="center"
            maximizable={max}
        >
            <div className="mb-2">
                <ReactToPrint
                    trigger={() => <button className="btn btn-dark btn-block mb-0 mr-2"><i className="fa fa-print mr-2"></i> Imprimer</button>}
                    content={() => contentRef.current}
                    onBeforePrint={() => { !locality?.description?.length > 0 && setIsPrinting(true) }}
                    onAfterPrint={() => setIsPrinting(false)}
                    pageStyle={`@media print {body {
                        padding: 20px; /* Adjust the padding as needed */
                      } @page { size: 450mm 250mm;}};`}
                />
                <hr />
            </div>
            <div className="" ref={contentRef}>
                <div className="p-0">
                    <div>
                        <h5>Sous-préfecture : <b>{locality?.subPrefeture?.name}</b></h5>
                        <h4>Nom de la localité : <b>{locality?.name}</b></h4>
                        {!isPrinting && <>
                                {locality?.description?.length > 0 && <>
                            <div className="bg-light">
                                    <p>{locality?.description} </p>
                            </div>
                                </>}
                        </>}
                    </div>
                    <div>
                    </div>
                </div>
                <div className="bg-light">
                    <div class="">
                        <div class="no-gutters row">
                            <div class="col-md-6 ">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning"><i className="fa fa-location"></i></div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Longitude</div>
                                            <div class="widget-subheading">{locality?.longitude}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning"><i className="fa fa-location"></i></div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Latitude</div>
                                            <div class="widget-subheading">{locality?.latitude}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            {locality?.fialbility ? <>
                                                <div class="widget-numbers text-success"><i className="fa fa-check"></i></div>
                                            </> : <>
                                                <div class="widget-numbers text-danger"><i className="fa fa-xmark"></i></div>
                                            </>}
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Fiabilité</div>
                                            <div class="widget-subheading"></div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div class="col-md-6">
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-dark"><small>{handleNumThousand(localLocality?.pop)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-warning"><small>{handleNumThousand(localLocality?.popCov)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population couverte</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-content">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-right ml-0 mr-3">
                                            <div class="widget-numbers text-danger"><small>{handleNumThousand(localLocality?.popCov - localLocality?.popCov)}</small> </div>
                                        </div>
                                        <div class="widget-content-left">
                                            <div class="widget-heading">Population non couverte</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {localLocality && <>
                <div className="mt-3 w-100">
                    <table className="table table-bordered table-striped table-hover table-width">
                        <thead>
                            <tr>
                                <th>Opérateur</th>
                                <th colSpan={technologies?.length * 2} className="text-center">Population</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th colSpan={technologies?.length} className="text-center">Couverte</th>
                                <th colSpan={technologies?.length} className="text-center">Non couverte</th>
                            </tr>
                            <tr>
                                <th></th>
                                {technologies?.length > 0 && technologies?.map((technology, index) => {
                                    return (
                                        <th key={"t2" + index} className="text-center"> {technology?.name} </th>
                                    )
                                })}
                                {technologies?.length > 0 && technologies?.map((technology, index) => {
                                    return (
                                        <th key={"t3" + index} className="text-center"> {technology?.name} </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {operators?.length > 0 && operators?.map((operator, index) => {
                                return (
                                    <tr key={"ot" + index}>
                                        <td>
                                            <img src={baseFileUrl + operator.image_path} className="mr-2" height={30} alt="" />
                                            <b>{operator?.name}</b> </td>
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            return (
                                                <td key={"tt3" + index}> {handleNumThousand(localLocality["pop" + operator.name + technology.name])} <br />
                                                    <small> -> {(localLocality["pop" + operator.name + technology.name] > 0 ? (localLocality["pop" + operator.name + technology.name] * 100 / localLocality?.pop).toFixed(1) : 0)} %</small>
                                                </td>
                                            )
                                        })}
                                        {technologies?.length > 0 && technologies?.map((technology, index) => {
                                            return (
                                                <td key={"tt4" + index}> {handleNumThousand(localLocality.pop - localLocality["pop" + operator.name + technology.name])}<br />
                                                    <small> -> {(localLocality["pop" + operator.name + technology.name] > 0 ? ((localLocality.pop - localLocality["pop" + operator.name + technology.name]) * 100 / localLocality?.pop).toFixed(1) : 0)} %</small>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            <tr>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </>}
            </div>

        </Dialog>
    </>)
}