import { userOperator } from "../../../../../constants";
import { localVariables } from "../../../../local/SecureLocalStorageVariable";
import { operatorAxiosInstance } from "../../../config/axiosConfig";




export const addOperatorCoveragesList = async(coverages,setState) =>{
    try {
        const us = localVariables.getItem(userOperator);
        const response1 = await operatorAxiosInstance.post('excel-files',{
            owner_id: us?.id,
        }).then((res => res));

        let errors = [];

        if (response1.status == 201) {
            if (response1?.data.error == false) {
                const e_data = response1?.data;
                try {
                    const response = await operatorAxiosInstance.post(`/buffer-operator-coverages`, {
                        user_id: us?.id,
                        e_code_id: e_data?.id,
                        coverages,

                    }).then((res => res));
                    console.log('OK77777 ====>', response)
                    // await coverages.forEach(async data => {
                    //     console.log('OK6 ====>',  data)

                    //     const response = await operatorAxiosInstance.post(`/buffer-operator-coverages`, {
                    //         user_id: us?.id,
                    //         e_code_id: e_data?.id,
                    //         data,

                    //     }).then((res => res))
                    //     console.log('OK7 ====>', response)
                    // });

                    if(response?.status == 201){
                        setState({
                            error:false
                        })
                    }

                    
                } catch (error) {
                    console.error(error)
                    setState({ error: true })
                }
            }
        } else {
            console.log("EEEEEEEEEEE==========>", response1)
        }



    } catch (error) {
        
    }

}