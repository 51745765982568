import { adminAxiosInstance } from "../../config/axiosConfig";



export const getBufferLimites = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/buffer-limites`).then((res => res))
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);

        if (response.status == 200) {
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addBufferLimite = async (bufferLimite, setState) => {
    try {
        const { type,file, description } = bufferLimite

        const response = await adminAxiosInstance.post(`/buffer-limites`, {
            type: type,
            file:file,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            console.log("RESPONSE BUFFER LIMITE ==============>",response )
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const addBufferLimitesList = async (bufferLimites, setState) => {
    try {
        let isDone;
        bufferLimites.forEach(async dist => {
            const { type, description } = dist

            const response = await adminAxiosInstance.post(`/buffer-limites`, {
                type: type,
                description: description,
            }).then((res => res))

            if (response.status != 201) {
                return
            }

        });

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const editBufferLimite = async (bufferLimite, setState) => {
    try {
        const { type,file, description } = bufferLimite

        const response = await adminAxiosInstance.post('/buffer-imite-update/' + bufferLimite.id, {
            type: type,
            file:file,
            description: description,
        }).then((res => res))

        if (response.status == 201) {
            setState(response.data)
        } else {
        }

    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const deleteBufferLimite = async (bufferLimite, setState) => {
    try {

        await adminAxiosInstance.delete('/buffer-limites/' + bufferLimite.id).then((response) => {
            setState(response.data);
        }
        )
    } catch (error) {
        console.error(error)
        setState({ error: true })
    }
}

export const getBufferLimitesByGeoData = async () => {

}