import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { baseFileUrl } from "../../../../../constants";

export const AdminHomeMessageDialog = ({ display, setDisplay, homeMessage, setHomeMessage, title, handleSumitHomeMessage, onInputChange }) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);

  const [imageFile, setImageFile] = useState();
  const [previewImageFile, setPreviewImageFile] = useState()
  const [imgUrl, setImgUrl] = useState();

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    setHomeMessage()
    setPreviewImageFile()
    // handleInitVar()
  };

  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);

  const onShowDataInputChange = (e, name) => {
    const val = (e.target && e.target.checked) || false;
    let _homeMessage = { ...homeMessage };

    _homeMessage[`${name}`] = val;

    setHomeMessage(_homeMessage);
  };




  return (
    <>
      <Dialog
        header={title}
        visible={display}
        modal
        style={{ width: "60vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
        <form onSubmit={handleSumitHomeMessage}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Titre :</label>
                  <input type="text" className="form-control bwd" placeholder="Entrer le titre de votre message" value={homeMessage?.title} onChange={(e) => onInputChange(e, "title")} required />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="form-group">
                <div tabIndex="-1" role="group">
                  <label htmlFor="exampleEmail">Description :</label>
                  <textarea name="" id="" cols="30" rows="5" className="form-control" value={homeMessage?.description} placeholder="Entrer une description du homeMessage" onChange={(e) => onInputChange(e, "description")}></textarea>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" checked={homeMessage?.status} onChange={(e) => onShowDataInputChange(e, "status")} />
                <label class="form-check-label" for="exampleCheck1">Activation</label>
              </div>
            </div>
          </div>
          <div>
            <div className="modal-footer pt-3 d-flex justify-content-between">
              <button type="button" className="btn btn-dark my-0" onClick={() => onHide()}>Fermer</button>
              <button type="submit" className="btn btn-primary my-0" ><i className="fa fa-save mr-2"></i> Enregistrer le message d'accueil</button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};
