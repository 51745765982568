import React, { useState, useEffect, useCallback, useRef } from "react";
import { Dialog } from "primereact/dialog";
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import { strUppercCase } from "../../../../../services/conversion/stringConversion";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataLoaderTailSpin } from "../../../../../components/loader/LoaderComponent";

export const AdminCategoryDialog = ({
  display,
  setDisplay,
  category,
  title,
  handleSumitCategory,
  onInputChange,
}) => {
  const [displayMaximizable, setDisplayMaximizable] = useState(false);
  const [position, setPosition] = useState("center");
  const [max, setMax] = useState(true);

  const onClick = (position) => {
    setDisplay(true);
    if (position) {
      setPosition(position);
    }
  };

  const onHide = (name) => {
    setDisplay(false);
    // handleInitVar()
  };

  useEffect(() => {
    if (display) {
      onClick();
    } else {
      onHide();
    }
  }, [display]);

  return (
    <>
      <Dialog
        header={title}
        visible={display}
        modal
        style={{ width: "50vw" }}
        onHide={() => onHide("displayMaximizable")}
        position="center"
        maximizable={max}
      >
      <form onSubmit={handleSumitCategory}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <div tabIndex="-1" role="group">
                <label htmlFor="name">Nom de la catégorie</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Entrer le nom de la catégorie..."
                  className="form-control"
                  value={category?.name}
                  onChange={(e) => onInputChange(e, "name")}
                />
              </div>
            </div>
            <div className="form-group">
              <div tabIndex="-1" role="group">
                <label htmlFor="description">Description</label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  className="form-control"
                  value={category?.description}
                  placeholder="Entrer une description de la catégorie"
                  onChange={(e) => onInputChange(e, "description")}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="modal-footer pt-3 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-dark my-0"
              onClick={() => onHide()}
            >
              Fermer
            </button>
            <button type="submit" className="btn btn-primary my-0">
              <i className="fa fa-save mr-2"></i> Enregistrer la catégorie
            </button>
          </div>
        </div>
      </form>
      </Dialog>
    </>
  );
};
