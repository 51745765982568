import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import { AdminTechnologyList } from "./AdminTechnologyList";
import {
  DeleteTechnologyDialog,
  DeleteTechnologiesDialog,
  DeleteSTechnologiesDialog,
} from "./AdminTechnologyDeleteDialog";
import {
  addTechnology,
  deleteTechnology,
  editTechnology,
  getTechnologies,
} from "../../../../services/api/admin/technologies/technologyApiService";
import { AdminTechnologyDialog } from "./AdminTechnologyDialog";
import { toast } from "react-toastify";
import {
  toastError,
  toastSuccess,
  toastWarning,
} from "../../../../components/notification/notification";
import { AdminMainPageContainer } from "../../AdminMainPageContainer";
import { AdminTechnologyVIewDialog } from "./AdminTechnologyViewDialog";
import { AdminTechnologyExcelDialog } from "./AdminTechnologyExcelDialog";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { DataLoaderTailSpin } from "../../../../components/loader/LoaderComponent";

export const AdminTechnologyPage = () => {
  const [openTechnologyViewDialog, setOpenTechnologyViewDialog] = useState(
    false
  );
  const [openTechnologyDialog, setOpenTechnologyDialog] = useState(false);
  const [openTechnologyExcelDialog, setOpenTechnologyExcelDialog] = useState(
    false
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteSelected, setopenDeleteSelected] = useState(false);
  const [technologies, setTechnologies] = useState();
  const [technology, setTechnology] = useState();

  const [selectedTechnologies, setSelectedTechnologies] = useState(null);

  const [technologyDialogTitle, setTechnologyDialogTitle] = useState(
    "CREATION D'UNE TECNOLOGIE"
  );
  //CREATE VAR

  const [createResponse, setCreateResponse] = useState();
  const [editResponse, setEditResponse] = useState();
  const [deleteResponse, setDeleteResponse] = useState();

  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    getTechnologies(setTechnologies);
  }, []);

  useEffect(() => {
    console.log("Les technologies :", technology);
  }, [technology]);

  useEffect(() => {
    console.log('ok')
    if (createResponse?.error == false) {
      toastSuccess("Enregistrement de technology effectué avec succès!");
      getTechnologies(setTechnologies);
    } else if (createResponse?.error == true) {
      toastError("Echec de l'enregistrement du technology !");
    }
    setOpenTechnologyDialog(false);
    handleInitVar();
  }, [createResponse]);

  useEffect(() => {
    if (editResponse?.error == false) {
      toastSuccess("Modification du technology effectué avec succès!");
      getTechnologies(setTechnologies);
    } else if (editResponse?.error == true) {
      toastError("Echec de la modification du technology !");
    }
    setOpenTechnologyDialog(false);
    handleInitVar();
  }, [editResponse]);

  useEffect(() => {
    if (deleteResponse?.error == false) {
      toastSuccess("Supression du technology effectué avec succès!");
      getTechnologies(setTechnologies);
    } else if (deleteResponse?.error == true) {
      toastError("Echec de la suppression du technology !");
    }
    setOpenDeleteDialog(false);
    setopenDeleteSelected(false);
    handleInitVar();
  }, [deleteResponse]);

  //INITIALISATION
  const handleInitVar = () => {
    setTechnology();
    setCreateResponse();
    setEditResponse();
    setDeleteResponse();
    setTechnologyDialogTitle("CREATION D'UNE TECNOLOGIE");
    setSelectedTechnologies();
    setOpenTechnologyDialog(false);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _technology = { ...technology };

    _technology[`${name}`] = val;

    setTechnology(_technology);
  };

  const handleSubmitTechnology = async (e) => {
    e.preventDefault();

    if (technology.name.trim()) {
      technology?.description?.trim();
      if (technology.id) {
        await editTechnology(technology, setEditResponse);
      } else {
        await addTechnology(technology, setCreateResponse);
      }
    } else {
      toastWarning("Un champs n'est pas valide !");
    }
  };

  //VISUALISATION
  const handleViewTechnology = (dist) => {
    setTechnology({ ...dist });
    setOpenTechnologyViewDialog(true);
  };

  //EDITION
  const handleEditTechnology = (dist) => {
    setTechnologyDialogTitle("MODIFICATION D'UNE TECNOLOGIE");
    console.log("AZERTY", dist);
    setTechnology({ ...dist });
    setOpenTechnologyDialog(true);
  };

  //SUPPRESSION
  const handleDeleteTechnologyDialog = (dist) => {
    setTechnology({ ...dist });
    setOpenDeleteDialog(true);
  };

  const handleDeleteTechnology = async () => {
    await deleteTechnology(technology, setDeleteResponse);
    setOpenDeleteDialog(false);
  };

  const handleDeleteTechnologies = async () => {
    try {
      selectedTechnologies.forEach(async (technology) => {
        await deleteTechnology(technology, setDeleteResponse);
      });
    } catch (error) {
      toastError(
        "Erreur lors de la suppression du technologies selectionnés !"
      );
    }
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const cols = [
    { field: "code", header: "Code" },
    { field: "name", header: "Nom" },
    { field: "nb_locs", header: "Localités" },
    { field: "nb_locs_cov", header: "Localité couvertes" },
    { field: "nb_locs_not_cov", header: "Localités non couvertes" },
    { field: "nb_pop", header: "Population" },
    { field: "nb_cov_pop", header: "Population couverte" },
    { field: "nb_pop_not_cov", header: "Population non couverte" },
    { field: "description", header: "Description" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default({
          format: "a4",
          orientation: "landscape",
          unit: "cm",
          compressPdf: true,
        });

        doc.autoTable(exportColumns, technologies, {
          headStyles: { fillColor: [255, 140, 0] },
        });
        doc.save("artci-cartodonnees-extract-dist-optical.pdf");
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(technologies);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "artci-cartodonnees-extract-technologies");
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [globalFilter, setGlobalFilter] = useState(null);

  return (
    <>
      <Toast ref={toast} />
      <AdminMainPageContainer
        icon="fas fa-microchip"
        title="Gestion des technologies"
        subTitle="Gestion des technologies"
        description="Créer, Modifier, suprimer des technologies"
        children={
          <>
            <div className="">
              <div className="mb-3 card">
                <div className="card-header-tab card-header">
                  <div className="card-header-title font-size-lg font-weight-normal">
                    <i className="header-icon lnr-charts icon-gradient bg-happy-green"></i>
                    Liste des technologies
                  </div>
                  <div className="btn-actions-pane-right text-capitalize">
                    <button
                      className="btn-wide btn-outline-2x mr-md-2 btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setOpenTechnologyDialog(true);
                        setTechnology();
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Créer une technologie
                    </button>
                  </div>
                </div>
                <div className="no-gutters row">
                  <div className="card w-100">
                    <div className="card-body">
                      {technologies?.length > 0 ? (
                        <AdminTechnologyList
                          technologies={technologies}
                          handleEditTechnology={handleEditTechnology}
                          handleDeleteTechnologyDialog={
                            handleDeleteTechnologyDialog
                          }
                          setopenDeleteSelected={setopenDeleteSelected}
                          exportCSV={exportCSV}
                          exportPDF={exportPdf}
                          exportExcel={exportExcel}
                          dt={dt}
                          selectedTechnologies={selectedTechnologies}
                          setSelectedTechnologies={setSelectedTechnologies}
                          handleViewTechnology={handleViewTechnology}
                        />
                      ) : (
                        <>
                          <div className="container px-1 px-md-5 px-lg-1 mbody">
                            <DataLoaderTailSpin />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdminTechnologyVIewDialog
              display={openTechnologyViewDialog}
              setDisplay={setOpenTechnologyViewDialog}
              technology={technology}
            />

            <AdminTechnologyDialog
              display={openTechnologyDialog}
              setDisplay={setOpenTechnologyDialog}
              handleSumitTechnology={handleSubmitTechnology}
              technology={technology}
              title={technologyDialogTitle}
              handleInitVar={handleInitVar}
              onInputChange={onInputChange}
              setTechnology={setTechnology}
            />

            <DeleteTechnologyDialog
              display={openDeleteDialog}
              setDisplay={setOpenDeleteDialog}
              handleDeletetechnology={handleDeleteTechnology}
              technology={technology}
            />
            <DeleteSTechnologiesDialog
              display={openDeleteSelected}
              setDisplay={setopenDeleteSelected}
              handleDeleteSelectedTechnology={handleDeleteTechnologies}
            />
          </>
        }
      />
    </>
  );
};
