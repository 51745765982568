import { adminAxiosInstance } from "../../../config/axiosConfig"



export const getLocalities = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/localities`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log("données :",response.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addLocality = async (locality, setState) => {
    try {
        const {sub_pref_id,name,longitude,latitude,fialbility,description} = locality

        const response = await adminAxiosInstance.post(`/localities`, {
            sub_pref_id:sub_pref_id,
            name: name,
            longitude:longitude,
            latitude:latitude,
            fialbility:fialbility,
            description: description,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Locality enregistré :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const editLocality = async (locality, setState) => {
    try {
        const {sub_pref_id,name,longitude,latitude,fialbility,description} = locality

        await adminAxiosInstance.put('/localities/'+locality.id, {
            sub_pref_id: sub_pref_id,
            name: name,
            longitude:longitude,
            latitude:latitude,
            fialbility:fialbility,
            description: description,
        }).then((response) => {
            console.log('Vérification de l\'edition', response.data)
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const deleteLocality = async (locality, setState) => {
    try {

        await adminAxiosInstance.delete('/localities/'+locality.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getLocalitiesByGeoData = async(setState,id) => {
    try {
        const response = await adminAxiosInstance.get('/get-data-file/geojson/all_data2023.geojson').then((res => res))

        if (response.status == 200){
            const _data = response.data;
            const _districts = _data.features.filter(feature => feature.id == id)
            const _properties = _districts[0]?.properties;
            setState(_properties)
        }

    } catch (error) {
        
    }
}

export const getLocalityById = async (id,setState) => {
    try {
        const response = await adminAxiosInstance.get('/search-loc/'+id).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log("données ====> :",response.data.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data[0])
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const getAllLocalityData = async (setState) => {
    try {
        const response = await adminAxiosInstance.get('/export-all-localities').then((res => res) )

        setState([]);
        
        if(response.status == 200) {
            console.log("données :",response.data)
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}