import { Link } from "react-router-dom";
import { AdminHeader } from "../../components/layout/header/AdminHeader";
import { AdminSideBar } from "../../components/layout/sidebar/AdminSidebar";
import { lazy, useEffect, useState } from "react";
// import { ExportComponent } from "../../components/modals/export/ExportComponent";
import './adminMainPageContainer.css'
// import { Error404ModalComponent } from "../../components/modals/error/Error404ModalComponent";
import { AdminFooter } from "../../components/layout/footer/AdminFooter";
import ExportComponent from "../../components/modals/export/ExportComponent";
import Error404ModalComponent from "../../components/modals/error/Error404ModalComponent";


export const AdminMainPageContainer = ({ children, icon, title, subTitle, description }) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [levelExport, setLevelExport] = useState(null);
  const [showOnSide, setShowOnSide] = useState(null);
  const [showError4O4, setShowError4O4] = useState(false);

  useEffect(() => {
    let _show = { ...showOnSide };

    _show["showCovGest"] = true;

    setShowOnSide(_show);
  }, []);

  const handleInitExportData = (level) => {
    if (level != 5) {
      setShowExportDialog(true);
    }
    setLevelExport(level);
  }

  return (
    <>
      <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar">
        <AdminHeader setShowError4O4={setShowError4O4} handleInitExportData={handleInitExportData} />
        <main className="">
          <AdminSideBar setShowError4O4={setShowError4O4} />
          <div className="main-dash">
            <div className="p-3">
              <div>
                <div className="page-title-heading m-0 ">
                  <div>
                    <div className="page-title-subheading opacity-10">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/admin">Tableau de bord</Link>
                          </li>
                          <li
                            aria-current="page"
                            className="active breadcrumb-item"
                          >
                            {title}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="app-page-title p-2 m-0 mb-3">
                  <div className="page-title-wrapper">
                    <div className="page-title-heading">
                      <div className="page-title-icon">
                        <i className={icon}></i>
                      </div>
                      <div>
                        {subTitle}
                        <div className="page-title-subheading">
                          {description}
                        </div>
                      </div>
                    </div>
                    <div className="page-title-actions"></div>
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>
        </main>
        <AdminFooter/>
      </div>

      <ExportComponent display={showExportDialog} setDisplay={setShowExportDialog} level={levelExport}/>
      <Error404ModalComponent  display={showError4O4} setDisplay= {setShowError4O4}/>
    </>
  );
};
