import { adminAxiosInstance } from "../../config/axiosConfig"



export const getNewsLetters = async (setState) => {
    try {
        const response = await adminAxiosInstance.get(`/newsletters`).then((res => res) )
        // => {
        //     console.log("Données sur la liste des categories", response.data)
        //     setState(response.data)
        // }
        setState([]);
        
        if(response.status == 200) {
            console.log("reponse ===>",response.data.data)  
            //console.log("liste des categories :", response.data)
            setState(response.data.data)
        }
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const addNewsLetter = async (newsLetter, setState) => {
    try {
        const {category_id,title,description,imageFile,pdfFile,video_link} = newsLetter

        const response = await adminAxiosInstance.post(`/newsletters`, {
            category:category_id,
            title:title,
            description: description,
            image: imageFile,
            file: pdfFile,
            video: video_link,
        }).then((res => res))

        if(response.status == 201) {
                console.log(response.data)
                console.log("Trançon enregistré :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}

export const editNewsLetter = async (newsLetter, setState) => {

    try {
        const {category_id,title,description,imageFile,pdfFile,video_link} = newsLetter

        console.log("Soumission =======++++>", newsLetter)

        const response = await adminAxiosInstance.post('/newsletter-update/'+newsLetter.id, {
            category:category_id,
            title:title,
            description: description,
            image: imageFile,
            file: pdfFile,
            video: video_link,
        }).then((res => res))

        console.log("response ========+++>", response)
        if(response.status == 201) {
                console.log(response.data)
                console.log("newletter modifié :", response.data)
                setState(response.data)
        }else{
        }

    } catch (error) {
        console.error(error)
        setState({error:true})
    }

}

export const deleteNewsLetter = async (newsLetter, setState) => {
    try {

        await adminAxiosInstance.delete('/newsletters/'+newsLetter.id).then((response) => {
            setState(response.data)
        }
        )
    } catch (error) {
        console.error(error)
        setState({error:true})
    }
}